import React, {useEffect, useState} from 'react';
import {Center, Group, Pagination, Stack, Text, useMantineTheme} from '@mantine/core';
import {paginationEndCount, paginationStartCount, paginationTotalPages} from "../../util/pagination";
import {useTranslation} from "react-i18next";
import {SimpleBox} from "../simpleBox/SimpleBox";
import classes from './Paginator.module.css';
import useMedia from "../../pages/useMedia";

/**
 * Paginator
 */
export const Paginator = ({page,
                          infoColor = "tertiary",
                          color = "secondary",
                          paginatorClassNames = {
                                control: classes.paginationcontrol,
                                dots: classes.paginationdots
                            },
                          wrap = true,
                          wrapOn = "sm",
                          size,
                          gap = "sm",
                          withControls= true,
                          paginationSize,
                          selectedCount,
                          totalCount,
                          onPageChange = () => {}}) => {

    const { t } = useTranslation();
    const theme = useMantineTheme();

    const { isSm } = useMedia();

    function textToDisplay() {

        if(selectedCount > paginationSize) {

            if(selectedCount === totalCount) {

                return t(`common.paginationTotal`, {
                        startCount: paginationStartCount(page, paginationSize),
                        endCount: paginationEndCount(page, paginationSize, selectedCount),
                        totalCount: totalCount
                    }
                );
            }
            else {

                return t(`common.paginationSelected`, {
                    startCount: paginationStartCount(page, paginationSize),
                    endCount:  paginationEndCount(page, paginationSize, selectedCount),
                    selectedCount: selectedCount,
                    totalCount: totalCount}
                )
            }
        }
        else {
            return t(`common.pagination`, {
                    count: selectedCount,
                    totalCount: totalCount
                }
            );
        }
    }

    const Content = () => <>
        <SimpleBox color={infoColor} flex={1}>
            <Text size={"xs"}>
                {textToDisplay()}
            </Text>
        </SimpleBox>

        {selectedCount <= paginationSize ? null :
            <Center>
                <Pagination total={paginationTotalPages(selectedCount, paginationSize)}
                            gap={gap}
                            color={color}
                            size={size === undefined ? isSm ? "md" : "lg" : size}
                            withControls={withControls}
                            value={page}
                            onChange={onPageChange}
                            classNames={paginatorClassNames}/>
            </Center>
        }
    </>

    return wrap ?
        <>
            <Group justify={"center"} visibleFrom={wrapOn}>
                <Content/>
            </Group>
            <Stack align={"stretch"} justify={"flex-start"} hiddenFrom={wrapOn}>
                <Content/>
            </Stack>
        </>
        :
        <Content/>
}

/**
 * PaginatorAZ
 *
 * @param page
 * @param color
 * @param paginationSize
 * @param selectedCount
 * @param totalCount
 * @param onPageChange
 * @returns {Element}
 * @constructor
 */
export const PaginatorAZ = ({page, color, letters, totalPages, selectedCount, totalCount, onPageChange = () => {}}) => {

    const [idPrefix, setIdPrefix] = useState(`paginator-${Math.random()}`);
    const { t } = useTranslation();

    useEffect(() => {

        // TODO remove this solution
        letters.map((letter, index) => {
            let component = document.getElementById(`${idPrefix}-${index}`);

            if(component) {
                component.innerHTML = letter
            }
        })

    }, [page, letters]);

    function textToDisplay() {

        if(selectedCount > totalCount) {
            //
            // if(selectedCount === totalCount) {
            //
            //     return t(`common.paginationTotal`, {
            //             startCount: paginationStartCount(page, paginationSize),
            //             endCount: paginationEndCount(page, paginationSize, selectedCount),
            //             totalCount: totalCount
            //         }
            //     );
            // }
            // else {
            //
            //     return t(`common.paginationSelected`, {
            //         startCount: paginationStartCount(page, paginationSize),
            //         endCount:  paginationEndCount(page, paginationSize, selectedCount),
            //         selectedCount: selectedCount,
            //         totalCount: totalCount}
            //     )
            // }
        }
        else {
            return t(`common.pagination`, {
                    count: selectedCount,
                    totalCount: totalCount
                }
            );
        }
    }

    return (
        <Group justify="space-between" pb={"lg"}>

            <SimpleBox color={color} flex={1}>
                <Text size={"xs"}>
                    {textToDisplay()}
                </Text>
            </SimpleBox>

            {totalPages === 0 ? null :
                <Pagination total={totalPages}
                            color="secondary"
                            size={"lg"}
                            value={page}
                            onChange={onPageChange}
                            getItemProps={(p) => ({
                                id: `${idPrefix}-${p - 1}`
                            })}
                            classNames={{
                                control: classes.paginationcontrol,
                                dots: classes.paginationdots
                            }}/>
            }
        </Group>
    );
}