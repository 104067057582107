import React from "react";
import {useTranslation} from "react-i18next";
import {usePaymentContext} from "./PaymentContext";
import {Anchor, Button, Group, Stack, Text} from "@mantine/core";
import {SimpleBox} from "../simpleBox/SimpleBox";
import {Icon} from "../icons/Icons";
import {StripeCredits} from "../credits/Credits";
import classes from './Payment.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";

/**
 * PaymentSubscription
 */
export const PaymentSubscription = ({priceId, priceUsd, pricePeriod, disabled, color="secondary"}) => {

	const {subscription} = usePaymentContext();

	const { t } = useTranslation();


	const Price = () => {

		return (
			<Group gap={"xs"} align={"flex-end"} justify={"flex-start"} wrap={"nowrap"} style={{overflow: "hidden"}}>
				<Text size={"24px"} fw={700} wrap={"nowrap"}>
					{priceUsd}
				</Text>
				{/*<Stack gap={0} align={"flex-start"} wrap={"nowrap"}>*/}
					<Text wrap={"nowrap"}>{t(`payment.${pricePeriod}`)}</Text>
					{/*<Text size={"xs"} wrap={"nowrap"}>{t(`payment.${pricePeriod}Discount`)}</Text>*/}
				{/*</Stack>*/}
			</Group>
		)
	}
	return disabled ?
		<Price />
		:
		<Button color={color} variant={"filled"} size="lg"
				onClick={() => subscription(priceId)}
				style={{height: "90px"}}
				p={"md"}
				mb={2}
				justify="space-between"
				fullWidth
				rightSection={<FontAwesomeIcon icon={faChevronRight} size={"2md"}/>}
				>
			<Price />
		</Button>
};

/**
 * PaymentDonateLink
 */
export const PaymentDonateLink = () => {

	const {paymentContributeLink} = usePaymentContext();

	const { t } = useTranslation();

	return (
		<Anchor target={"_blank"} underline="never"
				href={paymentContributeLink}
			>
			<SimpleBox color={"tertiary"}>
				<Group justify={"space-between"} align={"center"}>
					<Icon name={"contribute"} className={classes.paymentcontributeicon}/>
					<Stack gap={0} flex={1}>
						<Text size={"sm"} flex={1}>
							{t("payment.donateDescription")}
						</Text>
						<Group justify="space-between" pt={"md"}>
							<StripeCredits i18nKey={"common.stripeAttribution"} disabled={true}/>
							<Button color={"tertiary"} variant={"light"}>{t("payment.donate")}</Button>
						</Group>
					</Stack>
				</Group>
			</SimpleBox>
		</Anchor>
	);
};

/**
 * PaymentCustomerManagement
 */
export const PaymentCustomerManagement = ({color="tertiary"}) => {

	const {customerSession} = usePaymentContext();

	const { t } = useTranslation();

	return (
		<Button color={color} variant={"light"}
				onClick={() => customerSession()}
			>
			{t("payment.manage")}
		</Button>
	);
};