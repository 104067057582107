import React from 'react';
import { motion } from "motion/react";

/**
 * Motion
 */
const Motion = ({children, initial, animate, delay = 0.4, duration = 0.1}) => {

	return (
		<motion.div
			initial={initial}
			animate={animate}
			transition={{
				delay: delay,
				duration: duration,
				ease: "easeOut"
			}}
		>
			{children}
		</motion.div>
	)
}

export default Motion;
