import React, {useEffect, useState} from 'react';
import {Page, PageCard, PageTitle} from "../../components/page/Page";
import useKey from "../useKey";
import {useLoadingContext} from "../../components/loading/LoadingContext";
import {useTranslation} from "react-i18next";
import {useAccountContext} from "../../components/account/AccountContext";
import {
    ActionIcon,
    Box,
    Button, Card, Checkbox, Collapse,
    Container, Divider,
    Group, Image, Modal, Overlay, Pill, ScrollArea, SimpleGrid,
    Space,
    Stack,
    Table,
    Tabs,
    Text,
    TextInput,
    Title, Transition,
    VisuallyHidden
} from "@mantine/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ScrollTab} from "../../components/scrollTab/ScrollTab";
import {IngredientIconTitle, IngredientText} from "../ingredient/IngredientLink";
import {MoleculeIconTitle} from "../molecule/MoleculeLink";
import {RecipeIconTitle, recipeNavigate} from "../recipe/RecipeLink";
import {AdvertisingGrid} from "../../components/advertising/Advertising";
import {SimpleBox} from "../../components/simpleBox/SimpleBox";
import {Ingredient09} from "../ingredient/IngredientList";
import {Molecule09} from "../molecule/MoleculeList";
import {Recipe09} from "../recipe/RecipeList";
import useIngredientAll from "../ingredient/useIngredientAll";
import useMoleculeAll from "../molecule/useMoleculeAll";
import useRecipeAll from "../recipe/useRecipeAll";
import useResult from "../useResult";
import {StripedTable} from "../../components/stripedTable/StripedTable";
import classes from "./AccountPage.module.css";
import {faCircleUser} from "@fortawesome/free-regular-svg-icons";
import {useForm} from "@mantine/form";
import {useTimerManagerContext} from "../../components/timermanager/TimerManagerContext";
import {Auth0Credits, StripeCredits} from "../../components/credits/Credits";
import {useDisclosure} from "@mantine/hooks";
import {alphabeticComparator, convertToFraction, stringToImageSrc} from "../../util/utils";
import {daysFromNow, toDate, toDateTime} from "../../util/time";
import {
    PaymentCustomerManagement
} from "../../components/payment/Payment";
import {homeNavigate} from "../home/HomeLink";
import {useNavigate, useParams} from "react-router-dom";
import {FeaturesPlan} from "../../components/features/Features";
import useMedia from "../useMedia";
import useRecipeImage from "../recipe/useRecipeImage";
import useRecipe from "../recipe/useRecipe";
import {
    faChevronDown,
    faChevronRight,
    faHandHoldingHeart,
    faMinus,
    faPlus,
    faStopwatch, faTrash,
    faUser
} from "@fortawesome/free-solid-svg-icons";
import i18n, {localized, parameterLanguage} from "../../i18n";
import {Icon} from "../../components/icons/Icons";
import {sortedCategories} from "../recipe/RecipeUtil";
import {DeleteModal} from "../../components/delete/DeleteModal";

/**
 * @param a
 * @param b
 * @returns {number}
 */
function sortComparator(a, b) {

    const sortComparison = a.sort - b.sort;
    if (sortComparison !== 0) {
        return sortComparison;
    }

    return localized(a, "name").localeCompare(localized(b, "name"));
}

/**
 * User
 */
const User = () => {

    const {userAccount, updateUserAccount, logoutAccount} = useAccountContext();

    const { isSm } = useMedia();

    const {t} = useTranslation();

    const form = useForm({
        mode: 'uncontrolled',
        initialValues: {
            name: userAccount.getName(),
            picture: userAccount.getPicture(),
        },
        validate: {
            name: (value) => value ? null : t("common.fieldRequired"),
            picture: (value) => value ? null : t("common.fieldRequired"),
        },
    });

    // https://scontent-zrh1-1.xx.fbcdn.net/v/t39.30808-1/311605785_452580250299267_3831760712805595942_n.png?stp=dst-png_s200x200&_nc_cat=104&ccb=1-7&_nc_sid=f4b9fd&_nc_ohc=C9Gk7yuDepgQ7kNvgGb7uIZ&_nc_zt=24&_nc_ht=scontent-zrh1-1.xx&_nc_gid=AAVWCUaciFUVadfNlHEAdIz&oh=00_AYARcxTQpghvk2T7mvOI7GgvwTWnQbl29OUuGN7qzOg6rQ&oe=672834A4

    // https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=10235088270057452&height=50&width=50&ext=1732701655&hash=AbZsH2vo5OKzDgCHZIIqlrXn

    return (
        <SimpleBox color={"secondary"} mt={"lg"}>

            <form onSubmit={form.onSubmit((values) => {
                userAccount.setName(values.name);
                userAccount.setPicture(values.picture);
                updateUserAccount(userAccount);
            })}>

                <Title order={2}>{t('account.profile')}</Title>
                <Space h="lg"/>
                <Text>{t("account.profileDescription")}</Text>
                <Space h="lg"/>
                <SimpleGrid cols={2}>
                    <Stack>
                        <TextInput
                            label={t("account.name")}
                            placeholder={t("account.name")}
                            key={form.key('name')}
                            {...form.getInputProps('name')}
                            classNames={{
                                input: classes.accountinputinput,
                                label: classes.accountinputlabel
                            }}
                        />
                        <TextInput
                            label={t("account.picture")}
                            placeholder={t("account.picture")}
                            key={form.key('picture')}
                            {...form.getInputProps('picture')}
                            classNames={{
                                input: classes.accountinputinput,
                                label: classes.accountinputlabel
                            }}
                        />
                    </Stack>
                    <Stack>
                        {userAccount.getCreated() &&
                            <Stack gap={"xs"}>
                                <Text size={"sm"} fw={700}>
                                    {t("common.created")}
                                </Text>
                                <Text size={"sm"}>
                                    {toDateTime(userAccount.getCreated())}
                                </Text>
                            </Stack>
                        }
                        {userAccount.getUpdated() &&
                            <Stack gap={"xs"}>
                                <Text size={"sm"} fw={700}>
                                    {t("common.modified")}
                                </Text>
                                <Text size={"sm"}>
                                    {toDateTime(userAccount.getUpdated())}
                                </Text>
                            </Stack>
                        }
                        {userAccount.getRoles().length === 0 ? null :
                            <>
                                <Text size={"sm"} fw={700}>{t("account.roles")}</Text>
                                <Group gap={"xs"}>
                                    {userAccount.getRoles().sort(alphabeticComparator)
                                        .map((role, index) => (
                                            <Pill key={`role-${index}`} mr={0}
                                                  classNames={{
                                                      root: classes.pillroot
                                                  }}>
                                                {role}
                                            </Pill>
                                        ))}
                                </Group>
                            </>
                        }
                    </Stack>
                </SimpleGrid>
                <Space h="lg"/>
                <Group justify={!isSm ? "flex-end" : "space-between"} pt={"md"}>
                    {isSm &&
                        <Button color={"secondary"} miw={140} onClick={() => logoutAccount()}>{t("common.logout")}</Button>
                    }
                    <Button color={"secondary"} miw={140} type="submit">{t("common.save")}</Button>
                </Group>
            </form>
        </SimpleBox>
    )
}

/**
 * Payment
 */
const Payment = () => {

    const {userAccount} = useAccountContext();

    const {t} = useTranslation();

    const color = userAccount?.isAdmin() || userAccount?.isPremium() || userAccount?.isStudio() ? "tertiary" : "secondary";

    return (
        <SimpleBox color={color} mt={"lg"}>

            <Stack justify="space-between" gap={"lg"} h={"100%"}>

                <Stack justify="flex-start" gap={"lg"}>
                    <Title order={2}>{t('account.subscription')}</Title>
                    <Text>{t("account.subscriptionDescription")}</Text>
                    {userAccount.getProducts().length > 0 &&
                        <StripedTable stripedColor={"tertiary"} style={{color: `var(--mantine-color-${color}-9)`}} className={classes.table}>
                            <Table.Thead>
                                <Table.Tr>
                                    <Table.Th flex={1}>
                                        {t("account.subscription")}
                                    </Table.Th>
                                    <Table.Th ta={"right"}>
                                        {t("account.from")}
                                    </Table.Th>
                                    <Table.Th ta={"right"}>
                                        {t("account.to")}
                                    </Table.Th>
                                </Table.Tr>
                            </Table.Thead>
                            <Table.Tbody>
                                {userAccount.getProducts().map((product, index) => (
                                    <Table.Tr key={`product-${index}`}>
                                        <Table.Td style={{verticalAlign: "top"}}>
                                            <Group gap={4} wrap={"nowrap"} align={"flex-start"}>
                                                {/*<FontAwesomeIcon icon={faCrown} style={{paddingTop: "2px"}}/>*/}
                                                <Stack gap={0}>
                                                    <Text size={"sm"} ta={"left"} fw={700}>{product.name}</Text>
                                                    <Text size={"xs"} ta={"left"}>{t("account.subscriptionExpiration", {days: daysFromNow(product.validTo)})}</Text>
                                                </Stack>
                                            </Group>
                                        </Table.Td>
                                        <Table.Td ta={"right"} style={{verticalAlign: "top"}}>
                                            <Text size={"sm"} ta={"right"}>{toDate(product.validFrom)}</Text>
                                        </Table.Td>
                                        <Table.Td ta={"right"} style={{verticalAlign: "top"}}>
                                            <Text size={"sm"} ta={"right"}>{toDate(product.validTo)}</Text>
                                        </Table.Td>
                                    </Table.Tr>
                                ))}
                            </Table.Tbody>
                        </StripedTable>
                    }
                </Stack>

                <Group justify={"flex-end"} pt={"md"} gap={"sm"}>
                    {userAccount.getProducts().length > 0 ?
                        <PaymentCustomerManagement color={color}/>
                        :
                        <FeaturesPlan targetComponent={
                            <Button color={color} variant={"light"}
                                    leftSection={ <FontAwesomeIcon icon={faHandHoldingHeart} style={{width: "18px", height: "18px", fill: "var(--mantine-color-white)"}}/>}>{t("feature.checkPlans")}</Button>
                        }/>
                    }
                </Group>

            </Stack>
        </SimpleBox>
    )
}

/**
 * Dangerous
 */
const Dangerous = () => {

    const {deleteAccount} = useAccountContext();

    const {t} = useTranslation();

    return (
        <SimpleBox color={"primary"} mt={"lg"}>

            <Title order={2}>{t('account.dangerous')}</Title>
            <Space h="lg"/>

            <Stack gap={"md"}>
                <Text>{t("account.deleteAccountDescription")}</Text>

                <DeleteModal
                    title={t("account.deleteAccount")}
                    description={t("account.deleteAccountModal")}
                    deleteText={t("account.deleteAccount")}
                    targetComponent={
                        <Group justify={"flex-end"}>
                            <Button miw={140}>{t("account.deleteAccount")}</Button>
                        </Group>
                    }
                    onDelete={() => deleteAccount()}/>
            </Stack>

        </SimpleBox>
    )
}

/**
 * Timers
 */
const TimersCount = ({recipeId}) => {

    const { timers } = useTimerManagerContext();

    const filteredTimers = Object.keys(timers).filter(
        (key) => timers[key].payload.recipe.recipeId === recipeId
    );

    return (
        <Text inherit>{filteredTimers?.length}</Text>
    );
}

/**
 * Timers
 */
const Timers = ({recipeId}) => {

    const { removeTimer, getTimeLeft, timers } = useTimerManagerContext();

    const {t} = useTranslation();

    const filteredTimers = Object.keys(timers).filter(
        (key) => timers[key].payload.recipe.recipeId === recipeId
    );

    // Format time as mm:ss
    const formatTime = (seconds) => {
        const mins = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    };

    return (
        <StripedTable stripedColor={"secondary"} className={classes.table}>
            <Table.Thead>
                <Table.Tr>
                    <Table.Th>
                        {t('recipe.preparation')}
                    </Table.Th>
                    <Table.Th style={{textAlign: "right", verticalAlign: "top"}}>{t('recipe.timeLeft')}</Table.Th>
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
                {filteredTimers?.length > 0 ?
                    filteredTimers.map((key) => (
                        <Table.Tr style={{cursor: "pointer"}}>
                            <Table.Td>
                                <Text>
                                    {`${timers[key].payload.instruction.sort} - ${localized(timers[key].payload.instruction, "name")}`}
                                </Text>
                            </Table.Td>
                            <Table.Td ta={"right"} >
                                <Text>
                                    {formatTime(getTimeLeft(key))}
                                </Text>
                            </Table.Td>
                        </Table.Tr>
                    ))
                    :
                    <Table.Tr>
                        <Table.Td colSpan={2}>
                            <Text>
                                {t("recipe.noTimers")}
                            </Text>
                        </Table.Td>
                    </Table.Tr>
                }
            </Table.Tbody>
        </StripedTable>
    );
}

/**
 * Image169WithZoom
 */
const Image169WithZoom = ({src, recipe, showCategory}) => {

    const {t} = useTranslation();
    const navigate = useNavigate();

    const { isSm } = useMedia();

    const [hovered, setHovered] = useState(false);

    return (
        <Box className={classes.workrecipeimage169root} pb={!isSm ? "25%" : "56.25%"}
             onClick={() => recipeNavigate(navigate, recipe)}
             onMouseEnter={() => setHovered(true)}
             onMouseLeave={() => setHovered(false)}>
            <Transition
                mounted={true}
                transition="scale"
                timingFunction="ease-in-out">
                {(styles) => (
                        <Image
                            src={src}
                            className={classes.workrecipeimage169image}
                            style={{
                                transform: hovered? 'scale(1.1)' : 'scale(1)',  // Scale on hover
                                transition: 'transform 0.15s ease-in-out',  // Smooth transition
                            }}
                            withPlaceholder
                        />
                )}
            </Transition>

            <Container className={classes.workrecipeimage169overlay} p={"md"}>
                <Overlay color="var(--mantine-color-secondary-9)" backgroundOpacity={!hovered ? 0.8 : 0.95} blur={0} zIndex={1}/>
                <Group gap={0} justify="space-between" wrap={"nowrap"} className={classes.workrecipeimage169title}>
                    <Group align="flex-start" gap={6} wrap={"nowrap"} >

                        <Stack gap={0}>
                            <Text c={"white"} lineClamp={1} pb={4}>
                                {localized(recipe, 'name')}
                            </Text>
                            {showCategory ?
                                <Text c={"white"} size={'xs'} opacity={0.75}>
                                    {sortedCategories(recipe.categories)
                                        .map((category, index) => localized(category, 'name'))
                                        .join(" / ")}
                                </Text>
                                :
                                // <Text c={"white"} size={'xs'} opacity={0.75}>
                                //     &nbsp;
                                // </Text>
                                null
                            }
                        </Stack>
                    </Group>
                    <FontAwesomeIcon icon={faChevronRight} color={"white"} />
                </Group>
            </Container>
        </Box>
    );
};

/**
 * Kitchen
 */
const Kitchen = ({settings, onReset = () => {}}) => {

    const {t} = useTranslation();

    /**
     * WorkRecipe
     */
    const WorkRecipe = ({recipeId}) => {

        const { userAccount, updateUserAccountSettings } = useAccountContext();

        const {t} = useTranslation();

        const [recipeSettings, setRecipeSettings] = useState(settings.getRecipeById(recipeId) || {});
        const [selectedIngredients, setSelectedIngredients] = useState(recipeSettings.ingredients || []);
        const [serving, setServing] = useState(1);

        const [collapseOpened, { toggle: collapseToggle }] = useDisclosure(false);

        const { isSm } = useMedia();

        const {	data: dataItem, isLoaded: isLoadedItem, ingredientIds,
            reset: resetItem, refetch: refetchItem} =
            useRecipe({
                enabled: !!recipeId,
                recipeId: recipeId,
                onSuccess: (recipe) => {
                    setServing(recipeSettings.serving || recipe.serving);
                },
                onError: (error) => {
                    console.error(error);
                    resetRecipeSettings();
                }
            })

        const {	data: dataItemImage, isLoaded: isLoadedItemImage,
            reset: resetItemImage, refetch: refetchItemImage} =
            useRecipeImage({
                enabled: isLoadedItem && dataItem?.recipeId !== undefined,
                recipeId: dataItem.recipeId
            })

        const {	data: dataIngredients, isLoaded: isLoadedIngredients, entityIds,
            reset: resetIngredients, refetch: refetchIngredients} =
            useIngredientAll({
                enabled: isLoadedItem && ingredientIds?.length > 0,
                ingredientIds: ingredientIds
            })

        const {isSuccess, isError} =
            useResult({
                isSuccess: isLoadedItem && isLoadedItemImage && isLoadedIngredients,
                onSuccess: () => {

                }
            })

        /**
         * allIngredientsInBasket
         */
        const allIngredientsInBasket = () => {
            return selectedIngredients.length === dataIngredients.length;
        }

        /**
         * resetRecipeSettings
         */
        const resetRecipeSettings = () => {
            settings.removeRecipe(recipeId);
            updateUserAccountSettings(userAccount);
            onReset();
        }

        /**
         * Increment serving
         */
        const incrementServing = () => {

            if(!recipeSettings.serving) {
                recipeSettings.serving = serving;
            }

            recipeSettings.serving = recipeSettings.serving + 1;
            updateUserAccountSettings(userAccount);
            setServing(recipeSettings.serving);
        }

        /**
         * Decrement serving
         */
        const decrementServing = () => {

            if(!recipeSettings.serving) {
                recipeSettings.serving = serving;
            }

            if(recipeSettings.serving > 1) {
                recipeSettings.serving = recipeSettings.serving - 1;
                updateUserAccountSettings(userAccount);
                setServing(recipeSettings.serving);
            }
        }

        /**
         * @param ingredientId
         */
        const handleCheckboxChange = (ingredientId) => {

            if(!recipeSettings.ingredients) {
                recipeSettings.ingredients = [];
            }

            // Update settings.ingredients based on whether ingredientId is present or not
            if (recipeSettings.ingredients.includes(ingredientId)) {
                // Remove ingredientId if it already exists
                recipeSettings.ingredients = recipeSettings.ingredients.filter(itemId => itemId !== ingredientId);
            } else {
                // Add ingredientId if it doesn't exist
                recipeSettings.ingredients = [...recipeSettings.ingredients, ingredientId];
            }

            updateUserAccountSettings(userAccount);

            // Update selected ingredients state with the new ingredients list
            setSelectedIngredients([...recipeSettings.ingredients]);
        };

        /**
         * @param entities
         * @returns {undefined|*|string}
         */
        function getIngredient(ingredientId) {

            if (!dataIngredients) {
                return undefined;
            }

            let result = dataIngredients.filter(ingredient => ingredient.ingredientId === ingredientId);

            if(result.length >= 0) {
                return result[0];
            }

            return undefined;
        }

        return isSuccess &&
            <Card shadow="none" padding="0"
                  style={{height: "100%"}}
                  bg={"var(--mantine-color-secondary-outline-hover)"}
            >

                <Card.Section>
                    <Image169WithZoom src={stringToImageSrc(dataItemImage)} recipe={dataItem} showCategory/>
                </Card.Section>

                <Stack align="center" justify="flex-start" onClick={collapseToggle} style={{cursor: "pointer", color: "var(--mantine-color-secondary-9)"}} pt={"md"} gap={"md"}>
                    <Group align="flex-start" justify="center" wrap={"nowrap"} w={!isSm ? "50%": "100%"} gap="0" p={0}>
                        <Stack align="stretch" justify="center" gap="4" w={"33%"}>
                            <Group justify="center" gap="6">
                                <FontAwesomeIcon icon={faUser}/>
                                <Text ta={"center"}>{recipeSettings.serving || 1}</Text>
                            </Group>
                            <Text ta={"center"} size={'xs'} opacity={0.75}>
                                {t("recipe.serving")}
                            </Text>
                        </Stack>
                        <Divider w={1} orientation="vertical" color={"var(--mantine-color-secondary-9)"} opacity={0.1}/>
                        <Stack align="stretch" justify="center" gap="4" w={"33%"}>
                            <Group justify="center" gap="6">
                                <Icon name={"leaf"} style={{width: "18px", height: "18px", fill: `var(--mantine-color-secondary-${allIngredientsInBasket() ? "6" : "9"})`, marginBottom: "-5px"}}/>
                                <Text ta={"center"} c={`var(--mantine-color-secondary-${allIngredientsInBasket() ? "6" : "9"})`}>{recipeSettings.ingredients?.length || 0}/{dataItem.ingredientsCount}</Text>
                            </Group>
                            <Text ta={"center"} size={'xs'} opacity={0.75} c={`var(--mantine-color-secondary-${allIngredientsInBasket() ? "6" : "9"})`}>
                                {t("recipe.basket")}
                            </Text>
                        </Stack>
                        <Divider w={1} orientation="vertical" color={"var(--mantine-color-secondary-9)"} opacity={0.1}/>
                        <Stack align="stretch" justify="center" gap="4" w={"33%"}>
                            <Group justify="center" gap="6">
                                <FontAwesomeIcon icon={faStopwatch}/>
                                <TimersCount recipeId={recipeId}/>
                            </Group>
                            <Text ta={"center"} size={'xs'} opacity={0.75}>
                                {t("recipe.timers")}
                            </Text>
                        </Stack>
                    </Group>
                    <FontAwesomeIcon icon={faChevronDown} style={{paddingBottom: "var(--mantine-spacing-md)"}}/>
                </Stack>

                <Collapse in={collapseOpened} p={"md"} style={{color: "var(--mantine-color-secondary-9)"}}>
                    <Box bg={"var(--mantine-color-secondary-outline-hover)"} style={{padding: "var(--mantine-spacing-md)", borderRadius: "var(--mantine-radius-md)"}}>
                        <Group justify="space-between" hiddenFrom={"sm"}>
                            <ActionIcon size={"38px"} onClick={decrementServing} color={"secondary"}>
                                <FontAwesomeIcon icon={faMinus} size={"md"}/>
                            </ActionIcon>
                            <Title order={3}>
                                {serving} {t("recipe.serving")}
                            </Title>
                            <ActionIcon size={"38px"} onClick={incrementServing} color={"secondary"}>
                                <FontAwesomeIcon icon={faPlus} size={"md"}/>
                            </ActionIcon>
                        </Group>
                        <Group justify="space-between" visibleFrom={"sm"}>
                            <Title order={3}>
                                {serving} {t("recipe.serving")}
                            </Title>
                            <Group gap={"xs"}>
                                <ActionIcon size={"38px"} onClick={decrementServing} color={"secondary"}>
                                    <FontAwesomeIcon icon={faMinus} size={"md"}/>
                                </ActionIcon>
                                <ActionIcon size={"38px"} onClick={incrementServing} color={"secondary"}>
                                    <FontAwesomeIcon icon={faPlus} size={"md"}/>
                                </ActionIcon>
                            </Group>
                        </Group>
                    </Box>
                    <Group align={"flex-start"}>
                        <Stack flex={1}>
                            <Title order={2} pt={"lg"}>{t('recipe.basket')}</Title>
                            <StripedTable stripedColor={"secondary"} className={classes.table}>
                                <Table.Thead>
                                    <Table.Tr>
                                        <Table.Th colSpan={2}>
                                            {t('common.ingredient')}
                                        </Table.Th>
                                        <Table.Th w={"230px"} style={{textAlign: "right", verticalAlign: "top"}}>{t('common.amount')}</Table.Th>
                                    </Table.Tr>
                                </Table.Thead>
                                <Table.Tbody>
                                    {dataItem.ingredients?.sort(sortComparator).map((item, index) => (
                                        <Table.Tr key={`ingredient-${index}`} style={{cursor: "pointer"}}>
                                            <Table.Td w={"28px"} onClick={() =>  handleCheckboxChange(getIngredient(item.ingredientId).name)}>
                                                <Checkbox size={18} radius={"sm"} pl={8} color={"secondary"}
                                                          classNames={{
                                                              input: classes.checkboxinput,
                                                          }}
                                                          checked={selectedIngredients.includes(getIngredient(item.ingredientId).name)}
                                                            // onChange={(event) => handleCheckboxChange(item.ingredientId)}
                                                          style={{pointerEvents: "none"}}
                                                />
                                            </Table.Td>
                                            <Table.Td >
                                                <IngredientText color={"var(--mantine-color-secondary-9)"} ingredient={getIngredient(item.ingredientId)}/>
                                            </Table.Td>
                                            <Table.Td style={{textAlign: "right", verticalAlign: "middle"}}>
                                                <Text>{`${item.amount ? convertToFraction(item.amount * (recipeSettings.serving || 1)) : ''} ${item.unit ? t('recipe.amountUnit.' + item.unit) : ''}`}</Text>
                                            </Table.Td>
                                        </Table.Tr>
                                    ))}
                                </Table.Tbody>
                            </StripedTable>
                        </Stack>
                        <Stack flex={1}>
                            <Title order={2} pt={"lg"}>{t('recipe.timers')}</Title>
                            <Timers recipeId={recipeId}/>
                        </Stack>
                    </Group>
                    <Group justify={!isSm ? "flex-end" : "center"} pt={"lg"}>
                        <Button color={"secondary"} onClick={resetRecipeSettings}>{t("recipe.endCooking")}</Button>
                    </Group>
                </Collapse>
            </Card>
    }

    return <>
                {settings.getRecipeIds()?.map((recipeId, index) => (
                    <>
                        <Space h={"lg"}/>
                        <WorkRecipe recipeId={recipeId}/>
                    </>
                ))}
                <Space h={"lg"}/>
                <SimpleBox color={"tertiary"} flex={1}>
                    <Text size={"xs"}>{t(`common.totalCount`, {totalCount: settings.getRecipeIds()?.length})}</Text>
                </SimpleBox>
            </>
}

/**
* AccountPage
*
* @param props
* @returns {
    Element}
 * @constructor
 */
const AccountPage = (props) => {

    const {paramLng} = useParams();
    const lng = parameterLanguage(paramLng);

    const {isAuthenticated, userAccount, onUpdateUserAccount, offUpdateUserAccount, onUpdateUserAccountSettings, offUpdateUserAccountSettings} = useAccountContext();
    const [settings, setSettings] = useState(userAccount?.getValidSettings());

    const {isLoading, isLoaded} = useLoadingContext();

    const [tabValue, setTabValue] = useState("0");

    const [pageFavoriteRecipes, setPageFavoriteRecipes] = useState(1);

    const navigate = useNavigate();
    const { t } = useTranslation();

    const {key, stateKey, random} =
        useKey({
            isLoading: isLoading,
            onKeyChange: () => {
                console.debug(`AccountPage`);

                if(!isAuthenticated) {
                    homeNavigate(navigate);
                }

                setPageFavoriteRecipes(1);

                resetFavoriteIngredients();
                resetFavoriteMolecules();
                resetFavoriteRecipes();

                // handleOnUpdateUserAccount();
            }
        });

    // Define the callback to execute on update
    const handleOnUpdateUserAccount = () => {

        // if(settings.getFavoriteIngredients() !== undefined && settings.getFavoriteIngredients().length > 0) {
        //     refetchFavoriteIngredients();
        // }
        // else {
        //     resetFavoriteIngredients();
        // }
        //
        // if(settings.getFavoriteMolecules() !== undefined && settings.getFavoriteMolecules().length > 0) {
        //     refetchFavoriteMolecules();
        // }
        // else {
        //     resetFavoriteMolecules();
        // }
        //
        // if(settings.getFavoriteRecipes() !== undefined && settings.getFavoriteRecipes().length > 0) {
        //     refetchFavoriteRecipes();
        // }
        // else {
        //     resetFavoriteRecipes();
        // }
    };

    // useEffect(() => {
    //
    //     // Subscribe to the settings update event
    //     onUpdateUserAccount(handleOnUpdateUserAccount);
    //     onUpdateUserAccountSettings(handleOnUpdateUserAccount);
    //
    //     // Clean up by unsubscribing when component unmounts
    //     return () => {
    //         offUpdateUserAccount(handleOnUpdateUserAccount);
    //         offUpdateUserAccountSettings(handleOnUpdateUserAccount);
    //     };
    //
    // }, [onUpdateUserAccount, offUpdateUserAccount, onUpdateUserAccountSettings, offUpdateUserAccountSettings]);

    useEffect(() => {
        if(lng !== undefined && lng !== i18n.language) {
            i18n.changeLanguage(lng)
                .then(value => window.location.reload());
        }
    }, [lng]);

    const isEnabledFavoriteIngredients = settings?.getFavoriteIngredients() !== undefined && settings?.getFavoriteIngredients().length > 0;
    const { data: dataFavoriteIngredients, isLoaded: isLoadedFavoriteIngredients, totalCount: totalCountFavoriteIngredients,
        reset: resetFavoriteIngredients, refetch: refetchFavoriteIngredients} =
        useIngredientAll({
            enabled: isEnabledFavoriteIngredients,
            ingredientNames: settings?.getFavoriteIngredients(),
        })

    const isEnabledFavoriteMolecules = settings?.getFavoriteMolecules() !== undefined && settings?.getFavoriteMolecules().length > 0;
    const { data: dataFavoriteMolecules, isLoaded: isLoadedFavoriteMolecules, totalCount: totalCountFavoriteMolecules,
        reset: resetFavoriteMolecules, refetch: refetchFavoriteMolecules} =
        useMoleculeAll({
            enabled: isEnabledFavoriteMolecules,
            moleculeIds: settings?.getFavoriteMolecules(),
        })

    const isEnabledFavoriteRecipes = settings?.getFavoriteRecipes() !== undefined && settings?.getFavoriteRecipes().length > 0;
    const {	data: dataFavoriteRecipes, isLoaded: isLoadedFavoriteRecipes, totalCount: totalCountFavoriteRecipes,
        reset: resetFavoriteRecipes, refetch: refetchFavoriteRecipes} =
        useRecipeAll({
            enabled: isEnabledFavoriteRecipes,
            page: pageFavoriteRecipes,
            recipeIds: settings?.getFavoriteRecipes(),
        })

    const {isSuccess, isError} =
        useResult({
            isLoaded: isLoaded,
            isSuccess:  key === stateKey &&
                        (isEnabledFavoriteIngredients ? isLoadedFavoriteIngredients : true) &&
                        (isEnabledFavoriteMolecules ? isLoadedFavoriteMolecules : true) &&
                        (isEnabledFavoriteRecipes ? isLoadedFavoriteRecipes : true)
        })

    useEffect(() => {
        refetchFavoriteRecipes();
    }, [pageFavoriteRecipes]);

    /**
     * AccountCard
     */
    const AccountCard = () => {

        return (!isSuccess ? null :
            <PageCard>

                <StripedTable stripedColor={"tertiary"} className={classes.accountcardtable}>
                    <Table.Tbody>
                        <Table.Tr>
                            <Table.Td pl={0}>
                                <IngredientIconTitle textKey={"recipe.kitchenRecipes"}/>
                            </Table.Td>
                            <Table.Td pr={0} ta={"right"}>
                                <Text >
                                    {settings.getRecipeIds()?.length || 0}
                                </Text>
                            </Table.Td>
                        </Table.Tr>
                        <Table.Tr>
                            <Table.Td pl={0}>
                                <IngredientIconTitle textKey={"account.favoriteIngredients"}/>
                            </Table.Td>
                            <Table.Td pr={0} ta={"right"}>
                                {!isLoadedFavoriteIngredients ?
                                    0
                                    :
                                    <Text >
                                        {totalCountFavoriteIngredients}
                                    </Text>
                                }
                            </Table.Td>
                        </Table.Tr>
                        <Table.Tr>
                            <Table.Td pl={0}>
                                <MoleculeIconTitle textKey={"account.favoriteMolecules"}/>
                            </Table.Td>
                            <Table.Td pr={0} ta={"right"}>
                                {!isLoadedFavoriteMolecules ?
                                    0
                                    :
                                    <Text>
                                        {totalCountFavoriteMolecules}
                                    </Text>
                                }
                            </Table.Td>
                        </Table.Tr>
                        <Table.Tr>
                            <Table.Td pl={0}>
                                <RecipeIconTitle textKey={"account.favoriteRecipes"}/>
                            </Table.Td>
                            <Table.Td pr={0} ta={"right"}>
                                {!isLoadedFavoriteRecipes ?
                                    0
                                    :
                                    <Text>
                                        {totalCountFavoriteRecipes}
                                    </Text>
                                }
                            </Table.Td>
                        </Table.Tr>
                    </Table.Tbody>
                </StripedTable>
            </PageCard>
        );
    }

    return (!isAuthenticated || !isSuccess ? null :

        <Page
            pageTitle={userAccount?.getName()}
            pageKeywords={"account"}
            pageUrl={`/account`}
            hasTab
            pageTitles={[
                <PageTitle
                    displayTitle={<Text lineClamp={2} inherit>{userAccount?.getName()}</Text>}
                    titleIcon={<FontAwesomeIcon icon={faCircleUser}/>}
                    pt={"82px"}
                    withDescription
                    descriptionComponent={<Text size={"sm"} lineClamp={5}>{t("account.description", {name: isAuthenticated && userAccount?.getFirstName() ? userAccount?.getFirstName() : ``})}</Text>}
                    withCard
                    cardComponent = {<AccountCard />}
                    hasTabs
                    />
            ]}>

            <Tabs defaultValue="i" variant="outline" value={tabValue}>

                <Container>

                    <ScrollTab>
                        <ScrollTab.Tab selected={tabValue === "0"} onClick={() => setTabValue("0")}>
                            {t("common.kitchen")}
                        </ScrollTab.Tab>
                        <ScrollTab.Tab selected={tabValue === "1"} onClick={() => setTabValue("1")}>
                            <IngredientIconTitle textKey={"account.favoriteIngredients"}/>
                        </ScrollTab.Tab>
                        <ScrollTab.Tab selected={tabValue === "2"} onClick={() => setTabValue("2")}>
                            <MoleculeIconTitle textKey={"account.favoriteMolecules"}/>
                        </ScrollTab.Tab>
                        <ScrollTab.Tab selected={tabValue === "3"} onClick={() => setTabValue("3")}>
                            <RecipeIconTitle textKey={"account.favoriteRecipes"}/>
                        </ScrollTab.Tab>
                        <ScrollTab.Tab selected={tabValue === "4"} onClick={() => setTabValue("4")}>
                            {userAccount.getName()}
                        </ScrollTab.Tab>
                    </ScrollTab>

                    <AdvertisingGrid>

                        <Tabs.Panel value="0">
                            <Kitchen settings={settings} onReset={() => setSettings(userAccount.getSettings())}/>
                        </Tabs.Panel>

                        <Tabs.Panel value="1">

                            <Ingredient09 ingredients={dataFavoriteIngredients || []}
                                          title={
                                              <>
                                                  <VisuallyHidden>
                                                      <Title order={2} pt={"lg"}>{t('common.ingredients')}</Title>
                                                  </VisuallyHidden>
                                                  <Space h="lg"/>
                                              </>
                                          }
                                          showCategory
                            />
                        </Tabs.Panel>

                        <Tabs.Panel value="2">

                            <Molecule09 molecules={dataFavoriteMolecules || []}
                                        title={
                                            <>
                                                <VisuallyHidden>
                                                    <Title order={2} pt={"lg"}>{t('common.molecules')}</Title>
                                                </VisuallyHidden>
                                                <Space h="lg"/>
                                            </>
                                        }
                            />
                        </Tabs.Panel>

                        <Tabs.Panel value="3">

                            <Recipe09 recipes={dataFavoriteRecipes || []}
                                    title={
                                        <>
                                            <VisuallyHidden>
                                                <Title order={2} pt={"lg"}>{t('common.recipes')}</Title>
                                            </VisuallyHidden>
                                            <Space h="lg"/>
                                        </>
                                    }
                                  page={pageFavoriteRecipes}
                                  onPageChange={setPageFavoriteRecipes}
                                  totalCount={totalCountFavoriteRecipes}
                            />
                            <Space h={"lg"}/>
                        </Tabs.Panel>

                        <Tabs.Panel value="4">
                            <User />
                            <Payment />
                            <Dangerous />
                            <Group gap={0}>
                                <Auth0Credits pl={"md"} pt={"md"} i18nKey={"common.auth0Attribution"}/>
                                <StripeCredits pl={"md"} pt={"md"} i18nKey={"common.stripeAttribution"}/>
                            </Group>
                        </Tabs.Panel>

                    </AdvertisingGrid>
                </Container>
            </Tabs>

        </Page>
    );
}

export default AccountPage;