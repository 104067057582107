import React from 'react';
import {MantineProvider, Modal} from '@mantine/core';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import {cssVariablesResolver, theme} from './Theme';
import AppRoutes from './routes';
import '@mantine/core/styles.css';
import '@mantine/carousel/styles.css';
import '@mantine/charts/styles.css';
import {LoadingPage} from "./pages/loading/LoadingPage";
import {useNavigate} from "react-router-dom";
import classes from './App.css';
import {useWindowPopstate} from "./window";
import {keyState} from "./pages/useKey";
import {useLoadingContext} from "./components/loading/LoadingContext";
import {useApplicationContext} from "./components/application/ApplicationContext";
import '@mantine/notifications/styles.css';
import {Notifications} from "@mantine/notifications";
import {TimerManagerProvider} from "./components/timermanager/TimerManagerContext";
import {ScrollParallaxProvider} from "./components/parallax/ScrollParallaxContext";
import {SessionConflict, useAccountContext} from "./components/account/AccountContext";
import {Onboarding} from "./pages/home/HomePage";

/**
 * App
 *
 * @returns {Element}
 * @constructor
 */
const App = () => {

    const { loaded, showHeader, showFooter } = useLoadingContext();
    const { isSuccess } = useApplicationContext();

    const { conflict } = useAccountContext();

    const navigate = useNavigate();

    useWindowPopstate((url) => {
        navigate(url, {replace: true, state: {...keyState()}});
    })

    return !isSuccess ? null :
        <ScrollParallaxProvider>
            <MantineProvider withGlobalStyles
                             withNormalizeCSS
                             theme={theme}
                             cssVariablesResolver={cssVariablesResolver}>

                <Notifications  position="top-right"
                                style={{
                                    marginTop: 'var(--mantine-custom-components-header-height)'
                                }}/>

                    <TimerManagerProvider>

                        <Onboarding />

                        <SessionConflict conflict={conflict}/>

                        {!showHeader ? null : <Header/>}

                        <AppRoutes/>

                        {loaded ?
                            !showFooter ? null : <Footer/>
                            :
                            <LoadingPage/>
                        }
                    </TimerManagerProvider>
            </MantineProvider>
        </ScrollParallaxProvider>
}

export default App;