import i18n, {localized} from "../../i18n";
import {
	getEntityNaturalSourceIngredient,
	getEntityRepresentativeIngredient,
	getEntitySynonymIngredients
} from "../ingredient/IngredientUtil";
import {localizedLink, WIKI_LINK_NAME} from "../../util/links";

/**
 * ACCENT_MAP
 */
export const ACCENT_MAP = {
	"A": ["A", "А", "Ă", "Ǎ", "Ą", "À", "Ã", "Á", "Æ", "Â", "Å", "Ǻ", "Ā", "א"],
	"B": ["B", "Б", "ב", "Þ"],
	"C": ["C", "Ĉ", "Ć", "Ç", "Ц", "צ", "Ċ", "Č", "ץ"],
	"D": ["D", "Д", "Ď", "Đ", "ד", "Ð"],
	"E": ["E", "È", "Ę", "É", "Ë", "Ê", "Е", "Ē", "Ė", "Ě", "Ĕ", "Є", "Ə", "ע"],
	"F": ["F", "Ф", "Ƒ"],
	"G": ["G", "Ğ", "Ġ", "Ģ", "Ĝ", "Г", "ג", "Ґ"],
	"H": ["H", "ח", "Ħ", "Х", "Ĥ", "ה"],
	"I": ["I", "Ï", "Î", "Í", "Ì", "Į", "Ĭ", "ı", "И", "Ĩ", "Ǐ", "י", "Ї", "Ī", "І"],
	"J": ["J", "Й", "Ĵ"],
	"K": ["K", "ĸ", "כ", "Ķ", "К", "ך"],
	"L": ["L", "Ł", "Ŀ", "Л", "Ļ", "Ĺ", "Ľ", "ל"],
	"M": ["M", "מ", "М", "ם"],
	"N": ["N", "Ñ", "Ń", "Н", "Ņ", "ן", "Ŋ", "נ", "ŉ", "Ň"],
	"O": ["O", "Ø", "Ó", "Ò", "Ô", "Õ", "О", "Ő", "Ŏ", "Ō", "Ǿ", "Ǒ", "Ơ"],
	"P": ["P", "פ", "ף", "П"],
	"Q": ["Q", "ק"],
	"R": ["R", "Ŕ", "Ř", "Ŗ", "ר", "Р"],
	"S": ["S", "Ş", "Ś", "Ș", "Š", "С", "Ŝ", "ס"],
	"T": ["T", "Т", "Ț", "ט", "Ŧ", "ת", "Ť", "Ţ"],
	"U": ["U", "Ù", "Û", "Ú", "Ū", "У", "Ũ", "Ư", "Ǔ", "Ų", "Ŭ", "Ů", "Ű", "Ǖ", "Ǜ", "Ǚ", "Ǘ"],
	"V": ["V", "В", "ו"],
	"Y": ["Y", "Ý", "Ы", "Ŷ", "Ÿ"],
	"Z": ["Z", "Ź", "Ž", "Ż", "З", "ז"],
	"a": ["a", "а", "ă", "ǎ", "ą", "à", "ã", "á", "æ", "â", "å", "ǻ", "ā", "א"],
	"b": ["b", "б", "ב", "þ"],
	"c": ["c", "ĉ", "ć", "ç", "ц", "צ", "ċ", "č", "ץ"],
	"d": ["d", "д", "ď", "đ", "ד", "ð"],
	"e": ["e", "è", "ę", "é", "ë", "ê", "е", "ē", "ė", "ě", "ĕ", "є", "ə", "ע"],
	"f": ["f", "ф", "ƒ"],
	"g": ["g", "ğ", "ġ", "ģ", "ĝ", "г", "ג", "ґ"],
	"h": ["h", "ח", "ħ", "х", "ĥ", "ה"],
	"i": ["i", "ï", "î", "í", "ì", "į", "ĭ", "ı", "и", "ĩ", "ǐ", "י", "ї", "ī", "і"],
	"j": ["j", "й", "ĵ"],
	"k": ["k", "ĸ", "כ", "ķ", "к", "ך"],
	"l": ["l", "ł", "ŀ", "л", "ļ", "ĺ", "ľ", "ל"],
	"m": ["m", "מ", "м", "ם"],
	"n": ["n", "ñ", "ń", "н", "ņ", "ן", "ŋ", "נ", "ŉ", "ň"],
	"o": ["o", "ø", "ó", "ò", "ô", "õ", "о", "ő", "ŏ", "ō", "ǿ", "ǒ", "ơ"],
	"p": ["p", "פ", "ף", "п"],
	"q": ["q", "ק"],
	"r": ["r", "ŕ", "ř", "ŗ", "ר", "р"],
	"s": ["s", "ş", "ś", "ș", "š", "с", "ŝ", "ס"],
	"t": ["t", "т", "ț", "ט", "ŧ", "ת", "ť", "ţ"],
	"u": ["u", "ù", "û", "ú", "ū", "у", "ũ", "ư", "ǔ", "ų", "ŭ", "ů", "ű", "ǖ", "ǜ", "ǚ", "ǘ"],
	"v": ["v", "в", "ו"],
	"y": ["y", "ý", "ы", "ŷ", "ÿ"],
	"z": ["z", "ź", "ž", "ż", "з", "ז", "ſ"],
	"ae": ["ae", "Ä", "Ǽ", "ä", "æ", "ǽ"],
	"ch": ["ch", "Ч", "ч"],
	"ij": ["ij", "ĳ", "Ĳ"],
	"ja": ["ja", "я", "Я"],
	"je": ["je", "Э", "э"],
	"jo": ["jo", "ё", "Ё"],
	"ju": ["ju", "ю", "Ю"],
	"oe": ["oe", "œ", "Œ", "ö", "Ö"],
	"sch": ["sch", "щ", "Щ"],
	"sh": ["sh", "ш", "Ш"],
	"ss": ["ss", "ß"],
	"ue": ["ue", "Ü"],
	"zh": ["zh", "Ж", "ж"]
};

/**
 * CUSTOM_CATEGORY_ID
 */
export const CUSTOM_CATEGORY_ID = -1;

/**
 * GRID_SIZE
 */
export const GRID_SIZE = {
	min: 1,
	max: 20
}

/**
 * GROUP_WEIGHTING
 */
export const GROUP_WEIGHTING= {
	min: 0.00,
	max: 0.99,
};

/**
 * PAIRING_LEVELS
 */
export const PAIRING_LEVELS = {

	// Each ingredient appears only once in the workspace
	// For structured workflows where duplicate ingredients cause confusion.
	UNIQUE_INGREDIENT_GLOBALLY: 1,

	// One pairing per unique ingredient pair.
	// When preventing redundant pairings improves clarity.
	UNIQUE_PAIRING_PER_INGREDIENT: 2,

	// No limit on pairings per ingredient pair.
	// When experimenting with multiple relationships between the same ingredients.
	UNLIMITED_PAIRINGS: 3,
}

/**
 * COMPATIBILITY_MARKS
 */
export const COMPATIBILITY_MARKS=[
	{value: 0},
	{value: 20, label: "20%"},
	{value: 40, label: "40%"},
	{value: 60, label: "60%"},
	{value: 80, label: "80%"},
	{value: 100},
];

/**
 * INGREDIENTS_THRESHOLDS
 */
export const INGREDIENTS_THRESHOLDS = [
	{ limit: 12, color: "red", difficulty: "high" },
	{ limit: 6, color: "yellow", difficulty: "medium" },
	{ limit: 0, color: "lime", difficulty: "low" }
];

/**
 * MOLECULES_THRESHOLDS
 */
export const MOLECULES_THRESHOLDS = [
	{ limit: 66, color: "lime" },
	{ limit: 33, color: "yellow" },
	{ limit: 0, color: "red" }
];

/**
 * PAIRINGS_THRESHOLDS
 */
export const PAIRINGS_THRESHOLDS = [
	{ limit: 66, color: "#fa5252" },	// red-6
	{ limit: 33, color: "#fab005" },	// yellow-6
	{ limit: 0, color: "#82c91e" },		// lime-6
];

/**
 * NUTRIENT_DEFAULT_SERVING
 */
export const NUTRIENT_DEFAULT_SERVING = "100g";

/**
 * NUTRIENT_CATEGORIES
 */
export const NUTRIENT_CATEGORIES = [
	{
		id: 1, // calories
		ranks: [280, 290, 300, 400]
	},
	{
		id: 2, // proteins
		ranks: [500, 600]
	},
	{
		id: 3, // totalFats
		ranks: [800, 900, 9700, 11400, 12900, 15400, 15500, 15601, 15619]
	},
	{
		id: 4, // carbohydrates
		ranks: [1110, 1120, 1500, 1510]
	},
	{
		id: 5, // typesOfFat
		ranks: [7444, 7534, 9800, 9850, 9900, 9950, 10000, 10050, 10100, 10200, 10300, 10400, 10500, 10600, 10700, 10800, 10900, 11100, 11150, 11200, 11250, 11300, 11450, 11500, 11501, 11600, 11700, 11800, 12000, 12001, 12100, 12200, 12310, 12400, 12401, 12500, 12600, 12601, 12602, 12800, 13100, 13150, 13200, 13300, 13350, 13900, 13910, 14000, 14100, 14200, 14250, 14300, 14400, 14450, 14500, 14600, 14650, 14675, 14700, 14750, 14900, 14950, 15000, 15100, 15150, 15160, 15200, 15250, 15300]
	},
	{
		id: 6, // fiber
		ranks: [1200, 1240, 1260, 1300, 1305, 1306]
	},
	{
		id: 7, // vitamins
		ranks: [6300, 6400, 6500, 6600, 6700, 6800, 6850, 6900, 7100, 7200, 7300, 7340, 7420, 7430, 7500, 7905, 7920, 8000, 8100, 8200, 8650, 8700, 8710, 8720, 8730, 8800, 8900, 8950, 999999]
	},
	{
		id: 8, // minerals
		ranks: [5300, 5400, 5500, 5600, 5700, 5800, 5900, 6000, 6100, 6200, 6240]
	},
	{
		id: 9, // water
		ranks: [100]
	},
	{
		id: 10, // aminoAcids
		ranks: [16300, 16400, 16500, 16600, 16700, 16800, 17000, 17200, 17400]
	},
	{
		id: 11, // miscellaneous
		ranks: [1000, 1327, 1600, 1700, 1800, 1900, 2000, 2100, 2200, 2300, 2400, 2450, 3500, 4200, 4400, 4600, 4700, 6150, 6241, 6242, 6243, 6244, 6245, 7000, 7220, 7230, 7240, 7250, 7260, 7270, 7290, 7440, 7442, 7450, 7455, 7460, 7461, 7530, 7532, 7560, 7561, 7562, 7564, 7570, 7580, 8300, 8400, 8500, 8600, 8955, 9000, 15510, 15520, 15521, 15540, 15550, 15610, 15611, 15615, 15660, 15700, 15800, 15801, 15900, 16000, 16100, 16200, 16210, 16211, 16220, 16221, 16222, 16224, 16226, 16227, 16255, 16900, 17100, 17300, 17500, 17600, 17700, 17800, 17900, 18000, 18100, 18150, 18200, 18300, 18400, 19100, 19200, 19310, 19320, 19330]
	}
]

/**
 * removeAccents
 */
export function removeAccents(str) {
	return str.normalize("NFD").replace(/\p{Diacritic}/gu, "");
}


// Function to get all accent variations for a full word
export const generateAccentVariantsForWord = (word) => {

	if(!word) {
		return [];
	}

	const variations = new Set([word]); // Start with the original word

	// Check if the entire word has a variant in ACCENT_MAP (e.g., oe → œ, ae → ä)
	Object.keys(ACCENT_MAP).forEach(key => {
		if (word.includes(key)) {
			ACCENT_MAP[key].forEach(variant => {
				variations.add(word.replace(key, variant));
			});
		}
	});

	return Array.from(variations);
};

/**
 * getDataByThreshold
 */
export function getDataByThreshold(value, thresholds) {

	for (const threshold of thresholds) {
		if (value >= threshold.limit) {
			return threshold;
		}
	}

	return undefined;
}

/**
 * getColorByThreshold
 *
 * Funzione generica per determinare il colore in base a un valore e a un set di soglie.
 *
 * @param {number} value - Il valore per il quale determinare il colore.
 * @param {Array} thresholds - Le soglie definite come array di oggetti { limit, color }.
 * @param {Array} colorLevel
 * @param {Array} defaultColor
 * @returns {string} - Il colore associato al valore.
 */
export function getColorByThreshold(value, thresholds, colorLevel = "6", defaultColor = "tertiary") {

	for (const { limit, color } of thresholds) {
		if (value >= limit) {
			return `var(--mantine-color-${color}-${colorLevel})`;
		}
	}

	return `var(--mantine-color-${defaultColor}-${colorLevel})`;
}

/**
 * seasonSortComparator - Comparator function to sort seasons in fixed order
 */
export const seasonSortComparator = (a, b) => {
	const seasonOrder = ["all", "spring", "summer", "autumn", "winter"];
	return seasonOrder.indexOf(a) - seasonOrder.indexOf(b);
};

/**
 * getWeightingName
 */
export function getWeightingName(weighting) {

	switch (weighting) {

		case GROUP_WEIGHTING.min:
			return i18n.t("studio.weighting.creative");

		case (GROUP_WEIGHTING.max - GROUP_WEIGHTING.min) / 2:
			return i18n.t("studio.weighting.balanced");

		case GROUP_WEIGHTING.max:
			return i18n.t("studio.weighting.coherent");
	}
}

// /**
//  * getFirstInputNode
//  */
// export function getFirstInputNode(nodes) {
// 	return nodes.find((node) => node.type === "input") || undefined;
// }

/**
 * getEntityCategory
 */
export function getEntityCategory(entity) {
	return entity.categories ? entity.categories[0] : undefined;
}

/**
 * getEntityById
 */
export function getEntityById(entities, entityId) {
	return entities.find((entity) => entity.entityId === entityId) || undefined;
}

/**
 * Finds the common molecules between two arrays of molecules.
 *
 * @param {Array} molecules1 - The first array of molecule objects, each with a 'moleculeId'.
 * @param {Array} molecules2 - The second array of molecule objects, each with a 'moleculeId'.
 * @returns {Array} - An array of common molecule objects from molecules2.
 */
export function extractSharedMolecules(molecules1, molecules2) {

	// Step 1: Extract moleculeIds from the first array into a Set for fast lookup
	const moleculeIds1 = new Set(molecules1.map((molecule) => molecule.moleculeId));

	// Step 2: Filter molecules from the second array that have an id present in the first array's Set
	return molecules2.filter((molecule) => moleculeIds1.has(molecule.moleculeId));
}

/**
 * extractMolecules
 *
 * Extracts all unique molecules from the given entities and nodes.
 * Handles group nodes and uses their weighting to dynamically balance between intersection and union.
 */
export function extractMolecules(entities, entitiesMolecules, nodes, selectedNodes) {

	// Step 1: Extract all group nodes from selectedNodes
	const groupNodes = selectedNodes.filter((node) => node.type === "group");

	// Step 2: Calculate molecules for group nodes, applying group weighting
	const moleculesFromGroups = groupNodes.flatMap((groupNode) => {

		// Get all nodes with this groupNode's ID as their parentId
		const childNodes = nodes.filter((node) => node.parentId === groupNode.id);

		// Get all molecules from these child nodes
		const childMolecules = childNodes.flatMap(
			(node) => {

				// Collect all molecules from all entities
				if(node.type === "custom") {
					return entitiesMolecules;
				}

				return getEntityById(entities, toEntityId(node))?.molecules || [];
			}
		);

		// Apply weighting to combine intersection and union
		const uniqueChildMolecules = [...new Set(childMolecules)];
		const groupWeighting = groupNode.data.weighting ?? 0.5; // Default weighting to 0.5 if not provided

		// Calculate intersection and union
		const moleculeIntersection = uniqueChildMolecules.filter((molecule, index, self) =>
			self.indexOf(molecule) === index && childMolecules.every((m) => m === molecule)
		);

		const moleculeUnion = uniqueChildMolecules;

		// Apply weighted combination
		const combinedMolecules = new Set([
			...moleculeIntersection.slice(0, Math.ceil(moleculeIntersection.length * groupWeighting)), // Take part of the intersection
			...[...moleculeUnion].slice(0, Math.ceil(moleculeUnion.length * (1 - groupWeighting))), // Take part of the union
		]);

		return Array.from(combinedMolecules);
	});

	// Step 4: Filter all selectedNodes that do not have parentId in groupNodes
	const nodesOutsideGroups = selectedNodes.filter(
		(node) =>
			!groupNodes.some((groupNode) => groupNode.id === node.parentId) // Exclude nodes that are children of group nodes
	);

	// Step 5: Get all molecules from nodes that are not part of groups
	const moleculesFromNodesOutsideGroups = nodesOutsideGroups.flatMap(
		(node) => {

			// Collect all molecules from all entities
			if(node.type === "custom") {
				return entitiesMolecules;
			}

			return getEntityById(entities, toEntityId(node))?.molecules || []; // Extract molecules from each node
		}
	);

	// Step 6: Combine all molecules and remove duplicates
	return [...new Set([...moleculesFromGroups, ...moleculesFromNodesOutsideGroups])];
}

/**
 * sharedMoleculesPc
 */
export function getSharedMoleculesPc(sharedMolecules, moleculesIds) {

	if(!sharedMolecules || sharedMolecules.length === 0 || !moleculesIds || moleculesIds.length === 0) {
		return undefined;
	}

	return sharedMolecules / moleculesIds.length * 100;
}

/**
 * extractEntityPairings
 */
export function extractEntityPairings(entities, moleculeIds) {

	return entities
		.map((entity) => {

			// Extract moleculeIds from the entity
			const entityMoleculeIds = entity.molecules.map((molecule) => molecule.moleculeId);

			// Calculate the shared molecules
			const sharedMolecules = entityMoleculeIds.filter((id) => moleculeIds.includes(id));
			const sharedMoleculesCount = sharedMolecules.length;

			// Return a copy of the entity with the new properties
			return {
				...entity, // Copy the original entity
				sharedMolecules: sharedMoleculesCount
			};
		})
		.filter((entity) => entity.sharedMolecules > 0); // Only include entities with sharedMolecules > 0
}

/**
 * Filters entities whose entityId is NOT present in nodes
 */
export function extractEntitiesNotInNodes(entities, nodes) {

	// Create a Set of entityId values from nodes for faster lookup
	const entityIds = new Set(nodes.map((node) => toEntityId(node)));

	// Filter entities whose entityId is NOT in the Set of entityIds
	return entities.filter((entity) => !entityIds.has(entity.entityId));
}

// export function accumulateFrequency(molecules, attribute) {
//
// 	const items = molecules.flatMap(molecule => molecule[attribute] || [])
//
// 	// Ensure items array exists and is valid
// 	if (!items || items.length === 0) return [];
//
// 	// Count occurrences
// 	const totalItems = items.length;
// 	const frequencyMap = items.reduce((acc, item) => {
// 		const name = localized(item, "name"); // Use the localized name as the unique key
// 		if (name) {
// 			acc[name] = acc[name] || { name, count: 0 }; // Initialize if not present
// 			acc[name].count += 1; // Increment count
// 			acc[name].pc = acc[name].count / totalItems * 100;
// 		}
// 		return acc;
// 	}, {});
//
// 	// Convert the frequency map to an array
// 	const frequencyArray = Object.values(frequencyMap);
//
// 	// Sort by pc (descending) and then by name (alphabetical ascending)
// 	return frequencyArray.sort((a, b) => {
// 		if (b.pc === a.pc) {
// 			return a.name.localeCompare(b.name); // Sort alphabetically if pc is the same
// 		}
// 		return b.pc - a.pc; // Sort by pc (descending)
// 	});
// }

/**
 * Accumulate attribute frequencies from molecules, with strategy-based weighting
 * @param {Array} molecules - Array of molecule objects
 * @param {String} attribute - The attribute name to extract (e.g., "flavors", "odors", etc.)
 * @param {String} strategy - Strategy for weighting ("default", "advanced", "log", "none")
 * @returns {Object[]} - Sorted array of unique items with counts, weight, and percentages
 */
export function accumulateFrequency(molecules, attribute, strategy = "advanced") {
	if (!molecules || molecules.length === 0 || !attribute) return [];

	// Strategy map for weighting
	const weightStrategyMap = {
		flavors: {
			default: molecule => molecule.complexity,
			advanced: molecule => (molecule.complexity + molecule.molecularWeight) / 2,
			log: molecule => Math.log(1 + (molecule.complexity || 0))
		},
		odors: {
			default: molecule => 1 / (molecule.exactMass || 1),
			advanced: molecule => 1 / ((molecule.exactMass || 1) * (molecule.topologicalPolorSurfacearea || 1)),
			log: molecule => Math.log(1 + (1 / (molecule.exactMass || 1)))
		},
		tastes: {
			default: molecule => (molecule.hbaCount || 0) + (molecule.hbdCount || 0),
			advanced: molecule => (molecule.hbaCount || 0) + 1.5 * (molecule.hbdCount || 0),
			log: molecule => Math.log(1 + ((molecule.hbaCount || 0) + (molecule.hbdCount || 0)))
		},
		compounds: {
			default: molecule => molecule.compoundsCount || 0,
			advanced: molecule => molecule.compoundsCount || 0,
			log: molecule => Math.log(1 + (molecule.compoundsCount || 0))
		},
		emotions: {
			default: molecule => computeEmotionWeight(molecule, "default"),
			advanced: molecule => computeEmotionWeight(molecule, "advanced"),
			log: molecule => computeEmotionWeight(molecule, "log")
		}
	};

	// Emotion weight is based on weights of flavors, odors, and tastes
	function computeEmotionWeight(molecule, strat) {
		const getFlavor = getWeightFunction("flavors", strat);
		const getOdor = getWeightFunction("odors", strat);
		const getTaste = getWeightFunction("tastes", strat);

		const flavorCount = molecule.flavors?.length || 0;
		const odorCount = molecule.odors?.length || 0;
		const tasteCount = molecule.tastes?.length || 0;
		const totalCount = flavorCount + odorCount + tasteCount;
		if (totalCount === 0) return 0;

		const flavorWeight = getFlavor(molecule) * flavorCount;
		const odorWeight = getOdor(molecule) * odorCount;
		const tasteWeight = getTaste(molecule) * tasteCount;

		return (flavorWeight + odorWeight + tasteWeight) / totalCount;
	}

	// Strategy selector
	function getWeightFunction(attr, strat) {
		if (strat === "none") return () => 1;
		return weightStrategyMap[attr]?.[strat] || (() => 1);
	}

	const getWeight = getWeightFunction(attribute, strategy);
	const frequencyMap = {};
	let totalWeight = 0;

	for (const molecule of molecules) {
		const items = molecule[attribute] || [];
		const weight = getWeight(molecule) || 1;

		for (const item of items) {
			const name = localized(item, "name");
			if (!name) continue;

			if (!frequencyMap[name]) {
				frequencyMap[name] = { name, count: 0, weight: 0 };
			}

			frequencyMap[name].count += 1;
			frequencyMap[name].weight += weight;
			totalWeight += weight;
		}
	}

	const result = Object.values(frequencyMap).map(entry => ({
		...entry,
		pc: entry.weight / totalWeight * 100
	}));

	return result.sort((a, b) => b.pc - a.pc || a.name.localeCompare(b.name));
}

/**
 * toIngredients
 */
export function toIngredients(entities) {

	// Flatten all ingredient copies from entities
	const allIngredients = entities.flatMap(entity => {

		// Create an array to store all ingredient copies
		let result = [];

		// Add one copy for the representative ingredient
		if (entity.representativeIngredient) {
			result.push({
				...entity,
				// representativeIngredient: entity.representativeIngredient
			});
		}

		// // Add one copy for the natural source ingredient
		// if (entity.naturalSourceIngredient) {
		// 	result.push({
		// 		...entity,
		// 		representativeIngredient: entity.naturalSourceIngredient
		// 	});
		// }

		// Add copies for each synonym ingredient
		if (entity.synonymIngredients && entity.synonymIngredients.length > 0) {
			result.push(...entity.synonymIngredients.map(synonym => ({
				...entity,
				representativeIngredient: {
					...synonym,
					category: entity.categories ? entity.categories[0] : undefined
				}
			})));
		}

		return result;
	});

	// Remove duplicates based on representativeIngredient.name
	const uniqueIngredients = Array.from(new Map(allIngredients.map(item => [item.representativeIngredient?.name, item])).values());

	return uniqueIngredients;
}

/**
 * entitiesMoleculesAggregation
 */
export function entitiesMoleculesAggregation(entities, molecules, Features) {

	return entities.map((entity) => {

		// Get entity molecules
		const entityMolecules = molecules.filter((molecule) =>
			molecule.entityIds.includes(entity.entityId)
		);

		// // Search ingredients string
		// const searchIngredientsString = [
		// 	...new Set(
		// 		entity.ingredients.flatMap(ingredient => localized(ingredient, "name").toLowerCase().split(" "))
		// 	)
		// ].join(",");

		// Mapping seasons into a searchable string
		const searchSeasonsString = entity.seasons
			? entity.seasons.filter(season => season !== "all").map(season => removeAccents(i18n.t(`recipe.seasonType.${season}`).toLowerCase())).join(",")
			: "";

		// Search emotions string
		const searchEmotionsString = Features.studio.features.ingredientEmotions.plan.enabled ?
			accumulateFrequency(entityMolecules, "emotions").slice(0, 10) // only the first 10 most frequent
				.map(emotion => removeAccents(emotion.name.toLowerCase())).join(",")
			: "";

		// Search flavors string
		const searchFlavorsString = Features.studio.features.ingredientFlavors.plan.enabled ?
			accumulateFrequency(entityMolecules, "flavors").slice(0, 10) // only the first 10 most frequent
				.map(flavor => removeAccents(flavor.name.toLowerCase())).join(",")
			: "";

		// Search odors string
		const searchOdorsString = Features.studio.features.ingredientOdors.plan.enabled ?
			accumulateFrequency(entityMolecules, "odors").slice(0, 10) // only the first 10 most frequent
				.map(odor => removeAccents(odor.name.toLowerCase())).join(",")
			: "";

		// Search tastes string
		const searchTastesString = Features.studio.features.ingredientTastes.plan.enabled ?
			accumulateFrequency(entityMolecules, "tastes").slice(0, 10) // only the first 10 most frequent
				.map(taste => removeAccents(taste.name.toLowerCase())).join(",")
			: "";

		// Search compounds string
		const searchCompoundsString = Features.studio.features.ingredientCompounds.plan.enabled ?
			accumulateFrequency(entityMolecules, "compounds")
				.map(compound => removeAccents(compound.name.toLowerCase())).join(",")
			: "";

		return {
			...entity,
			entityId: `${entity.entityId}`,
			representativeIngredient: getEntityRepresentativeIngredient(entity),
			naturalSourceIngredient: getEntityNaturalSourceIngredient(entity),
			synonymIngredients: getEntitySynonymIngredients(entity),
			search: {
				// ingredients: searchIngredientsString,
				seasons: searchSeasonsString,
				emotions: searchEmotionsString,
				flavors: searchFlavorsString,
				odors: searchOdorsString,
				tastes: searchTastesString,
				compounds: searchCompoundsString,
			},
			molecules: entityMolecules
		}
	});
}

/**
 * searchEntities
 */
export function searchEntities(entities, search, molecularSearchFilter = []) {

	// Split the search input into multiple terms using commas or spaces as delimiters
	const searchTerms = search
		.split(/[, ]+/) // Split by commas, spaces, or both
		.map((term) => removeAccents(term.trim().toLowerCase())) // Trim, remove accents, and convert to lowercase
		.filter((term) => term.length > 0); // Remove empty terms

	return entities.filter((entity) => {

		// Combine all search fields into one set (ingredients + selected molecular fields)
		const combinedSearchText = [
			removeAccents(localized(entity.representativeIngredient, "name")),
			...molecularSearchFilter.map(filterKey => removeAccents(entity.search[filterKey] || "")),
			removeAccents(entity.search.seasons || "")
		].join(" ").toLowerCase(); // Merge ingredients and molecular fields, ensuring lowercase

		// Check if all search terms match within the combined search text
		return searchTerms.every(term => combinedSearchText.includes(term));
	});
}


/**
 * extractNodes
 *
 * Extracts all unique nodes from the given selectedNodes (handles group nodes)
 */
export function extractNodes(nodes, selectedNodes) {

	// Step 1: Extract all group nodes from selectedNodes
	const groupNodes = selectedNodes.filter((node) => node.type === "group");

	// Step 2: Calculate molecules for group nodes, applying group weighting
	const nodesFromGroups = groupNodes.flatMap((groupNode) => {

		// Get all nodes with this groupNode's ID as their parentId
		return nodes.filter((node) => node.parentId === groupNode.id);
	});

	// Step 4: Filter all selectedNodes that do not have parentId in groupNodes
	const nodesOutsideGroups = selectedNodes.filter(
		(node) =>
			!groupNodes.some((groupNode) => groupNode.id === node.parentId) // Exclude nodes that are children of group nodes
	);

	// Step 6: Combine all nodes and remove duplicates
	return [...new Set([...nodesFromGroups, ...nodesOutsideGroups])];
}

/**
 * Get unique parent IDs from selected nodes
 */
export const getParentIds = (nodes) => {

	// Filter the nodes that have a parentId and return the unique parentId values.
	return [...new Set(nodes.filter((node) => node.parentId).map((node) => node.parentId))];
};

/**
 * findGroupNodeAtPosition
 */
export const findGroupNodeAtPosition = (nodes, position) => {

	return nodes.find(
		(existingNode) => existingNode.type === "group" && // Check only for group nodes
			position.x >= existingNode.position.x &&
			position.x <= existingNode.position.x + existingNode.measured.width &&
			position.y >= existingNode.position.y &&
			position.y <= existingNode.position.y + existingNode.measured.height
	);
}

/**
 * getNodeById
 */
export const getNodeById = (nodes, id) => {
	return nodes.find((node) => node.id === id);
};

/**
 * getNodeByType
 */
export const getNodeByType = (nodes, type) => {
	return nodes.find((node) => node.type === type);
};

/**
 * getNodesByParentId
 */
export const getNodesByParentId = (nodes, parentId) => {
	return nodes.filter((node) => node.parentId === parentId);
};

/**
 * sortNodesByParent
 */
export const sortNodesByParent = (nodes) => {

	// Create a map of nodes to facilitate lookup
	const nodeMap = nodes.reduce((acc, node) => {
		acc[node.id] = node;
		return acc;
	}, {});

	// Recursive function to calculate node deep
	const getDepth = (node) => {
		let depth = 0;
		let currentNode = node;
		while (currentNode?.parentId) {
			depth++;
			currentNode = nodeMap[currentNode.parentId];
		}
		return depth;
	};

	// Sort nodes based on the calculated deep
	return nodes.sort((a, b) => getDepth(a) - getDepth(b));
};

/**
 * checkedGroupWeighting
 */
export function checkedGroupWeighting(weighting) {

	if(weighting < GROUP_WEIGHTING.min) {
		return GROUP_WEIGHTING.min;
	}

	if(weighting > GROUP_WEIGHTING.max) {
		return GROUP_WEIGHTING.max;
	}

	return weighting
}

/**
 * toGroup
 */
export function toGroup(id, position, size) {

	return {
		id: id,
		type: "group",
		position: position,
		data: {
			label: i18n.t("studio.newGroupNodeLabel"),
			description: "",
			weighting: GROUP_WEIGHTING.min
		},
		style: {
			width: size.width,
			height: size.height,
		},
	}
}

/**
 * toEntityId
 */
export function toEntityId(node) {
	return node.data?.entity?.id;
}

/**
 * toNode
 */
export function toNode(id, type, position, label, ingredientName, entity, nodePadding, selected = false) {

	return {
		id,
		type,
		position: {
			x: position.x - nodePadding,
			y: position.y - nodePadding
		},
		selected,
		data: {
			// label: `${localized(entity.representativeIngredient, "name")} Molecules ${entity.molecules.length}`,
			label: label,
			entity: {
				id: entity.entityId,
				ingredientName: ingredientName,
				categoryLabel: localized(getEntityCategory(entity), "name"),
				wikiLink: localizedLink(entity?.links, WIKI_LINK_NAME),
				moleculesCount: entity.molecules.length
			}
		},
		style: {padding: `${nodePadding}px`}
	};
}

/**
 * toCustomNode
 */
export function toCustomNode(id, position, label, nodePadding = GRID_SIZE.max) {

	return {
		id: id,
		type: "custom",
		position: {
			x: position.x - nodePadding,
			y: position.y - nodePadding
		},
		data: {
			label
		},
		style: {padding: `${nodePadding}px`}
	};
}

/**
 * toChildNode
 */
export function toChildNode(node, groupId, groupPosition) {

	return {
		...node,
		// selected: false,
		parentId: groupId,
		extent: "parent",
		position: {
			x: node.position.x - groupPosition.x,
			y: node.position.y - groupPosition.y,
		}
	}
}

/**
 * toChildNode
 */
export function ungroupNode(node, position) {

	return {
		...node,
		parentId: undefined,
		extent: undefined,
		position
	}
}

/**
 * Get edges connected to a specific set of nodes
 * @param {Array} edges - The array of edges.
 * @param {Array} nodes - The array of nodes to check.
 * @returns {Array} - Array of edges connected to the nodes.
 */
export function getConnectedEdges(edges, nodes) {
	const nodeIds = nodes.map(node => node.id);
	return edges.filter(edge => nodeIds.includes(edge.source) || nodeIds.includes(edge.target));
}

/**
 * toEdge
 */
export function toEdge(sourceNode, targetNode, type, selectable = false, targetEntity, moleculesIds) {

	const sharedMoleculesPc = targetEntity && moleculesIds ? getSharedMoleculesPc(targetEntity.sharedMolecules, moleculesIds) : 0;

	return {
		id: `${sourceNode.id}-${targetNode.id}`,
		type: type,
		source: `${sourceNode.id}`,
		target: `${targetNode.id}`,
		deletable: false,
		selectable: selectable,
		data: {
			// label: `Molecules ${entity.sharedMolecules} / ${sharedMoleculesPc}%`,
			// label: `${i18n.t("ingredient.compatibleAt", {compatibility: formatPc(sharedMoleculesPc)})}\n${sourceNode.data.label} - ${localized(targetEntity.representativeIngredient, "name")}`,
			// label: `${i18n.t("ingredient.compatibleAt", {compatibility: formatPc(sharedMoleculesPc)})}`,
			sharedMolecules: targetEntity?.sharedMolecules || 0,
			sharedMoleculesPc: sharedMoleculesPc || 0,
			width: getEdgeWidth(sharedMoleculesPc),
		}
	};
}

/**
 * getEdgeWidth
 */
export function getEdgeWidth(sharedMoleculesPc) {
	return sharedMoleculesPc / 3;
}

/**
 * toSelection
 */
export function toSelection(nodes = [], edges = [], onPane = true) {

	return {
		nodes: nodes,
		edges: edges,
		onPane: onPane,
		timestamp: Date.now()
	}
}

/**
 * formatPc
 */
export function formatPc(valuePc, digitsAboveOne = 0, digitsBelowOne = 2, suffix = "%") {

	// Handle cases where the value is null, undefined, or zero
	if (!valuePc || valuePc === 0) {
		return `0${suffix}`;
	}

	// Format based on the value and the specified digits
	return valuePc >= 1
		? `${valuePc.toFixed(digitsAboveOne)}${suffix}`
		: `${valuePc.toFixed(digitsBelowOne)}${suffix}`;
}