import axios from 'axios';
import i18n from 'i18next';
import {capitalize} from "../i18n";
import {paginationStartSkip} from "../util/pagination";
import axiosRetry from "axios-retry";
import {decrypt} from "./crypto";

/** DEFAULT_PAGE_SIZE */
const DEFAULT_PAGE_SIZE = 12;

/** getWebSocketUrl */
export function getWebSocketUrl(userId) {

    return `${(process.env.REACT_APP_API_BASE_URL || window.location.origin)
        .replace(/^https/, "wss")
        .replace(/^http/, "ws")}/api/v1/ws/${userId}`;
}

/**
 * @type {axios.AxiosInstance}
 */
const apiClient = axios.create({
    baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/v1`,
    headers: {
        'Content-Type': 'application/json'
    }
});

axiosRetry(apiClient, {
    retries: 2, // Maximum number of retries
    retryCondition: (error) => {
        const status = error.response?.status;

        // Retry for network errors, idempotent requests, 429, 503, and 500 errors
        return (
            axiosRetry.isNetworkOrIdempotentRequestError(error) ||
            [500, 503, 429].includes(status)
        );
    },
    retryDelay: (retryCount) => {
        const delay = Math.pow(2, retryCount) * 1000; // Exponential backoff: 1s, 2s, 4s
        console.debug(`Retry attempt #${retryCount}, waiting for ${delay} ms`);
        return delay;
    },
});

/**
 * decryptJson
 */
function decryptJson(response) {

    if(process.env.REACT_APP_API_ENCRYPTION_ENABLED === "true") {
        return decrypt(response.data);
    }

    return response.data;
}

/**
 * Fetch Account
 *
 * @param idToken
 * @returns {Promise<any>}
 */
export const fetchAccount = async (idToken) => {
    const response = await apiClient.get(`/account`, toHeaders(idToken));
    return decryptJson(response);
};

/**
 * Fetch Account Logout
 *
 * @param idToken
 * @returns {Promise<any>}
 */
export const fetchAccountLogout = async (idToken) => {
    const response = await apiClient.post(`/account/logout`, undefined, toHeaders(idToken));
    return response.data;
};

/**
 * Fetch Account User
 *
 * @param settings
 * @param idToken
 * @returns {Promise<any>}
 */
export const fetchAccountUser = async (name, picture, idToken) => {

    const data = {
        name: name,
        picture: picture
    }

    const response = await apiClient.put(`/account/user`, data, toHeaders(idToken));
    return decryptJson(response);
};

/**
 * Fetch Account User Settings
 *
 * @param settings
 * @param idToken
 * @returns {Promise<any>}
 */
export const fetchAccountUserSettings = async (settings, idToken) => {
    const response = await apiClient.put(`/account/user/settings`, settings, toHeaders(idToken));
    return decryptJson(response);
};

/**
 * Fetch Account Delete
 *
 * @param idToken
 * @returns {Promise<any>}
 */
export const fetchAccountDelete = async (idToken) => {
    const response = await apiClient.delete(`/account`, toHeaders(idToken));
    return response.data;
};

/**
 * Fetch User
 *
 * @param userId
 * @param fields
 * @param cancelToken
 * @returns {Promise<any>}
 */
export const fetchUser = async (userId, fields, cancelToken) => {

    let paramFields = "";

    if(fields) {
        paramFields = `?fields=${fields}`;
    }

    const response = await apiClient.get(`/user/${userId}/${paramFields}`, toHeaders(undefined, cancelToken));
    return decryptJson(response);
};

/**
 * Fetch Payment Subscription
 *
 * @param priceId
 * @param idToken
 * @returns {Promise<any>}
 */
export const fetchPaymentSubscription = async (priceId, idToken) => {

    const data = {
        priceId: priceId,
        successUrl: `${window.location.origin}/success`,
        cancelUrl: `${window.location.origin}/cancel`
    };

    const response = await apiClient.post(`/payment/subscription`, data, toHeaders(idToken));
    return response.data;
};

/**
 * Fetch Payment Customer Session
 *
 * @param idToken
 * @returns {Promise<any>}
 */
export const fetchPaymentCustomerSession = async (idToken) => {

    const data = {
        returnUrl: `${window.location.origin}/account`
    };

    const response = await apiClient.post(`/payment/customer/session`, data, toHeaders(idToken));
    return response.data;
};

/**
 * Fetch Sponsor All
 *
 * @param random
 * @param longitude
 * @param latitude
 * @param section
 * @param id
 * @param cancelToken
 * @returns {Promise<any>}
 */
export const fetchSponsorAll = async (random, longitude, latitude, section, id, cancelToken) => {

    let paramLongitude = "";
    let paramLatitude = "";
    let paramId = "";

    if(longitude !== undefined) {
        paramLongitude = `&longitude=${longitude}`;
    }

    if(latitude !== undefined) {
        paramLatitude = `&latitude=${latitude}`;
    }

    if(id !== undefined) {
        paramId = `&id=${id}`;
    }

    const response = await apiClient.get(`/sponsor/all?random=${random}${paramLongitude}${paramLatitude}&section=${section}${paramId}`, toHeaders(undefined, cancelToken));
    return decryptJson(response);
};

/**
 * Send Mail
 *
 * @param subject
 * @param body
 * @param idToken
 * @param cancelToken
 * @returns {Promise<any>}
 */
export const sendMail = async (subject, body, idToken, cancelToken) => {
    const response = await apiClient.post(`/mail?subject=${subject}`, body, toHeaders(idToken, cancelToken));
    return response.data;
};

/**
 * Fetch Environment
 *
 * @returns {Promise<any>}
 */
export const fetchEnvironment = async (cancelToken) => {
    const response = await apiClient.post(`/environment`, undefined, toHeaders(undefined, cancelToken));
    return decryptJson(response);
};

/**
 * Fetch Wiki
 *
 * @param url
 * @param idToken
 * @param cancelToken
 * @returns {Promise<any>}
 */
export const fetchWiki = async (url, idToken, cancelToken) => {
    const response = await apiClient.post(`/wiki?lng=en&url=${url}`, undefined, toHeaders(idToken, cancelToken));
    return decryptJson(response);
};

/**
 * Fetch Insights
 *
 * @param collectionName
 * @param id
 * @param cancelToken
 * @returns {Promise<any>}
 */
export const fetchInsights = async (collectionName, id, cancelToken) => {
    const response = await apiClient.get(`/insights?collectionName=${collectionName}&id=${id}`, toHeaders(undefined, cancelToken));
    return decryptJson(response);
};

/**
 * Fetch Domain All
 *
 * @param idToken
 * @param cancelToken
 * @returns {Promise<any>}
 */
export const fetchDomainAll = async (idToken, cancelToken) => {
    const response = await apiClient.post(`/domain/all`, undefined, toHeaders(idToken, cancelToken));
    return decryptJson(response);
};

/**
 * Fetch Entity Category All
 *
 * @param random
 * @param page
 * @param pageSize
 * @param idToken
 * @param cancelToken
 * @returns {Promise<any>}
 */
export const fetchEntityCategoryAll = async (random, page, pageSize = DEFAULT_PAGE_SIZE, idToken, cancelToken) => {

    let where = '';
    let orderBy = `sort, name${capitalize(i18n.language)}`;

    const data = {
        lng: i18n.language,
        where: where,
        orderBy: orderBy,
    };

    if(random) {
        data.random = random;
    }

    if(page) {
        data.skip = paginationStartSkip(page, pageSize);
        data.limit = pageSize;
    }

    const response = await apiClient.post(`/entityCategory/all`, data, toHeaders(idToken, cancelToken));
    return decryptJson(response);
};

/**
 * Fetch Entity Category
 *
 * @param entityCategoryId
 * @param incrementViews
 * @param idToken
 * @param cancelToken
 * @returns {Promise<any>}
 */
export const fetchEntityCategory = async (entityCategoryId, incrementViews = false, idToken, cancelToken) => {
    const response = await apiClient.get(`/entityCategory/${entityCategoryId}?lng=${i18n.language}&incrementViews=${incrementViews}`, toHeaders(idToken, cancelToken));
    return decryptJson(response);
};

/**
 * Fetch Entity Category Pairing
 *
 * @param entityId
 * @param idToken
 * @param cancelToken
 * @returns {Promise<any>}
 */
export const fetchEntityCategoryPairing = async (entityId, idToken, cancelToken) => {
    const response = await apiClient.get(`/entityCategory/pairing/${entityId}?lng=${i18n.language}`, toHeaders(idToken, cancelToken));
    return decryptJson(response);
};

/**
 * Fetch Entity All
 *
 * @param entityIds
 * @param entityCategoryId
 * @param linkNames
 * @param random
 * @param page
 * @param pageSize
 * @param idToken
 * @param cancelToken
 * @returns {Promise<any>}
 */
export const fetchEntityAll = async (entityIds, entityCategoryId, linkNames, random, page, pageSize = DEFAULT_PAGE_SIZE, idToken, cancelToken) => {

    let where = '';

    if(entityIds && entityIds.length > 0) {
        if(where !== '') {
            where += ' AND ';
        }
        where += `entityId IN (${entityIds})`;
    }

    if(entityCategoryId) {
        if(where !== '') {
            where += ' AND ';
        }
        where += `categories.entityCategoryId = ${entityCategoryId}`;
    }

    if(linkNames?.length > 0) {
        if(where !== '') {
            where += ' AND ';
        }
        const quotedNames = linkNames.map(name => `'${name}'`).join(',');
        where += `links.name IN (${quotedNames})`;
    }

    const data = {
        lng: i18n.language,
        where: where,
    };

    if(random) {
        data.random = random;
    }

    if(page) {
        data.skip = paginationStartSkip(page, pageSize);
        data.limit = pageSize;
    }

    const response = await apiClient.post(`/entity/all`, data, toHeaders(idToken, cancelToken));
    return decryptJson(response);
};

/**
 * Fetch Entity
 *
 * @param entityId
 * @param excludeProjections
 * @param incrementViews
 * @param idToken
 * @param cancelToken
 * @returns {Promise<any>}
 */
export const fetchEntity = async (entityId, excludeProjections, incrementViews = false, idToken, cancelToken) => {

    let paramExcludeProjections = "";

    if(excludeProjections?.filter(id => id !== undefined).length > 0) {
        paramExcludeProjections = `&excludeProjections=${excludeProjections.filter(id => id !== undefined).map(excludeProjection => `${excludeProjection}`).join(',')}`;
    }

    const response = await apiClient.get(`/entity/${entityId}?lng=${i18n.language}${paramExcludeProjections}&incrementViews=${incrementViews}`, toHeaders(idToken, cancelToken));
    return decryptJson(response);
};

/**
 * Fetch Entity Pairing
 *
 * @param entityId
 * @param idToken
 * @param cancelToken
 * @returns {Promise<any>}
 */
export const fetchEntityPairing = async (entityId, random, idToken, cancelToken) => {

    let paramRandom = "";

    if(random !== undefined) {
        paramRandom = `&random=${random}`;
    }

    const response = await apiClient.get(`/entity/pairing/${entityId}?lng=${i18n.language}${paramRandom}`, toHeaders(idToken, cancelToken));
    return decryptJson(response);
};

/**
 * Fetch Ingredient All
 *
 * @param entityCategoryId
 * @param molecularSearchTypes
 * @param linkNames
 * @param ingredientIds
 * @param ingredientNames
 * @param search
 * @param random
 * @param page
 * @param pageSize
 * @param idToken
 * @param cancelToken
 * @returns {Promise<any|*[]>}
 */
export const fetchIngredientAll = async (entityCategoryId, molecularSearchTypes, linkNames, ingredientIds, ingredientNames, search, random, page, pageSize = DEFAULT_PAGE_SIZE, idToken, cancelToken) => {

    let where = '';

    if(entityCategoryId) {
        if(where !== '') {
            where += ' AND ';
        }
        where += `categories.entityCategoryId = ${entityCategoryId}`;
    }

    if(linkNames?.length > 0) {
        if(where !== '') {
            where += ' AND ';
        }
        const quotedNames = linkNames.map(name => `'${name}'`).join(',');
        where += `links.name IN (${quotedNames})`;
    }

    if(ingredientIds?.length > 0) {
        if(where !== '') {
            where += ' AND ';
        }
        where += `ingredientId IN (${ingredientIds})`;
    }

    if(ingredientNames?.length > 0) {
        if(where !== '') {
            where += ' AND ';
        }
        const quotedNames = ingredientNames.map(name => `'${name}'`).join(',');
        where += `name IN (${quotedNames})`;
    }

    if(!random && where === '' && search === undefined) {
        // console.debug('No search');
        return [];
    }

    // console.debug(`where ${where}`);

    let orderBy = `name${capitalize(i18n.language)}`;

    const data = {
        lng: i18n.language,
        search: search || '',
        molecularSearchTypes: molecularSearchTypes || '',
        where: where,
        orderBy: orderBy,
    };

    if(random) {
        data.random = random;
    }

    if(page) {
        data.skip = paginationStartSkip(page, pageSize);
        data.limit = pageSize;
    }

    const response = await apiClient.post(`/ingredient/all`, data, toHeaders(idToken, cancelToken));
    return decryptJson(response);
};

/**
 * Fetch Ingredient
 *
 * @param ingredientId
 * @param ingredientName
 * @param entityId
 * @param excludeProjections
 * @param incrementViews
 * @param idToken
 * @param cancelToken
 * @returns {Promise<*>}
 */
export const fetchIngredient = async (ingredientId, ingredientName, entityId, excludeProjections, incrementViews = false, idToken, cancelToken) => {

    let response = null;

    let paramExcludeProjections = "";

    if(excludeProjections?.filter(id => id !== undefined).length > 0) {
        paramExcludeProjections = `&excludeProjections=${excludeProjections.filter(id => id !== undefined).map(excludeProjection => `${excludeProjection}`).join(',')}`;
    }

    if (ingredientId) {
        response = await apiClient.get(`/ingredient/${ingredientId}?lng=${i18n.language}${paramExcludeProjections}&incrementViews=${incrementViews}`, toHeaders(idToken, cancelToken));
    }
    else if (ingredientName) {
        response = await apiClient.get(`/ingredient/name/${ingredientName}?lng=${i18n.language}${paramExcludeProjections}&incrementViews=${incrementViews}`, toHeaders(idToken, cancelToken));
    }
    else if (entityId) {
        response = await apiClient.get(`/ingredient/entity/${entityId}?lng=${i18n.language}${paramExcludeProjections}&incrementViews=${incrementViews}`, toHeaders(idToken, cancelToken));
    }

    return decryptJson(response);
};

/**
 * Fetch Molecule All
 *
 * @param andEntityIds
 * @param orEntityIds
 * @param moleculeIds
 * @param search
 * @param random
 * @param page
 * @param pageSize
 * @param idToken
 * @param cancelToken
 * @returns {Promise<any>}
 */
export const fetchMoleculeAll = async (andEntityIds, orEntityIds, moleculeIds, search, random, page, pageSize = DEFAULT_PAGE_SIZE, idToken, cancelToken) => {

    let where = '';

    if(andEntityIds?.length > 0) {
        andEntityIds.forEach(entityId => {
            if(where !== '') {
                where += ' AND ';
            }
            where += `entityIds=${entityId}`;
        });
    }

    if(orEntityIds?.filter(id => id !== undefined).length > 0) {
        if(where !== '') {
            where += ' AND ';
        }
        where += `entityIds IN (${orEntityIds})`;
    }

    if(moleculeIds?.filter(id => id !== undefined).length > 0) {
        if(where !== '') {
            where += ' AND ';
        }
        where += `moleculeId IN (${moleculeIds})`;
    }

    // if(where === '' && search === undefined) {
    //     console.debug('No search');
    //     return [];
    // }

    // console.debug(`where ${where}`);

    let orderBy = `name${capitalize(i18n.language)}`;

    const data = {
        lng: i18n.language,
        search: search || '',
        where: where,
        orderBy: orderBy
    };

    if(random) {
        data.random = random;
    }

    if(page) {
        data.skip = paginationStartSkip(page, pageSize);
        data.limit = pageSize;
    }

    const response = await apiClient.post(`/molecule/all`, data, toHeaders(idToken, cancelToken));
    return decryptJson(response);
};

/**
 * Fetch Molecule Full
 *
 * @param idToken
 * @param cancelToken
 * @returns {Promise<any>}
 */
export const fetchMoleculeFull = async (idToken, cancelToken) => {

    const data = {
        lng: i18n.language,
    };

    const response = await apiClient.post(`/molecule/full`, data, toHeaders(idToken, cancelToken));
    return decryptJson(response);
};

/**
 * Fetch Molecule
 *
 * @param moleculeId
 * @param incrementViews
 * @param idToken
 * @param cancelToken
 * @returns {Promise<any>}
 */
export const fetchMolecule = async (moleculeId, incrementViews = false, idToken, cancelToken) => {
    const response = await apiClient.get(`/molecule/${moleculeId}?lng=${i18n.language}&incrementViews=${incrementViews}`, toHeaders(idToken, cancelToken));
    return decryptJson(response);
};

/**
 * Fetch Recipe Category All
 *
 * @param level
 * @param random
 * @param page
 * @param pageSize
 * @param idToken
 * @param cancelToken
 * @returns {Promise<any>}
 */
export const fetchRecipeCategoryAll = async (level, random, page, pageSize = DEFAULT_PAGE_SIZE, idToken, cancelToken) => {

    let where = 'recipeCount>0';
    let orderBy = `sort, connectPath`;

    if(level) {
        if(where !== '') {
            where += ' AND ';
        }
        where += `level = ${level}`;
        orderBy = `sort, name${capitalize(i18n.language)}`;
    }

    // console.debug(`where ${where}`)

    const data = {
        lng: i18n.language,
        where: where,
        orderBy: orderBy,
    };

    if(random) {
        data.random = random;
    }

    if(page) {
        data.skip = paginationStartSkip(page, pageSize);
        data.limit = pageSize;
    }

    const response = await apiClient.post(`/recipeCategory/all`, data, toHeaders(idToken, cancelToken));
    return decryptJson(response);
};

/**
 * Fetch Recipe Category
 *
 * @param recipeCategoryId
 * @param incrementViews
 * @param idToken
 * @returns {Promise<any>}
 */
export const fetchRecipeCategory = async (recipeCategoryId, incrementViews = false, idToken, cancelToken) => {
    const response = await apiClient.get(`/recipeCategory/${recipeCategoryId}?lng=${i18n.language}&incrementViews=${incrementViews}`, toHeaders(idToken, cancelToken));
    return decryptJson(response);
};

/**
 * Fetch Recipe All
 *
 * @param recipeCategoryId
 * @param parentRecipeCategoryId
 * @param excludeRecipeId
 * @param recipeIds
 * @param ingredientIds
 * @param search
 * @param random
 * @param page
 * @param pageSize
 * @param idToken
 * @param cancelToken
 * @returns {Promise<any|*[]>}
 */
export const fetchRecipeAll = async (recipeCategoryId, parentRecipeCategoryId, excludeRecipeId, recipeIds, ingredientIds, search, random, page, pageSize = DEFAULT_PAGE_SIZE, idToken, cancelToken) => {

    let where = '';

    if(recipeCategoryId) {
        if(where !== '') {
            where += ' AND ';
        }
        where += `categories.recipeCategoryId = ${recipeCategoryId}`;
    }

    if(parentRecipeCategoryId) {
        if(where !== '') {
            where += ' AND ';
        }
        where += `categories.parentRecipeCategoryId = ${parentRecipeCategoryId}`;
    }

    if(excludeRecipeId) {
        if(where !== '') {
            where += ' AND ';
        }
        where += `recipeId NOT IN (${excludeRecipeId})`;
    }

    if(recipeIds?.filter(id => id !== undefined).length > 0) {
        if(where !== '') {
            where += ' AND ';
        }
        where += `recipeId IN (${recipeIds})`;
    }

    if(ingredientIds?.filter(id => id !== undefined).length > 0) {
        if(where !== '') {
            where += ' AND ';
        }
        where += `ingredients.ingredientId IN (${ingredientIds})`;
    }

    if(!random && where === '' && !search) {
        // console.debug('No search');
        return [];
    }

    // console.debug(`where ${where}`)

    let orderBy = `name${capitalize(i18n.language)}`;

    const data = {
        lng: i18n.language,
        search: search || '',
        where: where,
        orderBy: orderBy,
    };

    if(random) {
        data.random = random;
    }

    if(page) {
        data.skip = paginationStartSkip(page, pageSize);
        data.limit = pageSize;
    }

    const response = await apiClient.post(`/recipe/all`, data, toHeaders(idToken, cancelToken));
    return decryptJson(response);
};

/**
 * Fetch Recipe
 *
 * @param recipeId
 * @param incrementViews
 * @param idToken
 * @param cancelToken
 * @returns {Promise<any>}
 */
export const fetchRecipe = async (recipeId, incrementViews = false, idToken, cancelToken) => {
    const response = await apiClient.get(`/recipe/${recipeId}?lng=${i18n.language}&incrementViews=${incrementViews}`, toHeaders(idToken, cancelToken));
    return decryptJson(response);
};

/**
 * Fetch Recipe Image
 *
 * @param recipeId
 * @param idToken
 * @param cancelToken
 * @returns {Promise<any>}
 */
export const fetchRecipeImage = async (recipeId, idToken, cancelToken) => {

    try {
        const response = await apiClient.get(`/recipe/image/${recipeId}`, toHeaders(idToken, cancelToken));
        return response.data;
    }
    catch (error) {
        if (error.response && error.response.status === 404) {
            return "";
        }
        // else {
        //     throw error;
        // }
    }
};

/**
 * Fetch Generated Recipe All
 *
 * @param userId
 * @param projectId
 * @param search
 * @param random
 * @param page
 * @param pageSize
 * @param idToken
 * @param cancelToken
 * @returns {Promise<any|*[]>}
 */
export const fetchGeneratedRecipeAll = async (userId, projectId, publish, search, random, page, pageSize = DEFAULT_PAGE_SIZE, idToken, cancelToken) => {

    let where = '';

    if(userId) {
        if(where !== '') {
            where += ' AND ';
        }
        where += `userId='${userId}'`;
    }

    if(projectId) {
        if(where !== '') {
            where += ' AND ';
        }
        where += `projectId='${projectId}'`;
    }

    if(publish) {
        if(where !== '') {
            where += ' AND ';
        }
        where += `publish=${publish}`;
    }

    if(!random && where === '' && !search) {
        // console.debug('No search');
        return [];
    }

    // console.debug(`where ${where}`)

    let orderBy = `created DESC, title`;

    const data = {
        lng: i18n.language,
        search: search?.trim() || '',
        where: where,
        orderBy: orderBy,
    };

    if(random) {
        data.random = random;
    }

    if(page) {
        data.skip = paginationStartSkip(page, pageSize);
        data.limit = pageSize;
    }

    const response = await apiClient.post(`recipe/generated/all`, data, toHeaders(idToken, cancelToken));
    return decryptJson(response);
};

/**
 * Delete Generated Recipe Image
 *
 * @param generatedRecipeId
 * @param idToken
 * @param cancelToken
 * @returns {Promise<any>}
 */
export const deleteGeneratedRecipe = async (generatedRecipeId, idToken, cancelToken) => {
    const response = await apiClient.delete(`/recipe/generated/${generatedRecipeId}`, toHeaders(idToken, cancelToken));
    return response.data;
};

/**
 * Fetch Generated Recipe Image
 *
 * @param generatedRecipeId
 * @param idToken
 * @param cancelToken
 * @returns {Promise<any>}
 */
export const fetchGeneratedRecipeImage = async (generatedRecipeId, idToken, cancelToken) => {

    try {
        const response = await apiClient.get(`/recipe/generated/image/${generatedRecipeId}`, toHeaders(idToken, cancelToken));
        return response.data;
    }
    catch (error) {
        if (error.response && error.response.status === 404) {
            return "";
        }
        // else {
        //     throw error;
        // }
    }
};

/**
 * Generate Recipe
 *
 * @param projectId
 * @param message
 * @param newThread
 * @param idToken
 * @param cancelToken
 * @returns {Promise<any>}
 */
export const generateRecipe = async (projectId, newThread, message, idToken, cancelToken) => {
    const response = await apiClient.post(`/recipe/generate/${projectId}?newThread=${newThread}`, message, toHeaders(idToken, cancelToken));
    return response.data;
}

/**
 * Generate Recipe Image
 *
 * @param generatedRecipeId
 * @param message
 * @param idToken
 * @param cancelToken
 * @returns {Promise<any>}
 */
export const generateRecipeImage = async (generatedRecipeId, message, idToken, cancelToken) => {
    const response = await apiClient.post(`/recipe/generate/image/${generatedRecipeId}`, message, toHeaders(idToken, cancelToken));
    return response.data;
}

/**
 * Fetch Technique All
 *
 * @param search
 * @param random
 * @param page
 * @param pageSize
 * @param idToken
 * @param cancelToken
 * @returns {Promise<any|*[]>}
 */
export const fetchTechniqueAll = async (search, random, page, pageSize = DEFAULT_PAGE_SIZE, idToken, cancelToken) => {

    // console.debug(`where ${where}`)

    let orderBy = `name${capitalize(i18n.language)}`;

    const data = {
        lng: i18n.language,
        search: search || '',
        orderBy: orderBy,
    };

    if(random) {
        data.random = random;
    }

    if(page) {
        data.skip = paginationStartSkip(page, pageSize);
        data.limit = pageSize;
    }

    const response = await apiClient.post(`/technique/all`, data, toHeaders(idToken, cancelToken));
    return decryptJson(response);
};


/**
 * Fetch Utensil All
 *
 * @param search
 * @param random
 * @param page
 * @param pageSize
 * @param idToken
 * @param cancelToken
 * @returns {Promise<any|*[]>}
 */
export const fetchUtensilAll = async (search, random, page, pageSize = DEFAULT_PAGE_SIZE, idToken, cancelToken) => {

    if(!random && !search) {
        // console.debug('No search');
        return [];
    }

    // console.debug(`where ${where}`)

    let orderBy = `name${capitalize(i18n.language)}`;

    const data = {
        lng: i18n.language,
        search: search || '',
        orderBy: orderBy,
    };

    if(random) {
        data.random = random;
    }

    if(page) {
        data.skip = paginationStartSkip(page, pageSize);
        data.limit = pageSize;
    }

    const response = await apiClient.post(`/utensil/all`, data, toHeaders(idToken, cancelToken));
    return decryptJson(response);
};

/**
 * Fetch Count All
 *
 * @param domainId
 * @param random
 * @param where
 * @param idToken
 * @param cancelToken
 * @returns {Promise<any>}
 */
export const fetchCountAll = async (domainId, random, where, idToken, cancelToken) => {

    const data = {
        lng: i18n.language,
        where: where,
    };

    if(random) {
        data.random = random;
    }

    const response = await apiClient.post(`/${domainId}/count/all`, data, toHeaders(idToken, cancelToken));
    return response.data;
};

/**
 * toHeaders
 */
function toHeaders(idToken, cancelToken) {

    const result = {};

    if(idToken !== undefined) {
        result.headers = {
            Authorization: `Bearer ${idToken}`
        }
    }

    if(cancelToken !== undefined) {
        result.cancelToken = cancelToken;
    }

    return result;
}