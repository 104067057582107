import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import i18n, {localized, parameterLanguage} from "../../i18n";
import {useLoadingContext} from "../../components/loading/LoadingContext";
import useLifecycle from "../useLifecycle";
import {
	addEdge, Background, BackgroundVariant,
	getNodesBounds,
	MiniMap, Panel,
	ReactFlow, ReactFlowProvider,
	useEdgesState,
	useNodesState, useReactFlow
} from '@xyflow/react';
import classes from "./Studio.module.css";
import {
	Anchor,
	Box, Button, Center, Divider, Group, Highlight, NumberFormatter, Overlay,
	Paper,
	Stack,
	Table, Text, Transition,
	useMantineTheme
} from "@mantine/core";

import '@xyflow/react/dist/style.css';
import './react-flow-style.css';
import "allotment/dist/style.css";
import './allotment-style.css';
import './style.css';
import {useAccountContext} from "../../components/account/AccountContext";
import {homeNavigate} from "../home/HomeLink";
import {useTranslation} from "react-i18next";
import useResult from "../useResult";
import {StripedTable} from "../../components/stripedTable/StripedTable";
import {SearchInput} from "../../components/search/Search";
import {alphabeticComparator} from "../../util/utils";
import {paginationEndSkip, paginationStartSkip} from "../../util/pagination";
import {Paginator} from "../../components/paginator/Paginator";
import useEntityAll from "../ingredient/useEntityAll";
import useMoleculeFull from "../molecule/useMoleculeFull";
import {
	GRID_SIZE,
	entitiesMoleculesAggregation,
	getEntityById,
	searchEntities,
	toChildNode,
	toGroup,
	toNode,
	sortNodesByParent,
	getParentIds,
	findGroupNodeAtPosition,
	toSelection,
	toEntityId,
	getEntityCategory,
	PAIRING_LEVELS,
	accumulateFrequency,
	toCustomNode,
	generateAccentVariantsForWord,
	removeAccents,
	toIngredients,
	getNodeById,
} from "./StudioUtils";
import {CustomNode, DefaultEdge, GroupNode, InputNode, IntermediateNode} from "./Types";
import {create} from 'zustand';
import {useBodyStyle} from "../useBodyStyle";
import {Meta} from "../../components/meta/Meta";
import {useViewportHeight} from "../useViewportHeight";
import {ScrollableArea} from "./ScrollableArea";
import {Creations, DetailsPanel, InfoPanel, TabsLayout} from "./Details";
import useBrowserOrientation from "../useBrowserOrientation";
import {HeaderPanel, CommandPanel, ToolsPanel, Command} from "./CommandPanel";
import {Icon} from "../../components/icons/Icons";
import {Flexible} from "./Flexible";
import {studioNavigate, studioNewProjectNavigate, studioProjectNavigate} from "./StudioLink";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faHome, faTableList, faTrash} from "@fortawesome/free-solid-svg-icons";
import {DndContext, DragOverlay, MouseSensor, TouchSensor, useDroppable, useSensor, useSensors} from "@dnd-kit/core";
import {toDateTime} from "../../util/time";
import WordCloudComponent from "./Wordcloud";
import {createSnapModifier} from "@dnd-kit/modifiers";
import moment from "moment";
import useMedia from "../useMedia";
import {useFeatures} from "../../components/features/useFeatures";
import {LanguageSelector} from "../../components/languageSelector/LanguageSelector";
import {AccountLogin} from "../../components/account/Account";
import ScrollWatcher from "./ScrollWatcher";
import Motion from "./Motion";
import {SimpleBox} from "../../components/simpleBox/SimpleBox";
import {DeleteModal} from "../../components/delete/DeleteModal";

/**
 * useStudioStore
 */
export const useStudioStore = create((set) => ({

	studioLightThemeEnabled: localStorage.getItem('studioLightThemeEnabled') === null ? true : localStorage.getItem('studioLightThemeEnabled') === 'true',
	setStudioLightThemeEnabled: (studioLightThemeEnabled) => {
		set({ studioLightThemeEnabled });
		localStorage.setItem('studioLightThemeEnabled', studioLightThemeEnabled);
	},

	studioHeatmapEnabled: localStorage.getItem('studioHeatmapEnabled') === null ? false : localStorage.getItem('studioHeatmapEnabled') === 'true',
	setStudioHeatmapEnabled: (studioHeatmapEnabled) => {
		set({ studioHeatmapEnabled });
		localStorage.setItem('studioHeatmapEnabled', studioHeatmapEnabled);
	},

	studioMoleculesEnabled: localStorage.getItem('studioMoleculesEnabled') === null ? false : localStorage.getItem('studioMoleculesEnabled') === 'true',
	setStudioMoleculesEnabled: (studioMoleculesEnabled) => {
		set({ studioMoleculesEnabled });
		localStorage.setItem('studioMoleculesEnabled', studioMoleculesEnabled);
	},

	// Grid size state with default value 0
	studioGridSize: localStorage.getItem('studioGridSize') === null ? GRID_SIZE.min : parseInt(localStorage.getItem('studioGridSize'), GRID_SIZE.max),
	setStudioGridSize: (studioGridSize) => {
		set({ studioGridSize });
		localStorage.setItem('studioGridSize', studioGridSize);
	},

	// Entities state, stored only in memory
	entities: [],
	setEntities: (entities) => {
		set({ entities });
	},

}));

/**
 * StudioBanner
 */
export const StudioBanner = ({color = "primary"}) => {

	const {t} = useTranslation();
	const [left] = useState(Math.random() < 0.6);

	const { isAuthenticated, loginAccount } = useAccountContext();

	const navigate = useNavigate();

	const StudioIcon = () => <Icon name={"logo-full-fp"} style={{fill: `var(--mantine-color-${color}-6)`, width: "100%", height: "100%"}}/>

	return	<Anchor underline={false} onClick={() => isAuthenticated ? studioNavigate(navigate) : loginAccount()}>
			<SimpleBox color={color} highlightOnHover={isAuthenticated} onClick={() => isAuthenticated ? studioNavigate(navigate) : null}>
				<Group justify={"space-between"} align={"center"} gap={"md"}>
					{left && <StudioIcon />}
					<Stack gap={0} flex={1}>
						<Text flex={1} size={"sm"}>
							{t("studio.studioDescription")}
						</Text>
					</Stack>
					{!left && <StudioIcon />}
					{/*{left &&*/}
					{/*    <FontAwesomeIcon icon={faUpRightFromSquare} color={`var(--mantine-color-${color}-3)`} style={{width: "18px", height: "18px"}}/>*/}
					{/*}*/}
				</Group>
			</SimpleBox>
	</Anchor>
}

/**
 * ProjectLoader
 */
const ProjectLoader = ({color="secondary", onLoad = () => {}, onRemoveProject = () => {}, onNew = () => {}}) => {

	let entities = useStudioStore((state) => state.entities);

	const { userAccount, onUpdateUserAccountSettings, offUpdateUserAccountSettings } = useAccountContext();
	const [settings, setSettings] = useState(userAccount.getSettings().getStudio());

	const { FeaturePlans, Features } = useFeatures();

	const {t} = useTranslation();
	const theme = useMantineTheme();

	const { isSm } = useMedia();

	const [scrollActive, setScrollActive] = useState(false);

	/**
	 * handleOnLoad
	 */
	const handleOnLoad = (projectId) => {
		onLoad(projectId);
	}

	/**
	 * handleOnNew
	 */
	const handleOnNew = (entityId, ingredientLabel, ingredientName) => {
		onNew(entityId, ingredientLabel, ingredientName);
	}

	/**
	 * Header
	 */
	const Header = () => {

		const navigate = useNavigate();

		return (
			<Box p={"md"}>
				<Overlay color={`var(--mantine-color-${color}-2)`} backgroundOpacity={0.5} blur={7} radius={"md"} zIndex={-1}/>

				<Group align="center" justify="center" wrap={"nowrap"} gap={"xs"}>
					<Command color={"white"} label={"web"} onClick={() => homeNavigate(navigate)} variant={"subtle"}/>
					<AccountLogin showFPS={false} authenticatedColor = "white" authenticatedVariant={"subtle"}/>
					<LanguageSelector />
				</Group>
			</Box>
		)
	}

	/**
	 * Projects
	 */
	const Projects = ({w = 470, h= 470}) => {

		const [search, setSearch] = useState(" ");
		const [page, setPage] = useState(1);

		// Define the callback to execute on update
		const handleOnUpdateUserAccount = () => {
			setSettings(userAccount.getSettings().getStudio())
		};

		useEffect(() => {

			// Subscribe to the settings update event
			onUpdateUserAccountSettings(handleOnUpdateUserAccount);

			// Clean up by unsubscribing when component unmounts
			return () => {
				offUpdateUserAccountSettings(handleOnUpdateUserAccount);
			};

		}, [onUpdateUserAccountSettings, offUpdateUserAccountSettings]);

		useEffect(() => {
			setSearch("");
			setPage(1);
		}, []);

		/**
		 * searchItems
		 */
		const searchItems = useMemo(() => {

			try {
				if (!search) {

					if (settings.length <= 12) {
						setPage(1);
					}

					return settings;
				}

				const normalizedSearch = removeAccents(search.trim().toLowerCase());

				return settings.filter(project => {
					const projectTitle = project.title;
					if (!projectTitle) return false;

					return removeAccents(projectTitle.toLowerCase()).includes(normalizedSearch);
				});
			}
			catch(ignored) {
				// noop
			}

			return [];
		}, [search, settings]);

		/**
		 * @param value
		 */
		const onSearch = (value) => {
			setSearch(value);
			setPage(1);
		}

		return (
			<TabsLayout
				title={t("studio.myProjects")}
				color={`var(--mantine-color-${color}-9)`}
				backgroundColor={`var(--mantine-color-${color}-2)`}
				backgroundOpacity={0.5}
				backgroundZIndex={0}
				blur={7}
				w={w}
				h={h}
				collapsed={
					<Text size={"36px"} fw={700} lineClamp={1} c={`var(--mantine-color-${color}-9)`}>
						{settings.length}
					</Text>
				}
				tabs={[
					{
						fontAwesomeIcon: faTableList,
						content: <Flexible>

							<Flexible.Expandable>

								<ScrollableArea color={color}>

									<StripedTable striped={"even"} stripedColor={color} stickyHeader highlightOnHover
												  highlightOnHoverColor={color} className={classes.projectstable}>
										<Table.Thead>
											<Table.Tr>
												<Table.Th>
													<SearchInput
														iconColor={"white"}
														textInputClassNames={{
															input: classes.detailssarchinputsecondary
														}}
														size={"xs"}
														radius={0}
														value={search}
														placeholder={t('studio.project.project')}
														onChange={(value) => onSearch(value)}
													/>
												</Table.Th>
												<Table.Th ta={"right"}>
													{t("common.recipes")}
												</Table.Th>
												<Table.Th w={!isSm ? "60px" : "30px"}>
													&nbsp;
												</Table.Th>
											</Table.Tr>
										</Table.Thead>
										<Table.Tbody>
											{searchItems
												.sort((a, b) => alphabeticComparator(a.title, b.title))
												.slice(paginationStartSkip(page, 12), paginationEndSkip(page, 12))
												.map((item, index) => (
													<Table.Tr style={{cursor: "pointer", verticalAlign: "top"}}>
														<Table.Td onClick={() => handleOnLoad(item.id)}>
															<Stack gap={4}>
																<Highlight inherit highlight={generateAccentVariantsForWord(search)} fw={700}>
																	{item.title}
																</Highlight>
																<Text size={"xs"} opacity={0.75}>{item.description}</Text>
																<Text size={"xs"} opacity={0.75}>{toDateTime(item.created)}</Text>
															</Stack>
														</Table.Td>
														<Table.Td ta={"right"} style={{verticalAlign: "middle"}}
																  onClick={() => handleOnLoad(item.id)}>
															<Text size={"sm"} fw={700}>
																{item.creations?.length > 0 && item.creations?.length}
															</Text>
														</Table.Td>
														<Table.Td w={!isSm ? "60px" : "30px"} style={{verticalAlign: "middle"}}>
															<Group gap={"md"} wrap={"nowrap"}>
																{!isSm &&
																	<DeleteModal
																		targetComponent={
																			<Button color={"secondary"} c={"secondary.9"} variant={"subtle"} p={0} pl={7} pr={7}>
																				<FontAwesomeIcon icon={faTrash}/>
																			</Button>
																		}
																		description={t("common.deleteModal", {item: item.title})}
																		onDelete={() => {
																			onRemoveProject(item.id);
																		}}/>
																}
																<FontAwesomeIcon icon={faChevronRight}/>
															</Group>
														</Table.Td>
													</Table.Tr>
												))}
										</Table.Tbody>
									</StripedTable>
								</ScrollableArea>
							</Flexible.Expandable>

							<Flexible.Fixed style={{zIndex: "1"}}>
								<Paginator page={page} onPageChange={setPage} paginationSize={12}
										   selectedCount={searchItems.length} totalCount={settings.length}
										   wrapOn={"xs"} size={"md"} gap={"6px"} withControls={false}
										   color={color}
										   infoColor={color}
										   paginatorClassNames={{
											   control: classes.detailspaginationcontrolsecondary,
											   dots: classes.detailspaginationdotssecondary
										   }}/>
							</Flexible.Fixed>
						</Flexible>
					}
				]}
			/>
		)
	}

	/**
	 * NewProject
	 */
	const NewProject = ({w = 470, h= 470}) => {

		const [search, setSearch] = useState(" ");
		const [page, setPage] = useState(1);

		useEffect(() => {
			setSearch("");
			setPage(1);
		}, []);

		/**
		 * searchableEntities
		 */
		const searchableEntities = useMemo(() => {
			return FeaturePlans.STUDIO.enabled ? toIngredients(entities) : entities;
		}, [entities]);

		/**
		 * searchItems
		 */
		const searchItems = useMemo(() => {

			try {

				if (!search) {
					return searchableEntities;
				}

				const normalizedSearch = removeAccents(search);

				return searchEntities(searchableEntities, normalizedSearch);
			}
			catch(ignored) {
				// noop
			}

			return [];
		}, [search, searchableEntities]);

		/**
		 * @param value
		 */
		const onSearch = (value) => {
			setSearch(value);
			setPage(1);
		}

		/**
		 * TableRow
		 */
		const TableRow = ({entity, index}) => {

			/**
			 * entityRepresentativeIngredient
			 */
			const entityRepresentativeIngredient = useMemo(() => {
				return localized(entity.representativeIngredient, "name");
			}, [entity]);

			/**
			 * categoriesRepresentativeIngredient
			 */
			const categoriesRepresentativeIngredient = useMemo(() => {

				const ingredient = entity.representativeIngredient;
				let result = [];

				// Categories
				if(ingredient.categories && ingredient.categories.length > 0) {
					let categories = ingredient.categories.filter((category) => category.representative > 0)
					// return categories?.length > 0 ? categories[0] : ingredient.categories[0];
					result = categories && categories.length > 0 ? categories : ingredient.categories;
				}
				// Single category
				else if (ingredient.category) {
					result = [ingredient.category];
				}

				return result
					.sort((a, b) => alphabeticComparator(localized(a, "name"), localized(b, "name")))
					.map((category, index) => localized(category, 'name')).join(", ");

			}, [entity]);

			return (
				<Table.Tr key={`entity-${index}`} style={{color: `var(--mantine-color-${color}-9)`, cursor: "pointer"}} onClick={() => handleOnNew(entity.entityId, entityRepresentativeIngredient, entity.representativeIngredient.name)}>
					<Table.Td style={{verticalAlign: "middle"}}>
						<Group align={"center"} gap={0} wrap={"nowrap"}>
							<Stack gap={0}>
								<Text size={"sm"} fw={700}>
									<Highlight inherit highlight={generateAccentVariantsForWord(search)}>
										{entityRepresentativeIngredient}
									</Highlight>
								</Text>
								<Text size={"xs"} lh={1.3} opacity={0.75}>
									{categoriesRepresentativeIngredient}
								</Text>
							</Stack>
						</Group>
					</Table.Td>
					<Table.Td w={"30px"}>
						<FontAwesomeIcon icon={faChevronRight}/>
					</Table.Td>
				</Table.Tr>
			);
		}

		return (
			<TabsLayout
				title={t("studio.newProject")}
				color={`var(--mantine-color-${color}-9)`}
				backgroundColor={`var(--mantine-color-${color}-2)`}
				backgroundOpacity={0.5}
				backgroundZIndex={0}
				blur={7}
				collapsed={
					<Group align="flex-end" c={`var(--mantine-color-${color}-9)`} gap={"xs"}>
						<Text size={"36px"} fw={700} >
							<NumberFormatter thousandSeparator="'" value={searchableEntities.length} />
						</Text>
						<Text size={"sm"}>{t("studio.baseIngredients")}</Text>
					</Group>
				}
				tabs={[
					{
						fontAwesomeIcon: faTableList,
						content: <Flexible>

							<Flexible.Fixed>
								<Text size={"xs"} pb={"sm"}>{t("studio.addMainIngredient")}</Text>
							</Flexible.Fixed>

							<Flexible.Expandable>

								<ScrollableArea color={color}>

									<StripedTable striped={"even"} stripedColor={color} stickyHeader highlightOnHover highlightOnHoverColor={color} className={classes.newprojecttable}>
										<Table.Thead>
											<Table.Tr>
												<Table.Th colSpan={2}>
													<SearchInput
														iconColor={"white"}
														textInputClassNames= {{
															input: classes.detailssarchinputsecondary
														}}
														size={"xs"}
														radius={4}
														value={search}
														placeholder={t('studio.baseIngredient')}
														onChange={(value) => onSearch(value)}
													/>
												</Table.Th>
											</Table.Tr>
										</Table.Thead>
										<Table.Tbody>
											{searchItems
												.sort((a, b) => alphabeticComparator(localized(a.representativeIngredient, "name"), localized(b.representativeIngredient, "name")))
												.slice(paginationStartSkip(page, theme.custom.ingredient.paginationSize), paginationEndSkip(page, theme.custom.ingredient.paginationSize))
												.map((item, index) => (
													<TableRow entity={item} index={index}/>
												))}
										</Table.Tbody>
									</StripedTable>
								</ScrollableArea>
							</Flexible.Expandable>

							<Flexible.Fixed style={{zIndex: "1"}}>
								<Paginator page={page} onPageChange={setPage} paginationSize={theme.custom.ingredient.paginationSize}
										   selectedCount={searchItems.length} totalCount={searchableEntities.length}
										   wrapOn={"xs"} size={"md"} gap={"6px"} withControls={false}
										   color={color}
										   infoColor={color}
										   paginatorClassNames = {{
											   control: classes.detailspaginationcontrolsecondary,
											   dots: classes.detailspaginationdotssecondary
										   }}/>
							</Flexible.Fixed>
						</Flexible>
					}
				]}
			/>
		)
	}

	/**
	 * memoizedCreations
	 */
	const memoizedCreations = useMemo(() => {
		return <Creations showDivider={false}
						  tabColor={`var(--mantine-color-tertiary-9)`}
						  backgroundColor={`var(--mantine-color-tertiary-2)`}
						  backgroundOpacity={0.5}
						  backgroundZIndex={0}
						  blur={7}/>;
	}, [settings]);

	/**
	 * memoizedProjects
	 */
	const memoizedProjects = useMemo(() => {
		return settings.length > 0 ? <Projects /> : null;
	}, [settings]);

	/**
	 * memoizedNewProject
	 */
	const memoizedNewProject = useMemo(() => {
		return entities?.length > 0 && (Features.studio.features.unlimitedProjects.plan.enabled || (Features.studio.features.limitedProjects.plan.enabled && settings.length < Features.studio.features.limitedProjects.limit)) ?
			<NewProject /> : null;
	}, [entities, settings]);

	/**
	 * randomAttribute
	 */
	const randomAttribute = useMemo(() => {

		// Defines a list of attributes, each with an assigned weight that determines its likelihood of being chosen.
		const attributes = [
			{ value: "emotions", weight: 8 },
			{ value: "flavors", weight: 5 },
			{ value: "tastes", weight: 2 },
			{ value: "odors", weight: 2 }
		];

		// Creates an array where each attribute appears multiple times based on its weight, increasing its probability of selection.
		const weightedArray = [];
		attributes.forEach(attr => {
			for (let i = 0; i < attr.weight; i++) {
				weightedArray.push(attr.value);
			}
		});

		// Selects a random value from the weighted array, ensuring probability distribution follows the assigned weights.
		const randomIndex = Math.floor(Math.random() * weightedArray.length);
		return weightedArray[randomIndex];
	}, []);

	/**
	 * randomEntity
	 */
	const words = useMemo(() => {

		const max = 20;
		const min = 8;

		if(entities && entities.length > 0) {

			const randomEntity = entities[Math.floor(Math.random() * entities.length)];

			const accumulatedFrequencies = accumulateFrequency(randomEntity.molecules, randomAttribute).slice(0, 5); // only the first 5 most frequent

			// Add the representative ingredient with a fixed value
			const result = [{ text: localized(randomEntity.representativeIngredient, "name"), value: 30 }];

			// Add the attribute
			result.push({
				text: t(`molecule.${randomAttribute}`),
				value: Math.floor(Math.random() * (max - min)) + min // Random value between max and min
			});

			// Add selected molecules attribute with random values
			result.push(...accumulatedFrequencies.map(accumulatedFrequency => ({
				text: accumulatedFrequency.name.toLowerCase(),
				value: Math.floor(Math.random() * (max - min)) + min // Random value between max and min
				// value: (accumulatedFrequency.pc * 10 / 100) + 10
			})));

			return result;
		}

		return [];

	}, [entities]);

	return (!isSm ?
			<Group w={"100%"} h={"100%"} justify={"space-between"} wrap={"nowrap"} style={{position: "relative"}}>

				<Box w={"100%"} h={"100%"} className={classes.animatedbackground} opacity={1}
					 style={{position: "absolute"}}/>

				<Box className={classes.homebackground}/>

				<Box style={{
					position: "absolute",
					top: "26px",
					// right: `calc(var(--mantine-spacing-md) ${scrollActive ? " * 2 + 470px" : ""})`,
					left: "var(--mantine-spacing-md)",
					// transform: 'translate(-50%, 0%)',
					zIndex: 2
				}}>
					<Header />
				</Box>

				<Box style={{
					position: "absolute",
					left: "50%",
					bottom: "calc(var(--mantine-spacing-xs) * 2 - 10px)",
					transform: 'translate(-50%, -50%)'
				}}>
					<StudioCredits color={`var(--mantine-color-white)`} dividerColor={`var(--mantine-color-white)`}/>
				</Box>

				<Transition
					mounted={words && words.length > 0}
					transition="fade-right"
					enterDelay={100}
					duration={200}
					timingFunction="ease-out">
					{(styles) => (
						<Box style={{
							...styles,
							position: "absolute",
							top: "1%",
							left: "20%",
							width: "100%",
							height: "40%",
							mixBlendMode: "overlay",
							opacity: 0.25,
							zIndex: 0
						}}>
							{words && words.length > 0 &&
								<WordCloudComponent words={words}/>
							}
						</Box>
					)}
				</Transition>

				<Center p={"md"} w={"50%"}>
					<Stack miw={"500px"} w={"100%"} justify={"flex-start"} gap={"60px"} pl={"xl"} style={{zIndex: 1}}>
						<Icon name={"logo-full-fp"}
							  style={{position: "relative", fill: "white", width: "100%", height: "100%"}}/>
						<Text pl={"20%"} c={"white"} fw={300} size={"md"}
							  style={{textAlign: "justify"}}>{t("studio.studioDescription")}</Text>
					</Stack>
				</Center>

				<Box miw={"470px"} h="100%" style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					overflow: "hidden",
					zIndex: 1
				}}>
					<ScrollableArea style={{overflowY: "auto", display: "flex", justifyContent: "center"}}>

						<Motion initial={{ opacity: 0, x: 50 }} animate={{ opacity: 1, x: 0 }}>
						<Stack gap={"xs"} align="flex-end" justify="center" m={"md"}
							   style={{
								   minHeight: "calc(var(--vh, 1vh) * 100 - 32px) ", // Ensures it takes full height when smaller
								   maxHeight: "100%", // Prevents exceeding the viewport height
							   }}
						>
							<ScrollWatcher onScrollChange={(value) => setScrollActive(value)}>
								{memoizedCreations}
								{memoizedProjects}
								{memoizedNewProject}
							</ScrollWatcher>

						</Stack>
						</Motion>
					</ScrollableArea>
				</Box>
			</Group>
		:
			<>
				<Box w={"100%"} h={"100%"} className={classes.animatedbackground} opacity={1}
					 style={{position: "absolute"}}/>

				<Box className={classes.homebackground}/>

				<Stack w={"100%"} h={"100%"} align="center" justify="space-between" style={{position: "relative"}}>

					<Icon name={"logo-full-fp"}
						  style={{position: "relative", fill: "white", top: "calc(var(--mantine-spacing-xl) * 1)", marginLeft: "-10%", width: "120%", height: "120%"}}/>

					<Box w={"100%"} p={"sm"}>
						{memoizedProjects}
					</Box>

				</Stack>
			</>
	)
}

/**
 * StudioCredits
 */
export const StudioCredits = ({blur = 64, color = `var(--mantine-color-gray-5)`, dividerColor = `var(--mantine-color-gray-3)`,
							  withFlavorDb2 = true, withFoodDataCentral = true, withReactFlow = true}) => {

	return (
		<Box p={6} c={color} mah={22}>
			<Overlay backgroundOpacity={0} blur={blur} radius={"md"} zIndex={-1}/>
			<Group gap={"xs"} wrap={"nowrap"}>
				<Text size={"10px"} component={"a"} style={{cursor: "pointer", whiteSpace: "nowrap"}} href={"https://swissimpact.com"} target={"_blank"}>@ 2025 Swiss Impact Ltd. All rights reserved.</Text>
				{withFlavorDb2 &&
					<>
						<Divider orientation="vertical" color={dividerColor}/>
						<Text size={"10px"} component={"a"} style={{cursor: "pointer", whiteSpace: "nowrap"}} href={"https://cosylab.iiitd.edu.in/flavordb2/"} target={"_blank"}>FlavorDb2</Text>
					</>
				}
				{withFoodDataCentral &&
					<>
						<Divider orientation="vertical" color={dividerColor}/>
						<Text size={"10px"} component={"a"} style={{cursor: "pointer", whiteSpace: "nowrap"}} href={"https://fdc.nal.usda.gov/"} target={"_blank"}>FoodData Central USDA</Text>
					</>
				}
				{withReactFlow &&
					<>
						<Divider orientation="vertical" color={dividerColor}/>
						<Text size={"10px"} component={"a"} style={{cursor: "pointer", whiteSpace: "nowrap"}} href={"https://reactflow.dev/"} target={"_blank"}>React Flow</Text>
					</>
				}
			</Group>
		</Box>
	)
}

/**
 * useUndoRedoFlow
 */
const useUndoRedoFlow = ({nodes, setNodes, edges, setEdges, maxHistory = 1000}) => {

	const [history, setHistory] = useState([]);
	const [future, setFuture] = useState([]);

	const isSameFlow = (a, b) => {
		return JSON.stringify(a.nodes) === JSON.stringify(b.nodes) &&
			JSON.stringify(a.edges) === JSON.stringify(b.edges);
	};

	/**
	 * undoRedoHistory
	 */
	const undoRedoHistory = useCallback((customNodes = nodes, customEdges = edges) => {

		if (customNodes.length === 0 && customEdges.length === 0) {
			return;
		}

		const current = { nodes: customNodes, edges: customEdges };
		const last = history[history.length - 1];

		setHistory((prev) => {
			const next = [...prev, current];
			return next.length > maxHistory ? next.slice(1) : next;
		});

		setFuture([]); // Clear redo stack

		console.log("undoRedoHistory", customNodes, customEdges);

	}, [nodes, edges, history, maxHistory]);

	/**
	 * undo
	 */
	const undo = useCallback(() => {

		if (history.length === 0) {
			return;
		}

		const previous = history[history.length - 1];

		setFuture((f) => [...f, { nodes, edges }]);

		setNodes(previous.nodes);
		setEdges(previous.edges);

		setHistory((h) => h.slice(0, h.length - 1));

		console.log("undo", history, future)

	}, [history, nodes, edges]);

	/**
	 * redo
	 */
	const redo = useCallback(() => {

		if (future.length === 0) {
			return;
		}

		const next = future[future.length - 1];

		setHistory((h) => [...h, { nodes, edges }]);

		setNodes(next.nodes);
		setEdges(next.edges);

		setFuture((f) => f.slice(0, f.length - 1));

		console.log("redo", history, future)

	}, [future, nodes, edges]);

	/**
	 * reset - clears history and future stacks
	 */
	const undoRedoReset = useCallback(() => {

		setHistory([]);
		setFuture([]);

		console.log("undoRedoReset", history, future)
	}, []);

	return {
		undoRedoHistory,
		undoRedoReset,
		undo,
		canUndo: history.length > 0,
		redo,
		canRedo: future.length > 0,
	};
}

/**
 * StudioPage
 */
const StudioPage = (props) => {

	const location = useLocation();

	const {paramLng, paramProjectId} = useParams();
	const lng = parameterLanguage(paramLng);

	const { isAuthenticated, userAccount, updateUserAccountSettings } = useAccountContext();

	const {isLoading, isLoaded} = useLoadingContext();

	const navigate = useNavigate();
	const {t} = useTranslation();

	const [nodes, setNodes, onNodesChange] = useNodesState([]);
	const [edges, setEdges, onEdgesChange] = useEdgesState([]);

	const [selection, setSelection] = useState(toSelection());

	let entities = useStudioStore((state) => state.entities);
	const setEntities = useStudioStore((state) => state.setEntities);

	const {screenToFlowPosition, setViewport, getIntersectingNodes} = useReactFlow();

	const { Features } = useFeatures();

	const { isSm } = useMedia();
	// const { isLandscape } = useBrowserOrientation();

	const {undoRedoHistory, undoRedoReset, undo, canUndo, redo, canRedo} =
		useUndoRedoFlow({nodes, setNodes, edges, setEdges});

	let lightThemeEnabled = useStudioStore((state) => state.studioLightThemeEnabled);
	let studioGridSize = useStudioStore((state) => state.studioGridSize);

	const [rfInstance, setRfInstance] = useState(null);

	const [selectionOnDrag, setSelectionOnDrag] = useState(false);

	const [draggedData, setDraggedData] = useState(undefined);

	const mouseSensor = useSensor(MouseSensor, {
		// Require the mouse to move by 10 pixels before activating
		activationConstraint: {
			distance: 10,
		},
	});
	const touchSensor = useSensor(TouchSensor, {
		// Press delay of 250ms, with tolerance of 5px of movement
		activationConstraint: {
			delay: 100,
			tolerance: 10,
		},
	});

	const sensors = useSensors(
		mouseSensor,
		touchSensor
	);

	const nodeTypes = {
		input: InputNode,
		intermediate: IntermediateNode,
		custom: CustomNode,
		group: GroupNode
	};

	const edgeTypes = {
		default: DefaultEdge,
		unknownCompatibility: DefaultEdge
	};

	const {isOver, setNodeRef} = useDroppable({
		id: "droppable"
	});

	useLifecycle({
		onMount: () => {

			if (!isAuthenticated) {
				homeNavigate(navigate);
			}

			isLoading(false, false);
		},
		onUnmount: () => {
		}
	});

	useViewportHeight();

	// Change body style for this page
	useBodyStyle({
		overflow: "hidden",
		width: "100%",
		height: "100%",
		userSelect: "none",
		backgroundColor: "var(--mantine-color-body)",
		fontFamily: "Urbanist",
		letterSpacing: "0.6px",
	});

	const {data: dataEntities, isLoaded: isLoadedEntities, totalCount: totalCountEntities,
		reset: resetEntities, refetch: refetchEntities	} =
		useEntityAll({
			enabled: true,
		})

	const {data: dataMolecules, isLoaded: isLoadedMolecules, totalCount: totalCountMolecules,
		reset: resetMolecules, refetch: refetchMolecules	} =
		useMoleculeFull({
			enabled: isLoadedEntities,
		})

	const {isSuccess, isError} =
		useResult({
			isSuccess: isLoadedEntities & isLoadedMolecules,
			onSuccess: () => {
				const entities = entitiesMoleculesAggregation(dataEntities, dataMolecules, Features);
				setEntities(entities);
				isLoaded(true);
			}
		})

	useEffect(() => {
		if (lng !== undefined && lng !== i18n.language) {
			i18n.changeLanguage(lng)
				.then(value => window.location.reload());
		}
	}, [lng]);

	/**
	 * snapToGrid
	 */
	function snapToGrid(value) {
		const checkedGridSize = studioGridSize < GRID_SIZE.min ? GRID_SIZE.min : studioGridSize;
		return Math.round(value / checkedGridSize) * checkedGridSize;
	}

	/**
	 * getNodePadding
	 */
	function getNodePadding(moleculesLength) {
		return snapToGrid(moleculesLength / 4 + 10);
	}

	/**
	 * settings
	 */
	const settings = useMemo(() => {

		if(paramProjectId && entities) {
			const result = userAccount.getSettings().getStudioProjectById(paramProjectId, location.state?.entityId !== undefined);

			if(!result) {
				navigateTo();
			}

			return result;
		}

		return undefined;
	}, [paramProjectId, location, entities]);

	/**
	 * saveSettings
	 */
	const saveSettings = useCallback(() => {

		setTimeout(() => {

			console.debug("saveSettings", settings?.id)

			if(settings) {

				if (rfInstance) {
					settings.flow = rfInstance.toObject();
				}

				settings.updated = moment().toISOString();
			}

			updateUserAccountSettings(userAccount);
		}, 100);

	}, [rfInstance, settings]);

	/**
	 * onRestore
	 */
	const onRestore = useCallback((settings, entities) => {

		const restoreFlow = async () => {

			const flow = settings.flow;

			if (flow) {

				const { x = 0, y = 0, zoom = 1 } = flow.viewport;

				// Update nodes, applying changes only for type "input" and "intermediate"
				const updatedNodes = (flow.nodes || []).map(node => {

					if (node.type === "input" || node.type === "intermediate") {

						const entity = getEntityById(entities, node?.data?.entity?.id); // Retrieve entity
						const ingredient = entity.ingredients?.find(ing => ing.name === node.data?.entity?.ingredientName); // Retrieve ingredient

						return {
							...node,
							selected: false,
							data: {
								...node.data,
								label: localized(ingredient, "name"), // Update label if entity exists
								entity: {
									...node.data?.entity,
									categoryLabel: localized(getEntityCategory(entity), "name")
								}
							}
						};
					}

					// Leave other nodes unchanged
					return {
						...node,
						selected: false
					};
				});

				setNodes(updatedNodes);

				const updatedEdges = (flow.edges || []).map(edge => ({
					...edge,
					selected: false
				}));

				setEdges(updatedEdges);

				await setViewport({x, y, zoom});

				setSelection(toSelection());
			}
		};

		restoreFlow();

	}, [setNodes, setViewport]);

	/**
	 * Handle restore flow
	 */
	useEffect(() => {
		if (settings !== undefined && settings.flow !== undefined && entities !== undefined && entities.length > 0) {
			console.debug("restore flow", settings?.id)
			onRestore(settings, entities);
		}
		else {
			console.debug("reset nodes and edges")
			setNodes([]);
			setEdges([]);
		}
	}, [settings, entities]);

	/**
	 * Handle new input node
	 */
	useEffect(() => {

		if(!settings) {
			return;
		}

		// Get entityId from location state
		const entityId = location.state?.entityId;
		const ingredientLabel = location.state?.ingredientLabel;
		const ingredientName = location.state?.ingredientName;

		if(!entityId && !ingredientLabel && !ingredientName) {
			return;
		}

		// Get entity
		const entity = getEntityById(entities, entityId);

		// Handle new input node
		handleNewInputNode(entity, ingredientLabel, ingredientName);

		// Remove last browser history page by replacing with a new one without new entityId information
		navigateTo(settings.id, undefined, undefined, undefined, true);

	}, [settings, entities, location]);

	/**
	 * 	navigateTo
	 */
	function navigateTo(projectId, entityId, ingredientLabel, ingredientName, replace = false) {

		undoRedoReset();

		if (projectId !== undefined) {

			if(entityId !== undefined && ingredientLabel !== undefined && ingredientName !== undefined) {
				studioNewProjectNavigate(navigate, projectId, entityId, ingredientLabel, ingredientName);
			}
			else {
				studioProjectNavigate(navigate, projectId, replace);
			}
		}
		else {
			studioNavigate(navigate);
		}
	}

	/**
	 * pairingLevel
	 */
	const pairingLevel = useMemo(() => {

		if(Features.studio.features.unlimitedPairings.plan.enabled) {
			return PAIRING_LEVELS.UNLIMITED_PAIRINGS;
		}

		// if(Features.studio.features.uniquePairingPerIngredient.plan.enabled) {
		// 	return PAIRING_LEVELS.UNIQUE_PAIRING_PER_INGREDIENT;
		// }

		return PAIRING_LEVELS.UNIQUE_INGREDIENT_GLOBALLY;

	}, []);

	/**
	 * onConnect
	 */
	const onConnect = useCallback((params) => {
		setEdges((eds) => addEdge(params, eds));
	},[setEdges]);

	/**
	 * onSelectionChange
	 */
	const onSelectionChange = useCallback(({nodes: selectedNodes, edges: selectedEdges}) => {

		if(selectedNodes.length > 0 || selectedEdges.length > 0) {
			setSelection(toSelection(selectedNodes, selectedEdges, false));
		}
		// else if(selectedNodes.length === 0 && selectedEdges.length === 0) {
		// 	setSelection(toSelection([], [], true));
		// }

	}, []);

	/**
	 * onPaneClick
	 */
	const handlePaneClick = useCallback((event) => {
		setSelection(toSelection([], [], true))
	}, []);

	/**
	 * onDragOver
	 */
	const onDragOver = useCallback((event) => {
		event.preventDefault();
		event.dataTransfer.dropEffect = 'move';
	}, []);

	/**
	 * onDrop
	 */
	const onDrop = useCallback((clientX, clientY, node, edge) => {

		// Check if the dropped element is valid
		if (node === undefined) {
			return;
		}
		console.log("onDrop")
		undoRedoHistory();

		// Convert screen position to flow position
		const position = screenToFlowPosition({
			x: snapToGrid(clientX),
			y: snapToGrid(clientY),
		});

		let newNode = undefined;

		switch (node.type) {

			case "intermediate":
				const entity = getEntityById(entities, toEntityId(node));

				newNode = toNode(node.id, node.type, position,
					node.data?.label || localized(entity.representativeIngredient, "name"),
					node.data?.entity?.ingredientName || entity.representativeIngredient.name,
					entity, getNodePadding(entity.molecules.length));
				break;

			case "custom":
				newNode = toCustomNode(node.id, position, node.data.label);
				break;

			default:
				return;

		}

		const groupNode = findGroupNodeAtPosition(nodes, position);

		if(groupNode) {
			newNode = toChildNode(newNode, groupNode.id, groupNode.position);
		}

		setNodes((nds) => nds.concat(newNode));

		if(edge !== undefined) {
			setEdges((eds) => eds.concat(edge));
		}
	},[entities, nodes, screenToFlowPosition]);

	/**
	 * handleUndoRedoCooldown
	 */
	const undoRedoHistoryCooldownRef = useRef(false);
	function undoRedoHistoryCooldown(changes) {

		const immediateChangeNodeIds = changes.filter((change) =>
			["add", "remove", "reset", "update"].includes(change.type)
		).map((change) => change.id);

		const progressiveChangeNodeIds = changes.filter((change) =>
			change.type === "dimensions"
		).map((change) => change.id);

		if (immediateChangeNodeIds.length > 0) {
			console.log("handleNodesChange hasImmediateChange")
			undoRedoHistory();
		}
		else if (progressiveChangeNodeIds.length === 1 && getNodeById(nodes, progressiveChangeNodeIds[0]).type === "group" && !undoRedoHistoryCooldownRef.current) {

			console.log("handleNodesChange hasProgressiveChange")

			undoRedoHistory();
			undoRedoHistoryCooldownRef.current = true;

			setTimeout(() => {
				undoRedoHistoryCooldownRef.current = false;
			}, 1000);
		}
	}

	/**
	 * handleNodesChange
	 */
	const handleNodesChange = useCallback((changes) => {

		// Check for remove input node
		const filteredChanges = changes.filter(change => {

			if (change.type !== 'remove') {
				return true;
			}

			const nodeToRemove = nodes.find(n => n.id === change.id);
			return nodeToRemove?.type !== 'input'; // Do not remove if input
		});

		undoRedoHistoryCooldown(filteredChanges);

		// Get IDs of nodes to remove from the changes
		const nodesToRemoveIds = filteredChanges
			.filter((change) => change.type === "remove")
			.map((change) => change.id);

		if (nodesToRemoveIds.length > 0) {

			/**
			 * Function to recursively collect all nodes to remove, including connected nodes.
			 * @param {Array} nodeIdsToRemove - IDs of nodes to remove
			 * @returns {Array} - Array of all node IDs to remove
			 */
			const collectNodesToRemove = (nodeIdsToRemove) => {

				// Use a Set to store all nodes to remove (to avoid duplicates)
				let allNodesToRemove = new Set(nodeIdsToRemove);

				let newNodesToRemove; // To track newly identified nodes to remove

				do {
					// Find all nodes that are connected to the current set of nodes to remove
					newNodesToRemove = edges
						.filter((edge) => nodeIdsToRemove.includes(edge.source)) // Find edges where the source node is being removed
						.map((edge) => edge.target) // Get the target nodes of those edges
						.filter((nodeId) => !allNodesToRemove.has(nodeId)); // Only include nodes not already marked for removal

					// Add the new nodes to the set
					newNodesToRemove.forEach((nodeId) => allNodesToRemove.add(nodeId));

					// Update the list of nodes to check in the next iteration
					nodeIdsToRemove = newNodesToRemove;
				}
				while (newNodesToRemove.length > 0); // Continue until no new nodes are found

				return Array.from(allNodesToRemove); // Convert the Set back to an array
			};

			// Collect all nodes to remove, including connected ones
			const allNodesToRemoveIds = collectNodesToRemove(nodesToRemoveIds);
			const removedNodeIdsSet = new Set(allNodesToRemoveIds);

			// Filter out nodes that need to be removed
			let updatedNodes = nodes.filter(
				(node) => !allNodesToRemoveIds.includes(node.id)
			);

			// Detach input node if parent is removed
			updatedNodes = updatedNodes.map((node) => {

				if (node.type === 'input' && node.parentId && removedNodeIdsSet.has(node.parentId)) {
					const parentNode = nodes.find(n => n.id === node.parentId);
					if (!parentNode) return node;

					const absoluteX = (parentNode.position?.x || 0) + (node.position?.x || 0);
					const absoluteY = (parentNode.position?.y || 0) + (node.position?.y || 0);

					return {
						...node,
						parentId: undefined,
						position: { x: absoluteX, y: absoluteY },
						positionAbsolute: { x: absoluteX, y: absoluteY }
					};
				}

				return node;
			});

			// Remove edges that reference removed nodes
			const updatedEdges = edges.filter(
				(edge) =>
					updatedNodes.find((node) => node.id === edge.source) && // Keep edge if source node still exists
					updatedNodes.find((node) => node.id === edge.target)    // Keep edge if target node still exists
			);

			// Update nodes and edges
			setNodes(updatedNodes);
			setEdges(updatedEdges);
			setSelection(toSelection());
		}

		// Apply normal React Flow filteredChanges (dragging, adding nodes, etc.)
		onNodesChange(filteredChanges);
		onEdgesChange(filteredChanges);

		// Save settings
		saveSettings();

	}, [onNodesChange, onEdgesChange, nodes, edges, setNodes, setEdges]);

	/**
	 * handleNewInputNode
	 */
	const handleNewInputNode = useCallback((entity, ingredientLabel, ingredientName) => {

			try {

				// TODO check se corretto
				const reactFlowBounds = document.querySelector('.react-flow').getBoundingClientRect();

				const viewportCenter = {
					x: snapToGrid(reactFlowBounds.width / 2),
					y: snapToGrid(reactFlowBounds.height / 2),
				};

				// Usa `screenToFlowPosition` per convertire il centro del viewport nelle coordinate di React Flow
				const position = screenToFlowPosition(viewportCenter);

				const newNode = toNode(crypto.randomUUID(), "input", position,
					ingredientLabel || localized(entity.representativeIngredient, "name"),
					ingredientName || entity.representativeIngredient.name,
					entity, getNodePadding(entity.molecules.length),
					true);

				setNodes((nds) => [...nds, newNode]);
			}
			catch (e) {
				// noop
			}
		}, [setNodes]);

	/**
	 * handleGroupNodes
	 */
	const handleGroupNodes = useCallback(() => {

		console.log("handleGroupNodes")
		undoRedoHistory();

		const selectedNodes = nodes.filter((node) => node.selected);

		const groupId = crypto.randomUUID();
		const bounds = getNodesBounds(selectedNodes);

		if (!bounds) {
			console.error("Error: Unable to calculate the bounds of the selected nodes.");
			return;
		}

		const groupPosition = {
			x: snapToGrid(bounds.x) - GRID_SIZE.max,
			y: snapToGrid(bounds.y) - GRID_SIZE.max * 3,
		};

		const groupSize = {
			width: snapToGrid(bounds.width) + GRID_SIZE.max * 2,
			height: snapToGrid(bounds.height) + GRID_SIZE.max * 4,
		};

		const groupNode = toGroup(groupId, groupPosition, groupSize);

		const updatedNodes = nodes.map((node) =>
			selectedNodes.some((selectedNode) => selectedNode.id === node.id) ? toChildNode(node, groupId, groupPosition) : node
		);

		// Update nodes
		setNodes(sortNodesByParent([groupNode, ...updatedNodes]));

	}, [nodes, setNodes]);

	/**
	 * handleNodeDragStart
	 */
	const handleNodeDragStart = useCallback((event, node) => {
		console.log("handleNodeDragStart")

		undoRedoHistory([...nodes], [...edges]);
	}, [nodes, edges, undoRedoHistory]);

	/**
	 * handleNodeDragStop
	 * This function handles the logic for when a node is dragged and released (drag stop event).
	 * It checks if the released node should become a child of a group node and updates its position accordingly.
	 */
	const handleNodeDragStop = useCallback((event, node) => {

		/**
		 * getPushedX
		 */
		function getPushedX(node, intersectedNode) {

			if(node.position.x + node.measured.width / 2 < intersectedNode.position.x + intersectedNode.measured.width / 2) {
				return intersectedNode.position.x - node.measured.width - GRID_SIZE.max;
			}

			return intersectedNode.position.x + intersectedNode.measured.width + GRID_SIZE.max;
		}

		// Update the nodes state
		setNodes((prevNodes) => {

			const updatedNodes = prevNodes.map((n) => {

				// If the current node is not the one being released, return it unchanged
				if (n.id !== node.id) {
					return n;
				}

				// Check if actual node is a group
				if(n.type === "group") {

					// Get intersecting group nodes
					const intersectingGroups = getIntersectingNodes(node)
						.filter(intersectedNode => intersectedNode.parentId !== node.id && intersectedNode.type === "group");

					// Check if the node is in the intersectingGroups array
					if(intersectingGroups.length > 0) {

						const intersectedNode = intersectingGroups[0];

						return {
							...n,
							position: {
								x: snapToGrid(getPushedX(n, intersectedNode)),
								y: snapToGrid(n.position.y)
							}
						};
					}

					return n;
				}

				// If the current node is in a group, return it unchanged
				if(n.parentId) {
					return n;
				}

				// Find a group node at the current position of the released node
				const groupNode = findGroupNodeAtPosition(prevNodes, node.position);

				if (groupNode && groupNode.id !== n.parentId) {

					// If a group node is found and it's not already the parent of the released node:
					return toChildNode(n, groupNode.id, groupNode.position);
				}

				// If no group node is found, return the node unchanged
				return n;
			});

			// Update the nodes state with the modified array
			return updatedNodes;
		});

	}, [setNodes]);

	/**
	 * showGroup
	 */
	function showGroup() {

		// Check for groups
		const groups = selection.nodes.filter(node => node.type === "group");

		if(groups.length > 0) {
			return false;
		}

		if(selection.nodes.length === 0) {
			return false;
		}

		return getParentIds(selection.nodes).length === 0;

	}

	/**
	 * minimapNodeClassName
	 */
	const minimapNodeClassName = (node) => node.type;

	/**
	 * proOptions
	 */
	const proOptions = { hideAttribution: true };

	/**
	 * workSurfaceBackgroundStyle
	 */
	const workSurfaceBackgroundStyle = {
	   	backgroundImage: `url('${window.location.origin}/studiobg.png')`,
	   	backgroundRepeat: "no-repeat",
	   	backgroundSize: "cover",
	   	backgroundPosition: "center",
		mixBlendMode: "multiply",
	};

	return !isSuccess || !isAuthenticated ? null :
		<DndContext sensors={sensors}
			onDragStart={(event) => {
				console.debug(event)
				setDraggedData(event.active.data.current);
			}}
			onDragEnd={(event) => {

				const x = (event.activatorEvent.clientX || event.activatorEvent.changedTouches[0]?.clientX) + event.delta.x;
				const y = (event.activatorEvent.clientY || event.activatorEvent.changedTouches[0]?.clientY) + event.delta.y;

				console.debug(event, x, y)

				onDrop(x, y, draggedData.node, draggedData.edge);
				setDraggedData(undefined);
			}}>

			<Paper w={"100vw"} h={"calc(var(--vh, 1vh) * 100)"} radius={0} className={classes.paper}
				   bg={paramProjectId === undefined ? "var(--mantine-color-secondary-6)" :
					   lightThemeEnabled ? "var(--mantine-color-primary-0)" : "var(--mantine-color-gray-7)"}>

				<Meta title={`${t("studio.studio")}${settings !== undefined ? " - " + settings.title : ""}`}
					  description={""}
					  keywords={"studio"}/>

				{paramProjectId === undefined ?
					<ProjectLoader onLoad={(projectId) => navigateTo(projectId)}
								   onRemoveProject={(projectId) => {
									   userAccount.getSettings().removeStudioByProjectId(projectId);
									   saveSettings();
								   }}
								   onNew={(entityId, ingredientLabel, ingredientName) => navigateTo(crypto.randomUUID(), entityId, ingredientLabel, ingredientName)}/>
					:
					<>
						<Box w={"100%"} h={"100%"} style={{...workSurfaceBackgroundStyle, position: "absolute"}} opacity={1} />

						<ReactFlow
							ref={setNodeRef}
							onInit={setRfInstance}
							nodes={nodes}
							edges={edges}
							nodeTypes={nodeTypes}
							edgeTypes={edgeTypes}
							onNodesChange={handleNodesChange}
							onEdgesChange={onEdgesChange}
							onSelectionChange={onSelectionChange}

							deleteKeyCode={[]}
							multiSelectionKeyCode={"Shift"}

							nodesDraggable={!isSm}
							elementsSelectable={!isSm}

							onPaneClick={handlePaneClick}

							onNodeDragStart={handleNodeDragStart}
							onNodeDragStop={handleNodeDragStop}
							// connectionLineComponent={FloatingConnectionLine}
							onConnect={onConnect}

							snapToGrid={studioGridSize > GRID_SIZE.min}
							snapGrid={[GRID_SIZE.max, GRID_SIZE.max]}

							onDragOver={onDragOver}

							proOptions={proOptions}

							fitView
							fitViewOptions={{padding: 1}}

							selectionOnDrag={selectionOnDrag}
							panOnDrag={!selectionOnDrag}

							minZoom={0.2}
							maxZoom={2}

							colorMode={"light"}

							// style={{ backgroundColor: "#AAAAAA" }}
						>
							{nodes.length === 0 &&
								<Text>...</Text>
							}

							{nodes.length > 0 && !isSm &&
								<>
									<DetailsPanel position="top-right" entities={entities} nodes={nodes} edges={edges} selection={selection}
												  settings={settings}
												  pairingLevel={pairingLevel}/>

									<CommandPanel position={"top-center"} settings={settings}
												  canUndo={canUndo} onUndo={undo}
												  canRedo={canRedo} onRedo={redo}
												  onProjects={() => navigateTo()}
												  showGroup={showGroup()} onGroupClick={handleGroupNodes}/>

									<HeaderPanel position={"top-left"} settings={settings} onSave={() => saveSettings()}/>

									<ToolsPanel selectionOnDrag={selectionOnDrag} setSelectionOnDrag={(value) => setSelectionOnDrag(value)}/>

									<InfoPanel position="bottom-center" entities={entities} nodes={nodes} edges={edges} selection={selection}/>

									<MiniMap position={"bottom-left"} zoomable pannable nodeClassName={minimapNodeClassName}/>

								</>
							}

							{nodes.length > 0 && isSm &&
								<>
									<CommandPanel isSm={isSm} onProjects={() => navigateTo()}/>

									<ToolsPanel isSm={isSm} selectionOnDrag={false}/>

									<Panel position={"bottom-center"} style={{margin: "var(--mantine-spacing-xs)", marginBottom: "calc(var(--mantine-spacing-xs) * 2)"}}>
										<StudioCredits withFlavorDb2={false} withFoodDataCentral={false} />
									</Panel>
								</>
							}

							{studioGridSize > GRID_SIZE.min &&
								<Background offset={0.5} variant={BackgroundVariant.Dots} gap={GRID_SIZE.max} size={1} color={lightThemeEnabled ? "var(--mantine-color-primary-3)" : "var(--mantine-color-gray-9)"}/>
							}
						</ReactFlow>
					</>
				}
			</Paper>

			<DragOverlay style={{cursor: "grabbing"}} modifiers={[createSnapModifier(studioGridSize)]}>
				{draggedData &&
					<Box w={`${snapToGrid(220)}px`}
						 style={{
							padding: "var(--mantine-spacing-sm)",
							color: "var(--mantine-color-white)",
							backgroundColor: `var(--mantine-color-secondary-6)`,
							borderRadius: "4px",
							textAlign: "center",
							opacity: 0.75,
							pointerEvents: "none",
						}}>
						<Text size={"sm"}>
							{draggedData.ingredientLabel}
						</Text>
						<Text size={"xs"} lh={1.3} opacity={0.75}>
							{draggedData.ingredientCategoryLabel}
						</Text>
					</Box>
				}
			</DragOverlay>

		</DndContext>
}

export default () => (
	<ReactFlowProvider>
		<StudioPage/>
	</ReactFlowProvider>
);