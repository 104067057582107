import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Wordcloud } from '@visx/wordcloud';

const WordCloudComponent = ({words}) => {

	const containerRef = useRef(null);
	const [dimensions, setDimensions] = useState({ width: 300, height: 200 });
	const [wordData, setWordData] = useState(words);

	const updateDimensions = useCallback(() => {

		if (containerRef.current) {
			setDimensions({
				width: containerRef.current.clientWidth,
				height: containerRef.current.clientHeight,
			});

			setWordData([...words]);
		}

	}, [words]);

	useEffect(() => {
		updateDimensions();
		window.addEventListener('resize', updateDimensions);
		return () => window.removeEventListener('resize', updateDimensions);
	}, [updateDimensions]);

	return <div ref={containerRef} style={{ width: '100%', height: '100%' }}>
				<svg width={dimensions.width} height={dimensions.height}>
					<Wordcloud
						words={wordData} // Usa lo stato aggiornato
						width={dimensions.width}
						height={dimensions.height}
						fontSize={(word) => Math.max(10, (word.value / 40) * dimensions.width * 0.1)} // Scala la dimensione dinamicamente
						font={'Urbanist'}
						rotate={() => 0}
						padding={5}
						random={() => 0.5}
					>
						{(cloudWords) =>
							cloudWords.map((w, i) => (
								<text
									key={i}
									fontSize={w.size}
									fontWeight={200}
									fill="white"
									textAnchor="middle"
									transform={`translate(${w.x}, ${w.y}) rotate(${w.rotate})`}
								>
									{w.text}
								</text>
							))
						}
					</Wordcloud>
				</svg>
			</div>
};

export default WordCloudComponent;
