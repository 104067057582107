import classes from "./Studio.module.css";
import {ScrollArea} from "@mantine/core";

/**
 * ScrollableArea
 */
export const ScrollableArea = (props) => {

    const {children, color = "gray"} = props;

    return (

        <ScrollArea type={"hover"}
                    miw={"250px"}
                    h={"100%"}
                    offsetScrollbars={false}
                    classNames={{
                        scrollbar: `${classes['scrollbar' + color]}`,
                        thumb: `${classes['thumb' + color]}`,
                        corner: classes.corner
                    }}
                    {...props}
                    >
            {children}

        </ScrollArea>

    );
}