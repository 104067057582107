import {
    Group,
    Button,
    Text,
    Box,
    Burger,
    Drawer,
    ScrollArea,
    useMantineTheme,
    Paper,
    Grid,
    Stack,
    Space,
    Container,
    SimpleGrid, Menu, Anchor, Divider, ActionIcon,
} from '@mantine/core';
import {useDisclosure, useHeadroom, useInterval} from '@mantine/hooks';
import classes from './Header.module.css';
import {
    faDeleteLeft,
    faHandHoldingHeart,
    faHome,
    faMagnifyingGlass,
    faRightFromBracket
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useTranslation} from 'react-i18next';
import {LanguageIconTitle, LanguageSelector} from '../languageSelector/LanguageSelector';
import React, {useEffect, useRef, useState} from 'react';
import {SearchInput} from "../search/Search";
import RecipeCategoryLink from "../../pages/recipe/RecipeCategoryLink";
import {EntityCategoryLink} from "../../pages/ingredient/EntityCategoryLink";
import {Icon} from "../icons/Icons";
import {HomeLink, homeNavigate} from "../../pages/home/HomeLink";
import {Social} from "../social/Social";
import {searchNavigate} from "../../pages/search/SearchLink";
import {MoleculeIconTitle, MoleculesLink} from "../../pages/molecule/MoleculeLink";
import {useNavigate} from "react-router-dom";
import {AccountLogin} from "../account/Account";
import {useApplicationContext} from "../application/ApplicationContext";
import {CustomMenu} from "../menu/CustomMenu";
import useLifecycle from "../../pages/useLifecycle";
import {IngredientIconTitle} from "../../pages/ingredient/IngredientLink";
import {RecipeGptLink, RecipeIconTitle} from "../../pages/recipe/RecipeLink";
import {useFeatures} from "../features/useFeatures";
import {FeaturesPlan} from "../features/Features";
import {useEnvironmentContext} from "../environment/EnvironmentContext";
import {usePaymentContext} from "../payment/PaymentContext";
import useMedia from "../../pages/useMedia";
import {useAccountContext} from "../account/AccountContext";
import {accountNavigate} from "../../pages/account/AccountLink";
import {IconUserCircle} from "@tabler/icons-react";

/**
 * Header
 *
 * @returns {Element}
 * @constructor
 */
const Header = () => {

    const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);

    const {entityCategories, recipeCategories} = useApplicationContext();

    const [search, setSearch] = useState("");
    const [searchPlaceholder, setSearchPlaceholder] = useState("");
    const [searchTooltip, setSearchTooltip] = useState("");

    const [openedSearchSm, setOpenedSearchSm] = useState(false);
    const menuSearchSmRef = useRef(null);

    const {isAuthenticated, userAccount, logoutAccount} = useAccountContext();
    const {environment} = useEnvironmentContext();

    const {paymentContributeLink} = usePaymentContext();

    const { Features } = useFeatures();

    const theme = useMantineTheme();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { isSm } = useMedia();

    const pinned = useHeadroom({ fixedAt: theme.custom.components.header.heightPx * 3});

    const interval = useInterval(() => {
        let randomsearchPlaceholder = randomSearchPlaceholder();

        while (randomsearchPlaceholder === searchPlaceholder) {
            randomsearchPlaceholder = randomSearchPlaceholder();
        }

        setSearchPlaceholder(randomsearchPlaceholder);
        setSearchTooltip(randomsearchPlaceholder);
    }, 10000);

    useLifecycle({
        onMount: () => {
            setSearchPlaceholder(t("common.search"));
            setSearchTooltip(randomSearchPlaceholder());
            interval.start();
        },
        onUnmount: () => {
            interval.stop();
        }
    });

    useEffect(() => {
        setOpenedSearchSm(openedSearchSm && pinned);
        setSearch('');
    }, [pinned]);

    useEffect(() => {
        if(!isSm && drawerOpened) {
            closeDrawer();
        }

    }, [isSm]);

    /**
     * @returns Random search placeholder
     */
    function randomSearchPlaceholder() {
        return t(`search.${Math.floor(Math.random() * 5) + 1}`);
    }


    const closeSearchSm = () => {
        setSearch('');
        setOpenedSearchSm(false);
    }

    return (
        <Paper shadow={"sm"} radius={0} className={classes.header}
               style={{
                   transform: `translate3d(0, ${pinned ? 0 : "calc(" + theme.custom.components.header.height + " * -1)" }, 0)`,
               }}>
            {/*<Overlay className={classes.overlay} color={theme.colors.primary[6]} backgroundOpacity={0.95} blur={15} />*/}
            <header>

                {/* SM */}
                <Grid hiddenFrom={"sm"} overflow="hidden" pl={"md"} pr={"md"}>
                    <Grid.Col span="9">
                        <Group>
                            <Burger color={"white"} size={"sm"} lineSize={3} opened={drawerOpened} onClick={toggleDrawer} />
                            <HomeLink onClick={closeDrawer}>
                                <Icon name={"logo-full"}/>
                            </HomeLink>
                        </Group>
                    </Grid.Col>
                    <Grid.Col span="3">
                        <Group justify="flex-end" gap={"md"}>
                            <Menu trigger={"click"} zIndex={"calc(var(--mantine-z-index-overlay) + 2)"}
                                  shadow={0}
                                  transitionProps={{ duration: 0 }}
                                  position="left-end"
                                  ref={menuSearchSmRef}
                                  opened={openedSearchSm}
                                  onClose={closeSearchSm}
                                  classNames={{
                                      dropdown: classes.menudropdownsmsearch
                                  }}>
                                <Menu.Target>
                                    <FontAwesomeIcon icon={faMagnifyingGlass} color={"white"} className={classes.searchmenutarget}
                                                     onClick={() => {
                                                         closeDrawer();
                                                         if(search) {
                                                             searchNavigate(navigate, search);
                                                             closeSearchSm();
                                                         }
                                                         else {
                                                             setOpenedSearchSm((o) => !o);
                                                         }
                                                     }}/>
                                </Menu.Target>
                                <Menu.Dropdown hiddenFrom={"sm"}>
                                    <SearchInput flex={1} mt={-1}
                                                 withLeftSection={false}
                                                 focus={openedSearchSm}
                                                 placeholder={t("common.search")}
                                                 onChange={setSearch}
                                                 value={search}
                                                 onSearch={() =>  {
                                                     if(search) {
                                                         searchNavigate(navigate, search);
                                                         closeSearchSm();
                                                     }
                                                 }}
                                                 classNames={{
                                                     input: classes.headersarchinputinput
                                                 }}
                                    />
                                </Menu.Dropdown>
                            </Menu>
                            <AccountLogin onClick={closeDrawer}/>
                        </Group>
                    </Grid.Col>
                </Grid>

                {/* NOT SM */}
                <Container visibleFrom={"sm"}>

                    <Group gap={"xs"}>
                        <Box pr={"xs"}>
                            <HomeLink >
                                <Icon name={"logo-full"}/>
                            </HomeLink>
                        </Box>

                        <CustomMenu>

                            <CustomMenu.Target>
                                <Button variant={"filled"} color={"secondary"} className={classes.headerbutton} >
                                    <Text pl={6} size={"sm"} fw={700}>{t('common.cuckoo')}</Text>
                                </Button>
                            </CustomMenu.Target>

                            <CustomMenu.Dropdown /*withOverlay*/>

                                <SimpleGrid cols={2} spacing={"md"} verticalSpacing="0">

                                    <Stack align="stretch"
                                           justify="flex-start"
                                           gap="sm"
                                           style={{height: "100%"}}>

                                        <RecipeIconTitle pl={18} style={{color: "var(--mantine-color-white)"}}/>
                                        <Box pt={0} pb={0} p={"sm"} flex={1}>
                                            <ScrollArea type={"always"}
                                                        offsetScrollbars h={350}
                                                        classNames={{
                                                            scrollbar: classes.scrollbar,
                                                            thumb: classes.thumb,
                                                            corner: classes.corner
                                                        }}>
                                                {!recipeCategories ? null : recipeCategories.map((item, index) => (
                                                    <CustomMenu.Item key={`recipeCategory-${index}`}>
                                                        <RecipeCategoryLink recipeCategory={item}
                                                                            className={classes.anchor}/>
                                                    </CustomMenu.Item>
                                                ))}
                                            </ScrollArea>
                                        </Box>
                                    </Stack>

                                    <Stack align="stretch"
                                           justify="flex-start"
                                           gap="sm"
                                           style={{height: "100%"}}
                                           >
                                        <IngredientIconTitle pl={18} style={{color: "var(--mantine-color-white)"}}/>
                                        <Box pt={0} pb={0} p={"sm"} flex={1}>
                                            <ScrollArea type={"always"}
                                                        offsetScrollbars h={350}
                                                        classNames={{
                                                            scrollbar: classes.scrollbar,
                                                            thumb: classes.thumb,
                                                            corner: classes.corner
                                                        }}>
                                                {!entityCategories ? null : entityCategories.map((item, index) => (
                                                    <CustomMenu.Item key={`entityCategory-${index}`}>
                                                        <EntityCategoryLink entityCategory={item}
                                                                            className={classes.anchor}/>
                                                    </CustomMenu.Item>
                                                ))}
                                            </ScrollArea>
                                        </Box>
                                    </Stack>

                                    <Stack align="stretch"
                                           justify="center"
                                           gap="sm"
                                           style={{height: "100%"}}>

                                        <RecipeGptLink style={{width: "330px", height: "100%", marginTop: "var(--mantine-spacing-md)"}}/>
                                    </Stack>

                                    {!Features.molecule.plan.enabled ? <Box h={"100px"}/> :
                                        <Stack align="stretch"
                                               justify="flex-start"
                                               gap="sm"
                                               style={{height: "100%"}}>

                                            <MoleculeIconTitle pl={18} pt={"md"} style={{color: "var(--mantine-color-white)"}}/>
                                            <Box pt={0} pb={0} p={"sm"} flex={1}>
                                                <CustomMenu.Item key={`molecules-0`}>
                                                    <MoleculesLink textKey={"common.forNerdsOnly"} className={classes.anchor}/>
                                                </CustomMenu.Item>
                                            </Box>
                                        </Stack>
                                    }

                                </SimpleGrid>
                            </CustomMenu.Dropdown>
                        </CustomMenu>

                        <SearchInput flex={1}
                                     placeholder={searchPlaceholder}
                                     tooltip={search !== undefined && search.length > 0 ? searchTooltip : undefined}
                                     onChange={setSearch}
                                     value={search}
                                     onSearch={() =>  {
                                         if(search) {
                                             searchNavigate(navigate, search);
                                             setSearch('');
                                         }
                                     }}
                                     classNames={{
                                         input: classes.headersarchinputinput
                                     }}
                                    />

                        <Group gap={"xs"}>
                            <FeaturesPlan targetComponent={
                                <Button variant={"filled"} color={userAccount?.isAdmin() || userAccount?.isPremium() || userAccount?.isStudio() ? "tertiary" : "secondary"} w={100} h={38} p={1} >
                                    <FontAwesomeIcon icon={faHandHoldingHeart} style={{width: "24px", height: "24px", color: "var(--mantine-color-white)"}}/>
                                    <Text pl={4} mt={3}>{t("feature.plans")}</Text>
                                </Button>
                            }/>
                            {/*<PaymentSubscription />*/}
                            {/*<PaymentDonateLink />*/}
                            {/*<PaymentSubscriptionLink />*/}
                            <AccountLogin/>
                            <LanguageSelector/>
                        </Group>
                    </Group>

                </Container>

                {/*<Grid  justify="space-between" align="stretch"  overflow="hidden">*/}
                {/*    <Grid.Col span="auto">*/}
                {/*        <HomeLink>*/}
                {/*            <Icon name={"logo-full"}/>*/}
                {/*        </HomeLink>*/}
                {/*    </Grid.Col>*/}
                {/*    <Grid.Col span={8}>*/}
                {/*        /!*<Center maw={"var(--mantine-breakpoint-md)"}>*!/*/}
                {/*            <Group gap={"xs"} justify="center" style={{maxWidth: "var(--mantine-breakpoint-md) !important"}}>*/}
                {/*                <EntityCategories onLoad={(value) => setEntityCategories(value)}/>*/}
                {/*                <RecipeCategories onLoad={(value) => setRecipeCategories(value)}/>*/}
                {/*                <SearchBox flex={1}/>*/}
                {/*            </Group>*/}
                {/*        /!*</Center>*!/*/}
                {/*    </Grid.Col>*/}
                {/*    <Grid.Col span="auto" ta={"right"}>*/}
                {/*        <LanguageSelector/>*/}
                {/*    </Grid.Col>*/}
                {/*</Grid>*/}

            </header>

            <Drawer
                opened={drawerOpened}
                onClose={closeDrawer}
                withCloseButton={false}
                classNames={{
                    root: classes.headerdrawerroot,
                    header: classes.headerdrawerheader,
                    content: classes.headerdrawercontent,
                    body: classes.headerdrawerbody,
                }}
                overlayProps={{color: "var(--mantine-color-primary-12)", backgroundOpacity: 0.75, blur: 7}}
                size='100%'
                transitionProps={{ duration: 50 }}
                hiddenFrom='sm'>

                <Stack align="stretch"
                       justify="flex-start"
                       gap="xs">

                    <Space h={"lg"}/>

                    <Anchor underline="never" onClick={() => {homeNavigate(navigate); closeDrawer();}} className={classes.anchorxsroot}>
                        <Group gap="2" wrap={"nowrap"} justify={"flex-start"}>
                            <FontAwesomeIcon icon={faHome} style={{width: "18px", height: "18px", fill: "var(--mantine-color-white)"}}/>
                            <Text pl={4} mt={3}>{t("home.title")}</Text>
                        </Group>
                    </Anchor>

                    {isAuthenticated &&
                        <>
                            <Divider opacity={0.5} m={"md"}/>

                            <Anchor underline="never" onClick={() => {accountNavigate(navigate); closeDrawer();}}>
                                <Group gap="2" wrap={"nowrap"} justify={"flex-start"} className={classes.anchorxsroot}>
                                    <IconUserCircle style={{width: "18px", height: "18px"}}/>
                                    <Text pl={4} mt={3}>{t("account.myCuckooKitchen")}</Text>
                                </Group>
                            </Anchor>
                        </>
                    }

                    {!recipeCategories && recipeCategories.length > 0 ? null :
                        <>
                            <Divider opacity={0.5} m={"md"}/>
                            <Stack gap={"sm"}>
                                <RecipeIconTitle pl={"md"} style={{color: "var(--mantine-color-white)"}}/>
                                {recipeCategories.map((item, index) =>
                                    <RecipeCategoryLink recipeCategory={item} className={classes.anchorxs}
                                                        onClick={closeDrawer}/>
                                )}
                            </Stack>
                        </>
                    }

                    {!entityCategories && entityCategories.length > 0 ? null :
                        <>
                            <Divider opacity={0.5} m={"md"}/>
                            <Stack gap={"sm"}>
                                <IngredientIconTitle pl={"md"} style={{color: "var(--mantine-color-white)"}}/>

                                {entityCategories.map((item, index) =>
                                    <EntityCategoryLink entityCategory={item} className={classes.anchorxs}
                                                        onClick={closeDrawer}/>
                                )}
                            </Stack>
                        </>
                    }

                    {!Features.molecule.plan.enabled ? null :
                        <>
                            <Divider opacity={0.5} m={"md"}/>
                            <Stack gap={"sm"}>
                                <MoleculeIconTitle pl={"md"} style={{color: "var(--mantine-color-white)"}}/>
                                <MoleculesLink textKey={"common.forNerdsOnly"} className={classes.anchorxs}
                                                   onClick={closeDrawer}/>
                            </Stack>
                        </>
                    }

                    <Divider opacity={0.5} m={"md"}/>

                    <FeaturesPlan targetComponent={
                        // <Button variant={"filled"} color={"secondary"} className={classes.headerbutton} >
                        //     <Text pl={6} size={"sm"} fw={700}>{t('feature.plans')}</Text>
                        // </Button>

                        <Anchor underline="never">
                            <Group gap="2" wrap={"nowrap"} justify={"flex-start"} className={classes.anchorxsroot}>
                                <FontAwesomeIcon icon={faHandHoldingHeart} style={{width: "18px", height: "18px", fill: "var(--mantine-color-white)"}}/>
                                <Text pl={4} mt={3}>{t("feature.plans")}</Text>
                            </Group>
                        </Anchor>

                    }/>

                    {isAuthenticated &&
                        <>
                            <Divider opacity={0.5} m={"md"}/>

                            <Anchor underline="never" onClick={() => {closeDrawer(); logoutAccount();}}>
                                <Group gap="2" wrap={"nowrap"} justify={"flex-start"} className={classes.anchorxsroot}>
                                    <FontAwesomeIcon icon={faRightFromBracket} style={{width: "18px", height: "18px", fill: "var(--mantine-color-white)"}}/>
                                    <Text pl={4} mt={3}>{t("common.logout")}</Text>
                                </Group>
                            </Anchor>
                        </>
                    }

                    <Divider opacity={0.5} m={"md"}/>

                    <Stack gap={"sm"}>
                        <LanguageIconTitle pl={"md"} style={{color: "var(--mantine-color-white)"}}/>
                        <LanguageSelector compact className={classes.anchorxs}/>
                    </Stack>

                    <Divider opacity={0.5} m={"md"}/>

                    <Box pl={38} pr={"md"}>
                        <Text size={"sm"} fw={700} c={"var(--mantine-color-white-6)"}>{t("common.followUs")}</Text>
                        <Space h={"md"}/>
                        <Social justify={"space-between"} variant={"transparent"}/>
                    </Box>

                    <Divider opacity={0.5} m={"md"}/>

                    <Stack gap={0}>
                        <Anchor href={"/static/about-us.html"} target={"_blank"} underline="never" onClick={closeDrawer} className={classes.anchorStatic} size={"xs"}>{t("footer.aboutUs")}</Anchor>
                        <Anchor href={paymentContributeLink} target={"_blank"} underline="never" onClick={closeDrawer} className={classes.anchorStatic} size={"xs"}>{t("payment.donate")}</Anchor>
                        <Anchor href={"/static/disclaimer.html"} target={"_blank"} underline="never" onClick={closeDrawer} className={classes.anchorStatic} size={"xs"}>{t("footer.disclaimer")}</Anchor>
                        <Space h={"md"}/>
                        <Anchor href={"/static/terms-of-use.html"} target={"_blank"} underline="never" onClick={closeDrawer} className={classes.anchorStatic} size={"xs"}>{t("footer.termsOfUse")}</Anchor>
                        <Anchor href={"/static/privacy-policy.html"} target={"_blank"} underline="never" onClick={closeDrawer} className={classes.anchorStatic} size={"xs"}>{t("footer.privacyPolicy")}</Anchor>
                        <Anchor href={"/static/cookie-policy.html"} target={"_blank"} underline="never" onClick={closeDrawer} className={classes.anchorStatic} size={"xs"}>{t("footer.cookiePolicy")}</Anchor>
                        <Anchor href={"/static/cookie-settings.html"} target={"_blank"} underline="never" onClick={closeDrawer} className={classes.anchorStatic} size={"xs"}>{t("footer.cookieSettings")}</Anchor>
                    </Stack>

                    <Stack gap={0} opacity={0.5}>
                        <Text size={"xs"}  c={"var(--mantine-color-white)"} className={classes.anchorStaticnopadding}>
                            {t("footer.companyInformation1", {registration: environment.companyRegistration})}
                        </Text>
                        <Text size={"xs"}  c={"var(--mantine-color-white)"} className={classes.anchorStaticnopadding}>
                            {t("footer.companyInformation2", {duns: environment.companyDuns})}
                        </Text>
                        <Text size={"xs"}  c={"var(--mantine-color-white)"} className={classes.anchorStaticnopadding}>
                            {t("footer.companyInformation3", {vat: environment.companyVatNumber})}
                        </Text>
                    </Stack>

                    <Space h={"lg"}/>
                </Stack>
            </Drawer>
        </Paper>
    );
}

export default Header;