import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {
	Box,
	Center,
	Group,
	Highlight,
	Image,
	NumberFormatter,
	Overlay,
	RangeSlider,
	Space,
	Stack,
	Table,
	Tabs,
	Text,
	Transition,
	useMantineTheme,
	Tooltip as MantineTooltip,
	Button,
	Divider,
	Radio,
	CheckIcon,
	PillGroup, Pill, Collapse, Modal, ScrollArea, Title, List,
} from "@mantine/core";
import {useTranslation} from "react-i18next";
import {
	CUSTOM_CATEGORY_ID,
	extractMolecules,
	extractNodes,
	formatPc,
	getConnectedEdges,
	getEntityById,
	getColorByThreshold,
	INGREDIENTS_THRESHOLDS,
	MOLECULES_THRESHOLDS,
	getDataByThreshold,
	getNodeById,
	extractSharedMolecules,
	toEdge,
	extractEntitiesNotInNodes,
	extractEntityPairings,
	COMPATIBILITY_MARKS,
	getSharedMoleculesPc,
	searchEntities,
	toEntityId,
	getEntityCategory,
	PAIRING_LEVELS,
	accumulateFrequency,
	getWeightingName,
	toIngredients, generateAccentVariantsForWord, removeAccents, NUTRIENT_CATEGORIES, NUTRIENT_DEFAULT_SERVING,
} from "./StudioUtils";
import {localized} from "../../i18n";
import {
	Pie,
	PieChart,
	PolarAngleAxis,
	PolarGrid,
	PolarRadiusAxis,
	Radar,
	RadarChart,
	ResponsiveContainer, Scatter, ScatterChart,
	Sector, Tooltip, XAxis, YAxis, ZAxis
} from "recharts";
import classes from "./Studio.module.css";
import {entityCategoryIdNavigate, entityCategoryNavigate} from "../ingredient/EntityCategoryLink";
import {useNavigate} from "react-router-dom";
import {Icon} from "../../components/icons/Icons";
import {StripedTable} from "../../components/stripedTable/StripedTable";
import {SearchInput} from "../../components/search/Search";
import {alphabeticComparator, stringToImageSrc} from "../../util/utils";
import {moleculeIdNavigate} from "../molecule/MoleculeLink";
import {ScrollableArea} from "./ScrollableArea";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faChevronDown, faChevronRight, faChevronUp, faCircleInfo,
	faGripVertical,
	faImage, faSliders,
	faTableList, faTrash, faUpRightFromSquare
} from "@fortawesome/free-solid-svg-icons";
import {Flexible} from "./Flexible";
import {ingredientNameNavigate, ingredientNavigate} from "../ingredient/IngredientLink";
import {Paginator} from "../../components/paginator/Paginator";
import {paginationEndSkip, paginationStartSkip} from "../../util/pagination";
import {WikipediaCredits} from "../../components/credits/Credits";
import {Panel, useReactFlow} from "@xyflow/react";
import {StudioCredits, useStudioStore} from "./StudioPage";
import {useDebouncedCallback, useDisclosure} from "@mantine/hooks";
import * as allTablerIcons from "@tabler/icons-react";
import {useDraggable} from "@dnd-kit/core";
import {toDateTime} from "../../util/time";
import {useFeatures} from "../../components/features/useFeatures";
import ScrollWatcher from "./ScrollWatcher";
import Motion from "./Motion";
import {useAccountContext} from "../../components/account/AccountContext";
import useGeneratedRecipeAll from "./useGeneratedRecipeAll";
import useGeneratedRecipeImage from "./useGeneratedRecipeImage";
import useGenerateRecipeImage from "./useGenerateRecipeImage";
import {useEnvironmentContext} from "../../components/environment/EnvironmentContext";
import {Command} from "./CommandPanel";
import useMedia from "../useMedia";
import useDeleteGeneratedRecipe from "./useDeleteGeneratedRecipe";
import {DeleteModal} from "../../components/delete/DeleteModal";

/**
 * useMemoizedSelection
 */
const useMemoizedSelection = ({ nodes, edges, selection }) => {

	const {t} = useTranslation();

	let entities = useStudioStore((state) => state.entities);

	/**
	 * EntitiesMolecules
	 */
	const entitiesMolecules = useMemo(() => {
		return entities.flatMap(entity => entity.molecules || []);
	}, [entities]);

	/**
	 * entitiesMoleculeIds
	 */
	const entitiesMoleculeIds = useMemo(() => {
		return [...new Set(entitiesMolecules?.map((molecule) => molecule.moleculeId) || [])];
	}, [entitiesMolecules]);

	/**
	 * SelectedNode
	 */
	const selectedNode = useMemo(() => {
		return selection.nodes.length === 1 ? nodes.find((node) => node.id === selection.nodes[0].id) : undefined;
	}, [nodes, selection]);

	/**
	 * SelectedGroup
	 */
	const selectedGroup = useMemo(() => {

		if(selectedNode === undefined || selectedNode.parentId === undefined) {
			return undefined;
		}

		return nodes.find(n => n.id === selectedNode.parentId);
	}, [nodes, selectedNode]);

	/**
	 * SelectedEntity
	 */
	const selectedEntity = useMemo(() => {

		if(selectedNode === undefined) {
			return undefined;
		}

		return getEntityById(entities, toEntityId(selectedNode));
	}, [entities, selectedNode]);

	/**
	 * SelectedEntityCategory
	 */
	const selectedEntityCategory = useMemo(() => {

		if(selectedEntity === undefined || selectedEntity.categories.length === 0) {
			return undefined;
		}

		return selectedEntity.categories[0];
	}, [selectedEntity]);

	/**
	 * SelectedNodeEdges
	 */
	const selectedNodeEdges = useMemo(() => {

		if(selectedNode === undefined) {
			return [];
		}

		return getConnectedEdges(edges, [selectedNode]);
	}, [edges, selectedNode]);

	/**
	 * SelectedEdge
	 */
	const selectedEdge = useMemo(() => {
		return selection.edges.length === 1 && selection.nodes.length === 0 ? selection.edges[0] : undefined;
	}, [selection]);

	/**
	 * SelectedNodes
	 */
	const selectedNodes = useMemo(() => {

		if(selectedEdge === undefined) {
			return selection.nodes?.length > 0 ? selection.nodes : nodes;
		}

		return [getNodeById(nodes, selectedEdge.source), getNodeById(nodes, selectedEdge.target)];
	}, [nodes, edges, selection, selectedEdge]);

	/**
	 * SelectedMolecules
	 */
	const selectedMolecules = useMemo(() => {

		if(selectedEdge === undefined) {
			return extractMolecules(entities, [], nodes, selectedNodes);
		}

		const molecules1 = extractMolecules(entities, [], nodes, [getNodeById(nodes, selectedEdge.source)]);
		const molecules2 = extractMolecules(entities, [], nodes, [getNodeById(nodes, selectedEdge.target)]);

		return extractSharedMolecules(molecules1, molecules2);

	}, [entities, nodes, edges, selection, selectedEdge]);

	/**
	 * selectedConnectedNodes
	 */
	const selectedConnectedNodes = useMemo(() => {

		if (selectedNodeEdges === undefined || selectedNodeEdges.length === 0) {
			return [];
		}

		// Create a Map to store unique connected nodes by their IDs
		const connectedNodesMap = new Map();

		// Step 1: Collect initial data from edges
		selectedNodeEdges.forEach((edge) => {
			if (edge.source !== selectedNode.id) {
				connectedNodesMap.set(edge.source, {
					id: edge.source,
					sharedMolecules: edge.data?.sharedMolecules || 0,
					sharedMoleculesPc: edge.data?.sharedMoleculesPc || 0
				});
			}
			if (edge.target !== selectedNode.id) {
				connectedNodesMap.set(edge.target, {
					id: edge.target,
					sharedMolecules: edge.data?.sharedMolecules || 0,
					sharedMoleculesPc: edge.data?.sharedMoleculesPc || 0
				});
			}
		});

		// Step 2: Enrich the data with additional node information
		nodes.forEach((node) => {
			if (connectedNodesMap.has(node.id)) {
				const existingNode = connectedNodesMap.get(node.id);

				const connectedNode = {
					...existingNode,
					type: node.type,
					entityId: node.data?.entity?.id,
					categoryName: node.data?.entity?.categoryLabel || "",
					name: node.data?.label || "",
					ingredientName: node.data?.entity?.ingredientName,
				}

				if(selectedNode.type !== "group") {
					connectedNode.sharedMoleculesPc = existingNode.sharedMolecules > 0 && selectedMolecules.length > 0 ? (existingNode.sharedMolecules * 100) / selectedMolecules.length : 0;
				}

				connectedNodesMap.set(node.id, connectedNode);
			}
		});

		// Convert the Map values to an array
		return Array.from(connectedNodesMap.values());
	}, [selectedNodeEdges, selectedMolecules, nodes, selectedNode]);

	/**
	 * Calculates the average `sharedMoleculesPc` of selected connected nodes,
	 * excluding nodes of type "custom".
	 */
	const selectedConnectedNodesAverageSharedMoleculesPc = useMemo(() => {

		// If there are no selected connected nodes, return 0
		if(selectedConnectedNodes === undefined || selectedConnectedNodes.length === 0) {
			return 0;
		}

		// Filter out nodes that have type "custom"
		const filteredNodes = selectedConnectedNodes.filter(node => node.type !== "custom");

		// If no valid nodes remain after filtering, return 0 to avoid division by zero
		if (filteredNodes.length === 0) {
			return 0;
		}

		// Sum all sharedMoleculesPc values from the filtered nodes
		const total = filteredNodes.reduce((sum, selectedConnectedNode) => {
			const sharedMoleculesPc = selectedConnectedNode.sharedMoleculesPc || 0; // Fallback a 0 se non esiste
			return sum + sharedMoleculesPc;
		}, 0);

		// Return the average sharedMoleculesPc of the filtered nodes
		return total / filteredNodes.length;

	}, [selectedConnectedNodes]);

	/**
	 * SelectedExtractedNodes
	 */
	const selectedExtractedNodes = useMemo(() => {

		if(nodes === undefined || selectedNodes === undefined) {
			return [];
		}

		return extractNodes(nodes, selectedNodes);
	}, [nodes, selectedNodes]);

	/**
	 * SelectedCustomNodes
	 */
	const selectedCustomNodes = useMemo(() => {
		const result = selectedExtractedNodes.filter(selectedExtractedNode => selectedExtractedNode.type === "custom");

		// Creates a map to store the first occurrence of each label while maintaining its original case
		const labelMap = new Map();

		result.forEach(node => {
			const lowerLabel = node.data.label.toLowerCase();
			if (!labelMap.has(lowerLabel)) {
				labelMap.set(lowerLabel, node.data.label); // Preserve the original case
			}
		});

		return [...labelMap.values()]; // Returns an array with original case names, ensuring uniqueness

	}, [selectedExtractedNodes]);

	/**
	 * SelectedEntities
	 */
	const selectedEntities = useMemo(() => {

		if(entities === undefined || selectedExtractedNodes === undefined) {
			return [];
		}

		// Create an array to store unique entities
		const uniqueEntities = [];

		// Create a Set to track added ingredient names and ensure uniqueness
		const seenIngredientNames = new Set();

		selectedExtractedNodes.forEach((node) => {
			const ingredientName = node?.data?.entity?.ingredientName; // Extract ingredient name

			if (ingredientName && !seenIngredientNames.has(ingredientName)) {
				seenIngredientNames.add(ingredientName); // Mark ingredient name as seen

				const entity = getEntityById(entities, toEntityId(node)); // Retrieve entity by ID

				if (entity) {
					uniqueEntities.push({
						...entity, // Copy the retrieved entity properties
						selectedIngredient: {
							name: ingredientName, // Add ingredient name from node data
							label: node?.data?.label || "" // Add label (default to empty string if undefined)
						}
					});
				}
			}
		});

		// Sort the list of entities alphabetically by label and return it
		return uniqueEntities.sort((a, b) => a.selectedIngredient.label.localeCompare(b.selectedIngredient.label));

	}, [entities, selectedExtractedNodes]);

	/**
	 * selectedNutrients - Aggregates and averages nutrient values per 100g across selected ingredients,
	 * preserving all original fields while updating only the `amount`.
	 */
	const selectedNutrients = useMemo(() => {

		if (!selectedEntities || selectedEntities.length === 0) return [];

		// Map to accumulate nutrient values and store their units
		const nutrientSums = new Map();
		const nutrientCounts = new Map(); // Stores how many ingredients have each nutrient

		// Iterate through each selected entity
		selectedEntities.forEach(entity => {
			if (!entity.nutrients) return;

			entity.nutrients.forEach(nutrient => {
				const { nutrientId, amount } = nutrient;

				// If the nutrient already exists, sum the values and count occurrences
				if (nutrientSums.has(nutrientId)) {
					nutrientSums.get(nutrientId).amount += amount;
					nutrientCounts.set(nutrientId, nutrientCounts.get(nutrientId) + 1);
				}
				else {
					// Otherwise, add the nutrient to the map, keeping all its fields
					nutrientSums.set(nutrientId, { ...nutrient });
					nutrientCounts.set(nutrientId, 1); // First occurrence of this nutrient
				}
			});
		});

		// If no nutrients were found, return an empty array
		if (nutrientSums.size === 0) return [];

		// Calculate the correct average nutrient values per 100g, preserving all fields
		const averagedNutrients = Array.from(nutrientSums.values()).map(nutrient => {
			const avgAmount = nutrient.amount / nutrientCounts.get(nutrient.nutrientId);

			const category = NUTRIENT_CATEGORIES.find(cat =>
				cat.ranks.includes(nutrient.rank)
			);

			return {
				...nutrient,
				amount: avgAmount,
				nutrientCategoryId: category ? category.id : null,
				nutrientCategory: category ? t(`nutrientCategory.${category.id}`) : null
			};
		});

		return averagedNutrients;

	}, [selectedEntities]);

	/**
	 * SelectedEdges
	 */
	const selectedEdges = useMemo(() => {

		if(selectedEdge === undefined && selectedExtractedNodes === undefined) {
			return [];
		}

		// Get selected edges or all edges
		return selectedEdge === undefined ? getConnectedEdges(edges, selectedExtractedNodes) : [selectedEdge] ;

	}, [edges, selectedEdge, selectedExtractedNodes]);

	/**
	 * SelectedEdgesAverageSharedMoleculesPc
	 */
	const selectedEdgesAverageSharedMoleculesPc = useMemo(() => {

		if(selectedEdges === undefined) {
			return 0;
		}

		return calculateAverageSharedMoleculesPc(selectedEdges);

	}, [selectedEdges]);

	return {
		entities,
		entitiesMolecules,
		entitiesMoleculeIds,

		selectedNode,
		selectedGroup,
		selectedEntity,
		selectedEntityCategory,
		selectedNodeEdges,
		selectedConnectedNodes,
		selectedConnectedNodesAverageSharedMoleculesPc,

		selectedEdge,

		selectedNodes,
		selectedCustomNodes,
		selectedMolecules,
		selectedExtractedNodes,
		selectedEntities,

		selectedNutrients,

		selectedEdges,

		selectedEdgesAverageSharedMoleculesPc
	};
};

/**
 * useMemoizedTitle
 */
export const useMemoizedTitle = ({ nodes, edges, selection }) => {

	const memoizedProps = useMemo(() => ({ nodes, edges, selection }), [nodes, edges, selection]);
	const {selectedNode, selectedEdge, selectedNodes, selectedEntities } = useMemoizedSelection(memoizedProps);

	const { t } = useTranslation();

	/**
	 * Subtitle
	 */
	const subtitle = useMemo(() => {

		if(selection.nodes.length > 0) {
			return t("studio.selected");
		}

		if(selectedEdge !== undefined) {
			return t("studio.compatible")
		}

		return undefined;

	}, [selection, selectedEdge]);

	/**
	 * Title
	 */
	const title = useMemo(() => {

		if(selectedNode !== undefined) {
			return `${selectedNode.data.label}`;
		}

		if(selectedEdge !== undefined) {
			return `${selectedNodes[0].data.label} - ${selectedNodes[1].data.label}`;
		}

		if(selection.onPane) {
			return t("studio.workSurface");
		}

		return `${selectedEntities?.length} ${t("common.ingredients")} ${subtitle !== undefined ? subtitle : ""}`;

	}, [selectedNode, selectedNodes, selectedEdge, selection, selectedEntities, subtitle]);

	return {
		title
	};
};

/**
 * TabsLayout
 */
export const TabsLayout = ({	title = "Tab", collapsed, tabs = [],
						visible = true,
						description = "",
						w = 470,
						h= 470,
						blur = 64,
						color = `var(--mantine-color-tertiary-6)`,
						backgroundColor = `var(--mantine-color-tertiary-6)`,
						backgroundOpacity = 0.1,
						backgroundZIndex = -1
					}) => {

	const [open, setOpen] = useState(false);
	const [activeTab, setActiveTab] = useState("0");
	const [oldTab, setOldTab] = useState("0");

	const [tooltipOpened, setTooltipOpened] = useState(false);

	const iconStyle = { width: "12px", height: "12px", paddingTop: "0", paddingRight: "4px"};
	const infoIconStyle = { width: "18px", height: "18px", opacity: 0.75, color: color};

	/**
	 * onTabChange
	 */
	const onTabChange = useCallback((value) => {

		if(activeTab === value) {
			setOpen(!open);
		}
		else {
			setOpen(true);
		}

		setOldTab(activeTab);
		setActiveTab(value);

	}, [activeTab, oldTab, open])

	/**
	 * filteredTabs
	 */
	const filteredTabs = useMemo(() => {
		return tabs.filter(Boolean);
	}, [tabs]);

	useEffect(() => {

		if(activeTab >= filteredTabs.length) {
			onTabChange("0");
		}

	}, [filteredTabs]);

	/**
	 * TabIcon
	 */
	const TabIcon = ({tab, index}) => {

		if(tab.fontAwesomeIcon !== undefined) {
			return <FontAwesomeIcon icon={tab.fontAwesomeIcon} style={{width: "18px", height: "18px", opacity: open && activeTab === "" + index ? 1 : 0.5}}/>
		}

		if(tab.tablerIcon !== undefined) {
			const TablerIcon = allTablerIcons[tab.tablerIcon];
			return <TablerIcon size={22} stroke={2} style={{opacity: open && activeTab === "" + index ? 1 : 0.5}}/>
		}

		if(tab.icon !== undefined) {
			return <Icon name={tab.icon} style={{paddingTop: "2px", width: "18px", height: "18px", fill: color, opacity: open && activeTab === "" + index ? 1 : 0.5}}/>
		}

		return null;
	}

	return (
		<Box w={`${w}px`} h={open ? `${h}px` : "100px"} style={{position: "relative"}} pt={"sm"} pl={"md"} pr={"md"} pb={"xs"} hidden={!visible}>

			<Overlay color={backgroundColor} backgroundOpacity={backgroundOpacity} blur={blur} radius={"md"} zIndex={backgroundZIndex}/>

			<Stack pt={3} style={{position: "absolute"}} gap={"xs"}>

				<Group align="flex-start" gap={3}>

					<Button variant={"subtle"} size={"compact-md"} justify="flex-start" color={color} radius={4} h={28} mt={-4} ml={0} pl={4} pr={4}
							onClick={() => {setOpen(!open)}}>

						<FontAwesomeIcon icon={!open ? faChevronDown : faChevronUp} style={iconStyle}/>
						<Text size={"sm"} fw={700} c={color}>
							{title}
						</Text>
					</Button>

					{description?.length > 0 &&
						<MantineTooltip opened={tooltipOpened} transitionProps={{ duration: 0 }} multiline maw={300} withArrow label={description} bg={backgroundColor} color={"white"}>
							<FontAwesomeIcon icon={faCircleInfo} style={infoIconStyle}
											 onMouseEnter={() => setTooltipOpened(true)}
											 onMouseLeave={() => setTooltipOpened(false)}
											 onTouchStart={() => setTooltipOpened((o) => !o)}/>
						</MantineTooltip>
					}
				</Group>

				{!open &&
					<Box w={"100%"} pl={"20px"}>
						{collapsed}
					</Box>
				}

			</Stack>

			<Tabs defaultValue={"0"} value={activeTab} onChange={onTabChange} variant="unstyled" w={"100%"} h={"100%"} pb={36} style={{overflow: "hidden"}}
				  classNames={{
					  list: classes.detailstab
				  }}>

				<Tabs.List justify="flex-end" className={classes.tabslayoutlist}>
					{filteredTabs.map((tab, index) => (
						<Tabs.Tab value={"" + index} key={index} w={32} h={28}>
							<Button variant={"subtle"} color={color} justify="center" size="compact-md" w={28} h={28} radius={4} >
								<TabIcon tab={tab} index={index} />
							</Button>
						</Tabs.Tab>
					))}
				</Tabs.List>

				{/*{filteredTabs.map((tab, index) => (*/}
				{/*	<Tabs.Panel value={"" + index} w={"100%"} h={"100%"} style={{color: color, visibility: open ? "visible" : "hidden" }} pt={"md"}>*/}
				{/*		{tab.content}*/}
				{/*	</Tabs.Panel>*/}
				{/*))}*/}

				{filteredTabs.map((tab, index) => (
					<Transition
						key={index}
						mounted={activeTab === "" + index}
						transition={`slide-${activeTab < oldTab ? "right" : "left"}`}
						timingFunction={"ease-out"}
						duration={100}
					>
						{(styles) => (
							<Tabs.Panel value={"" + index} w={"100%"} h={"100%"} style={{...styles, color: color, visibility: open ? "visible" : "hidden"/*, position: "absolute"*/ }} pt={"md"}>
								{tab.content}
							</Tabs.Panel>
						)}
					</Transition>
				))}

			</Tabs>

		</Box>
	);
};

/**
 * Calculates the average sharedMoleculesPc of edges,
 * excluding edges where sharedMoleculesPc is 0.
 */
function calculateAverageSharedMoleculesPc(edges) {

	// If no edges exist or the array is empty, return 0
	if (edges === undefined || edges.length === 0) {
		return 0;
	}

	// Filter out edges where sharedMoleculesPc is 0
	const filteredEdges = edges.filter(edge => (edge?.data?.sharedMoleculesPc || 0) > 0);

	// If no valid edges remain after filtering, return 0 to avoid division by zero
	if (filteredEdges.length === 0) {
		return 0;
	}

	// Sum all sharedMoleculesPc values from the valid edges
	const total = filteredEdges.reduce((sum, edge) => {
		return sum + (edge?.data?.sharedMoleculesPc || 0); // Default to 0 if undefined
	}, 0);

	// Return the average sharedMoleculesPc of the valid edges
	return total / filteredEdges.length;
}

/**
 * getAverageSharedMoleculesPcColor
 * Restituisce il colore basato sulla percentuale media di molecole condivise.
 *
 * @returns {string} - Il colore associato alla percentuale media di molecole condivise.
 */
function getAverageSharedMoleculesPcColor(averageSharedMoleculesPc) {
	return getColorByThreshold(averageSharedMoleculesPc, MOLECULES_THRESHOLDS);
}

/**
 * DetailsPanel
 */
export const DetailsPanel = ({	nodes, edges, selection,
								settings,
								position="top-right",
								h = "calc((var(--vh, 1vh) * 100) - 0px /*Footer height*/)",
								blur = 64,
								color = "secondary",
								isOnlyInputConnectable = false,
								pairingLevel = PAIRING_LEVELS.UNIQUE_INGREDIENT_GLOBALLY}) => {

	const memoizedProps = useMemo(() => ({ nodes, edges, selection }), [nodes, edges, selection]);
	const {entities, selectedNode, selectedMolecules } = useMemoizedSelection(memoizedProps);

	let studioMoleculesEnabled = useStudioStore((state) => state.studioMoleculesEnabled);
	const setStudioMoleculesEnabled = useStudioStore((state) => state.setStudioMoleculesEnabled);

	const { Features } = useFeatures();

	const isEntities = selectedNode === undefined || selectedNode.type === "group";

	const {t} = useTranslation();

	const [scrollActive, setScrollActive] = useState(false);

	/**
	 * ShowMolecules
	 */
	const ShowMolecules = ({color = "secondary"}) => {

		return <Box w={"100%"} ta={"center"}>
			<Button variant={"subtle"} color={color} radius={4} pl={7} pr={7} onClick={() => setStudioMoleculesEnabled(!studioMoleculesEnabled)} style={{pointerEvents: "auto"}}>
				<Overlay color={`var(--mantine-color-${color}-6)`} backgroundOpacity={0} blur={blur} radius={4} zIndex={-1}/>
				<Group gap={6}>
					<Icon name={"physics"} style={{width: "18px", height: "18px", fill: `var(--mantine-color-${color}-6)`, marginBottom: "-2px", opacity: 0.75}}/>
					<Text size={"xs"} opacity={1}>
						{t(`studio.${studioMoleculesEnabled ? "hideMolecularInformation" : "showMolecularInformation"}`)}
					</Text>
				</Group>
			</Button>
		</Box>
	}

	/**
	 * isOnlyMoleculesEnabled
	 */
	function isOnlyMoleculesEnabled() {

		return Features.studio.features.molecules.plan.enabled &&
			!(Features.studio.features.ingredientEmotions.plan.enabled ||
				Features.studio.features.ingredientFlavors.plan.enabled ||
				Features.studio.features.ingredientOdors.plan.enabled ||
				Features.studio.features.ingredientTastes.plan.enabled ||
				Features.studio.features.ingredientCompounds.plan.enabled);
	}

	return (
		<Panel position={position} style={{margin: "0"}} className={classes.defaultreactflowpanel}>
			<Motion	initial={{ opacity: 0, x: 50 }} animate={{ opacity: 1, x: 0 }}>
				<Box h={h}>
					<ScrollableArea style={{pointerEvents: scrollActive ? "auto" : "none"}}>
						<Box style={{minHeight: h, display: "flex", flexDirection: "column", justifyContent: "center"}}> {/* Center Stack*/}

							<Stack gap={"xs"} align="flex-end" justify="center" m={"md"} style={{pointerEvents: "auto"}}
								   mt={"calc(var(--mantine-spacing-md) + var(--mantine-spacing-xs) + 62px)"} mb={"calc(var(--mantine-spacing-md) + var(--mantine-spacing-xs) + 170px)"}>

								<ScrollWatcher onScrollChange={(value) => setScrollActive(value)}>

									<Creations projectId={settings.id} selectedMolecules={extractMolecules(entities, [], nodes, nodes) /* Get all node molecules as by click on pane */}/>

									<Group gap={4} c={"gray.5"} wrap={"nwrap"} mt={"-4px"} mb={"-4px"}>
										<Icon name={"leaf"} style={{width: "12px", height: "12px", fill: `var(--mantine-color-gray-5)`, marginBottom: "-1px"}}/>
										<Text size={"10px"} w={"100%"} ta={"right"} pr={"24px"}>{t("common.ingredients")}</Text>
									</Group>

									<Entities visible={isEntities} nodes={nodes} edges={edges} selection={selection}/>
									<Entity visible={!isEntities} nodes={nodes} edges={edges} selection={selection}/>

									<Group gap={4} c={"gray.5"} wrap={"nwrap"} mt={"-4px"} mb={"-4px"}>
										<Icon name={"circle-overlap"} style={{width: "12px", height: "12px", fill: `var(--mantine-color-gray-5)`, marginBottom: "-1px"}}/>
										<Text size={"10px"} w={"100%"} ta={"right"} pr={"24px"}>{t("studio.pairings")}</Text>
									</Group>

									<EntityCategoryPairings nodes={nodes} edges={edges} selection={selection}/>

									<EntityPairings visible={selectedNode !== undefined} nodes={nodes} edges={edges} selection={selection}
													isOnlyInputConnectable={isOnlyInputConnectable} pairingLevel={pairingLevel}/>

									{selectedMolecules && selectedMolecules.length > 0 &&
										<>
											{(isOnlyMoleculesEnabled() || studioMoleculesEnabled) &&
												<Group gap={4} c={"gray.5"} wrap={"nwrap"} mt={"-4px"} mb={"-4px"}>
													<Icon name={"physics"} style={{width: "12px", height: "12px", fill: `var(--mantine-color-gray-5)`, marginBottom: "-1px"}}/>
													<Text size={"10px"} w={"100%"} ta={"right"} pr={"24px"}>{t("studio.molecularInformation")}</Text>
												</Group>
											}

											<Molecules visible={Features.studio.features.molecules.plan.enabled && (isOnlyMoleculesEnabled() || studioMoleculesEnabled)} nodes={nodes} edges={edges} selection={selection}/>

											<MolecularChart visible={Features.studio.features.ingredientEmotions.plan.enabled && studioMoleculesEnabled} nodes={nodes} edges={edges} selection={selection} attribute={"emotions"} />
											<MolecularChart visible={Features.studio.features.ingredientFlavors.plan.enabled && studioMoleculesEnabled} nodes={nodes} edges={edges} selection={selection} attribute={"flavors"} />
											<MolecularChart visible={Features.studio.features.ingredientOdors.plan.enabled && studioMoleculesEnabled} nodes={nodes} edges={edges} selection={selection} attribute={"odors"} />
											<MolecularChart visible={Features.studio.features.ingredientTastes.plan.enabled && studioMoleculesEnabled} nodes={nodes} edges={edges} selection={selection} attribute={"tastes"} />
											<MolecularChart visible={Features.studio.features.ingredientCompounds.plan.enabled && studioMoleculesEnabled} nodes={nodes} edges={edges} selection={selection} attribute={"compounds"} />

											{(Features.studio.features.ingredientEmotions.plan.enabled ||
												Features.studio.features.ingredientFlavors.plan.enabled ||
												Features.studio.features.ingredientOdors.plan.enabled ||
												Features.studio.features.ingredientTastes.plan.enabled ||
												Features.studio.features.ingredientCompounds.plan.enabled) &&

												<ShowMolecules color={"secondary"}/>
											}
										</>
									}
								</ScrollWatcher>

							</Stack>
						</Box>
					</ScrollableArea>
				</Box>
			</Motion>
		</Panel>
	)
}

/**
 * InfoPanel
 */
export const InfoPanel = ({	nodes, edges, selection,
							  position="bottom-center",
							  blockMiw = "80px",
							  blur = 64,
							  color = "tertiary",
							  backgroundColor ="tertiary" }) => {

	const memoizedProps = useMemo(() => ({ nodes, edges, selection }), [nodes, edges, selection]);
	const {selectedNode, selectedEdge, selectedCustomNodes, selectedEdges, selectedConnectedNodesAverageSharedMoleculesPc, selectedEdgesAverageSharedMoleculesPc, selectedEntities } = useMemoizedSelection(memoizedProps);
	const { title } = useMemoizedTitle(memoizedProps);

	const [tooltipOpened, setTooltipOpened] = useState(false);

	const { t } = useTranslation();

	/**
	 * ingredientsLength
	 */
	const ingredientsLength = useMemo(() => {
		return (selectedEntities?.length ?? 0) + (selectedCustomNodes?.length ?? 0);
	}, [selectedEntities, selectedCustomNodes]);

	/**
	 * ingredientsDifficulty
	 */
	const ingredientsDifficulty = useMemo(() => {
		return getDataByThreshold(ingredientsLength, INGREDIENTS_THRESHOLDS).difficulty;
	}, [ingredientsLength]);

	/**
	 * IngredientCompatibility
	 */
	const IngredientCompatibility = ({color = "tertiary"}) => {

		const pc = selectedNode !== undefined && selectedNode.type !== "group" ? selectedConnectedNodesAverageSharedMoleculesPc : selectedEdgesAverageSharedMoleculesPc;

		return (pc > 0 &&
			<Stack align="stretch" justify="flex-start" gap={0} miw={blockMiw}>
				<Text size={"36px"} fw={700} ta={"center"} lineClamp={1} c={pc > 0 ? getAverageSharedMoleculesPcColor(pc) : `var(--mantine-color-${color}-9)`}>
					{formatPc(pc, 0, 1, "")}%
				</Text>
				<Text size={"sm"} ta={"center"} opacity={1} c={pc > 0 ? getAverageSharedMoleculesPcColor(pc) : `var(--mantine-color-${color}-9)`}>
					{t("ingredient.compatibility")}
				</Text>
			</Stack>
		)
	}

	return (
		<>
			<Panel position={position} className={classes.defaultreactflowpanel} style={{marginBottom: "48px"}}>

				<Motion initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }}>

					<Box p={"md"} c={`var(--mantine-color-${color}-9)`} mih={150}>

						<Overlay color={`var(--mantine-color-${backgroundColor}-6)`} backgroundOpacity={0} blur={blur} radius={"md"} zIndex={-1}
								 // style={{boxShadow: "0 0 20px var(--mantine-color-tertiary-light-hover), 0 0 40px var(--mantine-color-tertiary-light-hover), 0 0 60px var(--mantine-color-tertiary-light-hover)"}}
						/>

						<Stack align="center" justify="flex-start" gap="sm">

							<Text size={"md"} fw={500} c={`${color}.9`} lineClamp={1} >{title}</Text>
							<Divider size={"xs"} color={`${color}.9`} w={"100%"} pb={4} style={{opacity: 0.15}} />

							<Group align="flex-start" justify="center" wrap={"nowrap"}>
								{(selectedNode !== undefined && selectedNode.type !== "group") || selectedEdge !== undefined /*|| ingredientsLength === 0*/ ? null :
									<Stack align="stretch" justify="flex-start" gap={0} miw={blockMiw}>
										<Text size={"36px"} fw={700} ta={"center"} lineClamp={1}>
											<NumberFormatter thousandSeparator="'" value={ingredientsLength}/>
										</Text>
										<Text size={"sm"} ta={"center"} opacity={1}>
											{t("common.ingredients")}
										</Text>
									</Stack>
								}
								{selectedEdge === undefined /*&& selectedEdges.length > 0*/ &&
									<Stack align="stretch" justify="flex-start" gap={0} miw={blockMiw}>
										<Text size={"36px"} fw={700} ta={"center"} lineClamp={1}>{selectedEdges.length}</Text>
										<Text size={"sm"} ta={"center"} opacity={1}>
											{t("studio.pairings")}
										</Text>
									</Stack>
								}
								<IngredientCompatibility />
								{selection.onPane &&
									<MantineTooltip opened={tooltipOpened} transitionProps={{ duration: 0 }} multiline maw={300} withArrow label={t("studio.difficultyDescription")} bg={"tertiary.9"} color={"white"}>
										<Stack align="stretch" justify="flex-start" gap={0} miw={blockMiw} style={{pointerEvents: "auto"}}
											   onMouseEnter={() => setTooltipOpened(true)}
											   onMouseLeave={() => setTooltipOpened(false)}
											   onTouchStart={() => setTooltipOpened((o) => !o)}>
												<Center>
													<Icon name={ingredientsDifficulty} style={{width: "36px", height: "36px", padding: "6px", marginBottom: "-8px", fill: `var(--mantine-color-${color}-9)`}}/>
												</Center>
												<Text size={"sm"} ta={"center"} opacity={1}>
													{t("recipe.difficulty")}
												</Text>
										</Stack>
									</MantineTooltip>
								}
							</Group>
						</Stack>
					</Box>
				</Motion>
			</Panel>

			<Panel position={position} style={{margin: "var(--mantine-spacing-xs)", marginBottom: "calc(var(--mantine-spacing-xs) * 2)"}}>
				<StudioCredits />
			</Panel>
		</>
	)
}

/**
 * Creations
 */
export const Creations = ({  projectId,
							  selectedMolecules,
								showDivider = true,
								visible = true,
								tabColor = "tertiary",
								color = "tertiary",
							  blur = 64,
							  backgroundColor = `var(--mantine-color-tertiary-6)`,
							  backgroundOpacity = 0.1,
							  backgroundZIndex = -1}) => {

	const { userAccount, onGeneratedRecipes, offGeneratedRecipes } = useAccountContext();
	const {environment} = useEnvironmentContext();

	const navigate = useNavigate();
	const { t } = useTranslation();

	const { Features } = useFeatures();

	const [page, setPage] = useState(1);
	const [totalCountItems, setTotalCountItems] = useState(0);
	const [search, setSearch] = useState(" ");

	const [deleteGeneratedRecipeId, setDeleteGeneratedRecipeId] = useState(undefined);

	const theme = useMantineTheme();

	const { isSm } = useMedia();

	const scrollAreaRef = useRef(null);

	const { data: dataItems, isLoaded: isLoadedItems,
		reset: resetItems, refetch: refetchItems} =
		useGeneratedRecipeAll({
			enabled: true,
			userId: userAccount.getUserId(),
			projectId: projectId,
			search: search,
			// random,
			page: page,
			onSuccess: (data, totalCount, count) => {
				setTotalCountItems(totalCount);
			}
		})

	const {reset: resetDeleteGeneratedRecipe, refetch: refetchDeleteGeneratedRecipe} =
		useDeleteGeneratedRecipe({
			generatedRecipeId: deleteGeneratedRecipeId,
			onSuccess: () => {
				handleOnGeneratedRecipes();
			}
		})

	// const {isSuccess, isError} =
	// 	useResult({
	// 		isSuccess: isLoadedItems,
	// 	})

	console.debug("Details.Creations.update")

	// Define the callback to execute on update
	const handleOnGeneratedRecipes = useDebouncedCallback((event) => {

		setSearch("");
		setPage(1);

		resetItems();
		refetchItems();

	}, 500);

	useEffect(() => {

		// Subscribe to the generated recipes
		onGeneratedRecipes(handleOnGeneratedRecipes);

		// Clean up by unsubscribing when component unmounts
		return () => {
			offGeneratedRecipes(handleOnGeneratedRecipes);
		};

	}, [onGeneratedRecipes, offGeneratedRecipes]);

	useEffect(() => {

		if(deleteGeneratedRecipeId) {
			resetDeleteGeneratedRecipe();
			refetchDeleteGeneratedRecipe();
		}

	}, [deleteGeneratedRecipeId]);

	/**
	 * scrollToTop
	 */
	const scrollToTop = () => {

		if (scrollAreaRef?.current !== undefined) {
			setTimeout(() => {
				try {
					scrollAreaRef.current.scrollTop = 0;
				}
				catch(e) {
					// noop
				}
			}, 100);
		}
	};

	/**
	 * @param value
	 */
	const onSearch = () => {

		setPage(1);

		resetItems();
		refetchItems();
	}

	useEffect(() => {
		resetItems();
		refetchItems();
	}, [page]);

	/**
	 * Creation
	 */
	const Creation = ({creation, highlight, color = "tertiary"}) => {

		const [opened, { open, close }] = useDisclosure(false, {
			// onOpen: () => console.log('Opened'),
			// onClose: () => console.log('Closed')
		});

		// /**
		//  * Extracts relevant details from the recipe schema and constructs an AI image generation prompt.
		//  * @param {Object} recipe - The recipe object following the schema.org Recipe format.
		//  * @returns {string} - A highly detailed prompt for generating an hyper-realistic, high-contrast, and design-focused food image.
		//  */
		// function generateImagePrompt(recipe) {
		// 	if (!recipe) {
		// 		return "";
		// 	}
		//
		// 	// Extract relevant information
		// 	const name = recipe.name || "A gourmet dish";
		// 	const cuisine = recipe.recipeCuisine ? `${recipe.recipeCuisine} cuisine` : "";
		// 	const category = recipe.recipeCategory || "";
		// 	const description = recipe.description || "";
		// 	const keywords = Array.isArray(recipe.keywords) ? recipe.keywords.join(", ") : "";
		// 	const ingredients = Array.isArray(recipe.recipeIngredient) ?
		// 		recipe.recipeIngredient.map(i => i.split(" ").slice(1).join(" ")).join(", ") : "";
		// 	const season = recipe.season ? `Season: ${recipe.season}` : "";
		// 	const tip = recipe.tip ? `Tip: ${recipe.tip}` : "";
		// 	const curiosity = recipe.curiosity ? `Fun fact: ${recipe.curiosity}` : "";
		//
		// 	// High-end food photography styling
		// 	const plating = "Michelin-star plating, meticulously arranged, minimalistic design, geometric food styling";
		// 	const lighting = "hard backlighting, reflective surfaces, strong highlights, deep blacks, cinematic shadows";
		// 	const contrast = "high-dynamic-range tonal depth, hyper-sharp textures, exquisite color balance";
		// 	const realism = "photo-realistic crisp textures, hyper-detailed macro, 16K resolution quality, perfect focus";
		// 	const camera = "high-end DSLR, 100mm macro lens, f/1.8 aperture for extreme depth";
		// 	const composition = "angled view, slightly tilted for depth, artistic negative space, plated on premium ceramic plate";
		// 	const textures = "moist, crispy, glossy highlights, luxurious reflections, silky smooth surfaces";
		//
		// 	// Construct the final prompt
		// 	return `Hyper-realistic, high-contrast macro studio photograph of a beautifully plated "${name}" dish.
		// 			${cuisine} ${category}.
		// 			${description}
		// 			Ingredients: ${ingredients}.
		// 			${season} ${tip} ${curiosity}.
		// 			${keywords}
		// 			Shot at a slight tilt, not top-down.
		// 			${plating}, ${lighting}, ${contrast}, ${realism}, ${camera}, ${composition}, ${textures}.`;
		// }

		// /**
		//  * Generates a highly detailed prompt for an hyper-realistic food image.
		//  * Ensures proper angle and removes unwanted elements like utensils.
		//  * @param {Object} recipe - The recipe object in schema.org Recipe format.
		//  * @returns {string} - The refined AI image prompt.
		//  */
		// function generateImagePrompt(recipe) {
		// 	if (!recipe) {
		// 		return "";
		// 	}
		//
		// 	/**
		// 	 * extractMoleculesFromAttribute
		// 	 */
		// 	function extractMoleculesFromAttribute(attribute) {
		// 		return accumulateFrequency(selectedMolecules?.flatMap(molecule => molecule[attribute] || []))
		// 			.slice(0, 5)
		// 			.map(accumulatedFrequency => accumulatedFrequency.name);
		// 	}
		//
		// 	// Extract relevant information
		// 	const name = recipe.name || "A gourmet dish";
		// 	const cuisine = recipe.recipeCuisine ? `${recipe.recipeCuisine} cuisine` : "";
		// 	const category = recipe.recipeCategory || "";
		// 	const description = recipe.description || "";
		// 	const keywords = Array.isArray(recipe.keywords) ? recipe.keywords.join(", ") : "";
		// 	const ingredients = Array.isArray(recipe.recipeIngredient)
		// 		? recipe.recipeIngredient.map(i => i.split(" ").slice(1).join(" ")).join(", ")
		// 		: "";
		//
		// 	// Extract sensory elements
		// 	const emotions = `Colors inspired by emotions: ${extractMoleculesFromAttribute("emotions").join(", ")}.`;
		// 	const flavors = `Colors inspired by flavors: ${extractMoleculesFromAttribute("flavors").join(", ")}.`;
		//
		// 	// Additional contextual details
		// 	const season = recipe.season ? `Season: ${recipe.season}.` : "";
		// 	const tip = recipe.tip ? `Tip: ${recipe.tip}.` : "";
		// 	const curiosity = recipe.curiosity ? `Fun fact: ${recipe.curiosity}.` : "";
		//
		// 	// CuckooKitchen color palette integration
		// 	const colorTheme = "Color scheme inspired by CuckooKitchen branding: Shocking Pink (#EF2AC1), Giants Orange (#FF5F1F), and Picton Blue (#00ADEE).";
		//
		// 	// Image details for improved angle and style
		// 	const plating = "high-end Michelin-star plating, modern and minimalist aesthetic, sleek and elegant presentation.";
		// 	const lighting = "hyper contrast, hyper dynamic range, bold highlights, sharp shadow definition.";
		// 	const depth = "side view, 45-degree tilt, immersive perspective, cinematic close-up, shallow depth of field.";
		// 	const realism = "hyper-realistic textures, hyper-crisp details, 8K resolution, hyper-detailed macro photography.";
		// 	// const camera = "high-end DSLR, 85mm f/2.8 lens, hyper professional studio photography, smooth bokeh effect.";
		// 	const camera = "4k HD DSLR Nikon photography.";
		// 	const textures = "moist, crispy, glossy reflections, rich color tones, perfect contrast, hyper-detailed refinement.";
		//
		// 	// Force no utensils, no top-down shots
		// 	const noUtensils = "No forks, no knives, no spoons";
		// 	const noTopDown = "Captured from an angled perspective, slight side view. No top-down view, no overhead shot.";
		//
		// 	// Construct the final prompt
		// 	const result = `Hyper-realistic macro studio photograph of a beautifully plated "${name}" dish.
		// 	${cuisine} ${category}.
		// 	${description}
		// 	Ingredients: ${ingredients}.
		// 	${emotions} ${flavors}.
		// 	${season} ${tip} ${curiosity}.
		// 	${keywords}
		// 	${noTopDown}
		// 	${noUtensils}
		// 	${plating}, ${lighting}, ${depth}, ${realism}, ${camera}, ${textures}.
		// 	${colorTheme}`;
		//
		// 	console.log(result);
		//
		// 	return result;
		// }

		// function generateImagePrompt(recipe) {
		// 	if (!recipe) {
		// 		return "";
		// 	}
		//
		// 	/**
		// 	 * extractMoleculesFromAttribute
		// 	 */
		// 	function extractMoleculesFromAttribute(attribute) {
		// 		return accumulateFrequency(selectedMolecules?.flatMap(molecule => molecule[attribute] || []))
		// 			.slice(0, 5)
		// 			.map(accumulatedFrequency => accumulatedFrequency.name);
		// 	}
		//
		// 	// Extract relevant information
		// 	const name = recipe.name || "A gourmet dish";
		// 	const cuisine = recipe.recipeCuisine ? `${recipe.recipeCuisine} cuisine` : "";
		// 	const category = recipe.recipeCategory || "";
		// 	const description = recipe.description || "";
		// 	const keywords = Array.isArray(recipe.keywords) ? recipe.keywords.join(", ") : "";
		// 	const ingredients = Array.isArray(recipe.recipeIngredient)
		// 		? recipe.recipeIngredient.map(i => i.split(" ").slice(1).join(" ")).join(", ")
		// 		: "";
		//
		// 	// Extract sensory elements
		// 	const emotions = `Colors inspired by emotions: ${extractMoleculesFromAttribute("emotions").join(", " )}.`;
		// 	const flavors = `Colors inspired by flavors: ${extractMoleculesFromAttribute("flavors").join(", " )}.`;
		//
		// 	// Additional contextual details
		// 	const season = recipe.season ? `Season: ${recipe.season}.` : "";
		// 	const tip = recipe.tip ? `Tip: ${recipe.tip}.` : "";
		// 	const curiosity = recipe.curiosity ? `Fun fact: ${recipe.curiosity}.` : "";
		//
		// 	// CuckooKitchen color palette integration
		// 	const colorTheme = "Color scheme inspired by CuckooKitchen branding: Shocking Pink (#EF2AC1), Giants Orange (#FF5F1F), and Picton Blue (#00ADEE).";
		//
		// 	// Enhanced image details for hyper-realism
		// 	const plating = "high-end Michelin-star plating, modern and minimalist aesthetic, sleek and elegant presentation.";
		// 	const lighting = "cinematic lighting, dramatic side illumination, hyper contrast, rich highlights, deep shadows, hyper dynamic range.";
		// 	const depth = "immersive perspective, 45-degree tilt, ultra-shallow depth of field, soft background bokeh.";
		// 	const realism = "8K ultra-sharp macro photography, hyper-realistic textures, visible surface pores, glossy balsamic reflections, silky pasta strands.";
		// 	const camera = "Captured with a Nikon D850 DSLR, 105mm macro lens, hyper-detailed refinement, smooth cinematic bokeh.";
		// 	const textures = "moist, crispy, slightly caramelized garlic edges, glistening oil emulsion, perfect contrast, bold highlights, rich color tones.";
		//
		// 	// Force no utensils, no top-down shots
		// 	const noUtensils = "No forks, no knives, no spoons";
		// 	const noTopDown = "Captured from an angled perspective, slight side view. No top-down view, no overhead shot.";
		//
		// 	// Construct the final prompt
		// 	const result = `A hyper-realistic macro studio photograph of a beautifully plated \"${name}\" dish.
		// 		- The plating is Michelin-star quality, with a modern, minimalist presentation on a matte black ceramic plate.
		// 		- Captured with a Nikon D850 DSLR, 105mm macro lens, and cinematic lighting, with dramatic side illumination enhancing texture depth.
		// 		- Rich highlights and sharp shadows emphasize the glistening oil emulsion, while steam subtly rises from the dish.
		// 		- The background is slightly blurred for depth, with perfect focus on moist, crispy, and glossy food textures.
		// 		- The recipe must be inspired by the following bibliography:
		// 				Institut Paul Bocuse Gastronomique, Hamlyn
		// 				Larousse Gastronomique, Hamlyn
		// 				Scenografia del piatto, Alexis Vergnory
		// 				Tecniche e tecnologie in cucina, Daniel Facen
		// 				Il piccolo manuale delle salse, L’Ippocampo
		// 				L’eccellenza della pasticceria dalla A alla Z, Iginio Massari
		// 				Il grande manuale del pasticciere, L’Ippocampo
		// 				Tutti i colori di Acquerello
		// 				La matrice dei sapori, Briscione
		// 				L’arte e la scienza del foodpairing, Peter Coucquyt, Bernard Lahousse, Johan Langenbick
		// 				Siete tutti invitati, Giorgio Locatelli
		// 				Basi professionali di cucina e pasticceria
		// 				Il piccolo manuale della viennoiserie, L’Ippocampo
		// 				Cottura sottovuoto a bassa temperatura, Marco Pirotta
		// 				A scuola di food design, Angela Simonelli
		// 				A scuola di food design in pasticceria, Angela Simonelli
		// 				Cannavacciuolo il meglio di Antonino, Einaudi
		// 				Artusi - La scienza in cucina e l'arte di mangiar bene
		// 		${emotions} ${flavors}
		// 		${season} ${tip} ${curiosity}
		// 		${keywords}
		// 		${noTopDown}
		// 		${noUtensils}
		// 		${plating}, ${lighting}, ${depth}, ${realism}, ${camera}, ${textures}.
		// 		${colorTheme}`;
		//
		// 	console.log(result);
		//
		// 	return result;
		// }

		function generateImagePrompt(recipe) {
			if (!recipe) {
				return "";
			}

			function extractMoleculesFromAttribute(attribute) {
				return accumulateFrequency(selectedMolecules, attribute)
					.slice(0, 5)
					.map(accumulatedFrequency => accumulatedFrequency.name);
			}

			const name = recipe.name || "A gourmet dish";
			const cuisine = recipe.recipeCuisine ? `${recipe.recipeCuisine} cuisine` : "";
			const category = recipe.recipeCategory || "";
			const description = recipe.description || "";
			const keywords = Array.isArray(recipe.keywords) ? recipe.keywords.join(", ") : "";
			const ingredients = Array.isArray(recipe.recipeIngredient)
				? recipe.recipeIngredient.map(i => i.split(" ").slice(1).join(" ")).join(", ")
				: "";

			const emotions = `Colors inspired by emotions: ${extractMoleculesFromAttribute("emotions").join(", ")}.`;
			const flavors = `Colors inspired by flavors: ${extractMoleculesFromAttribute("flavors").join(", ")}.`;

			const season = recipe.season ? `Season: ${recipe.season}.` : "";
			const tip = recipe.tip ? `Tip: ${recipe.tip}.` : "";
			const curiosity = recipe.curiosity ? `Fun fact: ${recipe.curiosity}.` : "";

			const colorTheme = "Color scheme must be bright and vibrant, inspired by CuckooKitchen branding: Shocking Pink (#EF2AC1), Giants Orange (#FF5F1F), and Picton Blue (#00ADEE).";

			const plating = "high-end Michelin-star plating, modern and minimalist aesthetic, sleek and elegant presentation.";
			const lighting = "bright, natural diffused lighting, soft shadows, balanced contrast, avoiding harsh darkness.";
			const depth = "immersive perspective, 45-degree tilt, ultra-shallow depth of field, soft background bokeh.";
			const realism = "8K ultra-sharp macro photography, hyper-realistic textures, visible surface pores, glossy balsamic reflections, silky pasta strands.";
			const camera = "Captured with a Nikon D850 DSLR, 105mm macro lens, hyper-detailed refinement, smooth cinematic bokeh.";
			const textures = "perfect contrast, bold highlights, rich color tones, luminous highlights for a fresh and airy feel.";
			const platingSurface = "Presented on an elegant ceramic plate or soft pastel-toned plate (random color: White or Shocking Pink (#EF2AC1) or Giants Orange (#FF5F1F) or Picton Blue (#00ADEE)), avoiding dark or moody backgrounds.";

			const noUtensils = "No forks, no knives, no spoons";
			const noTopDown = "Captured from an angled perspective, slight side view. No top-down view, no overhead shot.";

			const result = `A hyper-realistic macro studio photograph of a beautifully plated \"${name}\" dish. 
				- The recipe must be inspired by the following bibliography:                
						- Institut Paul Bocuse Gastronomique, Hamlyn
						- Larousse Gastronomique, Hamlyn
						- Scenografia del piatto, Alexis Vergnory
						- Tecniche e tecnologie in cucina, Daniel Facen
						- Il piccolo manuale delle salse, L’Ippocampo
						- L’eccellenza della pasticceria dalla A alla Z, Iginio Massari
						- Il grande manuale del pasticciere, L’Ippocampo
						- Tutti i colori di Acquerello
						- La matrice dei sapori, Briscione
						- L’arte e la scienza del foodpairing, Peter Coucquyt, Bernard Lahousse, Johan Langenbick
						- Siete tutti invitati, Giorgio Locatelli
						- Basi professionali di cucina e pasticceria
						- Il piccolo manuale della viennoiserie, L’Ippocampo
						- Cottura sottovuoto a bassa temperatura, Marco Pirotta
						- A scuola di food design, Angela Simonelli
						- A scuola di food design in pasticceria, Angela Simonelli
						- Cannavacciuolo il meglio di Antonino, Einaudi
						- Artusi - La scienza in cucina e l'arte di mangiar bene
				${cuisine} ${category}.
				${description}.
				Ingredients: ${ingredients} (decorations must be made only with this ingredients).
				${emotions} ${flavors}.
				${season}, ${tip}, ${curiosity}.
				${keywords}.
				${noTopDown}, ${noUtensils}.
				${plating}, ${platingSurface}, ${lighting}, ${depth}, ${realism}, ${camera}, ${textures}.
				${colorTheme}`;

			// console.log(result);
			return result;
		}

		const {	data: dataGeneratedRecipeImage, isLoaded: isLoadedGeneratedRecipeImage,
			reset: resetGeneratedRecipeImage, refetch: refetchGeneratedRecipeImage} =
			useGeneratedRecipeImage({
				enabled: opened,
				generatedRecipeId: creation.generatedRecipeId,
				onSuccess: (data) => {
					// console.log("useGeneratedRecipeImage", data)
				},
				onError: (error) => {
					console.error("useGeneratedRecipeImage", error);
				}
			})

		const {isLoading: isLoadingGenerateRecipeImage, reset: resetGenerateRecipeImage, refetch: refetchGenerateRecipeImage} =
			useGenerateRecipeImage({
				generatedRecipeId: creation.generatedRecipeId,
				message: generateImagePrompt(creation.recipe),
				onSuccess: (data) => {
					// console.log("useGenerateRecipeImage", data);
					refetchGeneratedRecipeImage();
				},
				onError: (error) => {
					console.error("useGenerateRecipeImage", error);
				}
			})

		/**
		 * RecipeDisplay
		 */
		const RecipeDisplay = () => {

			return (
				<Stack>
					<Text size="xl">{creation.recipe.name}</Text>
					<Text size="sm">{creation.recipe.description}</Text>
					<Divider my="sm" />
					<Text weight={500}>Ingredients:</Text>
					<List>
						{creation.recipe.recipeIngredient.map((ingredient, index) => (
							<List.Item key={index}>{ingredient}</List.Item>
						))}
					</List>
					<Divider my="sm" />
					<Text weight={500}>Instructions:</Text>
					<List>
						{creation.recipe.recipeInstructions.map((step, index) => (
							<List.Item key={index}>
								<Text weight={500}>{step.name}: </Text>{step.text}
							</List.Item>
						))}
					</List>
					<Divider my="sm" />
					<Text weight={500}>Nutrition Information:</Text>
					<Text>Calories: {creation.recipe.nutrition.calories}</Text>
					<Text>Fat: {creation.recipe.nutrition.fatContent}</Text>
					<Text>Carbohydrates: {creation.recipe.nutrition.carbohydrateContent}</Text>
					<Text>Protein: {creation.recipe.nutrition.proteinContent}</Text>
					<Text>Serving Size: {creation.recipe.nutrition.servingSize}</Text>
					<Divider my="sm" />
					<Text weight={500}>Additional Information:</Text>
					<Text>Published on: {creation.recipe.datePublished}</Text>
					<Text>Yield: {creation.recipe.recipeYield}</Text>
					<Text>Difficulty: {creation.recipe.difficulty}</Text>
					<Text>Tip: {creation.recipe.tip}</Text>
					<Text>Storage: {creation.recipe.storage}</Text>
					<Text>Curiosity: {creation.recipe.curiosity}</Text>
				</Stack>
			)
		}

		return (

			<>
				<Modal opened={opened && isLoadedGeneratedRecipeImage}
					   color={color}
					   // centered
					   closeOnClickOutside={false}
					   withCloseButton={false}
					   fullScreen
					   // overlayProps={{color: `var(--mantine-color-${color}-12)`, backgroundOpacity: 0.75, blur: 7}}
					   // zIndex={404}
					   onClose={close}
					   // scrollAreaComponent={ScrollArea.Autosize}
					   classNames={{
						   root: classes.modalrootcreation,
						   header: classes.modalheadercreation,
						   content: classes.modalcontentcreation,
						   inner: classes.modalinnercreation,
						   body: classes.modabodycreation,
					   }}
				>

					<Box className={classes.generatedrecipeimageroot}
						 style={{backgroundImage: dataGeneratedRecipeImage ? `url(${stringToImageSrc(dataGeneratedRecipeImage)})` : "none"}}/>

					{!dataGeneratedRecipeImage &&
							<FontAwesomeIcon icon={faImage} color={"white"} opacity={0.07}
											 style={{position: 'absolute', top: "50%", left: "50%", transform: 'translate(-50%, -50%)', width: "30%", height: "30%"}}
							/>
					}

					<Group align={"flex-end"}  ml={"calc(var(--mantine-spacing-xl) * 2)"}>

						<Stack gap={0} w={"30%"}>
							<Box h={"calc((var(--vh, 1vh) * 100) - 100px /*Padding*/)"} style={{position: "relative"}} >
								<Overlay color={`var(--mantine-color-tertiary-2)`} backgroundOpacity={0.25} blur={32} radius={0} zIndex={-1}/>

								<ScrollableArea>
									<Box p={"md"} style={{minHeight: "calc((var(--vh, 1vh) * 100) - 100px /*Padding*/)", display: "flex", flexDirection: "column", justifyContent: "center"}}> {/* Center Stack*/}
										<Text c={"tertiary.0"} style={{ wordBreak: "break-word", whiteSpace: "pre-wrap", overflowX: "hidden" }}>
											<RecipeDisplay />
										</Text>
									</Box>
								</ScrollableArea>

							</Box>

							{Features.studio.features.aiImageGenerator.plan.enabled &&
								<Box p={"md"} h={100} style={{position: "relative"}}>

									<Overlay color={`var(--mantine-color-secondary-6)`} backgroundOpacity={0.25} blur={32} radius={0} zIndex={-1}/>

									<Command withOverlay tablerIcon={"IconBrandOpenai"} onClick={() => refetchGenerateRecipeImage()}
											 label={t("studio.generateRecipeImageTokens", {usedImageTokens: userAccount.getAi().imageTokens, totalImageTokens: environment.aiMaxImageTokens})}
											 loading={isLoadingGenerateRecipeImage}/>
								</Box>
							}
						</Stack>

						<Box>
							{/*<Command withOverlay tablerIcon={"IconBrandOpenai"} onClick={() => refetchGenerateRecipeImage()}*/}
							{/*		 label={t("studio.generateRecipeImageTokens", {usedImageTokens: userAccount.getAi().imageTokens, totalImageTokens: environment.aiMaxImageTokens})}*/}
							{/*		 loading={isLoadingGenerateRecipeImage}/>*/}
						</Box>
					</Group>
				</Modal>

				<Stack gap={4} style={{cursor: !opened ? 'pointer' : 'wait', color: `var(--mantine-color-${color}-9)`}} onClick={open}>
					<Highlight inherit highlight={generateAccentVariantsForWord(highlight)} >
						{creation.title}
					</Highlight>
					<Text size={"xs"} opacity={0.75}>
						{toDateTime((creation.created))}
					</Text>
				</Stack>
			</>
		)
	}

	return (totalCountItems > 0 &&
		<>
			{showDivider &&
				<Group gap={4} c={"gray.5"} wrap={"nwrap"} mt={"-4px"} mb={"-4px"}>
					<Icon name={"hat-chef"} style={{width: "12px", height: "12px", fill: `var(--mantine-color-gray-5)`, marginBottom: "-1px"}}/>
					<Text size={"10px"} w={"100%"} ta={"right"} pr={"24px"}>{t("common.recipes")}</Text>
				</Group>
			}
			<TabsLayout
				visible={visible}
				title={t("common.recipes")}
				blur={blur}
				color={tabColor}
				backgroundColor={backgroundColor}
				backgroundOpacity={backgroundOpacity}
				backgroundZIndex={backgroundZIndex}
				collapsed={
					<Text size={"36px"} fw={700} lineClamp={1} c={`var(--mantine-color-${color}-9)`}>
						<NumberFormatter thousandSeparator="'" value={totalCountItems}/>
					</Text>
				}
				tabs={[
					{
						fontAwesomeIcon: faTableList,
						content: <Flexible>

							<Flexible.Expandable>
								<ScrollableArea color={color} viewportRef={scrollAreaRef}>

									<StripedTable striped={"even"} highlightOnHover highlightOnHoverColor={color} stripedColor={color} stickyHeader className={classes.detailscreationstable}>
										<Table.Thead>
											<Table.Tr>
												<Table.Th p={0} pl={0} colSpan={3}>
													<SearchInput
														iconColor={"white"}
														textInputClassNames= {{
															input: classes.detailssarchinputtertiary
														}}
														size={"xs"}
														radius={4}
														value={search?.trim()}
														placeholder={t(`common.recipes`)}
														onChange={(value) => setSearch(value)}
														onSearch={(value) => onSearch()}
													/>
												</Table.Th>
											</Table.Tr>
										</Table.Thead>
										<Table.Tbody>
											{dataItems && dataItems
												.map((item, index) => (
													<Table.Tr style={{cursor: "pointer"}}>
														<Table.Td>
															<Creation creation={item} highlight={search}/>
														</Table.Td>
														<Table.Td w={!isSm ? "60px" : "30px"} style={{verticalAlign: "middle"}}>
															<Group gap={"md"} wrap={"nowrap"}>
																{!isSm &&
																	<DeleteModal
																		targetComponent={
																			<Button color={"tertiary"} variant={"subtle"} p={0} pl={7} pr={7}>
																				<FontAwesomeIcon icon={faTrash}/>
																			</Button>
																		}
																		description={t("common.deleteModal", {item: item.title})}
																		onDelete={() => {
																			setDeleteGeneratedRecipeId(item.generatedRecipeId);
																		}}/>
																}
																<FontAwesomeIcon icon={faChevronRight}/>
															</Group>
														</Table.Td>
													</Table.Tr>
												))}
										</Table.Tbody>
									</StripedTable>
								</ScrollableArea>
							</Flexible.Expandable>

							<Flexible.Fixed style={{zIndex: "1"}}>
								<Paginator page={page} onPageChange={setPage} paginationSize={theme.custom.recipe.paginationSize}
										   selectedCount={totalCountItems} totalCount={totalCountItems}
										   wrapOn={"xs"} size={"md"} gap={"6px"} withControls={false}
										   color={color}
										   infoColor={color}
										   paginatorClassNames = {{
											   control: classes.detailspaginationcontroltertiary,
											   dots: classes.detailspaginationdotstertiary
										   }}/>
							</Flexible.Fixed>
						</Flexible>
					}
				]}
			/>
		</>
	);
};

/**
 *
 * @param a
 * @param b
 * @returns {number}
 */
function entityPairingsComparator(a, b) {

	// Inverted comparison
	if(a.sharedMoleculesPc &&  b.sharedMoleculesPc) {

		const pcComparison = b.sharedMoleculesPc - a.sharedMoleculesPc;

		if (pcComparison !== 0) {
			return pcComparison;
		}
	}

	return a.name.localeCompare(b.name);
}

/**
 * Nutrients
 */
const Nutrients = ({nodes, edges, selection,
					   color = "primary" }) => {

	const memoizedProps = useMemo(() => ({ nodes, edges, selection }), [nodes, edges, selection]);
	const {selectedNutrients} = useMemoizedSelection(memoizedProps);

	const [page, setPage] = useState(1);
	const [search, setSearch] = useState(" ");

	const scrollAreaPRef = useRef(null);

	const {t} = useTranslation();
	const theme = useMantineTheme();

	useEffect(() => {
		setSearch("");
		setPage(1);
	}, [selection]);

	/**
	 * @param value
	 */
	const onSearch = (value) => {
		setSearch(value);
		setPage(1);
	}

	/**
	 * searchItems
	 */
	const searchItems = useMemo(() => {
		try {
			let items = [];

			const lower = removeAccents(search?.toLowerCase().trim() || "");

			if (!lower) {
				items = [...selectedNutrients]; // clone per sicurezza
			}
			else {
				items = selectedNutrients.filter(nutrient => {
					const name = removeAccents(localized(nutrient, 'name')?.toLowerCase() || "");
					const category = removeAccents(nutrient.nutrientCategory?.toLowerCase() || "");
					return name.includes(lower) || category.includes(lower);
				});
			}

			// Ordina per categoria ID, poi nutrient rank, poi nome
			items.sort((a, b) => {
				const catA = a.nutrientCategoryId ?? 999;
				const catB = b.nutrientCategoryId ?? 999;
				if (catA !== catB) return catA - catB;

				const rankA = a.rank ?? 999999;
				const rankB = b.rank ?? 999999;
				if (rankA !== rankB) return rankA - rankB;

				const nameA = removeAccents(localized(a, 'name')?.toLowerCase() || "");
				const nameB = removeAccents(localized(b, 'name')?.toLowerCase() || "");
				return nameA.localeCompare(nameB);
			});

			return items;
		}
		catch (e) {
			return [];
		}
	}, [search, selectedNutrients]);

	/**
	 * pagedItems
	 */
	const pagedItems = useMemo(() => {
		return searchItems.slice(paginationStartSkip(page, theme.custom.ingredient.paginationSize), paginationEndSkip(page, theme.custom.ingredient.paginationSize))
	}, [searchItems, page, theme.custom.ingredient.paginationSize]);

	/**
	 * groupedByCategory
	 */
	const groupedByCategory = useMemo(() => {
		return pagedItems.reduce((acc, nutrient) => {
			const catId = nutrient.nutrientCategoryId ?? 999;
			const catName = nutrient.nutrientCategory ?? "Other";

			if (!acc[catId]) {
				acc[catId] = {
					categoryId: catId,
					categoryName: catName,
					items: []
				};
			}

			acc[catId].items.push(nutrient);
			return acc;
		}, {});
	}, [pagedItems]);

	/**
	 * sortedCategories
	 */
	const sortedCategories = useMemo(() => {
		return Object.values(groupedByCategory).sort((a, b) => {
			return a.categoryId - b.categoryId;
		});
	}, [groupedByCategory]);

	return (<Flexible>

			<Flexible.Expandable>
				<ScrollableArea color={color} viewportRef={scrollAreaPRef}>

					<StripedTable striped={false} stickyHeader className={classes.detailsentitytablenutrients}>
						<Table.Thead>
							<Table.Tr style={{backgroundColor: `var(--mantine-color-${color}-6)`}}>
								<Table.Th p={0} pl={0}>
									<SearchInput
										iconColor={"white"}
										textInputClassNames= {{
											input: classes[`detailssarchinput${color}`]
										}}
										size={"xs"}
										radius={0}
										value={search}
										placeholder={t(`ingredient.nutrient`)}
										onChange={(value) => onSearch(value)}
									/>
								</Table.Th>
								<Table.Th ta={"right"} w={"30%"}>
									{`${t('common.amount')} (${NUTRIENT_DEFAULT_SERVING})`}
								</Table.Th>
							</Table.Tr>
						</Table.Thead>
						<Table.Tbody>
							{sortedCategories.map((group, index) => (
								<React.Fragment key={`category-${group.categoryId}`}>
									<Table.Tr bg={`var(--mantine-color-${color}-light-hover)`} style={{color: `var(--mantine-color-${color}-9)`}}>
										<Table.Td colSpan={2}>
											<Text size={"sm"}>
												<Highlight highlight={search} fw={700}>
													{group.categoryName}
												</Highlight>
											</Text>
										</Table.Td>
									</Table.Tr>

									{group.items.map((item, idx) => (
										<Table.Tr style={{color: `var(--mantine-color-${color}-9)`, backgroundColor: idx % 2 === 1 ? `var(--mantine-color-${color}-outline-hover)` : "transparent"}}>
											<Table.Td style={{verticalAlign: "top"}}>
												<Text size={"sm"}>
													<Highlight inherit highlight={generateAccentVariantsForWord(search)} pl={"md"}>
														{localized(item, "name")}
													</Highlight>
												</Text>
											</Table.Td>
											<Table.Td ta={"right"}>
												<Stack align="flex-end" justify="flex-start" gap={0}>
													<Text size={"sm"}>
														<NumberFormatter value={item.amount} suffix={item.unit} thousandSeparator="'" decimalScale={3}/>
													</Text>
													{item.serving !== NUTRIENT_DEFAULT_SERVING &&
														<Text size={'xs'} ta={"right"} pb={3}>
															{t("ingredient.perServing", {serving: item.serving})}
														</Text>
													}
												</Stack>
											</Table.Td>
										</Table.Tr>
								))}
								</React.Fragment>
							))}
						</Table.Tbody>
					</StripedTable>
				</ScrollableArea>
			</Flexible.Expandable>

			<Flexible.Fixed style={{zIndex: "1"}}>
				<Paginator page={page} onPageChange={setPage} paginationSize={theme.custom.ingredient.paginationSize}
						   selectedCount={searchItems.length} totalCount={selectedNutrients.length}
						   wrapOn={"xs"} size={"md"} gap={"6px"} withControls={false}
						   color={color}
						   infoColor={color}
						   paginatorClassNames = {{
							   control: classes[`detailspaginationcontrol${color}`],
							   dots: classes[`detailspaginationdots${color}`]
						   }}/>
			</Flexible.Fixed>
		</Flexible>
	)
}

/**
 * Entities
 */
const Entities = ({ nodes, edges, selection,
					  visible = true,
					  color = "tertiary",
					  maxItems = 10 }) => {

	const navigate = useNavigate();
	const { t } = useTranslation();

	const memoizedProps = useMemo(() => ({ nodes, edges, selection }), [nodes, edges, selection]);
	const {selectedNode, selectedCustomNodes, selectedEntities, selectedConnectedNodes, selectedEdge, selectedEdges, selectedEdgesAverageSharedMoleculesPc } = useMemoizedSelection(memoizedProps);

	const [selectedEntityCategories, setSelectedEntityCategories] = useState([]);
	const [selectedCategorizedEntities, setSelectedCategorizedEntities] = useState([]);

	const [activeIndex, setActiveIndex] = useState(undefined);

	const [page, setPage] = useState(1);
	const [search, setSearch] = useState(" ");

	const [pagePairings, setPagePairings] = useState(1);
	const [searchPairings, setSearchPairings] = useState(" ");

	const { FeaturePlans, Features } = useFeatures();

	const theme = useMantineTheme();

	const scrollAreaRef = useRef(null);
	const scrollAreaPairingsRef = useRef(null);

	const [tooltipOpened, setTooltipOpened] = useState(false);

	console.debug("Details.Entities.update")

	/**
	 * Finds all selected items that belong to a specific category ID.
	 * @param {number} entityCategoryId - The ID of the entity category to search for.
	 * @returns {Array} - The filtered array of items belonging to the specified category.
	 */
	function findItemsByCategory(entityCategoryId) {

		if(entityCategoryId === CUSTOM_CATEGORY_ID) {
			return selectedCustomNodes;
		}

		return selectedEntities.filter(entity =>
			entity.categories.some(category => category.entityCategoryId === entityCategoryId)
		);
	}

	useEffect(() => {
		setSearchPairings("");
		setSearch("");
	}, []);

	useEffect(() => {

		setSearch("");
		setPage(1);
		setActiveIndex(undefined);

		// Create a Map to aggregate category counts
		const categoryMap = new Map();

		selectedEntities.forEach((entity) => {
			entity.categories.forEach((category) => {
				const categoryId = category.entityCategoryId;

				// If the category already exists in the map, update its count
				if (categoryMap.has(categoryId)) {
					const existing = categoryMap.get(categoryId);
					categoryMap.set(categoryId, {
						...existing,
						count: existing.count + 1,
					});
				}
				else {
					// Otherwise, initialize the category in the map
					categoryMap.set(categoryId, {
						categoryId: categoryId,
						name: localized(category, "name"),
						count: 1,
						pc: 0, // Will calculate this later
					});
				}
			});
		});

		// Check for selected custom nodes
		if(selectedCustomNodes && selectedCustomNodes.length > 0){
			categoryMap.set(CUSTOM_CATEGORY_ID, {
				categoryId: CUSTOM_CATEGORY_ID,
				name: t("studio.customNode"),
				count: selectedCustomNodes.length,
				pc: 0, // Will calculate this later
			});
		}

		// Calculate the percentage for each category
		const totalItems = selectedEntities.length + (selectedCustomNodes?.length ?? 0);
		const selectedEntityCategories = Array.from(categoryMap.values()).map((category) => ({
			...category,
			pc: ((category.count / totalItems) * 100),
		}))
			.sort((a, b) => a.name.localeCompare(b.name));

		// Update the state
		setSelectedEntityCategories(selectedEntityCategories);

	}, [selectedEntities]);

	useEffect(() => {

		// Prepare a flat list of categorized entities
		setSelectedCategorizedEntities(selectedEntityCategories.flatMap((selectedEntityCategory) => {
				const result = [];

				findItemsByCategory(selectedEntityCategory.categoryId).map((item) => {
					result.push({
						categoryId: selectedEntityCategory.categoryId,
						categoryName: selectedEntityCategory.name,
						entityId: item?.entityId,
						// name: localized(item.representativeIngredient, "name"),
						ingredientId: item?.selectedIngredient?.name,
						name: item?.selectedIngredient?.label || item
					});
				});

				return result;
			})
				.sort((a, b) => {
					// Sort the flat list by entityCategoryName and then by name alphabetically
					const categoryComparison = a.categoryName.localeCompare(b.categoryName);
					if (categoryComparison !== 0) {
						return categoryComparison; // If entityCategoryName is different, sort by it
					}
					// If entityCategoryName is the same, compare by name
					return a.name.localeCompare(b.name);
				})
		);

	}, [selectedEntityCategories]);

	/**
	 * searchPairingItems
	 */
	const searchPairingItems = useMemo(() => {

		try {
			if (!searchPairings) {
				return selectedConnectedNodes;
			}

			const normalizedSearch = removeAccents(searchPairings.toLowerCase());

			return selectedConnectedNodes.filter(selectedConnectedNode => {
				const nodeName = selectedConnectedNode.name;
				if (!nodeName) return false;

				return removeAccents(nodeName.toLowerCase()).includes(normalizedSearch);
			});
		}
		catch (ignored) {
			// noop
		}

		return [];
	}, [searchPairings, selectedConnectedNodes]);

	/**
	 * searchItems
	 */
	const searchItems = useMemo(() => {

		try {
			if (!search) {
				return selectedCategorizedEntities;
			}

			const normalizedSearch = removeAccents(search.toLowerCase());

			return selectedCategorizedEntities.filter(selectedCategorizedEntity => {
				const entityName = selectedCategorizedEntity.name;
				if (!entityName) return false;

				return removeAccents(entityName.toLowerCase()).includes(normalizedSearch);
			});
		}
		catch (ignored) {
			// noop
		}

		return [];
	}, [search, selectedCategorizedEntities]);

	// useEffect(() => {
	//
	// 	if(onChange) {
	// 		onChange(getTitle());
	// 	}
	//
	// }, [selectedEntityCategories, selectedEntities, selectedEdge]);
	//
	// useEffect(() => {
	// 	scrollToTop();
	// }, [tabChange]);

	/**
	 * getIngredients
	 */
	function getIngredientsLength(index) {

		if(index === undefined) {
			return (selectedEntities?.length ?? 0) + (selectedCustomNodes?.length ?? 0);
		}

		return selectedEntityCategories[index].count;
	}

	/**
	 * getIngredientDifficulty
	 */
	function getIngredientDifficulty() {
		return getDataByThreshold(getIngredientsLength(), INGREDIENTS_THRESHOLDS).difficulty;
	}

	/**
	 * scrollToTop
	 */
	const scrollToTop = () => {

		if (scrollAreaRef?.current !== undefined) {
			setTimeout(() => {
				try {
					scrollAreaRef.current.scrollTop = 0;
				}
				catch(e) {
					// noop
				}
			}, 100);
		}
	};

	// /**
	//  * getTitle
	//  */
	// function getTitle() {
	//
	// 	if(selectedNode !== undefined) {
	// 		return `${selectedNode.data.label}`;
	// 	}
	//
	// 	if(selectedEdge !== undefined) {
	// 		return `${selectedNodes[0].data.label} - ${selectedNodes[1].data.label}`;
	// 	}
	//
	// 	if(selection.onPane) {
	// 		return t("studio.workSurface");
	// 	}
	//
	// 	return `${getIngredients()} ${t("common.ingredients")} ${getSubtitle() !== undefined ? getSubtitle() : ""}`;
	// }
	//
	// /**
	//  * getSubtitle
	//  */
	// function getSubtitle() {
	//
	// 	if(selection.nodes.length > 0) {
	// 		return t("studio.selected");
	// 	}
	//
	// 	if(selectedEdge !== undefined) {
	// 		return t("studio.compatible")
	// 	}
	//
	// 	return undefined;
	// }
	//
	// /**
	//  * Subtitle
	//  */
	// const Subtitle = () => {
	//
	// 	return getSubtitle() === undefined ? null :
	// 		<Text c={activeIndex !== undefined ? "var(--mantine-color-tertiary-6)" : "var(--mantine-color-tertiary-9)"}>
	// 			{getSubtitle()}
	// 		</Text>
	// }

	/**
	 * @param value
	 */
	const onSearchPairings = (value) => {
		setSearchPairings(value);
		setPagePairings(1);
	}

	/**
	 * @param value
	 */
	const onSearch = (value) => {
		setSearch(value);
		setPage(1);
	}

	/**
	 * @param props
	 * @returns {Element}
	 */
	const RenderLabel = (props) => {

		const [tooltipOpened, setTooltipOpened] = useState(false);

		const RADIAN = Math.PI / 180;
		const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value, index } = props;
		const sin = Math.sin(-RADIAN * midAngle);
		const cos = Math.cos(-RADIAN * midAngle);
		const sx = cx + (outerRadius + 10) * cos;
		const sy = cy + (outerRadius + 10) * sin;
		const mx = cx + (outerRadius + 30) * cos;
		const my = cy + (outerRadius + 30) * sin;
		const ex = mx + (cos >= 0 ? 1 : -1) * 22;
		const ey = my;
		const textAnchor = cos >= 0 ? 'start' : 'end';

		// const wrapText = (text, maxCharsPerLine = 10) => {
		// 	const words = text.split(" ");
		// 	let lines = [];
		// 	let currentLine = "";
		//
		// 	words.forEach((word) => {
		// 		// If adding the current word doesn't exceed the limit, let's add it to the current line.
		// 		if ((currentLine + word).length <= maxCharsPerLine) {
		// 			currentLine += word + " ";
		// 		} else {
		// 			// Otherwise, we close the current line and start a new one.
		// 			lines.push(currentLine.trim());
		// 			currentLine = word + " ";
		// 		}
		// 	});
		//
		// 	// We add the last line
		// 	lines.push(currentLine.trim());
		//
		// 	return lines;
		// };

		return (
			<g>
				{index === activeIndex &&

					<Sector
						cx={cx}
						cy={cy}
						startAngle={startAngle}
						endAngle={endAngle}
						innerRadius={outerRadius + 8}
						outerRadius={outerRadius + 10}
						fill={`var(--mantine-color-${color}-6)`}
						style={{cursor: 'pointer'}}
						cornerRadius={1}
					/>

				}
				<path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={index === activeIndex ? `var(--mantine-color-${color}-6)` : `var(--mantine-color-${color}-9)`} strokeWidth={1}
					  strokeDasharray="3 3" fill="none"/>
				{/*<circle cx={ex} cy={ey} r={3} fill={"white"} stroke="none"/>*/}
				<MantineTooltip transitionProps={{ duration: 0 }} offset={-35} color={color}
								// onMouseEnter={() => setActiveIndex(index)}
								// onMouseLeave={() => setActiveIndex(undefined)}
								onClick={() => {
									if(payload.categoryId !== CUSTOM_CATEGORY_ID) {
										entityCategoryIdNavigate(navigate, payload.categoryId, "_blank");
									}
								}}
								label={
									<Group>
										<Stack gap={0}>
											<Text size={"14px"} fw={700} pb={2}>{payload.name}</Text>
											<Text size={"12px"} ta={textAnchor}>{formatPc(percent * 100)}</Text>
										</Stack>
										{payload.categoryId !== CUSTOM_CATEGORY_ID &&
											<FontAwesomeIcon icon={faUpRightFromSquare} size={"sm"}/>
										}
									</Group>
								}
				>
					<g style={{cursor: payload.categoryId !== CUSTOM_CATEGORY_ID ? 'pointer' : "default"}}
					   onMouseEnter={() => {setTooltipOpened(true); setActiveIndex(index);}}
					   onMouseLeave={() => {setTooltipOpened(false); setActiveIndex(undefined);}}
					   onTouchStart={() => {setTooltipOpened((o) => !o); setActiveIndex(activeIndex === undefined ? index : undefined)}}>
						<text x={ex + (cos >= 0 ? 1 : -1) * 8} y={ey} dy={5} textAnchor={textAnchor} fontSize={14} fontWeight={700}
							  fill={index === activeIndex ? `var(--mantine-color-${color}-6)` : `var(--mantine-color-${color}-9)`}>
							{payload.name}
						</text>
						<text x={ex + (cos >= 0 ? 1 : -1) * 8} y={ey} dy={20} textAnchor={textAnchor} fontSize={12}
							  fill={index === activeIndex ? `var(--mantine-color-${color}-6)` : `var(--mantine-color-${color}-9)`}>
							{formatPc(percent * 100)}
						</text>
					</g>
				</MantineTooltip>
			</g>
		);
	};

	return (
		<TabsLayout
			visible={visible}
			title={t("common.ingredients")}
			collapsed={
				<Text size={"36px"} fw={700} lineClamp={1} c={`var(--mantine-color-${color}-9)`}>
					<NumberFormatter thousandSeparator="'" value={getIngredientsLength()}/>
				</Text>
			}
			tabs={[
				{
					tablerIcon: "IconChartDonutFilled",
					content: <Stack align="stretch" justify="flex-start" h={"100%"} gap={0}>

						<Stack w={"100%"} h={"100%"} style={{position: "relative"}}
							   onMouseLeave={() => setActiveIndex(undefined)}>

							<Stack align="center" justify="flex-start" gap={0}
								   style={{position: "absolute", top: "50%", left: "50%", transform:"translate(-50%, -50%)"}}>
								<Text size={"36px"} fw={700} c={activeIndex !== undefined ? `var(--mantine-color-${color}-6)` : `var(--mantine-color-${color}-9)`}>
									<NumberFormatter thousandSeparator="'" value={getIngredientsLength(activeIndex)}/>
								</Text>
								<Text size={"sm"} c={activeIndex !== undefined ? `var(--mantine-color-${color}-6)` : `var(--mantine-color-${color}-9)`}>
									{t("common.ingredients")}
								</Text>
								{/*<Subtitle />*/}
							</Stack>

							<ResponsiveContainer width="100%" height="100%">
								<PieChart>
									<Pie
										data={selectedEntityCategories}
										cx="50%"
										cy="50%"
										innerRadius={"40%"}
										outerRadius={"50%"}
										fill={`var(--mantine-color-${color}-9)`}
										dataKey="count"
										paddingAngle={1}
										strokeWidth={0}
										isAnimationActive={false}
										startAngle={90}
										endAngle={-270}
										cornerRadius={1}
										activeIndex={activeIndex}
										activeShape={(props) => <Sector {...props} style={{cursor: 'pointer'}}
																		fill={`var(--mantine-color-${color}-6)`}/>}
										// onMouseEnter={(_, index) => setActiveIndex(index)}
										// onMouseLeave={() => setActiveIndex(undefined)}
										// onClick={(data, index) => {
										// 	entityCategoryNavigate(navigate, data, "_blank");
										// }}
										label={(props) => (
											<RenderLabel {...props}/>
										)}
									/>
								</PieChart>
							</ResponsiveContainer>
						</Stack>

						<Group align="flex-start" justify="center" c={`var(--mantine-color-${color}-9)`} wrap={"nowrap"} style={{zIndex: "1"}}>
							{selectedEdge === undefined &&
								<Stack align="stretch" justify="flex-start" gap={0} w={selection.onPane ? "33%" : "50%"}>
									<Text size={"36px"} fw={700} ta={"center"} lineClamp={1}>{selectedEdges.length}</Text>
									<Text size={"sm"} ta={"center"} opacity={1}>
										{t("studio.pairings")}
									</Text>
								</Stack>
							}
							<Stack align="stretch" justify="flex-start" gap={0} w={selection.onPane ? "33%" : selectedEdge === undefined ? "50%" : "100%"}>
								<Text size={"36px"} fw={700} ta={"center"} lineClamp={1} c={selectedEdgesAverageSharedMoleculesPc > 0 ? getAverageSharedMoleculesPcColor(selectedEdgesAverageSharedMoleculesPc) : `var(--mantine-color-${color}-9)`}>
									{formatPc(selectedEdgesAverageSharedMoleculesPc)}
								</Text>
								<Text size={"sm"} ta={"center"} opacity={1} c={selectedEdgesAverageSharedMoleculesPc > 0 ? getAverageSharedMoleculesPcColor(selectedEdgesAverageSharedMoleculesPc) : `var(--mantine-color-${color}-9)`}>
									{t("ingredient.compatibility")}
								</Text>
							</Stack>
							{selection.onPane &&
								<MantineTooltip opened={tooltipOpened} transitionProps={{ duration: 0 }} multiline maw={300} withArrow label={t("studio.difficultyDescription")} bg={"tertiary.9"} color={"white"}>
									<Stack align="stretch" justify="flex-start" gap={0} w={"33%"}
										   onMouseEnter={() => setTooltipOpened(true)}
										   onMouseLeave={() => setTooltipOpened(false)}
										   onTouchStart={() => setTooltipOpened((o) => !o)}>
										<Center>
											<Icon name={getIngredientDifficulty()} style={{width: "36px", height: "36px", padding: "6px", marginBottom: "-8px", fill: `var(--mantine-color-${color}-9)`}}/>
										</Center>
										<Text size={"sm"} ta={"center"} opacity={1}>
											{t("recipe.difficulty")}
										</Text>
									</Stack>
								</MantineTooltip>
							}
						</Group>

					</Stack>
				},
				{
					fontAwesomeIcon: faTableList,
					content: <Flexible>

							<Flexible.Expandable>
								<ScrollableArea color={color} viewportRef={scrollAreaRef}>

								<StripedTable striped={false} highlightOnHover highlightOnHoverColor={color} stickyHeader className={classes.detailsentitiestable}>
									<Table.Thead>
										<Table.Tr>
											<Table.Th p={0} pl={0}>
												<SearchInput
													iconColor={"white"}
													textInputClassNames= {{
														input: classes.detailssarchinputtertiary
													}}
													size={"xs"}
													radius={0}
													value={search}
													placeholder={t(`studio.entityCategoriesAndIngredients`)}
													onChange={(value) => onSearch(value)}
												/>
											</Table.Th>
											<Table.Th ta={"right"} w={"25%"}>
												{t("studio.count")}
											</Table.Th>
											<Table.Th ta={"right"} w={"25%"}>
												%
											</Table.Th>
											<Table.Th w={"30px"}>
												&nbsp;
											</Table.Th>
										</Table.Tr>
									</Table.Thead>
									<Table.Tbody>
										{searchItems
											.slice(paginationStartSkip(page, theme.custom.ingredient.paginationSize), paginationEndSkip(page, theme.custom.ingredient.paginationSize))
											.reduce((acc, item, index, arr) => {

												// Check if we need to add a category row
												const prevItem = arr[index - 1];

												const checkedColor = activeIndex === undefined ? `var(--mantine-color-${color}-9)` :
													item.categoryId === selectedEntityCategories[activeIndex].categoryId ? `var(--mantine-color-${color}-6)` : `var(--mantine-color-${color}-9)`;

												if (!prevItem || prevItem.categoryId !== item.categoryId) {

													const selectedEntityCategory = selectedEntityCategories.find((sec) => sec.categoryId === item.categoryId);

													if(selectedEntityCategory !== undefined) {

														// Add category row when entityCategoryId changes
														acc.push(
															<Table.Tr className={classes.detailsentitiestablecolored} style={{pointerEvents: selectedEntityCategory.categoryId !== CUSTOM_CATEGORY_ID ? "auto" : "none", color: checkedColor}}
																	  onClick={() => entityCategoryIdNavigate(navigate, item.categoryId, "_blank")}>
																<Table.Td>
																	{selectedEntityCategory.name}
																</Table.Td>
																<Table.Td ta={"right"}>
																	<NumberFormatter thousandSeparator="'" value={selectedEntityCategory.count}/>
																</Table.Td>
																<Table.Td ta={"right"}>
																	{formatPc(selectedEntityCategory.pc)}
																</Table.Td>
																<Table.Td w={"30px"}>
																	{selectedEntityCategory.categoryId !== CUSTOM_CATEGORY_ID &&
																		<FontAwesomeIcon icon={faUpRightFromSquare} size={"sm"}/>
																	}
																</Table.Td>
															</Table.Tr>
														);
													}
												}

												// Add the current item row
												acc.push(
													<Table.Tr key={`item-${item.name}`} className={classes.detailsentitiestablesmall} style={{pointerEvents: item.entityId ? "auto" : "none", color: checkedColor}}
															  onClick={() => ingredientNameNavigate(navigate, item.ingredientId, "_blank")}>
														<Table.Td colSpan={3}>
															<Highlight inherit highlight={generateAccentVariantsForWord(search)} pl={"md"}>
																{item.name}
															</Highlight>
														</Table.Td>
														<Table.Td w={"30px"}>
															{item.categoryId !== CUSTOM_CATEGORY_ID &&
																<FontAwesomeIcon icon={faUpRightFromSquare} size={"sm"}/>
															}
														</Table.Td>
													</Table.Tr>
												);

												return acc;
											}, [])}
									</Table.Tbody>
								</StripedTable>
							</ScrollableArea>
						</Flexible.Expandable>

						<Flexible.Fixed style={{zIndex: "1"}}>
							<Paginator page={page} onPageChange={setPage} paginationSize={theme.custom.ingredient.paginationSize}
									   selectedCount={searchItems.length} totalCount={selectedEntities.length}
									   wrapOn={"xs"} size={"md"} gap={"6px"} withControls={false}
									   color={color}
									   infoColor={color}
									   paginatorClassNames = {{
										   control: classes.detailspaginationcontroltertiary,
										   dots: classes.detailspaginationdotstertiary
									   }}/>
						</Flexible.Fixed>
					</Flexible>
				},
				selectedNode?.type === "group" && {
					tablerIcon: "IconId",
					content: <Stack align="stretch" justify="flex-start" h={"100%"} gap={0}>

						<Stack w={"100%"} h={"100%"} gap={"xs"} style={{position: "relative"}}>

							<Stack align="flex-start" justify="flex-start" gap={0} className={classes[`simplebox${color}`]}>
								<Text size={"sm"} fw={700} c={`var(--mantine-color-${color}-6)`} >{t("studio.group.group")}</Text>
								<Text size={"sm"} lineClamp={1}>
									{selectedNode.data?.label}
								</Text>
							</Stack>

							{selectedNode.data?.description &&
								<Stack align="flex-start" justify="flex-start" gap={0} className={classes[`simplebox${color}`]}>
									<Text size={"sm"} fw={700} c={`var(--mantine-color-${color}-6)`} >{t("studio.description")}</Text>
									<Text size={"sm"} lineClamp={2}>
										{selectedNode.data?.description}
									</Text>
								</Stack>
							}

							<Stack align="flex-start" justify="flex-start" gap={0} className={classes[`simplebox${color}`]}>
								<Text size={"sm"} fw={700} c={`var(--mantine-color-${color}-6)`} >{t("studio.weighting.description")}</Text>
								<Text size={"sm"} lineClamp={2}>
									{getWeightingName(selectedNode.data?.weighting)}
								</Text>
							</Stack>

						</Stack>
					</Stack>
				},
				Features.studio.features.nutrients.plan.enabled && {
					tablerIcon: "IconPlant2",
					content: <Nutrients color={color} nodes={nodes} edges={edges} selection={selection} />
				},
				selectedNode?.type === "group" && {
					icon: "circle-overlap",
					content: <Flexible>

						<Flexible.Expandable>
							<ScrollableArea color={color} viewportRef={scrollAreaPairingsRef}>

								<StripedTable striped={"even"} highlightOnHover highlightOnHoverColor={color} stickyHeader stripedColor={color} className={classes.detailsentitiestablepairings}>
									<Table.Thead>
										<Table.Tr>
											<Table.Th p={0} pl={0}>
												<SearchInput
													iconColor={"white"}
													textInputClassNames= {{
														input: classes.detailssarchinputtertiary
													}}
													size={"xs"}
													radius={0}
													value={searchPairings}
													placeholder={t(`studio.pairedIngredients`)}
													onChange={(value) => onSearchPairings(value)}
												/>
											</Table.Th>
											<Table.Th ta={"right"} w={"25%"}>
												<Text inherit lineClamp={1}>
													{t("studio.sharedMolecules")}
												</Text>
											</Table.Th>
											<Table.Th ta={"right"} w={"25%"}>
												%
											</Table.Th>
											<Table.Th w={"30px"}>
												&nbsp;
											</Table.Th>
										</Table.Tr>
									</Table.Thead>
									<Table.Tbody>
										{searchPairingItems
											.sort(entityPairingsComparator)
											.slice(paginationStartSkip(pagePairings, theme.custom.ingredient.paginationSize), paginationEndSkip(pagePairings, theme.custom.ingredient.paginationSize))
											.map((item, index) => (
												<Table.Tr onClick={() => ingredientNameNavigate(navigate, item.ingredientName, "_blank")} style={{pointerEvents: item.ingredientName ? "auto" : "none", color: `var(--mantine-color-${color}-9)`}}>
													<Table.Td>
														<Stack gap={0}>
															<Text size={"sm"} >
																<Highlight inherit highlight={generateAccentVariantsForWord(searchPairings)}>
																	{item.name}
																</Highlight>
															</Text>
															<Text size={"xs"} lh={1.3} opacity={0.75}>
																{item.categoryName}
															</Text>
														</Stack>
													</Table.Td>
													<Table.Td ta={"right"} style={{verticalAlign: "top"}}>
														{item.sharedMolecules > 0 &&
															<NumberFormatter thousandSeparator="'" value={item.sharedMolecules}/>
														}
													</Table.Td>
													<Table.Td ta={"right"} style={{verticalAlign: "top"}}>
														{item.sharedMolecules > 0 &&
															formatPc(item.sharedMoleculesPc)
														}
													</Table.Td>
													<Table.Td w={"30px"} style={{verticalAlign: "top"}}>
														{item.ingredientName &&
															<FontAwesomeIcon icon={faUpRightFromSquare} size={"sm"}/>
														}
													</Table.Td>
												</Table.Tr>
											))}
									</Table.Tbody>
								</StripedTable>
							</ScrollableArea>
						</Flexible.Expandable>

						<Flexible.Fixed style={{zIndex: "1"}}>
							<Paginator page={pagePairings} onPageChange={setPagePairings} paginationSize={theme.custom.ingredient.paginationSize}
									   selectedCount={searchPairingItems.length} totalCount={selectedConnectedNodes.length}
									   wrapOn={"xs"} size={"md"} gap={"6px"} withControls={false}
									   color={color}
									   infoColor={color}
									   paginatorClassNames = {{
										   control: classes.detailspaginationcontroltertiary,
										   dots: classes.detailspaginationdotstertiary
									   }}/>
						</Flexible.Fixed>
					</Flexible>
				}
			]}
			/>
	);
};

/**
 * ImageWithZoom
 */
const ImageWithZoom = ({node}) => {

	const [hovered, setHovered] = useState(false);

	return !node || !node.data || !node.data?.wiki ? null :
		<Box className={classes.detailsimagewithzoomroot}
			 onMouseEnter={() => setHovered(node.data?.wiki.url ? true : false)}
			 onMouseLeave={() => setHovered(false)}
			 style={{cursor: node.data?.wiki.url ? "pointer" : "auto"}}
			 onClick={() => node.data?.wiki.url && window.open(node.data?.wiki.url, "_blank")}
		>
			{node.data?.wiki.image ?
				<Image
					src={node.data?.wiki.image}
					className={classes.detailsimagewithzoomimage}
					style={{
						transform: hovered? 'scale(1.1)' : 'scale(1)',  // Scale on hover
						transition: 'transform 0.1s ease-in-out',  // Smooth transition
					}}
				/>
				:
				<Box className={classes.detailsimagewithzoomnoimage}>
					<FontAwesomeIcon icon={faImage} size={"8x"}/>
				</Box>
			}
		</Box>
};

/**
 * Entity
 */
const Entity = ({nodes, edges, selection,
					visible = true,
					color = "primary" }) => {

	const memoizedProps = useMemo(() => ({ nodes, edges, selection }), [nodes, edges, selection]);
	const {selectedNode, selectedEdges, selectedGroup, selectedEntity, selectedEntityCategory, selectedConnectedNodes, selectedConnectedNodesAverageSharedMoleculesPc } = useMemoizedSelection(memoizedProps);

	const [pageIngredients, setPageIngredients] = useState(1);
	const [searchIngredients, setSearchIngredients] = useState(" ");

	const [pagePairings, setPagePairings] = useState(1);
	const [searchPairings, setSearchPairings] = useState(" ");

	const navigate = useNavigate();
	const {t} = useTranslation();
	const theme = useMantineTheme();

	const { FeaturePlans, Features } = useFeatures();

	const scrollAreaPairingsRef = useRef(null);
	const scrollAreaIngredientsRef = useRef(null);

	const { setNodes, getNodes } = useReactFlow();

	useEffect(() => {
		setSearchIngredients("");
		setPageIngredients(1);

		setSearchPairings("");
		setPagePairings(1);
	}, [selection]);

	// useEffect(() => {
	//
	// 	if(onChange) {
	// 		onChange(selectedNode?.data.label);
	// 	}
	//
	// }, [selectedNode]);

	/**
	 * @param value
	 */
	const onSearchIngredients = (value) => {
		setSearchIngredients(value);
		setPageIngredients(1);
	}

	/**
	 * @param value
	 */
	const onSearchPairings = (value) => {
		setSearchPairings(value);
		setPagePairings(1);
	}

	/**
	 * allIngredients
	 */
	const allIngredients = useMemo(() => {

		// Add representative ingredient
		if(selectedEntity === undefined) {
			return [];
		}

		return selectedEntity.ingredients.filter(ingredient => ingredient.naturalSourceName !== 1);
	}, [selectedEntity]);

	/**
	 * searchIngredientItems
	 */
	const searchIngredientItems = useMemo(() => {

		try {
			if (!searchIngredients) {
				return allIngredients;
			}

			const normalizedSearch = removeAccents(searchIngredients.toLowerCase());

			return allIngredients.filter(ingredient => {
				const ingredientName = localized(ingredient, "name");
				if (!ingredientName) return false;

				return removeAccents(ingredientName.toLowerCase()).includes(normalizedSearch);
			});
		}
		catch (ignored) {
			// noop
		}

		return [];
	}, [searchIngredients, allIngredients]);

	/**
	 * searchPairingItems
	 */
	const searchPairingItems = useMemo(() => {

		try {
			if (!searchPairings) {
				return selectedConnectedNodes;
			}

			return selectedConnectedNodes.filter(selectedConnectedNode => {
				const nodeName = selectedConnectedNode.name;
				if (!nodeName) return false;

				return removeAccents(nodeName.toLowerCase()).includes(removeAccents(searchPairings.toLowerCase()));
			});
		}
		catch (ignored) {
			// noop
		}

		return [];
	}, [searchPairings, selectedConnectedNodes]);

	/**
	 * handleIngredientRadioChange
	 */
	const handleIngredientRadioChange = (ingredient) => {

		setNodes(prevNodes =>
			prevNodes.map(n =>
				n.id === selectedNode.id
					? {
						...n,
						data: {
							...n.data,
							label: localized(ingredient, "name"),
							entity: {
								...n.data.entity,
								ingredientName: ingredient.name,
								categoryLabel: localized(getEntityCategory(selectedEntity), "name"),
							}
						}
					}
					: n
			)
		);
	}

	return (
		<TabsLayout
			visible={visible}
			color={`var(--mantine-color-${color}-6)`}
			backgroundColor={`var(--mantine-color-${color}-6)`}
			title={t(`common.ingredient`)}
			collapsed={!selectedNode || !selectedNode.data ? null :
				<Text size={"md"} fw={500} lineClamp={1} c={`var(--mantine-color-${color}-9)`}>
					{selectedNode.data?.label}
				</Text>
			}
			tabs={[
				selectedNode && selectedNode.type !== "custom" && {
					fontAwesomeIcon: faImage,
					content: <Stack align="stretch" justify="flex-start" gap={0}>
						<ImageWithZoom node={selectedNode}/>
						{!selectedNode.data || !selectedNode.data?.wiki ? null :
							<Stack gap={"xs"} justify="flex-end" mt={"md"}>
								<Text size={"sm"} lineClamp={3} c={`${color}.9`}>{selectedNode.data?.wiki?.extract}</Text>
								<WikipediaCredits i18nKey={"common.wikiAttribution"} wikiUrl={selectedNode.data?.wiki?.url} textColor={`${color}.9`} linkColor={color}/>
							</Stack>
						}
					</Stack>
				},
				{
					tablerIcon: "IconId",
					content: <Stack align="stretch" justify="flex-start" h={"100%"} gap={0}>

						<Stack w={"100%"} h={"100%"} gap={"xs"} style={{position: "relative"}}>

							{selectedNode &&
								<Group justify={"space-between"} className={classes[`simplebox${selectedNode.data?.entity?.ingredientName ? "hoverable" : ""}${color}`]}
												onClick={(event) => selectedNode.data?.entity?.ingredientName ? ingredientNameNavigate(navigate, selectedNode.data?.entity?.ingredientName, "_blank") : event.preventDefault()}>

									<Stack align="flex-start" justify="flex-start" gap={0}>
										<Text size={"sm"} fw={700} c={`var(--mantine-color-${color}-6)`} >{t("common.ingredient")}</Text>
										<Text size={"sm"} lineClamp={1}>
											{selectedNode.data?.label}
										</Text>
									</Stack>

									{selectedNode.data?.entity?.ingredientName &&
										<FontAwesomeIcon icon={faUpRightFromSquare} size={"sm"}/>
									}
								</Group>
							}

							{selectedEntityCategory &&
								<Group justify={"space-between"} className={classes[`simpleboxhoverable${color}`]}
									   onClick={() => entityCategoryNavigate(navigate, selectedEntityCategory, "_blank")}>

									<Stack align="flex-start" justify="flex-start" gap={0}>
										<Text size={"sm"} fw={700} lineClamp={1} c={`var(--mantine-color-${color}-6)`} >{t("common.entityCategory")}</Text>
										<Text size={"sm"} lineClamp={1}>
											{localized(selectedEntityCategory, "name")}
										</Text>
									</Stack>
									<FontAwesomeIcon icon={faUpRightFromSquare} size={"sm"}/>
								</Group>
							}

							{(selectedEntity?.naturalSourceIngredient || selectedEntity?.seasons?.length > 0) &&
								<Group justify={"flex-start"} gap={"xs"} grow>

									{selectedEntity?.naturalSourceIngredient &&
										<Group justify={"space-between"} className={classes[`simpleboxhoverable${color}`]}
											   onClick={() => ingredientNameNavigate(navigate, selectedEntity.naturalSourceIngredient.name, "_blank")}>

											<Stack align="flex-start" justify="flex-start" gap={0}>
												<Text size={"sm"} fw={700} c={`var(--mantine-color-${color}-6)`} >{t("ingredient.naturalSource")}</Text>
												<Text size={"sm"} lineClamp={1}>
													{localized(selectedEntity.naturalSourceIngredient, "name")}
												</Text>
											</Stack>
											<FontAwesomeIcon icon={faUpRightFromSquare} size={"sm"}/>
										</Group>
									}

									{selectedEntity?.seasons?.length > 0 &&
										<Group justify={"space-between"} className={classes[`simplebox${color}`]}>

											<Stack align="flex-start" justify="flex-start" gap={0}>
												<Text size={"sm"} fw={700} c={`var(--mantine-color-${color}-6)`} >{t("recipe.season")}</Text>
												{selectedEntity.seasons.map((item, index) => (
													<Text size={"sm"} lineClamp={1}>
														{t(`recipe.seasonType.${item}`)}
													</Text>
												))}
											</Stack>
										</Group>
									}

								</Group>
							}

							{selectedGroup &&
								<Group justify={"space-between"} className={classes[`simplebox${color}`]}>

									<Stack align="flex-start" justify="flex-start" gap={0}>
										<Text size={"sm"} fw={700} c={`var(--mantine-color-${color}-6)`} >{t("studio.group.group")}</Text>
										<Text size={"sm"} lineClamp={1}>
											{selectedGroup.data.label}
										</Text>
									</Stack>
								</Group>
							}

						</Stack>

						<Group align="flex-start" justify="center" c={`var(--mantine-color-${color}-9)`} wrap={"nowrap"} style={{zIndex: "1"}}>
							<Stack align="stretch" justify="flex-start" gap={0} w={"50%"}>
								<Text size={"36px"} fw={700} ta={"center"} lineClamp={1}>{selectedEdges?.length}</Text>
								<Text size={"sm"} ta={"center"} opacity={1}>
									{t("studio.pairings")}
								</Text>
							</Stack>
							{selectedConnectedNodesAverageSharedMoleculesPc > 0 &&
								<Stack align="stretch" justify="flex-start" gap={0} w={"50%"}>
									<Text size={"36px"} fw={700} ta={"center"} lineClamp={1} c={selectedConnectedNodesAverageSharedMoleculesPc > 0 ? getAverageSharedMoleculesPcColor(selectedConnectedNodesAverageSharedMoleculesPc) : `var(--mantine-color-${color}-9)`}>
										{formatPc(selectedConnectedNodesAverageSharedMoleculesPc)}
									</Text>
									<Text size={"sm"} ta={"center"} opacity={1} c={selectedConnectedNodesAverageSharedMoleculesPc > 0 ? getAverageSharedMoleculesPcColor(selectedConnectedNodesAverageSharedMoleculesPc) : `var(--mantine-color-${color}-9)`}>
										{t("ingredient.compatibility")}
									</Text>
								</Stack>
							}
						</Group>
					</Stack>
				},
				// allIngredients && allIngredients.length > 0 && 1 !== 1 /* TODO delete this tab */&& {
				// 	icon: "leaf",
				// 	content: <Flexible>
				//
				// 		<Flexible.Expandable>
				// 			<ScrollableArea color={color} viewportRef={scrollAreaIngredientsRef}>
				//
				// 				<StripedTable striped={"even"} highlightOnHover highlightOnHoverColor={color} stickyHeader stripedColor={color} className={classes.detailsentitytableingredients}>
				// 					<Table.Thead>
				// 						<Table.Tr>
				// 							<Table.Th p={0} pl={0}>
				// 								<SearchInput
				// 									iconColor={"white"}
				// 									textInputClassNames= {{
				// 										input: classes.detailssarchinputprimary
				// 									}}
				// 									size={"xs"}
				// 									radius={4}
				// 									value={searchIngredients}
				// 									placeholder={t(`studio.alternativeIngredients`)}
				// 									onChange={(value) => onSearchIngredients(value)}
				// 								/>
				// 							</Table.Th>
				// 						</Table.Tr>
				// 					</Table.Thead>
				// 					<Table.Tbody>
				// 						{searchIngredientItems
				// 							.sort((a, b) => localized(a, "name").localeCompare(localized(b, "name")))
				// 							.slice(paginationStartSkip(pageIngredients, theme.custom.ingredient.paginationSize), paginationEndSkip(pageIngredients, theme.custom.ingredient.paginationSize))
				// 							.map((item, index, array) => (
				// 								<Table.Tr style={{color: `var(--mantine-color-${color}-9)`}}>
				// 									<Table.Td>
				// 										<Group gap={"md"}>
				// 											<Radio icon={CheckIcon} pl={8} color={"primary"}
				// 													  classNames={{
				// 														  radio: classes.detailsentityradio,
				// 													  }}
				// 													  checked={item.name === selectedNode.data?.entity?.ingredientName}
				// 													  onChange={(event) => handleIngredientRadioChange(item)}
				// 												      // onClick={(event) => handleIngredientRadioChange(item)}
				// 											/>
				// 											<Text size={"sm"} fw={item.representative === 1 ? 700 : 400} onClick={() => ingredientNavigate(navigate, item, "_blank")} className={classes.detailsentitytext}>
				// 												<Highlight inherit highlight={generateAccentVariantsForWord(searchIngredients)}>
				// 													{localized(item, "name")}
				// 												</Highlight>
				// 											</Text>
				// 										</Group>
				// 									</Table.Td>
				// 								</Table.Tr>
				// 							))}
				// 					</Table.Tbody>
				// 				</StripedTable>
				// 			</ScrollableArea>
				// 		</Flexible.Expandable>
				//
				// 		<Flexible.Fixed style={{zIndex: "1"}}>
				// 			<Paginator page={pageIngredients} onPageChange={setPageIngredients} paginationSize={theme.custom.ingredient.paginationSize}
				// 					   selectedCount={searchIngredientItems.length} totalCount={allIngredients.length}
				// 					   wrapOn={"xs"} size={"md"} gap={"6px"} withControls={false}
				// 					   color={color}
				// 					   infoColor={color}
				// 					   paginatorClassNames = {{
				// 						   control: classes.detailspaginationcontrolprimary,
				// 						   dots: classes.detailspaginationdotsprimary
				// 					   }}/>
				// 		</Flexible.Fixed>
				// 	</Flexible>
				// },
				selectedNode && selectedNode.type !== "custom" && Features.studio.features.nutrients.plan.enabled && {
					tablerIcon: "IconPlant2",
					content: <Nutrients color={color} nodes={nodes} edges={edges} selection={selection} />
				},
				{
					icon: "circle-overlap",
					content: <Flexible>

						<Flexible.Expandable>
							<ScrollableArea color={color} viewportRef={scrollAreaPairingsRef}>

								<StripedTable striped={"even"} highlightOnHover highlightOnHoverColor={color} stickyHeader stripedColor={color} className={classes.detailsentitytablepairings}>
									<Table.Thead>
										<Table.Tr>
											<Table.Th p={0} pl={0}>
												<SearchInput
													iconColor={"white"}
													textInputClassNames= {{
														input: classes.detailssarchinputprimary
													}}
													size={"xs"}
													radius={0}
													value={searchPairings}
													placeholder={t(`studio.pairedIngredients`)}
													onChange={(value) => onSearchPairings(value)}
												/>
											</Table.Th>
											<Table.Th ta={"right"} w={"25%"}>
												<Text inherit lineClamp={1}>
													{t("studio.sharedMolecules")}
												</Text>
											</Table.Th>
											<Table.Th ta={"right"} w={"25%"}>
												%
											</Table.Th>
											<Table.Th w={"30px"}>
												&nbsp;
											</Table.Th>
										</Table.Tr>
									</Table.Thead>
									<Table.Tbody>
										{searchPairingItems
											.sort(entityPairingsComparator)
											.slice(paginationStartSkip(pagePairings, theme.custom.ingredient.paginationSize), paginationEndSkip(pagePairings, theme.custom.ingredient.paginationSize))
											.map((item, index) => (
												<Table.Tr onClick={() => ingredientNameNavigate(navigate, item.ingredientName, "_blank")} style={{pointerEvents: item.ingredientName ? "auto" : "none", color: `var(--mantine-color-${color}-9)`}}>
													<Table.Td>
														<Stack gap={0}>
															<Text size={"sm"} >
																<Highlight inherit highlight={generateAccentVariantsForWord(searchPairings)}>
																	{item.name}
																</Highlight>
															</Text>
															<Text size={"xs"} lh={1.3} opacity={0.75}>
																{item.categoryName}
															</Text>
														</Stack>
													</Table.Td>
													<Table.Td ta={"right"} style={{verticalAlign: "top"}}>
														{item.sharedMolecules > 0 &&
															<NumberFormatter thousandSeparator="'" value={item.sharedMolecules}/>
														}
													</Table.Td>
													<Table.Td ta={"right"} style={{verticalAlign: "top"}}>
														{item.sharedMolecules > 0 &&
															formatPc(item.sharedMoleculesPc)
														}
													</Table.Td>
													<Table.Td w={"30px"} style={{verticalAlign: "top"}}>
														{item.ingredientName &&
															<FontAwesomeIcon icon={faUpRightFromSquare} size={"sm"}/>
														}
													</Table.Td>
												</Table.Tr>
											))}
									</Table.Tbody>
								</StripedTable>
							</ScrollableArea>
						</Flexible.Expandable>

						<Flexible.Fixed style={{zIndex: "1"}}>
							<Paginator page={pagePairings} onPageChange={setPagePairings} paginationSize={theme.custom.ingredient.paginationSize}
									   selectedCount={searchPairingItems.length} totalCount={selectedConnectedNodes.length}
									   wrapOn={"xs"} size={"md"} gap={"6px"} withControls={false}
									   color={color}
									   infoColor={color}
									   paginatorClassNames = {{
										   control: classes.detailspaginationcontrolprimary,
										   dots: classes.detailspaginationdotsprimary
									   }}/>
						</Flexible.Fixed>
					</Flexible>
				}
			]}
		/>
	);
}

/**
 * EntityPairings
 */
const EntityPairings = ({nodes, edges, selection,
						visible = true,
						color = "secondary",
						isOnlyInputConnectable = false,
						pairingLevel = PAIRING_LEVELS.UNIQUE_INGREDIENT_GLOBALLY}) => {

	const [page, setPage] = useState(1);
	const [search, setSearch] = useState(" ");
	const [searchItems, setSearchItems] = useState([]);
	const [molecularSearchFilter, setMolecularSearchFilter] = useState([]);

	const [minCompatibility, setMinCompatibility] = useState(undefined);
	const [maxCompatibility, setMaxCompatibility] = useState(undefined);

	const [moleculesIds, setMoleculesIds] = useState([]);
	const [entityPairings, setEntityPairings] = useState([]);
	const [draggable, setDraggable] = useState(false);

	const [lastSelectionTimestamp, setLastSelectionTimestamp] = useState(Date.now())

	const { FeaturePlans, Features } = useFeatures();

	const navigate = useNavigate();
	const {t} = useTranslation();
	const theme = useMantineTheme();

	const memoizedProps = useMemo(() => ({ nodes, edges, selection }), [nodes, edges, selection]);
	const {entities, entitiesMolecules, selectedNode, selectedNodes, selectedConnectedNodes } = useMemoizedSelection(memoizedProps);

	const [openedMolecularFilter, { toggle: toggleMolecularFilter}] = useDisclosure(false);

	useEffect(() => {

		if(lastSelectionTimestamp !== selection.timestamp) {

			console.debug("Details.Entity.reset")

			setLastSelectionTimestamp(selection.timestamp);

			setSearch("");
			setPage(1);
			setDraggable(false);
			setSearchItems([]);
			setMoleculesIds([]);
			setEntityPairings([]);
		}
	}, [selection]);

	/**
	 * entitiesNotInNodes
	 */
	const entitiesNotInNodes = useMemo(() => {

		const selectedNodeList = selectedNodes.map(node => getNodeById(nodes, node.id)).filter(Boolean);

		switch(pairingLevel) {

			case PAIRING_LEVELS.UNIQUE_INGREDIENT_GLOBALLY:
				return extractEntitiesNotInNodes(entities, nodes);

			case PAIRING_LEVELS.UNIQUE_PAIRING_PER_INGREDIENT:
				const selectedConnectedNodeList = selectedConnectedNodes.map(node => getNodeById(nodes, node.id)).filter(Boolean);
				return extractEntitiesNotInNodes(entities, [...new Set([...selectedNodeList, ...selectedConnectedNodeList])]);

			default:
				return extractEntitiesNotInNodes(entities, selectedNodeList);
		}

	}, [entities, nodes, selectedNodes, selectedConnectedNodes]);

	useEffect(() => {

		try {

			console.debug("Details.Entity.reset")

			setDraggable(isOnlyInputConnectable ? selectedNode?.type === "input" : true);

			// Get all selected entity molecules
			const moleculesIds = extractMolecules(entities, entitiesMolecules, nodes, [selectedNode]).map((molecule) => molecule.moleculeId);

			// Extract pairing
			const entityPairings = selectedNode?.type !== "custom" ?
				extractEntityPairings(entitiesNotInNodes, moleculesIds)
				: entitiesNotInNodes;

			// Calculate min/max compatibility range
			const compatibilities = lastSelectionTimestamp !== selection.timestamp ?
				defaultCompatibilities(entityPairings, moleculesIds, COMPATIBILITY_MARKS) :
				[minCompatibility, maxCompatibility];

			setMinCompatibility(compatibilities[0]);
			setMaxCompatibility(compatibilities[1]);

			// Entity pairings filtered by min/max compatibility
			const compatibilityEntityPairings = selectedNode?.type !== "custom" ?
				entityPairings.filter(entityPairing => {
					const sharedMoleculesPc = getSharedMoleculesPc(entityPairing.sharedMolecules, moleculesIds);
					return sharedMoleculesPc >= (minCompatibility || compatibilities[0]) && sharedMoleculesPc <= (maxCompatibility || compatibilities[1]);
				})
				: entityPairings;

			const compatibilityIngredientPairings = FeaturePlans.STUDIO.enabled ? toIngredients(compatibilityEntityPairings) : compatibilityEntityPairings;

			if(!search) {
				setSearchItems(compatibilityIngredientPairings);
			}
			else {
				setSearchItems(searchEntities(compatibilityIngredientPairings, search, molecularSearchFilter));
			}

			setMoleculesIds(moleculesIds);
			setEntityPairings(FeaturePlans.STUDIO.enabled ? toIngredients(entityPairings) : entityPairings);
		}
		catch (ignored) {
			// noop
		}

	}, [search, molecularSearchFilter, minCompatibility, maxCompatibility, entities, nodes.length, selection]);

	/**
	 * @param value
	 */
	const onSearch = (value) => {
		setSearch(value);
		setPage(1);
	}

	/**
	 * @param minCompatibility
	 * @param maxCompatibility
	 */
	const onMinMaxCompatibility = (minCompatibility, maxCompatibility) => {
		setMinCompatibility(minCompatibility);
		setMaxCompatibility(maxCompatibility);
		setPage(1);
	}

	/**
	 *
	 * @param a
	 * @param b
	 * @returns {number}
	 */
	function entityPairingsComparator(a, b) {

		// Inverted comparison
		if(a.sharedMolecules &&  b.sharedMolecules) {

			const pcComparison = b.sharedMolecules - a.sharedMolecules;

			if (pcComparison !== 0) {
				return pcComparison;
			}
		}

		return localized(a.representativeIngredient, "name").localeCompare(localized(b.representativeIngredient, "name"));
	}

	/**
	 * @param marks
	 * @param value
	 * @param type
	 * @returns {*}
	 */
	function defaultCompatibilities(compatibilities, moleculesIds, marks) {

		/**
		 * @param marks
		 * @param value
		 * @param type
		 * @returns {*}
		 */
		function normalize(marks, value, type) {

			for (let i = 0; i < marks.length - 1; i++) {

				if (value <= marks[i + 1].value) {
					// For the minimum, return the lower step
					if (type === 'min') {
						return marks[i].value;
					}
					// For the maximum, return the upper step
					if (type === 'max') {
						return marks[i + 1].value;
					}
				}
			}
		}

		// Extract shared molecules percentage
		const sharedMoleculesPc = compatibilities?.map(compatibility => getSharedMoleculesPc(compatibility.sharedMolecules, moleculesIds));

		// Fin min and max
		const minValue = Math.min(...sharedMoleculesPc);
		const maxValue = Math.max(...sharedMoleculesPc);

		const minValueNormalized = normalize(marks, minValue, 'min');
		const maxValueNormalized = normalize(marks, maxValue, 'max');

		return [minValueNormalized !== undefined ? minValueNormalized : 0, maxValueNormalized !== undefined ? maxValueNormalized : 0];
	}

	/**
	 * Define molecular search options
	 */
	const molecularSearchOptions = useMemo(() => {

		const result = {};

		if(Features.studio.features.ingredientEmotions.plan.enabled) {
			result.emotions = t("molecule.emotions");
		}

		if(Features.studio.features.ingredientFlavors.plan.enabled) {
			result.flavors = t("molecule.flavors");
		}

		if(Features.studio.features.ingredientOdors.plan.enabled) {
			result.odors = t("molecule.odors");
		}

		if(Features.studio.features.ingredientTastes.plan.enabled) {
			result.tastes = t("molecule.tastes");
		}

		if(Features.studio.features.ingredientCompounds.plan.enabled) {
			result.compounds = t("molecule.compounds");
		}

		return result;
	}, []);

	// Function to handle molecular search
	const onMolecularSearchChange = (key) => {
		setMolecularSearchFilter((prevMolecularSearch) =>
			prevMolecularSearch.includes(key)
				? prevMolecularSearch.filter((d) => d !== key) // Remove if already selected
				: [...prevMolecularSearch, key] // Add if not selected
		);
	};

	/**
	 * DraggableCustomIngredientRow
	 */
	const DraggableCustomIngredientRow = ({}) => {

		let node = undefined;
		let edge = undefined;

		/**
		 * getCustomIngredientLabel
		 */
		function getCustomIngredientLabel() {
			return search ||  t("studio.customNode");
		}

		if (selectedNode) {

			node = {
				id: crypto.randomUUID(),
				type: "custom",
				data: {
					label: getCustomIngredientLabel()
				}
			}

			edge = toEdge(selectedNode, node, "unknownCompatibility");
		}

		const {attributes, listeners, setNodeRef, transform, isDragging} = useDraggable({
			id: crypto.randomUUID(),
			data: {
				ingredientLabel: getCustomIngredientLabel(),
				node: node,
				edge: edge
			}
		});

		// const style = transform ? {
		// 	transform: `translate3d(-50%, -50%, 0)`,
		// } : undefined;

		return (
			<Table.Tr key={`custom`} style={{color: `var(--mantine-color-${color}-9)`, touchAction: isDragging ? "none" : "auto"}}
				// onDragStart={(event) => onDragStart(event, item)}
				// draggable={draggable}>
			>
				<Table.Td {...attributes}
						  {...listeners}
						  ref={draggable ? setNodeRef : null}
						  style={{cursor: draggable ? "grab" : "auto"}}>

					<Group align={"center"} gap={0} wrap={"nowrap"} h={"36px"}>
						{draggable && (
							<FontAwesomeIcon
								icon={faGripVertical}
								style={{
									paddingTop: "3px",
									marginLeft: "-2px",
									marginRight: "8px",
									color: `var(--mantine-color-${color}-6)`
								}}
							/>
						)}
						<Stack gap={0}>
							<Text size={"sm"} >
								{getCustomIngredientLabel()}
							</Text>
						</Stack>
					</Group>
				</Table.Td>
				<Table.Td ta={"right"} style={{verticalAlign: "top"}}>
					&nbsp;
				</Table.Td>
				<Table.Td ta={"right"} style={{verticalAlign: "top"}}>
					&nbsp;
				</Table.Td>
			</Table.Tr>
		);
	}

	/**
	 * DraggableRow
	 */
	const DraggableRow = ({entity, index}) => {

		let node = undefined;
		let edge = undefined;

		if (selectedNode) {

			node = {
				id: crypto.randomUUID(),
				type: "intermediate",
				data: {
					label: `${localized(entity.representativeIngredient, "name")}`,
					entity: {
						id: `${entity.entityId}`,
						ingredientName: entity.representativeIngredient.name,
					}
				}
			}

			if(selectedNode.type !== "custom") {
				edge = toEdge(selectedNode, node, "default", true, entity, moleculesIds);
			}
			else {
				edge = toEdge(selectedNode, node, "unknownCompatibility");
			}
		}

		/**
		 * entityRepresentativeIngredient
		 */
		const entityRepresentativeIngredient = useMemo(() => {
			return localized(entity.representativeIngredient, "name");
		}, [entity]);

		/**
		 * categoriesRepresentativeIngredient
		 */
		const categoriesRepresentativeIngredient = useMemo(() => {

			const ingredient = entity.representativeIngredient;
			let result = [];

			// Categories
			if(ingredient.categories && ingredient.categories.length > 0) {
				let categories = ingredient.categories.filter((category) => category.representative > 0)
				// return categories?.length > 0 ? categories[0] : ingredient.categories[0];
				result = categories && categories.length > 0 ? categories : ingredient.categories;
			}
			// Single category
			else if (ingredient.category) {
				result = [ingredient.category];
			}

			return result
				.sort((a, b) => alphabeticComparator(localized(a, "name"), localized(b, "name")))
				.map((category, index) => localized(category, 'name')).join(", ");

		}, [entity]);

		const {attributes, listeners, setNodeRef, transform, isDragging} = useDraggable({
			id: crypto.randomUUID(),
			data: {
				ingredientLabel: entityRepresentativeIngredient,
				ingredientCategoryLabel: categoriesRepresentativeIngredient,
				node: node,
				edge: edge
			}
		});

		// const style = transform ? {
		// 	transform: `translate3d(-50%, -50%, 0)`,
		// } : undefined;

		return (
			<Table.Tr key={`entity-${index}`} style={{color: `var(--mantine-color-${color}-9)`, touchAction: isDragging ? "none" : "auto"}}
					  // onDragStart={(event) => onDragStart(event, item)}
					  // draggable={draggable}>
				>
				<Table.Td {...attributes}
						  {...listeners}
						  ref={draggable ? setNodeRef : null}
						  style={{cursor: draggable ? "grab" : "auto"}}>

					<Group align={"center"} gap={0} wrap={"nowrap"}>
						{draggable && (
							<FontAwesomeIcon
								icon={faGripVertical}
								style={{
									paddingTop: "3px",
									marginLeft: "-2px",
									marginRight: "8px",
									color: `var(--mantine-color-${color}-6)`
								}}
							/>
						)}
						<Stack gap={0}>
							<Text size={"sm"} fw={700}>
								<Highlight inherit highlight={generateAccentVariantsForWord(search)}>
									{entityRepresentativeIngredient}
								</Highlight>
							</Text>
							<Text size={"xs"} lh={1.3} opacity={0.75}>
								{categoriesRepresentativeIngredient}
							</Text>
						</Stack>
						{/*<IngredientText color={"tertiary"} ingredient={item.representativeIngredient}*/}
						{/*				ingredientHighlight={search} showCategory/>*/}
					</Group>
				</Table.Td>
				<Table.Td ta={"right"} style={{verticalAlign: "top"}}>
					<NumberFormatter thousandSeparator="'" value={entity.sharedMolecules}/>
				</Table.Td>
				<Table.Td ta={"right"} style={{verticalAlign: "top"}}>
					{entity.sharedMolecules && formatPc(getSharedMoleculesPc(entity.sharedMolecules, moleculesIds))}
				</Table.Td>
			</Table.Tr>
		);
	}

	return entityPairings?.length > 0 &&

		<TabsLayout
			visible={visible}
			title={t("studio.pairedIngredients")}
			color={`var(--mantine-color-${color}-6)`}
			backgroundColor={`var(--mantine-color-${color}-6)`}
			h={700}
			collapsed={
				<Text size={"36px"} fw={700} lineClamp={1} c={`var(--mantine-color-${color}-9)`}>
					<NumberFormatter thousandSeparator="'" value={entityPairings?.length}/>
				</Text>
			}
			tabs={[
				{
					fontAwesomeIcon: faTableList,
					content: <Flexible>

						{/*{selectedEntity &&*/}
						{/*	<Flexible.Fixed style={{paddingBottom: "var(--mantine-spacing-md)"}}>*/}
						{/*		<ImageWithZoom node={selectedNode}/>*/}
						{/*	</Flexible.Fixed>*/}
						{/*}*/}

						<Flexible.Fixed>

							<Box className={classes[`simplebox${color}`]}>

								<Group justify="space-between" flex={1} h={28} onClick={toggleMolecularFilter} c={"secondary"}
									   className={classes.detailsentitypairingsmolecularfilter} style={{borderBottomLeftRadius: openedMolecularFilter ? "0" : "4px", borderBottomRightRadius: openedMolecularFilter ? "0" : "4px"}}>
									<Group gap={0}>
										<FontAwesomeIcon icon={openedMolecularFilter ? faChevronUp : faChevronDown} style={{ width: "12px", height: "12px", paddingTop: "2px", paddingRight: "4px"}}/>
										<Text size={"sm"} fw={700}>
											{t('studio.molecularFilter')}
										</Text>
									</Group>
									<FontAwesomeIcon icon={faSliders} style={{width: "16px", height: "16px"}}/>
								</Group>

								<Collapse transitionDuration={0} in={openedMolecularFilter}>
									{(Features.studio.features.ingredientEmotions.plan.enabled ||
											Features.studio.features.ingredientFlavors.plan.enabled ||
											Features.studio.features.ingredientOdors.plan.enabled ||
											Features.studio.features.ingredientTastes.plan.enabled ||
											Features.studio.features.ingredientCompounds.plan.enabled) &&
										<>
											<Space h="xl"/>
											<Text size={"xs"} fw={700} c={`var(--mantine-color-${color}-9)`}>
												{t('studio.molecularSearch')}
											</Text>
											<Space h="sm"/>

											<PillGroup>
												{Object.entries(molecularSearchOptions).map(([key, label]) => (
													// <Checkbox
													// 	key={key}
													// 	label={label}
													// 	checked={selectedDiets.includes(key)}
													// 	onChange={() => handleDietChange(key)}
													// 	color={color}
													// 	mb={"xs"}
													// />
													<Pill
														key={key}
														onClick={() => onMolecularSearchChange(key)}
														c={molecularSearchFilter.includes(key) ? `var(--mantine-color-white)` : `var(--mantine-color-${color}-9)`}
														bg={molecularSearchFilter.includes(key) ? `var(--mantine-color-${color}-6)` : `var(--mantine-color-${color}-light-hover)`}
														style={{ cursor: "pointer", textAlign: "center" }}
														miw={100}
														radius={4}
													>
														{label}
													</Pill>
												))}
											</PillGroup>
										</>
									}
									{/*</Box>*/}
									{/*<Box style={simpleBackgroundStyle(color)} mb={"xs"}>*/}
									{/*	<Text size={"xs"}>*/}
									{/*		TODO checkbox single ingredient per document (perhaps as document property)*/}
									{/*	</Text>*/}
									{/*</Box>*/}
									{/*<Box style={simpleBackgroundStyle(color)}>*/}
									{selectedNode?.type !== "custom" &&
										<>
											<Space h="md"/>
											<Space h="md"/>
											<Text size={"xs"} fw={700} c={`var(--mantine-color-${color}-9)`}>
												{t('ingredient.compatibilityRange')}
											</Text>
											<Space h="md"/>
											<RangeSlider color={`${color}.9`}
														 minRange={20} step={20}
														 label={null}
														 classNames={{
															 track: classes.rangeslidertrack,
															 markLabel: classes.rangeslidermarklabel,
															 mark: classes.rangeslidermark
														 }}
														 marks={COMPATIBILITY_MARKS}
														 value={[minCompatibility, maxCompatibility]}
														 thumbSize={24}
														 onChange={(value) => onMinMaxCompatibility(value[0], value[1])} />
											<Space h="md"/>
										</>
									}

								</Collapse>
							</Box>

							<Text size={"xs"} p={"sm"} pb={0} c={`var(--mantine-color-${color}-9)`}>{t("studio.pairingIngredientsDescription")}</Text>
						</Flexible.Fixed>

						<Space h="md"/>

						<Flexible.Expandable>

							<ScrollableArea color={color}>

								<StripedTable striped={"even"} stripedColor={color} stickyHeader highlightOnHover highlightOnHoverColor={color} className={classes.detailsentitypairingstable}>
									<Table.Thead>
										<Table.Tr>
											<Table.Th p={0} pl={0}>
												<SearchInput
													iconColor={"white"}
													textInputClassNames= {{
														input: classes.detailssarchinputsecondary
													}}
													size={"xs"}
													radius={0}
													value={search}
													placeholder={t('common.ingredients')}
													onChange={(value) => onSearch(value)}
												/>
											</Table.Th>
											<Table.Th ta={"right"} w={"25%"}>
												<Text inherit lineClamp={1}>
													{t("studio.sharedMolecules")}
												</Text>
											</Table.Th>
											<Table.Th ta={"right"} w={"25%"}>
												%
											</Table.Th>
										</Table.Tr>
									</Table.Thead>
									{search !== "" && searchItems.length === 0 && Features.studio.features.customIngredients.plan.enabled ?
										<Table.Tbody>
											<Table.Tr style={{pointerEvents: "none"}}>
												<Table.Td colSpan={3}>
													<Text size={"xs"} pt={"xs"} c={`var(--mantine-color-${color}-9)`}>{t("studio.noPairingsFound", {search: search})}</Text>
												</Table.Td>
											</Table.Tr>
											<DraggableCustomIngredientRow  />
										</Table.Tbody>
										:
										<Table.Tbody>
											{searchItems
												.sort(entityPairingsComparator)
												.slice(paginationStartSkip(page, theme.custom.ingredient.paginationSize), paginationEndSkip(page, theme.custom.ingredient.paginationSize))
												.map((item, index) => (
													<DraggableRow entity={item} index={index}/>
												))}
										</Table.Tbody>
									}
								</StripedTable>
							</ScrollableArea>
						</Flexible.Expandable>

						<Flexible.Fixed>
							<Paginator page={page} onPageChange={setPage} paginationSize={theme.custom.ingredient.paginationSize}
									   selectedCount={searchItems.length} totalCount={entityPairings.length}
									   wrapOn={"xs"} size={"md"} gap={"6px"} withControls={false}
									   color={color}
									   infoColor={color}
									   paginatorClassNames = {{
										   control: classes.detailspaginationcontrolsecondary,
										   dots: classes.detailspaginationdotssecondary
									   }}/>
						</Flexible.Fixed>
					</Flexible>
				}
			]}
			/>
}

/**
 * EntityCategoryPairings
 */
const EntityCategoryPairings = ({	nodes, edges, selection,
									visible = true,
									color = "tertiary" }) => {

	const memoizedProps = useMemo(() => ({ nodes, edges, selection }), [nodes, edges, selection]);
	const {entities, selectedNodes, selectedEdge, selectedMolecules } = useMemoizedSelection(memoizedProps);

	const [selectedEntityCategories, setSelectedEntityCategories] = useState([]);

	const [activeCategory, setActiveCategory] = useState(undefined);

	const [page, setPage] = useState(1);
	const [search, setSearch] = useState(" ");

	const scrollAreaRef = useRef(null);

	const navigate = useNavigate();
	const { t } = useTranslation();
	const theme = useMantineTheme();

	useEffect(() => {
		setSearch("");
	}, []);

	useEffect(() => {

		setSearch("");
		setPage(1);
		setActiveCategory(undefined);

		if(selectedMolecules.length === 0) {
			setSelectedEntityCategories([]);
			return;
		}

		// Extract nodes from selected nodes
		const extractedNodes = extractNodes(nodes, selectedNodes);

		// Select entities not in extracted nodes
		const entitiesNotInNodes = extractEntitiesNotInNodes(entities, extractedNodes);

		// Extract molecules
		const selectedMoleculeIds = new Set(selectedMolecules.map((molecule) => molecule.moleculeId)); // Efficient lookup

		// Create a Map to aggregate category counts
		const categoryMap = new Map();

		entitiesNotInNodes.forEach((entity) => {
			entity.categories.forEach((category) => {
				const categoryId = category.entityCategoryId;

				// Extract moleculeIds from entity.molecules
				const moleculeIds = entity.molecules?.map((molecule) => molecule.moleculeId) || [];

				// Calculate sharedMolecules between selectedMoleculeIds and moleculeIds
				const sharedMolecules = moleculeIds.filter((id) => selectedMoleculeIds.has(id)).length;

				// If the category already exists in the map, update its sharedMolecules
				if (categoryMap.has(categoryId)) {
					const existing = categoryMap.get(categoryId);

					categoryMap.set(categoryId, {
						...existing,
						sharedMolecules: existing.sharedMolecules + sharedMolecules, // Increment shared molecules count
					});
				} else {
					// Otherwise, initialize the category in the map
					categoryMap.set(categoryId, {
						categoryId: categoryId,
						index: 0.9,
						name: localized(category, "name"), // Localized name
						sharedMolecules: sharedMolecules, // Start with the shared molecules count
					});
				}
			});
		});

		// Calculate the total sharedMolecules
		const totalSharedMolecules = Array.from(categoryMap.values()).reduce(
			(sum, category) => sum + category.sharedMolecules,
			0
		);

		// Update sharedMoleculesPc for each category
		categoryMap.forEach((category) => {
			category.sharedMoleculesPc = totalSharedMolecules
				? (category.sharedMolecules / totalSharedMolecules) * 100
				: 0; // Avoid division by zero
		});

		// Filter categories with sharedMolecules > 0, convert to array, and sort
		setSelectedEntityCategories(
			Array.from(categoryMap.values())
				.filter((category) => category.sharedMolecules > 0) // Remove categories with sharedMolecules === 0
				.sort((a, b) => alphabeticComparator(a.name, b.name))
		);

	}, [entities, nodes, selectedNodes, selectedEdge, selectedMolecules]);

	/**
	 * searchItems
	 */
	const searchItems = useMemo(() => {

		try {
			let result;

			// Copy array
			if (!search) {
				result = [...selectedEntityCategories];
			}
			else {
				const normalizedSearch = removeAccents(search.toLowerCase());

				result = selectedEntityCategories.filter((entityCategory) => {
					const categoryName = entityCategory.name;
					if (!categoryName) return false;

					return removeAccents(categoryName.toLowerCase()).includes(normalizedSearch);
				});
			}

			result.sort(entityCategoryPairingsComparator);

			return result;
		}
		catch(ignored) {
			// noop
		}

		return [];
	}, [search, selectedEntityCategories]);

	/**
	 * TotalSharedMolecules
	 */
	const totalSharedMolecules = useMemo(() => {

		return selectedEntityCategories?.reduce((sum, pairing) => {
			return sum + (pairing.sharedMolecules || 0);
		}, 0);

	}, [selectedEntityCategories]);

	// useEffect(() => {
	// 	scrollToTop();
	// }, [tabChange]);

	/**
	 * scrollToTop
	 */
	const scrollToTop = () => {
		if (scrollAreaRef?.current !== undefined) {
			setTimeout(() => {
				try {
					scrollAreaRef.current.scrollTop = 0;
				}
				catch(e) {
					// noop
				}
			}, 100);
		}
	};

	/**
	 * @param value
	 */
	const onSearch = (value) => {
		setSearch(value);
		setPage(1);
	}

	/**
	 *
	 * @param a
	 * @param b
	 * @returns {number}
	 */
	function entityCategoryPairingsComparator(a, b) {

		// Inverted comparison
		if(a.sharedMolecules &&  b.sharedMolecules) {

			const pcComparison = b.sharedMolecules - a.sharedMolecules;

			if (pcComparison !== 0) {
				return pcComparison;
			}
		}

		return a.name.localeCompare(b.name);
	}

	/**
	 * @param props
	 * @returns {React.JSX.Element|null}
	 */
	const renderTooltip = (props) => {

		const { active, payload, coordinate } = props;

		// const activeCategoryData = selectedEntityCategories.find(item => item.name === activeCategory);
		//
		// console.log(activeCategoryData)
		// console.log(payload)

		if (active && payload && payload.length) {
			const data = payload[0].payload;
			const { x, y } = coordinate; // Get the coordinates of the mouse pointer

			const tooltipStyle = {
				position: 'absolute',
				// minWidth: "260px",
				transform: 'translate(-65%, -100%)', // Center horizontally, place above the bubble
				left: x,
				top: y - (40), // Adjust this value to position above the bubble
				pointerEvents: 'none',
				backgroundColor: `var(--mantine-color-${color}-6)`,
				color: "white",
				borderRadius: "var(--mantine-radius-default)",
				padding: "4px",
				paddingRight: "8px"
				// height: "123px"
			};

			return (
				<Group style={tooltipStyle} wrap={"nowrap"} gap={"xs"} >
					<Stack gap={0} align="stretch" justify="flex-start">
						<Text ta={"center"} size={"14px"} fw={700} pb={2}>{formatPc(data.sharedMoleculesPc)} </Text>
						<Text ta={"center"} size={'12px'}>
							<NumberFormatter thousandSeparator="'" value={data.sharedMolecules}/> {t("ingredient.sharedMolecules")}
						</Text>
					</Stack>
					<FontAwesomeIcon icon={faUpRightFromSquare} size={"sm"}/>
				</Group>
			)
		}

		return null;
	};

	// const CustomLabel = (props) => {
	//
	//     const {value, cx, cy, y} = props;
	//
	//     return <text
	//         x={cx}
	//         y={cy}
	//         dy={y + -93}  // Adjust to fine-tune vertical alignment (-5 moves it slightly upward)
	//         fill="var(--mantine-color-tertiary-9)"  // Customize label color here
	//         fontSize="16px"
	//         // fontWeight={700}
	//         textAnchor="middle"  // Centers the text horizontally
	//         alignmentBaseline="middle"  // Centers the text vertically relative to the bubble
	//     >
	//         {value}%
	//     </text>
	// }

	// Custom vertical dashed line
	const VerticalDashedLine = (props) => {

		return (
			<line
				x1={props.x}
				y1={props.y + 2}  // Start from the bottom of the X-axis
				x2={props.x}
				y2={props.height} // Goes up to the center of the bubble
				style={{
					stroke: `var(--mantine-color-${color}-6)`, // Color of the dashed line
					strokeDasharray: "2.5 2.5", // Defines the dashed style
					strokeWidth: 2,
					fill: 'none',
				}}
			/>
		);
	};

	return selectedEntityCategories?.length > 0 &&

		<TabsLayout
			visible={visible}
			title={t("ingredient.entityCategoryPairing")}
			collapsed={
				<Text size={"36px"} fw={700} lineClamp={1} c={`var(--mantine-color-${color}-9)`}>
					<NumberFormatter thousandSeparator="'" value={selectedEntityCategories?.length}/>
				</Text>
			}
			tabs={[
				{
					tablerIcon: "IconChartBubbleFilled",
					content: <Stack align="stretch" justify="flex-start" h={"100%"} gap={0}
									onMouseLeave={() => setActiveCategory(undefined)}>

						<Stack w={"100%"} h={"100%"} style={{position: "relative"}}
							   onMouseLeave={() => setActiveCategory(undefined)}>

							<ResponsiveContainer width="100%" height={"100%"}>
								<ScatterChart
									width={1000}
									margin={{
										top: 0,
										right: 20,
										bottom: 120,
										left: -20,
									}}>

									<XAxis type="category" dataKey="name" name="Category"
										   interval={0}
										   tickLine={false}
										   tick={({x, y, payload}) => {
											   const isActiveCategory = activeCategory !== undefined && activeCategory === payload.value;
											   return (
												   <>
													   {!isActiveCategory && ( // Render custom dashed tickLine if not hovered
														   <line
															   x1={x}
															   y1={y - 8}
															   x2={x}
															   y2={y - 14}  // Adjust this to move the tickLine
															   stroke={`var(--mantine-color-${color}-1)`}
															   strokeWidth={2}
														   />
													   )}
													   <text
														   x={x}
														   y={y}
														   dx={0} // Adjust the horizontal position
														   dy={10} // Adjust the vertical position
														   textAnchor="end"
														   fill={isActiveCategory ? `var(--mantine-color-${color}-6)` : `var(--mantine-color-${color}-9)`}
														   fontWeight={700}
														   transform={`rotate(-45, ${x}, ${y})`} // Ensure rotation around the correct point
														   fontSize={14}
														   // onMouseEnter={(data, index) => setActiveCategory(payload.value)}  // Track hovered bubble
														   // onMouseLeave={() => setActiveCategory(undefined)}  // Reset on mouse leave
													   >
														   {payload.value}
													   </text>
												   </>
											   );
										   }}
										   stroke={`var(--mantine-color-${color}-1)`}
										   strokeWidth={2}
										// strokeDasharray="2.5 2.5"
										   opacity={1}
									/>

									<YAxis type="number" dataKey="index" domain={[0, 2]} width={80}
										   tick={false} tickLine={false} axisLine={false}
										// stroke="var(--mantine-color-tertiary-9)"
										// label={{ value: 'Category pairings', position: 'insideRight' }}
									/>

									<ZAxis type="number" dataKey="sharedMolecules" range={[5, 500]}/>

									<Tooltip cursor={<VerticalDashedLine />} wrapperStyle={{ zIndex: 100, transition: 'none' }} content={renderTooltip}/>

									<Scatter data={selectedEntityCategories} animationDuration={0}
											 onMouseEnter={(data, index) => setActiveCategory(data.name)}  // Track hovered bubble
											 onMouseLeave={() => setActiveCategory(undefined)}  // Reset on mouse leave
											 onTouchStart={(data, index) => {setActiveCategory(activeCategory === undefined ? data.name : undefined)}}
											 shape={({ cx, cy, size, ...rest }) => {
												 const isActiveCategory = activeCategory != undefined && activeCategory === rest.name;
												 return <circle
													 cx={cx}
													 cy={cy}
													 r={Math.sqrt(size)}  // Ensure the radius is proportional
													 fill={isActiveCategory ? `var(--mantine-color-${color}-6)` : `var(--mantine-color-${color}-9)`}
													 style={{cursor: 'pointer'}}
												 />
											 }}
											 onClick={(data, index) => entityCategoryIdNavigate(navigate, data.categoryId, "_blank")}

									/>

								</ScatterChart>
							</ResponsiveContainer>
						</Stack>

						<Group align="flex-start" justify="center" c={`var(--mantine-color-${color}-9)`} wrap={"nowrap"} style={{zIndex: "1"}}>
							<Stack align="stretch" justify="flex-start" gap={0} w={"50%"}>
								<Text size={"36px"} fw={700} ta={"center"} lineClamp={1}>
									<NumberFormatter thousandSeparator="'" value={selectedEntityCategories.length}/>
								</Text>
								<Text size={"sm"} ta={"center"} opacity={1}>
									{t("studio.entityCategories")}
								</Text>
							</Stack>
							<Stack align="stretch" justify="flex-start" gap={0} w={"50%"}>
								<Text size={"36px"} fw={700} ta={"center"} lineClamp={1}>
									<NumberFormatter thousandSeparator="'" value={totalSharedMolecules}/>
								</Text>
								<Text size={"sm"} ta={"center"} opacity={1}>
									<Text inherit lineClamp={1}>
										{t("studio.sharedMolecules")}
									</Text>
								</Text>
							</Stack>
						</Group>

					</Stack>
				},
				{
					fontAwesomeIcon: faTableList,
					content: <Flexible>

						<Flexible.Expandable>
							<ScrollableArea color={color} viewportRef={scrollAreaRef}>

								<StripedTable striped={"even"} highlightOnHover highlightOnHoverColor={color} stripedColor={color} stickyHeader className={classes.detailsentitycategorypairingstable}>
									<Table.Thead>
										<Table.Tr>
											<Table.Th p={0} pl={0}>
												<SearchInput
													iconColor={"white"}
													textInputClassNames= {{
														input: classes.detailssarchinputtertiary
													}}
													size={"xs"}
													radius={0}
													value={search}
													placeholder={t(`studio.entityCategories`)}
													onChange={(value) => onSearch(value)}
												/>
											</Table.Th>
											<Table.Th ta={"right"} w={"25%"}>
												<Text inherit lineClamp={1}>
													{t("studio.sharedMolecules")}
												</Text>
											</Table.Th>
											<Table.Th ta={"right"} w={"25%"}>
												%
											</Table.Th>
											<Table.Th w={"30px"}>
												&nbsp;
											</Table.Th>
										</Table.Tr>
									</Table.Thead>
									<Table.Tbody>
										{searchItems
											// .sort(entityCategoryPairingsComparator)
											.slice(paginationStartSkip(page, theme.custom.ingredient.paginationSize), paginationEndSkip(page, theme.custom.ingredient.paginationSize))
											.map((item, index) => (
												<Table.Tr style={{color: activeCategory !== undefined ? activeCategory === item.name ? `var(--mantine-color-${color}-6)` : `var(--mantine-color-${color}-9)` : `var(--mantine-color-${color}-9)`}}
														  onClick={() => entityCategoryIdNavigate(navigate, item.categoryId, "_blank")}>
													<Table.Td>
														<Highlight inherit highlight={generateAccentVariantsForWord(search)}>
															{item.name}
														</Highlight>
													</Table.Td>
													<Table.Td ta={"right"}>
														<NumberFormatter thousandSeparator="'" value={item.sharedMolecules}/>
													</Table.Td>
													<Table.Td ta={"right"}>
														{formatPc(item.sharedMoleculesPc)}
													</Table.Td>
													<Table.Td w={"30px"}>
														<FontAwesomeIcon icon={faUpRightFromSquare} size={"sm"}/>
													</Table.Td>
												</Table.Tr>
											))}
									</Table.Tbody>
								</StripedTable>
							</ScrollableArea>
						</Flexible.Expandable>

						<Flexible.Fixed style={{zIndex: "1"}}>
							<Paginator page={page} onPageChange={setPage} paginationSize={theme.custom.ingredient.paginationSize}
									   selectedCount={searchItems.length} totalCount={selectedEntityCategories.length}
									   wrapOn={"xs"} size={"md"} gap={"6px"} withControls={false}
									   color={color}
									   infoColor={color}
									   paginatorClassNames = {{
										   control: classes.detailspaginationcontroltertiary,
										   dots: classes.detailspaginationdotstertiary
									   }}/>
						</Flexible.Fixed>
					</Flexible>
				}
			]}
		/>
}

/**
 * Molecules
 */
const Molecules = ({	nodes, edges, selection,
					   	visible = true,
					   	color = "tertiary" }) => {

	const navigate = useNavigate();
	const { t } = useTranslation();

	const memoizedProps = useMemo(() => ({ nodes, edges, selection }), [nodes, edges, selection]);
	const {entities, selectedMolecules, selectedNodes, selectedEntities , selectedEdge} = useMemoizedSelection(memoizedProps);

	const [selectedEntityCategories, setSelectedEntityCategories] = useState([]);

	const [activeIndex, setActiveIndex] = useState(undefined);

	const [page, setPage] = useState(1);
	const [search, setSearch] = useState(" ");

	const theme = useMantineTheme();

	const scrollAreaRef = useRef(null);

	console.debug("Details.Molecules.update")

	useEffect(() => {

		setSearch("");
		setPage(1);
		setActiveIndex(undefined);

		if(selectedMolecules.length === 0) {
			setSelectedEntityCategories([]);
			return;
		}

		// Create a Map to aggregate category counts
		const categoryMap = new Map();

		selectedEntities.forEach((entity) => {
			entity.categories.forEach((category) => {
				const categoryId = category.entityCategoryId;

				// Extract moleculeIds from entity.molecules
				const moleculeIds = entity.molecules?.map((molecule) => molecule.moleculeId) || [];

				// If the category already exists in the map, update its entityIds and moleculeIds
				if (categoryMap.has(categoryId)) {
					const existing = categoryMap.get(categoryId);

					categoryMap.set(categoryId, {
						...existing,
						// entityIds: [...new Set([...existing.entityIds, entity.entityId])], // Add entityId without duplicates
						moleculeIds: [...new Set([...existing.moleculeIds, ...moleculeIds])], // Add moleculeIds without duplicates
					});
				}
				else {
					// Otherwise, initialize the category in the map
					categoryMap.set(categoryId, {
						categoryId: categoryId,
						name: localized(category, "name"), // Localized name
						// entityIds: [entity.entityId], // Initialize with the current entityId
						moleculeIds: [...new Set(moleculeIds)], // Initialize with moleculeIds (deduplicated)
					});
				}
			});
		});

		// Calculate the 'count' field for each category
		categoryMap.forEach((category) => {
			category.count = category.moleculeIds.length;
		});

		// Convert the Map to an array
		setSelectedEntityCategories(Array.from(categoryMap.values())
			.sort((a, b) => a.name.localeCompare(b.name)));

	}, [selectedMolecules, selectedEntities]);

	useEffect(() => {
		setSearch("");
	}, []);

	/**
	 * searchItems
	 */
	const searchItems = useMemo(() => {

		try {
			if (!search) {
				return selectedMolecules;
			}

			const normalizedSearch = removeAccents(search.toLowerCase());

			return selectedMolecules.filter(molecule => {
				const moleculeName = localized(molecule, "name");
				if (!moleculeName) return false;

				return removeAccents(moleculeName.toLowerCase()).includes(normalizedSearch);
			});
		}
		catch(ignored) {
			// noop
		}

		return [];

	}, [search, selectedMolecules]);

	// useEffect(() => {
	//
	// 	if(onChange) {
	// 		onChange(`${t("molecule.molecules")} ${getSubtitle() !== undefined ? getSubtitle() : ""}`);
	// 	}
	//
	// }, [selectedEntityCategories]);
	//
	// useEffect(() => {
	// 	scrollToTop();
	// }, [tabChange]);

	/**
	 * scrollToTop
	 */
	const scrollToTop = () => {
		if (scrollAreaRef?.current !== undefined) {
			setTimeout(() => {
				try {
					scrollAreaRef.current.scrollTop = 0;
				}
				catch(e) {
					// noop
				}
			}, 100);
		}
	};

	/**
	 * @param value
	 */
	const onSearch = (value) => {
		setSearch(value);
		setPage(1);
	}

	/**
	 * selectedSourceEdge
	 */
	const selectedSourceEdge = useMemo(() => {

		const sourceNode = selectedNodes[0];

		if(!sourceNode) {
			return undefined;
		}

		const moleculeIds = extractMolecules(entities, [], nodes, [sourceNode]).map((molecule) => molecule.moleculeId);

		return {
			name: sourceNode.data?.label,
			ingredientName: sourceNode.data?.entity?.ingredientName,
			moleculeIds: moleculeIds,
			sharedMolecules: moleculeIds.length - selectedMolecules.length || 0,
			sharedMoleculesPc: (selectedMolecules.length * 100) / moleculeIds.length,
		}
	}, [selectedNodes, entities, nodes, selectedMolecules]);

	/**
	 * selectedTargetEdge
	 */
	const selectedTargetEdge = useMemo(() => {

		const targetNode = selectedNodes[1];

		if(!targetNode) {
			return undefined;
		}

		const moleculeIds = extractMolecules(entities, [], nodes, [targetNode]).map((molecule) => molecule.moleculeId);

		return {
			name: targetNode.data?.label,
			ingredientName: targetNode.data?.entity?.ingredientName,
			moleculeIds: moleculeIds,
			sharedMolecules: moleculeIds.length - selectedMolecules.length || 0,
			sharedMoleculesPc: (selectedMolecules.length * 100) / moleculeIds.length,
		}
	}, [selectedNodes, entities, nodes, selectedMolecules]);

	/**
	 * selectedEdgesData
	 */
	const selectedEdgesData = useMemo(() => {

		if(selectedSourceEdge === undefined || selectedTargetEdge === undefined) {
			return [];
		}

		const result = [];

		// Add source if shared molecules are not 100%
		if(100 - selectedSourceEdge.sharedMoleculesPc > 0) {
			result.push({
				name: selectedSourceEdge.name,
				ingredientName: selectedSourceEdge.ingredientName,
				moleculeIds: selectedSourceEdge.moleculeIds,
				count: selectedSourceEdge.sharedMolecules,
				pc: formatPc(100 - selectedSourceEdge.sharedMoleculesPc),
				fill: "var(--mantine-color-tertiary-light-hover)"
			})
		}

		// Add shared item
		result.push(
			{
				name: `${selectedSourceEdge.name} - ${selectedTargetEdge.name}`,
				moleculeIds: selectedMolecules.map((molecule) => molecule.moleculeId) || [],
				count: selectedMolecules.length,
				pc: `${formatPc(selectedSourceEdge.sharedMoleculesPc)} - ${formatPc(selectedTargetEdge.sharedMoleculesPc)}`,
				fill: "var(--mantine-color-tertiary-9)"
			})

		// Add target if shared molecules are not 100%
		if(100 - selectedTargetEdge.sharedMoleculesPc > 0) {
			result.push(
				{
					name: selectedTargetEdge.name,
					ingredientName: selectedTargetEdge.ingredientName,
					moleculeIds: selectedTargetEdge.moleculeIds,
					count: selectedTargetEdge.sharedMolecules,
					pc: formatPc(100 - selectedTargetEdge.sharedMoleculesPc),
					fill: "var(--mantine-color-tertiary-light-hover)"
				})
		}

		return result;

	}, [selectedMolecules, selectedSourceEdge, selectedTargetEdge]);

	/**
	 * getSubtitle
	 */
	function getSubtitle() {

		if(selectedEdge !== undefined) {
			return t("studio.shared")
		}

		return undefined;
	}

	/**
	 * Subtitle
	 */
	const Subtitle = () => {

		return getSubtitle() === undefined ? null :
			<Text>
				{getSubtitle()}
			</Text>
	}

	/**
	 * getMoleculeColor
	 */
	function getMoleculeColor(moleculeId) {

		if(activeIndex === undefined) {
			return `var(--mantine-color-${color}-9)`;
		}

		if(selectedEdge === undefined) {
			return selectedEntityCategories[activeIndex].moleculeIds.includes(moleculeId) ? `var(--mantine-color-${color}-6)` : `var(--mantine-color-${color}-9)`;
		}

		// return selectedEdgesData[activeIndex].moleculeIds.includes(moleculeId) ? "var(--mantine-color-tertiary-6)" : "var(--mantine-color-tertiary-9)";
		return `var(--mantine-color-${color}-9)`;
	}

	/**
	 * getMolecules
	 */
	function getMolecules(index) {

		if(index === undefined) {
			return selectedMolecules?.length;
		}

		return selectedEdge === undefined ? selectedEntityCategories[index]?.count : selectedEdgesData[index]?.count;
	}

	/**
	 * @param props
	 * @returns {Element}
	 */
	const RenderLabel = (props) => {

		const [tooltipOpened, setTooltipOpened] = useState(false);

		const RADIAN = Math.PI / 180;
		const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value, index } = props;
		const sin = Math.sin(-RADIAN * midAngle);
		const cos = Math.cos(-RADIAN * midAngle);
		const sx = cx + (outerRadius + 10) * cos;
		const sy = cy + (outerRadius + 10) * sin;
		const mx = cx + (outerRadius + 30) * cos;
		const my = cy + (outerRadius + 30) * sin;
		const ex = mx + (cos >= 0 ? 1 : -1) * 22;
		const ey = my;
		const textAnchor = cos >= 0 ? 'start' : 'end';

		// const wrapText = (text, maxCharsPerLine = 10) => {
		// 	const words = text.split(" ");
		// 	let lines = [];
		// 	let currentLine = "";
		//
		// 	words.forEach((word) => {
		// 		// If adding the current word doesn't exceed the limit, let's add it to the current line.
		// 		if ((currentLine + word).length <= maxCharsPerLine) {
		// 			currentLine += word + " ";
		// 		} else {
		// 			// Otherwise, we close the current line and start a new one.
		// 			lines.push(currentLine.trim());
		// 			currentLine = word + " ";
		// 		}
		// 	});
		//
		// 	// We add the last line
		// 	lines.push(currentLine.trim());
		//
		// 	return lines;
		// };

		return (
			<g>
				{index === activeIndex &&

					<Sector
						cx={cx}
						cy={cy}
						startAngle={startAngle}
						endAngle={endAngle}
						innerRadius={outerRadius + 8}
						outerRadius={outerRadius + 10}
						fill={`var(--mantine-color-${color}-6)`}
						style={{cursor: 'pointer'}}
						cornerRadius={1}
					/>

				}
				<path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={index === activeIndex ? `var(--mantine-color-${color}-6)` : `var(--mantine-color-${color}-9)`} strokeWidth={1}
					  strokeDasharray="3 3" fill="none"/>
				{/*<circle cx={ex} cy={ey} r={3} fill={"white"} stroke="none"/>*/}
				<MantineTooltip opened={tooltipOpened} transitionProps={{ duration: 0 }} offset={-35} color={color}
								// onMouseEnter={() => setActiveIndex(index)}
								// onMouseLeave={() => setActiveIndex(undefined)}
								onClick={() => {
									if(payload.categoryId) {
										entityCategoryIdNavigate(navigate, payload.categoryId, "_blank");
									}

									if(payload.ingredientName) {
										ingredientNameNavigate(navigate, payload.ingredientName, "_blank");
									}
								}}
								label={
									<Group>
										<Stack gap={0}>
											<Text size={"14px"} fw={700} pb={2}>{payload.name}</Text>
											<Text size={"12px"} ta={textAnchor}>{selectedEdge === undefined ? formatPc(percent * 100) : payload.pc}</Text>
										</Stack>
										{(payload.categoryId || payload.ingredientName) &&
											<FontAwesomeIcon icon={faUpRightFromSquare} size={"sm"}/>
										}
									</Group>
								}
				>
					<g style={{cursor: payload.categoryId || payload.ingredientName ? 'pointer' : "default"}}
					   onMouseEnter={() => {setTooltipOpened(true); setActiveIndex(index);}}
					   onMouseLeave={() => {setTooltipOpened(false); setActiveIndex(undefined);}}
					   onTouchStart={() => {setTooltipOpened((o) => !o); setActiveIndex(activeIndex === undefined ? index : undefined)}}>
						<text x={ex + (cos >= 0 ? 1 : -1) * 8} y={ey} dy={5} textAnchor={textAnchor} fontSize={14} fontWeight={700}
							  fill={index === activeIndex ? `var(--mantine-color-${color}-6)` : `var(--mantine-color-${color}-9)`}>
							{payload.name}
						</text>
						<text x={ex + (cos >= 0 ? 1 : -1) * 8} y={ey} dy={20} textAnchor={textAnchor} fontSize={12}
							  fill={index === activeIndex ? `var(--mantine-color-${color}-6)` : `var(--mantine-color-${color}-9)`}>
							{selectedEdge === undefined ? formatPc(percent * 100) : payload.pc}
						</text>
					</g>
				</MantineTooltip>
			</g>
		);
	};

	return selectedMolecules?.length > 0 &&

		<TabsLayout
			visible={visible}
			title={t("molecule.molecules")}
			collapsed={
				<Text size={"36px"} fw={700} lineClamp={1} c={`var(--mantine-color-${color}-9)`}>
					<NumberFormatter thousandSeparator="'" value={selectedMolecules?.length}/>
				</Text>
			}
			tabs={[
				{
					tablerIcon: "IconChartDonutFilled",
					content: <Stack align="stretch" justify="flex-start" h={"100%"} gap={0}>

						<Stack w={"100%"} h={"100%"} style={{position: "relative"}}
							   onMouseLeave={() => setActiveIndex(undefined)}>

							<Stack align="center" justify="flex-start" gap={0}
								   style={{position: "absolute", top: "50%", left: "50%", transform:"translate(-50%, -50%)", color: `var(--mantine-color-${color}-9)`}}>
								<Text size={"36px"} fw={700} c={activeIndex !== undefined ? `var(--mantine-color-${color}-6)` : `var(--mantine-color-${color}-9)`}>
									<NumberFormatter thousandSeparator="'" value={getMolecules(activeIndex)}/>
								</Text>
								<Text ta={"center"} size={"sm"} c={activeIndex !== undefined ? `var(--mantine-color-${color}-6)` : `var(--mantine-color-${color}-9)`}>
									{t("molecule.molecules")}
									{selectedEdge !== undefined &&
										<Text inline>{activeIndex === undefined ? getSubtitle() : <br/>}</Text>
									}
								</Text>
							</Stack>

							<ResponsiveContainer width="100%" height="100%">
								<PieChart>

									{selectedEdge === undefined ?
										<Pie
											data={selectedEntityCategories}
											cx="50%"
											cy="50%"
											innerRadius={"40%"}
											outerRadius={"50%"}
											fill={`var(--mantine-color-${color}-9)`}
											dataKey="count"
											paddingAngle={1}
											strokeWidth={0}
											isAnimationActive={false}
											startAngle={90}
											endAngle={-270}
											cornerRadius={1}
											activeIndex={activeIndex}
											activeShape={(props) => <Sector {...props} style={{cursor: 'pointer'}}
																			fill={`var(--mantine-color-${color}-6)`}/>}
											// onMouseEnter={(_, index) => setActiveIndex(index)}
											// onMouseLeave={() => setActiveIndex(undefined)}
											// onClick={(data, index) => {
											// 	entityCategoryNavigate(navigate, data, "_blank");
											// }}
											label={(props) => (
												<RenderLabel {...props}/>
											)}
										/>
										:
										<Pie
											data={selectedEdgesData}
											cx="50%"
											cy="50%"
											innerRadius={"40%"}
											outerRadius={"50%"}
											fill={`var(--mantine-color-${color}-9)`}
											dataKey="count"
											paddingAngle={1}
											strokeWidth={0}
											isAnimationActive={false}
											startAngle={90}
											endAngle={-270}
											cornerRadius={1}
											activeIndex={activeIndex}
											activeShape={(props) => <Sector {...props} style={{cursor: 'pointer'}}
																			fill={`var(--mantine-color-${color}-6)`}/>}
											// onMouseEnter={(_, index) => setActiveIndex(index)}
											// onMouseLeave={() => setActiveIndex(undefined)}
											// onClick={(data, index) => {
											// 	// if(onClick) {
											// 	// 	onClick(data.attribute);
											// 	// }
											// }}
											label={(props) => (
												<RenderLabel {...props}/>
											)}
										/>
									}
								</PieChart>
							</ResponsiveContainer>
						</Stack>

						<Group align="flex-start" justify="center" c={`var(--mantine-color-${color}-9)`} wrap={"nowrap"} style={{zIndex: "1"}}>
							{selectedEdge === undefined ?
								<Stack align="stretch" justify="flex-start" gap={0} w={"100%"}>
									<Text size={"36px"} fw={700} ta={"center"}>
										{selectedEntityCategories.length}
									</Text>
									<Text size={"sm"} ta={"center"} opacity={1}>
										{t("studio.entityCategories")}
									</Text>
								</Stack>
								:
								<>
									<Stack align="stretch" justify="flex-start" gap={0} w={"50%"}>
										<Text size={"36px"} fw={700} ta={"center"} lineClamp={1}>
											{formatPc(selectedSourceEdge.sharedMoleculesPc)}
										</Text>
										<Text lineClamp={1} size={"sm"} ta={"center"} opacity={1}>
											{selectedSourceEdge.name}
										</Text>
									</Stack>
									<Stack align="stretch" justify="flex-start" gap={0} w={"50%"}>
										<Text size={"36px"} fw={700} ta={"center"} lineClamp={1}>
											{formatPc(selectedTargetEdge.sharedMoleculesPc)}
										</Text>
										<Text lineClamp={1} size={"sm"} ta={"center"} opacity={1}>
											{selectedTargetEdge.name}
										</Text>
									</Stack>
								</>
							}
						</Group>

					</Stack>
				},
				{
					fontAwesomeIcon: faTableList,
					content: <Flexible>

						<Flexible.Expandable>
							<ScrollableArea color={color} viewportRef={scrollAreaRef}>

								<StripedTable striped={"even"} stripedColor={color} highlightOnHover highlightOnHoverColor={color} stickyHeader className={classes.detailsmoleculartable}>
									<Table.Thead>
										<Table.Tr>
											<Table.Th p={0} pl={0} colSpan={2}>
												<SearchInput
													iconColor={"white"}
													textInputClassNames= {{
														input: classes.detailssarchinputtertiary
													}}
													size={"xs"}
													radius={4}
													value={search}
													placeholder={t(`molecule.molecules`)}
													onChange={(value) => onSearch(value)}
												/>
											</Table.Th>
										</Table.Tr>
									</Table.Thead>
									<Table.Tbody>
										{searchItems
											.sort((a, b) => alphabeticComparator(localized(a, "name"), localized(b, "name")))
											.slice(paginationStartSkip(page, theme.custom.molecule.paginationSize), paginationEndSkip(page, theme.custom.molecule.paginationSize))
											.map((item, index) => (
												<Table.Tr style={{color: getMoleculeColor(item.moleculeId)}} onClick={() => moleculeIdNavigate(navigate, item.moleculeId, "_blank")}>
													<Table.Td>
														<Highlight inherit highlight={generateAccentVariantsForWord(search)}>
															{localized(item, "name")}
														</Highlight>
													</Table.Td>
													<Table.Td w={"30px"}>
														<FontAwesomeIcon icon={faUpRightFromSquare} size={"sm"}/>
													</Table.Td>
												</Table.Tr>
											))}
									</Table.Tbody>
								</StripedTable>
							</ScrollableArea>
						</Flexible.Expandable>

						<Flexible.Fixed style={{zIndex: "1"}}>
							<Paginator page={page} onPageChange={setPage} paginationSize={theme.custom.molecule.paginationSize}
									   selectedCount={searchItems.length} totalCount={selectedMolecules.length}
									   wrapOn={"xs"} size={"md"} gap={"6px"} withControls={false}
									   color={color}
									   infoColor={color}
									   paginatorClassNames = {{
										   control: classes.detailspaginationcontroltertiary,
										   dots: classes.detailspaginationdotstertiary
									   }}/>
						</Flexible.Fixed>
					</Flexible>
				}
			]}
		/>
};

/**
 * MolecularChart
 */
const MolecularChart = ({ 	nodes, edges, selection, attribute = "flavors",
							visible = true,
							maxItems = 10,
							color = "tertiary" }) => {

	const memoizedProps = useMemo(() => ({ nodes, edges, selection }), [nodes, edges, selection]);
	const {selectedMolecules } = useMemoizedSelection(memoizedProps);

	const [tooltipOpened, setTooltipOpened] = useState(false);

	const { t } = useTranslation();

	const [page, setPage] = useState(1);
	const [search, setSearch] = useState(" ");

	const theme = useMantineTheme();

	const scrollAreaRef = useRef(null);

	const infoIconStyle = { width: "18px", height: "18px", opacity: 0.75, color: color};

	console.debug("Details.MolecularChart.update")

	/**
	 * occurrences
	 */
	const occurrences = useMemo(() => {

		if(selectedMolecules === undefined) {
			return 0;
		}

		return selectedMolecules?.flatMap((molecule) => molecule[attribute] || []);

	}, [selectedMolecules]);

	/**
	 * data
	 */
	const data = useMemo(() => {

		if(occurrences.length === 0) {
			return [];
		}

		// Update all data for the specified attribute (emotions, flavors, odors, tastes, compounds)
		return accumulateFrequency(selectedMolecules, attribute);

	}, [occurrences]);

	/**
	 * chartData
	 */
	const chartData = useMemo(() => {

		if(data.length === 0) {
			return [];
		}

		// Limit the chart data to maxItems
		return data.slice(0, maxItems).map((item) => ({
			element: item.name,
			frequency: item.pc,
		}));

	}, [data, maxItems]);

	/**
	 * domain
	 */
	const domain = useMemo(() => {

		// Calculate the domain for PolarRadiusAxis based on limited data
		if (chartData.length > 0) {
			const frequencies = chartData.map((item) => item.frequency);
			const max = Math.max(...frequencies); // Maximum percentage

			return [0, Math.min(100, max)];
		}

		return [0, 100];

	}, [chartData]);

	/**
	 * searchItems
	 */
	const searchItems = useMemo(() => {

		try {
			if (!search) {
				return data;
			}

			const normalizedSearch = removeAccents(search.toLowerCase());

			return data.filter(d => {
				const itemName = d.name;
				if (!itemName) return false;

				return removeAccents(itemName.toLowerCase()).includes(normalizedSearch);
			});
		}
		catch(ignored) {
			// noop
		}

		return [];
	}, [search, data]);

	useEffect(() => {
		setSearch("");
		setPage(1);
	}, [occurrences]);

	useEffect(() => {
		setSearch("");
	}, []);

	/**
	 * scrollToTop
	 */
	const scrollToTop = () => {
		if (scrollAreaRef?.current !== undefined) {
			setTimeout(() => {
				try {
					scrollAreaRef.current.scrollTop = 0;
				}
				catch(e) {
					// noop
				}
			}, 100);
		}
	};

	/**
	 * @param value
	 */
	const onSearch = (value) => {
		setSearch(value);
		setPage(1);
	}

	/**
	 * PolarAngleAxisTick
	 */
	const PolarAngleAxisTick = ({ payload, x, y, cx, cy, index }) => {

		const [tooltipOpened, setTooltipOpened] = useState(false);

		// Determine whether the label is top, bottom, left, or right
		const isTop = y < cy; // Label is above the center
		const isBottom = y > cy; // Label is below the center
		const isLeft = x < cx; // Label is on the left side
		const isRight = x > cx; // Label is on the right side

		// Adjust positioning for labels
		const dx = isTop || isBottom ? "0" : isLeft ? "-5px" : "5px"; // Horizontal adjustment for left/right
		const dy = isTop
			? "-5px" // Move the top label slightly upward
			: isBottom
				? "10px" // Move the bottom label slightly downward
				: "0.35em"; // No vertical adjustment for left/right

		// Horizontal alignment
		let textAnchor = "";

		if (isLeft) {
			textAnchor = "end";
		}
		else if (isRight) {
			textAnchor = "start";
		}
		else if (isTop || isBottom) {
			textAnchor = "middle";
		}

		// Return the tick element
		return (
			<MantineTooltip opened={tooltipOpened} transitionProps={{ duration: 0 }} offset={-25} color={color}
							label={<Box>
								<Text size={"14px"} fw={700} pb={2}>{payload.value}</Text>
								<Text size={"12px"} ta={textAnchor}>{formatPc(chartData[index].frequency)}</Text>
							</Box>
							}>

				<g>
					<text
						x={x}
						y={y}
						dx={dx}
						dy={dy}
						textAnchor={textAnchor}
						fill={`var(--mantine-color-${color}-9)`}
						fontSize={14}
						fontWeight={700}
						// style={{ cursor: "pointer" }}
						onMouseEnter={() => setTooltipOpened(true)}
						onMouseLeave={() => setTooltipOpened(false)}
						onTouchStart={() => setTooltipOpened((o) => !o)}
					>
						{payload.value}
					</text>
				</g>
			</MantineTooltip>
		);
	};

	/**
	 * PolarRadiusAxisTick
	 */
	const PolarRadiusAxisTick = ({ payload, x, y, ...rest }) => {

		// if (payload.value === 0 || payload.value === 100) return null;

		return (
			<text
				x={x}
				y={y}
				dx={4}
				dy={14}
				// transform={`rotate(360 ${x} ${y})`} // Rotate label 90° clockwise
				fill={`var(--mantine-color-${color}-9)`}
				fontSize={12}
				textAnchor="left"
			>
				{`${formatPc(payload.value)}`}
			</text>
		);
	};

	// Render the graphics section dynamically based on the attributes prop
	return chartData === undefined || chartData.length === 0 ? null :

		<TabsLayout
			visible={visible}
			title={t(`molecule.${attribute}`)}
			description={t(`molecule.${attribute}Description`)}
			collapsed={
				<Text size={"36px"} fw={700} lineClamp={1} c={`var(--mantine-color-${color}-9)`}>
					<NumberFormatter thousandSeparator="'" value={data.length}/>
				</Text>
			}
			tabs={[
				{
					tablerIcon: "IconChartRadar",
					content:<Stack align="stretch" justify="flex-start" h={"100%"} gap={"xs"}>

						<Stack w={"100%"} h={"100%"} style={{position: "relative"}}>

							<ResponsiveContainer width="100%" height="100%">

								<RadarChart data={chartData} outerRadius="50%">

									<PolarGrid gridType={"circle"} stroke={`var(--mantine-color-${color}-9)`} strokeOpacity={0.35}/>

									<PolarAngleAxis dataKey="element"
													tick={(props) => (
														<PolarAngleAxisTick {...props}/>
													)}
									/>

									<Radar isAnimationActive={false}
										   dataKey="frequency"
										   stroke={`var(--mantine-color-${color}-6)`}
										   strokeOpacity={1}
										   fill={`var(--mantine-color-${color}-6)`}
										   fillOpacity={0.35}
										   strokeLinejoin="round"
									/>

									<PolarRadiusAxis angle={90}
													 domain={domain} // Dynamic domain
													 strokeWidth={0}
													 tick={<PolarRadiusAxisTick />}/>
								</RadarChart>
							</ResponsiveContainer>
						</Stack>

						<Group align="flex-start" justify="center" c={`var(--mantine-color-${color}-9)`} wrap={"nowrap"} style={{zIndex: "1"}}>
							<Stack align="stretch" justify="flex-start" gap={0} w={"50%"}>
								<Text size={"36px"} fw={700} ta={"center"} lineClamp={1}>
									<NumberFormatter thousandSeparator="'" value={data.length}/>
								</Text>
								<Text size={"sm"} ta={"center"} opacity={1}>
									{t(`molecule.${attribute}`)}
								</Text>
							</Stack>
							<Stack align="stretch" justify="flex-start" gap={0} w={"50%"}>
								<Text size={"36px"} fw={700} ta={"center"} lineClamp={1}>
									<NumberFormatter thousandSeparator="'" value={occurrences.length}/>
								</Text>
								<Text size={"sm"} ta={"center"} opacity={1}>
									{t(`studio.occurrences`)}
								</Text>
							</Stack>
						</Group>

					</Stack>
				},
				{
					fontAwesomeIcon: faTableList,
					content: <Flexible>

						<Flexible.Expandable>
							<ScrollableArea color={color} viewportRef={scrollAreaRef}>

								<StripedTable striped={"even"} stripedColor={color} stickyHeader className={classes.detailsmolecularcharttable}>
									<Table.Thead>
										<Table.Tr>
											<Table.Th p={0} pl={0}>
												<SearchInput
													iconColor={"white"}
													textInputClassNames= {{
														input: classes.detailssarchinputtertiary
													}}
													size={"xs"}
													radius={0}
													value={search}
													placeholder={t(`molecule.${attribute}`)}
													onChange={(value) => onSearch(value)}
												/>
											</Table.Th>
											<Table.Th ta={"right"} w={"25%"}>
												{t("studio.occurrences")}
											</Table.Th>
											<Table.Th ta={"right"} w={"30%"}>
												<Group wrap={"nowrap"} gap={4}>
													{t("studio.weightedPc")}
													<MantineTooltip opened={tooltipOpened} transitionProps={{ duration: 0 }} multiline maw={300} withArrow offset={20} label={t("studio.weightedPcDescription")} bg={`var(--mantine-color-tertiary-6)`} color={"white"}>
														<FontAwesomeIcon icon={faCircleInfo} style={infoIconStyle}
																		 onMouseEnter={() => setTooltipOpened(true)}
																		 onMouseLeave={() => setTooltipOpened(false)}
																		 onTouchStart={() => setTooltipOpened((o) => !o)}/>
													</MantineTooltip>
												</Group>
											</Table.Th>
										</Table.Tr>
									</Table.Thead>
									<Table.Tbody>
										{searchItems
											.slice(paginationStartSkip(page, theme.custom.molecule.paginationSize), paginationEndSkip(page, theme.custom.molecule.paginationSize))
											.map((item, index) => (
												<Table.Tr style={{color: `var(--mantine-color-${color}-9)`, fontWeight: ((page - 1) * theme.custom.molecule.paginationSize + index) < maxItems ? 700 : 400}}>
													<Table.Td>
														<Highlight inherit highlight={generateAccentVariantsForWord(search)}>
															{item.name}
														</Highlight>
													</Table.Td>
													<Table.Td ta={"right"}>
														<NumberFormatter thousandSeparator="'" value={item.count}/>
													</Table.Td>
													<Table.Td ta={"right"}>
														{formatPc(item.pc)}
													</Table.Td>
												</Table.Tr>
											))}
									</Table.Tbody>
								</StripedTable>
							</ScrollableArea>

						</Flexible.Expandable>

						<Flexible.Fixed style={{zIndex: "1"}}>
							<Paginator page={page} onPageChange={setPage} paginationSize={theme.custom.molecule.paginationSize}
									   selectedCount={searchItems.length} totalCount={data.length}
									   wrapOn={"xs"} size={"md"} gap={"6px"} withControls={false}
									   color={color}
									   infoColor={color}
									   paginatorClassNames = {{
										   control: classes.detailspaginationcontroltertiary,
										   dots: classes.detailspaginationdotstertiary
									   }}/>
						</Flexible.Fixed>
					</Flexible>
				}
			]}
			/>
};