import React, {useEffect, useMemo, useState} from 'react';
import {
    Container,
    Title,
    Text,
    Space,
    Stack,
    Table,
    useMantineTheme,
    Tabs,
    Highlight,
    RangeSlider,
    VisuallyHidden,
    NumberFormatter,
    Drawer,
    Group,
    Anchor,
    Grid, Center, Box} from '@mantine/core';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import i18n, {localized, localizedEn, parameterLanguage} from "../../i18n";
import classes from "./IngredientPage.module.css";
import {Image169WithZoom, Page, PageTitle} from "../../components/page/Page";
import {alphabeticComparator, formatTemperature} from "../../util/utils";
import {useTranslation} from "react-i18next";
import {
    paginationStartSkip,
    paginationEndSkip
} from "../../util/pagination";
import {
    IngredientIconTitle,
    ingredientNavigate, IngredientPairingIconTitle,
    IngredientText,
    IngredientTextByNameAndCategories,
} from "./IngredientLink";
import {EntityCategoryLink} from "./EntityCategoryLink";
import {Ingredient09} from "./IngredientList";
import {Recipe09} from "../recipe/RecipeList";
import {SearchInput} from "../../components/search/Search";
import {StripedTable} from "../../components/stripedTable/StripedTable";
import {Paginator} from "../../components/paginator/Paginator";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowsLeftRightToLine,
    faChevronLeft,
    faChevronRight, faScaleUnbalancedFlip, faClock, faTemperatureLow, faUpRightFromSquare
} from "@fortawesome/free-solid-svg-icons";
import {SimpleBox} from "../../components/simpleBox/SimpleBox";
import {Molecule09} from "../molecule/MoleculeList";
import {useDisclosure} from "@mantine/hooks";
import {ScrollTab} from "../../components/scrollTab/ScrollTab";
import {faWikipediaW} from "@fortawesome/free-brands-svg-icons";
import {Icon} from "../../components/icons/Icons";
import {ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis, ZAxis} from "recharts";
import {TooltipEn, useLanguageStore} from "../../components/languageSelector/LanguageSelector";
import {formatIsoDuration} from "../../util/time";
import {convertMeasure} from "../../util/measure";
import useKey from "../useKey";
import useMoleculeAll from "../molecule/useMoleculeAll";
import useResult from "../useResult";
import useIngredient, {IngredientTypes} from "./useIngredient";
import useEntity from "./useEntity";
import useEntityCategoryPairingAll from "./useEntityCategoryPairingAll";
import useEntityPairingAll from "./useEntityPairingAll";
import useEntityAll from "./useEntityAll";
import useWiki from "../useWiki";
import {AdvertisingGrid} from "../../components/advertising/Advertising";
import {
    FOOD_FACTS_VIDEO_ID_LINK_NAME, FOOD_FACTS_VIDEO_LINK_NAME,
    localizedLink,
    WIKI_LINK_NAME,
    WIKI_NATURAL_SOURCE_LINK_NAME
} from "../../util/links";
import {
    FlavorDbCredits,
    FoodDataCentralCredits,
    WikipediaCredits
} from "../../components/credits/Credits";
import {useLoadingContext} from "../../components/loading/LoadingContext";
import {YouTubeEmbed} from "../../components/youtube/YouTubeEmbed";
import {CustomCarousel} from "../../components/carousel/CustomCarousel";
import {MoleculeIconTitle} from "../molecule/MoleculeLink";
import {RecipeIconTitle} from "../recipe/RecipeLink";
import {Settings, useAccountContext} from "../../components/account/AccountContext";
import {faHeart as faHeartSolid} from "@fortawesome/free-solid-svg-icons/faHeart";
import {faHeart} from "@fortawesome/free-regular-svg-icons/faHeart";
import useIngredientAll from "./useIngredientAll";
import useRecipeAll from "../recipe/useRecipeAll";
import {homeNavigate} from "../home/HomeLink";
import {useFeatures} from "../../components/features/useFeatures";
import {Sponsors} from "../../components/sponsor/Sponsor";
import useInsights from "../useInsights";
import useMedia from "../useMedia";
import {theme} from "../../Theme";
import {useEnvironmentContext} from "../../components/environment/EnvironmentContext";
import {NUTRIENT_CATEGORIES, NUTRIENT_DEFAULT_SERVING, seasonSortComparator} from "../studio/StudioUtils";

/**
 * @param a
 * @param b
 * @returns {number}
 */
function sortComparator(a, b) {

    const sortComparison = a.sort - b.sort;
    if (sortComparison !== 0) {
        return sortComparison;
    }

    return localized(a, "name").localeCompare(localized(b, "name"));
}

/**
 * @param entities
 * @param item
 * @returns {unknown[]}
 */
function extractIngredientIds(entities, item){
    let result = [];

    entities
        .sort((a, b) => a.entityId - b.entityId)
        .forEach(entity => {

            entity.ingredients
                .sort((a, b) => a.ingredientId - b.ingredientId)
                .forEach(ingredient => {

                    if (!result.some(i => i.ingredientId === ingredient.ingredientId)) {

                        if(ingredient.ingredientId !== item.ingredientId) {

                            result.push(ingredient.ingredientId);
                        }
                    }
                });
        });

    return result;
}

/**
 * @param entity
 * @param entityPairingRepresentativeIngredients
 * @returns {unknown[]}
 */
function getEntityRepresentativeIngredientFromIngredients(entity, entityPairingRepresentativeIngredients)  {

    if (!entity.ingredients || entity.ingredients.length === 0) {
        return undefined;
    }

    for (let ingredient of entity.ingredients) {
        if (ingredient.representative) {
            // return {
            //     ...ingredient,
            //     category: entity.categories ? entity.categories[0] : undefined
            // };

            const representativeIngredient = entityPairingRepresentativeIngredients.find(
                repIngredient => repIngredient.ingredientId === ingredient.ingredientId
            );
            if (representativeIngredient) {
                return representativeIngredient;
            }
        }
    }

    return undefined;
}

/**
 * @param entity
 * @returns {string[]|string|*[]}
 */
function sortedEntityCategories(entity) {
    return entity.categories || [];
}

/**
 * Compatibility Marks
 */
const compatibilityMarks=[
    {value: 0},
    {value: 20, label: "20%"},
    {value: 40, label: "40%"},
    {value: 60, label: "60%"},
    {value: 80, label: "80%"},
    {value: 100},
];

/**
 * @param marks
 * @param value
 * @param type
 * @returns {*}
 */
function defaultCompatibilities(compatibilities, marks) {

    /**
     * @param marks
     * @param value
     * @param type
     * @returns {*}
     */
    function normalize(marks, value, type) {

        for (let i = 0; i < marks.length - 1; i++) {

            if (value <= marks[i + 1].value) {
                // For the minimum, return the lower step
                if (type === 'min') {
                    return marks[i].value;
                }
                // For the maximum, return the upper step
                if (type === 'max') {
                    return marks[i + 1].value;
                }
            }
        }
    }

    // Extract shared molecules percentage
    const sharedMoleculesPc = compatibilities.map(compatibility => compatibility.sharedMoleculesPc);

    // Fin min and max
    let minValue = Math.min(...sharedMoleculesPc);
    let maxValue = Math.max(...sharedMoleculesPc);

    return [normalize(marks, minValue, 'min'), normalize(marks, maxValue, 'max')];
}

// /**
//  * EntityCategoryPairings
//  *
//  * @param entityCategoryPairings
//  * @returns {Element}
//  * @constructor
//  */
// const EntityCategoryPairings = ({entityCategoryPairings}) => {
//
//     const [page, setPage] = useState(1);
//     const [search, setSearch] = useState(" ");
//     const [searchItems, setSearchItems] = useState([]);
//     const [minCompatibility, setMinCompatibility] = useState(0);
//     const [maxCompatibility, setMaxCompatibility] = useState(100);
//
//     const { t } = useTranslation();
//     const theme = useMantineTheme();
//
//     const { isSm } = useMedia();
//
//     useEffect(() => {
//         setSearch("");
//     }, []);
//
//     useEffect(() => {
//
//         try {
//
//             setSearchItems(entityCategoryPairings.filter(entityCategoryPairing => {
//                 const includeSearch = search ? localized(entityCategoryPairing, 'name').toLowerCase().includes(search.toLowerCase()) : true;
//
//                 return  includeSearch &&
//                         entityCategoryPairing.sharedMoleculesPc >= minCompatibility &&
//                         entityCategoryPairing.sharedMoleculesPc <= maxCompatibility;
//             }));
//         }
//         catch(ignored) {
//             // noop
//         }
//
//     }, [search, minCompatibility, maxCompatibility]);
//
//     /**
//      * @param value
//      */
//     const onSearch = (value) => {
//         setSearch(value);
//         setPage(1);
//     }
//
//     /**
//      * @param minCompatibility
//      * @param maxCompatibility
//      */
//     const onMinMaxCompatibility = (minCompatibility, maxCompatibility) => {
//         setMinCompatibility(minCompatibility);
//         setMaxCompatibility(maxCompatibility);
//         setPage(1);
//     }
//
//     /**
//      *
//      * @param a
//      * @param b
//      * @returns {number}
//      */
//     function entityCategoryPairingsComparator(a, b) {
//
//         // Inverted comparison
//         const pcComparison = b.sharedMolecules - a.sharedMolecules;
//         if (pcComparison !== 0) {
//             return pcComparison;
//         }
//
//         return localized(a, "name").localeCompare(localized(b, "name"));
//     }
//
//     return (
//         <Stack justify="space-between" gap={"0"}>
//             <Stack justify="flex-start" gap="0">
//                 <VisuallyHidden>
//                     <Title order={2} pt={"lg"}>{t('ingredient.entityCategoryPairing')}</Title>
//                 </VisuallyHidden>
//                 <Space h="lg"/>
//                 <SimpleBox color={"secondary"}>
//                     <Title order={3}>
//                         {t('ingredient.compatibilityRange')}
//                     </Title>
//                     <Space h="lg"/>
//                     <RangeSlider color={"secondary"}
//                                  minRange={20} step={20}
//                                  label={null}
//                                  classNames={{
//                                      track: classes.rangeslidertrack,
//                                      markLabel: classes.rangeslidermarklabel,
//                                      mark: classes.rangeslidermark
//                                  }}
//                                  marks={compatibilityMarks}
//                                  defaultValue={defaultCompatibilities(entityCategoryPairings, compatibilityMarks)}
//                                  onChangeEnd={(value) => onMinMaxCompatibility(value[0], value[1])} />
//                     <Space h="lg"/>
//                 </SimpleBox>
//                 <Space h="lg"/>
//                 <StripedTable stripedColor={"tertiary"} style={{color: "var(--mantine-color-tertiary-9)"}}>
//                     <Table.Thead>
//                         <Table.Tr>
//                             <Table.Th p={0} colSpan={isSm ? 2 : 1}>
//                                 <SearchInput
//                                     value={search}
//                                     placeholder={t('common.entityCategory')}
//                                     onChange={(value) => onSearch(value)}
//                                 />
//                             </Table.Th>
//                             <Table.Th  w={"237px"} style={{textAlign: "right", verticalAlign: "top"}} visibleFrom={"sm"}>{t('ingredient.compatibility')}</Table.Th>
//                         </Table.Tr>
//                     </Table.Thead>
//                     <Table.Tbody>
//
//                         {searchItems.length === 0 ? null :
//                             <Space h={"lg"}/>
//                         }
//
//                         {searchItems
//                             .sort(entityCategoryPairingsComparator)
//                             .slice(paginationStartSkip(page, theme.custom.ingredient.paginationSize), paginationEndSkip(page, theme.custom.ingredient.paginationSize))
//                             .map((item, index) => (
//
//                                 <Table.Tr key={`entityCategoryPairing-${index}`}>
//                                     <Table.Td style={{verticalAlign: "middle"}}>
//                                         {/*<EntityCategoryLink entityCategory={item} highlight={search} target="_self"/>*/}
//                                         <EntityCategoryText entityCategory={item} highlight={search}/>
//                                     </Table.Td>
//                                     <Table.Td ta={"right"}>
//                                         <Text>{item.sharedMoleculesPc > 0 ? `${item.sharedMoleculesPc}%` : '<1%'}</Text>
//                                         <Text size={'xs'}>
//                                             <NumberFormatter thousandSeparator="'" value={item.sharedMolecules} />
//                                             {t("ingredient.sharedMolecules")}
//                                         </Text>
//                                     </Table.Td>
//                                 </Table.Tr>
//                             ))}
//                     </Table.Tbody>
//                 </StripedTable>
//             </Stack>
//             <Space h={"lg"}/>
//             <Paginator color={"tertiary"} page={page} onPageChange={setPage} paginationSize={theme.custom.ingredient.paginationSize}
//                        selectedCount={searchItems.length} totalCount={entityCategoryPairings.length}/>
//         </Stack>
//     )
// }

// /**
//  * EntityPairingsLoadMoreVersion
//  *
//  * @param entityPairings
//  * @constructor
//  */
// const EntityPairingsLoadMoreVersion = ({entityPairings}) => {
//
//     const [page, setPage] = useState(1);
//     const [paginationSize, setPaginationSize] = useState(1);
//     const [search, setSearch] = useState(" ");
//     const [searchItems, setSearchItems] = useState([]);
//
//     const { t } = useTranslation();
//     const theme = useMantineTheme();
//
//     const [scroll, scrollTo] = useWindowScroll();
//
//     useEffect(() => {
//         setSearch("");
//         setPaginationSize(theme.custom.ingredient.paginationSize);
//     }, []);
//
//     useEffect(() => {
//
//         if(!search) {
//             setSearchItems(entityPairings);
//             setPaginationSize(theme.custom.ingredient.paginationSize);
//         }
//         else {
//             setSearchItems(entityPairings.filter(entityPairing => {
//                 const ingredient = getEntityRepresentativeIngredient(entityPairing);
//                 return localized(ingredient, 'name').toLowerCase().includes(search.toLowerCase())
//             }))
//             setPaginationSize(theme.custom.ingredient.paginationSize);
//         }
//
//     }, [search]);
//
//     /**
//      * @param value
//      */
//     const onSearch = (value) => {
//         setSearch(value);
//         setPage(1);
//     }
//
//     /**
//      *
//      * @param a
//      * @param b
//      * @returns {number}
//      */
//     function entityPairingsComparator(a, b) {
//         const pcComparison = b.sharedMoleculesPc - a.sharedMoleculesPc;
//         if (pcComparison !== 0) {
//             return pcComparison;
//         }
//
//         return localized(getEntityRepresentativeIngredient(a), "name").localeCompare(localized(getEntityRepresentativeIngredient(b), "name"));
//     }
//
//     return (
//             <Stack justify="space-between">
//                 <Stack justify="flex-start" gap="md">
//                     <Title order={2} pt={"lg"}>{t('ingredient.entityPairing')}</Title>
//                     <Table withRowBorders={false}
//                            striped={"even"}
//                            stripedColor={alpha("var(--mantine-color-secondary-6)", 0.07)}>
//                         <Table.Thead>
//                             <Table.Tr>
//                                 <Table.Th pl={0}>
//                                     <SearchInput
//                                         value={search}
//                                         placeholder={t('common.ingredient')}
//                                          onChange={(value) => onSearch(value)}
//                                     />
//                                 </Table.Th>
//                                 <Table.Th w={"30%"} ta={"right"}>{t('ingredient.compatibility')}</Table.Th>
//                             </Table.Tr>
//                         </Table.Thead>
//                         <Table.Tbody>
//                             {searchItems
// .sort(entityPairingsComparator)
//                                  .slice(paginationStartSkip(page, paginationSize), paginationEndSkip(page, paginationSize))
//
//                                 .map((item, index) => (
//
//                                     <Table.Tr key={`entityPairing-${index}`}>
//                                         <Table.Td>
//                                             <IngredientLink ingredient={getEntityRepresentativeIngredient(item)} ingredientHighlight={search} ingredientTarget="_self" categoryTarget="_self"/>
//                                         </Table.Td>
//                                         <Table.Td ta={"right"}>
//                                             <Text>{item.sharedMoleculesPc > 0 ? `${item.sharedMoleculesPc}%` : '<1%'}</Text>
//                                             <Text size={'xs'}>
//                                                 {t("ingredient.sharedMolecules", {molecules: item.sharedMolecules})}
//                                             </Text>
//                                         </Table.Td>
//                                     </Table.Tr>
//                                 ))}
//                         </Table.Tbody>
//                     </Table>
//                 </Stack>
//
//                     <Group>
//                         <Text size={"sm"}>
//                             {t(`common.pagination`, { startCount: paginationStartCount(page, paginationSize),
//                                 endCount:  paginationEndCount(page, paginationSize, searchItems.length),
//                                 totalCount: searchItems.length})}
//                         </Text>
//                         {searchItems.length <= paginationSize ? null :
//                             <Button onClick={() => setPaginationSize(paginationSize + theme.custom.ingredient.paginationSize)}>load more</Button>
//                         }
//                         {/*<Button*/}
//                         {/*    onClick={() => scrollTo({ y: 100 })}*/}
//                         {/*>*/}
//                         {/*    Scroll to top*/}
//                         {/*</Button>*/}
//
//                         <Button
//                             style={{visibility: paginationSize > theme.custom.ingredient.paginationSize ? "visible" : "hidden"}}
//                             onClick={() => scrollTo({ y: 100 })}
//                         >
//                             Scroll to top
//                         </Button>
//
//                     </Group>
//
//
//             </Stack>
//     )
// }

/**
 * EntityCategoryPairingsBubble
 * @param entityCategoryPairings
 * @returns {Element}
 * @constructor
 */
const EntityCategoryPairingsBubble = ({entityCategoryPairings}) => {

    const [data, setData] = useState([]);
    const [hoveredCategory, setHoveredCategory] = useState(null);
    const { t } = useTranslation();
    const theme = useMantineTheme();

    const { isSm } = useMedia();

    useEffect(() => {

        setData(entityCategoryPairings
            .sort((a, b) => alphabeticComparator(localized(a, "name"), localized(b, "name")))
            .map((entityCategoryPairing, index) => {
                return {
                    category: localized(entityCategoryPairing, "name"),
                    index: 0.9,
                    sharedMolecules: entityCategoryPairing.sharedMolecules,
                    sharedMoleculesPc: entityCategoryPairing.sharedMoleculesPc
                }
            }));

    }, []);

    /**
     * @param props
     * @returns {React.JSX.Element|null}
     */
    const renderTooltip = (props) => {

        const { active, payload, coordinate } = props;

        if (active && payload && payload.length) {
            const data = payload[0].payload;
            const { x, y } = coordinate; // Get the coordinates of the mouse pointer

            const tooltipStyle = {
                position: 'absolute',
                minWidth: "260px",
                transform: 'translate(-50%, -100%)', // Center horizontally, place above the bubble
                left: x,
                top: y - (!isSm ? 45 : 27), // Adjust this value to position above the bubble
                pointerEvents: 'none',
                color: "var(--mantine-color-tertiary-6)"
                // height: "123px"
            };

            return (
                <Stack style={tooltipStyle} gap={0} align="stretch" justify="flex-start">
                    <Text ta={"center"}>{data.sharedMoleculesPc > 0 ? `${data.sharedMoleculesPc}%` : '<1%'}</Text>
                    <Text ta={"center"} size={'xs'}>
                        <NumberFormatter thousandSeparator="'" value={data.sharedMolecules}/> {t("ingredient.sharedMolecules")}
                    </Text>
                </Stack>
            )
        }

        return null;
    };

    // const CustomLabel = (props) => {
    //
    //     const {value, cx, cy, y} = props;
    //
    //     return <text
    //         x={cx}
    //         y={cy}
    //         dy={y + -93}  // Adjust to fine-tune vertical alignment (-5 moves it slightly upward)
    //         fill="var(--mantine-color-tertiary-9)"  // Customize label color here
    //         fontSize="16px"
    //         // fontWeight={700}
    //         textAnchor="middle"  // Centers the text horizontally
    //         alignmentBaseline="middle"  // Centers the text vertically relative to the bubble
    //     >
    //         {value}%
    //     </text>
    // }

    // Custom vertical dashed line
    const VerticalDashedLine = (props) => {

        return (
            <line
                x1={props.x}
                y1={props.y + 2}  // Start from the bottom of the X-axis
                x2={props.x}
                y2={props.height} // Goes up to the center of the bubble
                style={{
                    stroke: "var(--mantine-color-tertiary-6)", // Color of the dashed line
                    strokeDasharray: "2.5 2.5", // Defines the dashed style
                    strokeWidth: 2,
                    fill: 'none',
                }}
            />
        );
    };

    return (
        <SimpleBox color={"tertiary"}>

            <Title order={3}>
                {t('ingredient.entityCategoryPairing')}
            </Title>
            <Space h="lg"/>
            <Space h="xs"/>

            <ResponsiveContainer width="100%" height={!isSm ? 270 : 240}>
                <ScatterChart
                    width={1000}
                    margin={{
                        top: 0,
                        right: !isSm ? 70 : 0,
                        bottom: 120,
                        left: !isSm ? -10 : -30,
                    }}>

                    <XAxis type="category" dataKey="category" name="Category"
                           interval={0}
                           tickLine={false}
                           tick={({x, y, payload}) => {
                               const isHovered = hoveredCategory === payload.value;
                               return (
                                   <>
                                       {!isHovered && ( // Render custom dashed tickLine if not hovered
                                           <line
                                               x1={x}
                                               y1={y - 8}
                                               x2={x}
                                               y2={y - 14}  // Adjust this to move the tickLine
                                               stroke="var(--mantine-color-tertiary-1)"
                                               strokeWidth={2}
                                           />
                                       )}
                                       <text
                                           x={x}
                                           y={y}
                                           dx={-10} // Adjust the horizontal position
                                           dy={10} // Adjust the vertical position
                                           textAnchor="end"
                                           fill={isHovered ? 'var(--mantine-color-tertiary-6)' : 'var(--mantine-color-tertiary-9)'}
                                           fontWeight={!isSm ? 700 : 400}
                                           transform={`rotate(-45, ${x}, ${y})`} // Ensure rotation around the correct point
                                           fontSize={!isSm ? 14 : 12}
                                       >
                                           {payload.value}
                                       </text>
                                   </>
                               );
                           }}
                           stroke="var(--mantine-color-tertiary-1)"
                           strokeWidth={2}
                           // strokeDasharray="2.5 2.5"
                           opacity={1}
                    />

                    <YAxis type="number" dataKey="index" domain={[0, 2]} width={80}
                        tick={false} tickLine={false} axisLine={false}
                        // stroke="var(--mantine-color-tertiary-9)"
                        // label={{ value: 'Category pairings', position: 'insideRight' }}
                    />

                    <ZAxis type="number" dataKey="sharedMolecules" range={[!isSm ? 10 : 4, !isSm ? 800 : 200]} />

                    <Tooltip cursor={<VerticalDashedLine />} wrapperStyle={{ zIndex: 100, transition: 'none' }} content={renderTooltip} />

                    <Scatter data={data} animationDuration={0}
                             onMouseEnter={(data, index) => setHoveredCategory(data.category)}  // Track hovered bubble
                             onMouseLeave={() => setHoveredCategory(null)}  // Reset on mouse leave
                             shape={({ cx, cy, size, ...rest }) => {
                                 const isHovered = hoveredCategory === rest.category;
                                 return <circle
                                     cx={cx}
                                     cy={cy}
                                     r={Math.sqrt(size)}  // Ensure the radius is proportional
                                     fill={isHovered ? 'var(--mantine-color-tertiary-6)' : 'var(--mantine-color-tertiary-9)'}
                                     // style={{cursor: 'pointer'}}
                                 />
                             }}

                    />

                </ScatterChart>
            </ResponsiveContainer>
        </SimpleBox>
    )
}

/**
 * EntityPairings
 *
 * @param ingredientName
 * @param ingredientNameEn
 * @param entity
 * @param entityCategoryPairings
 * @param entityPairings
 * @param totalCountEntityPairings
 * @param entityPairingRepresentativeIngredients
 * @returns {Element}
 * @constructor
 */
const EntityPairings = ({ingredientName, ingredientNameEn, entity, entityCategoryPairings, entityPairings, totalCountEntityPairings, entityPairingRepresentativeIngredients}) => {

    const { loginAccount } = useAccountContext();

    const [page, setPage] = useState(1);
    const [search, setSearch] = useState(" ");
    const [searchItems, setSearchItems] = useState([]);
    const [minCompatibility, setMinCompatibility] = useState(0);
    const [maxCompatibility, setMaxCompatibility] = useState(100);

    const { Features } = useFeatures();

    const navigate = useNavigate();
    const { t } = useTranslation();
    const theme = useMantineTheme();

    const { isSm } = useMedia();

    useEffect(() => {
        setSearch("");
    }, []);

    useEffect(() => {

        try {

            setSearchItems(entityPairings.filter(entityPairing => {
                const ingredient = getEntityRepresentativeIngredientFromIngredients(entityPairing, entityPairingRepresentativeIngredients);

                const includeSearch = search ? localized(ingredient, 'name').toLowerCase().includes(search.toLowerCase()) : true;

                return  includeSearch &&
                        entityPairing.sharedMoleculesPc >= minCompatibility &&
                        entityPairing.sharedMoleculesPc <= maxCompatibility;

            }));
        }
        catch(ignored) {
            // noop
        }

    }, [search, minCompatibility, maxCompatibility]);

    /**
     * @param value
     */
    const onSearch = (value) => {
        setSearch(value);
        setPage(1);
    }

    /**
     * @param minCompatibility
     * @param maxCompatibility
     */
    const onMinMaxCompatibility = (minCompatibility, maxCompatibility) => {
        setMinCompatibility(minCompatibility);
        setMaxCompatibility(maxCompatibility);
        setPage(1);
    }

    /**
     *
     * @param a
     * @param b
     * @returns {number}
     */
    function entityPairingsComparator(a, b) {

        // Inverted comparison
        const pcComparison = b.sharedMolecules - a.sharedMolecules;
        if (pcComparison !== 0) {
            return pcComparison;
        }

        return localized(getEntityRepresentativeIngredientFromIngredients(a, entityPairingRepresentativeIngredients), "name").localeCompare(localized(getEntityRepresentativeIngredientFromIngredients(b, entityPairingRepresentativeIngredients), "name"));
    }

    /**
     * PairingsCta
     */
    const PairingsCta = ({totalCount}) => {

        const {environment} = useEnvironmentContext();

        return (
                <SimpleBox color={"secondary"} highlightOnHover onClick={loginAccount}>
                    <Group justify={"space-between"} align={"center"} style={{cursor: "pointer"}}>
                        <Icon name={"circle-overlap"} className={classes.pairingsctaicon}/>
                        <Stack gap={0} flex={1}>
                            <Text size={"sm"} flex={1}>
                                {t("ingredient.accountRequiredForPairings", {totalCount: totalCount, trialDays: environment.paymentPremiumTrialDays})}
                            </Text>
                            {/*<Group justify="flex-end" pt={"md"} gap={"sm"}>*/}
                            {/*    /!*<FeaturesPlan targetComponent={<Button color={"secondary"} variant={"light"} leftSection={ <FontAwesomeIcon icon={faHandHoldingHeart} style={{width: "18px", height: "18px", fill: "var(--mantine-color-white)"}}/>}>{t("feature.checkPlans")}</Button>}/>*!/*/}
                            {/*    <Button color={"secondary"} variant={"light"} onClick={loginAccount}>{t("common.login")}</Button>*/}
                            {/*</Group>*/}
                        </Stack>
                    </Group>
                </SimpleBox>
        )
    }

    return (
        <Stack justify="space-between" gap={"0"}>
            <Stack justify="flex-start" gap="0">
                <VisuallyHidden>
                    <Title order={2} pt={"lg"}>{t('ingredient.entityPairing')}</Title>
                </VisuallyHidden>
                <Space h="lg"/>

                {!Features.ingredient.features.categoryPairing.plan.enabled || !entityCategoryPairings || entityCategoryPairings.length === 0 ? null :
                    <>
                        <EntityCategoryPairingsBubble entityCategoryPairings={entityCategoryPairings}/>
                        <Space h="lg"/>
                    </>
                }

                <SimpleBox color={"secondary"}>
                    <Title order={3}>
                        {t('ingredient.compatibilityRange')}
                    </Title>
                    <Space h="lg"/>
                    <RangeSlider color={"secondary"}
                                 minRange={20} step={20}
                                 label={null}
                                 classNames={{
                                     track: classes.rangeslidertrack,
                                     markLabel: classes.rangeslidermarklabel,
                                     mark: classes.rangeslidermark
                                 }}
                                 marks={compatibilityMarks}
                                 defaultValue={defaultCompatibilities(entityPairings, compatibilityMarks)}
                                 onChangeEnd={(value) => onMinMaxCompatibility(value[0], value[1])} />
                    <Space h="lg"/>
                </SimpleBox>

                <Space h="lg"/>

                <StripedTable highlightOnHover>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th p={0} colSpan={isSm ? 3 : 1}>
                                <SearchInput
                                    value={search}
                                    placeholder={t('common.ingredient')}
                                    onChange={(value) => onSearch(value)}
                                />
                            </Table.Th>
                            <Table.Th style={{textAlign: "right", verticalAlign: "top"}} visibleFrom={"sm"}>{t('ingredient.compatibility')}</Table.Th>
                            <Table.Th visibleFrom={"sm"}/>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>

                        {searchItems.length === 0 ? null :
                            <Space h={"lg"}/>
                        }

                        {searchItems
                            .sort(entityPairingsComparator)
                            .slice(paginationStartSkip(page, theme.custom.ingredient.paginationSize), paginationEndSkip(page, theme.custom.ingredient.paginationSize))
                            .map((item, index) => (
                                <Table.Tr key={`entityPairing-${index}`} style={{cursor: "pointer"}}>
                                    <Table.Td onClick={() => ingredientNavigate(navigate, getEntityRepresentativeIngredientFromIngredients(item, entityPairingRepresentativeIngredients))}>
                                        <Group justify="space-between" gap="xs">
                                            <IngredientText ingredient={getEntityRepresentativeIngredientFromIngredients(item, entityPairingRepresentativeIngredients)} ingredientHighlight={search}/>
                                        </Group>
                                    </Table.Td>

                                    {!Features.ingredient.features.unlimitedPairing.plan.enabled ?
                                        <Table.Td className={classes.moleculepairingstdinactive} w={"230px"} ta={"right"}>
                                            <Group align={"flex-start"} justify="flex-end" gap="xs">
                                                <Text>{item.sharedMoleculesPc > 0 ? `${item.sharedMoleculesPc}%` : '<1%'}</Text>
                                            </Group>
                                        </Table.Td>
                                        :
                                        <Table.Td className={classes.moleculepairingstd} w={"230px"}>
                                            <MoleculePairings ingredientName={ingredientName}
                                                              ingredientNameEn={ingredientNameEn}
                                                              entity={entity} pairedEntity={item}
                                                              entityPairingRepresentativeIngredients={entityPairingRepresentativeIngredients}
                                                              target={
                                                                    <Box>
                                                                        <Group justify="space-between" gap="xs">
                                                                            <Icon name={"circle-overlap"} style={{marginBottom: "-4px"}}/>
                                                                            <Stack align="flex-end" justify="flex-start" gap={0}>
                                                                                <Text>{item.sharedMoleculesPc > 0 ? `${item.sharedMoleculesPc}%` : '<1%'}</Text>
                                                                                <Text size={'xs'} ta={"right"} pb={3} style={{ wordBreak: "break-word", wordWrap: 'break-word', overflowWrap: "break-word", whiteSpace: 'normal' }}>
                                                                                    <NumberFormatter thousandSeparator="'" value={item.sharedMolecules} />&nbsp;
                                                                                    {t("ingredient.sharedMolecules")}
                                                                                </Text>
                                                                            </Stack>
                                                                        </Group>
                                                                    </Box>
                                                              }
                                            />
                                        </Table.Td>
                                    }

                                    <Table.Td w={"30px"} pt={9} style={{verticalAlign: "middle"}} onClick={() => ingredientNavigate(navigate, getEntityRepresentativeIngredientFromIngredients(item, entityPairingRepresentativeIngredients))}>
                                        <FontAwesomeIcon icon={faChevronRight}/>
                                    </Table.Td>
                                </Table.Tr>
                            ))}
                    </Table.Tbody>
                </StripedTable>
            </Stack>

            {!Features.ingredient.features.unlimitedPairing.plan.enabled &&
                <>
                    <Space h={"lg"}/>
                    <PairingsCta totalCount={totalCountEntityPairings}/>
                </>
            }

            <Space h={"lg"}/>
            <Paginator page={page} onPageChange={setPage} paginationSize={theme.custom.ingredient.paginationSize}
                       selectedCount={searchItems.length} totalCount={totalCountEntityPairings}/>

            <Space h="lg"/>
            <SimpleBox color="tertiary">
                <Text size={"sm"}>{t("ingredient.pairingDescription")}</Text>
            </SimpleBox>

            <FlavorDbCredits pl={"md"} pt={"md"} i18nKey={"common.flavordbAllAttribution"}/>
        </Stack>
    )
}

/**
 * MoleculePairings
 *
 * @param target
 * @param ingredientName
 * @param ingredientNameEn
 * @param entity
 * @param pairedEntity
 * @returns {Element}
 * @constructor
 */
const MoleculePairings = ({target, ingredientName, ingredientNameEn, entity, pairedEntity, entityPairingRepresentativeIngredients}) => {

    const navigate = useNavigate();
    const { t } = useTranslation();

    const [opened, { open, close }] = useDisclosure(false, {
        // onOpen: () => console.log('Opened'),
        // onClose: () => console.log('Closed')
    });

    const {key, stateKey} =
        useKey({
            onKeyChange: () => {
                console.debug(`MoleculePairings`);
            }
        });

    const {	data: dataItems, isLoaded: isLoadedItems,
        	reset: resetItems, refetch: refetchItems} =
        useMoleculeAll({
            andEntityIds: [entity.entityId, pairedEntity.entityId],
            enabled: opened,
        })

    const {isSuccess, isError} =
        useResult({
            isSuccess: isLoadedItems
        })

    return (
        <>
            <Drawer
                opened={opened}
                onClose={close}
                withCloseButton={false}
                title={
                    <Container classNames={{
                        root: classes.moleculepairingscontainertitleroot
                    }}>
                        <Title order={2} pt={"lg"}>{t('ingredient.entityPairing')}</Title>
                    </Container>
                }
                trapFocus={true}
                classNames={{
                    root: classes.moleculedrawerroot,
                    content: classes.moleculedrawercontent,
                    body: classes.moleculedrawerbody,
                    header: classes.moleculedrawerheader,
                    title: classes.moleculedrawertitle
                }}
                position={"bottom"}
                zIndex={402}
                shadow={"sm"}
                overlayProps={{color: "var(--mantine-color-primary-12)", backgroundOpacity: 0.75, blur: 7}}
                size={"85%"}
            >

                <Container classNames={{
                    root: classes.moleculepairingscontainerroot
                }}>

                    <Grid justify="space-between" align="stretch" overflow="hidden" grow>
                        <Grid.Col span={{ base: 12, xs: 12, sm: 4 }} style={{cursor: "pointer"}}>
                            <Center h={"100%"}>
                                <SimpleBox color={"secondary"} highlightOnHover flex={1} onClick={close}>
                                    <Group justify={"space-between"}>
                                        <FontAwesomeIcon icon={faChevronLeft}/>
                                        <IngredientTextByNameAndCategories ingredientName={ingredientName} ingredientNameEn={ingredientNameEn} entityCategories={sortedEntityCategories(entity)} align={"flex-end"}/>
                                    </Group>
                                </SimpleBox>
                            </Center>
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, xs: 12, sm: 4 }}>
                            <Center h={"100%"}>
                                <Text ta={"center"} c={"var(--mantine-color-secondary-9)"}>
                                    <Text>
                                        {t("ingredient.compatibleAt", {compatibility: pairedEntity.sharedMoleculesPc > 0 ? `${pairedEntity.sharedMoleculesPc}%` : '<1%'})}
                                    </Text>
                                    <Space h={"sm"}/>
                                    <Icon name={"circle-overlap"} className={classes.moleculedrawericon}/>
                                    <Space h={"sm"}/>
                                    <Text size={'xs'}>
                                        <NumberFormatter thousandSeparator="'" value={pairedEntity.sharedMolecules} />&nbsp;
                                        {t("ingredient.sharedMolecules")}
                                    </Text>
                                </Text>
                            </Center>
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, xs: 12, sm: 4 }} style={{cursor: "pointer"}}>
                            <Center h={"100%"}>
                                <SimpleBox color={"secondary"} highlightOnHover flex={1} onClick={() => ingredientNavigate(navigate, getEntityRepresentativeIngredientFromIngredients(pairedEntity, entityPairingRepresentativeIngredients))}>
                                    <Group justify={"space-between"}>
                                        <IngredientText ingredient={getEntityRepresentativeIngredientFromIngredients(pairedEntity, entityPairingRepresentativeIngredients)} color={"secondary.9"} favoriteColor={"secondary"}/>
                                        <FontAwesomeIcon icon={faChevronRight}/>
                                    </Group>
                                </SimpleBox>
                            </Center>
                        </Grid.Col>
                    </Grid>

                    <Space h="lg"/>

                    {!isSuccess ? null :
                        <Molecule09 molecules={dataItems}/>
                    }

                    <Space h="lg"/>
                    <Space h="lg"/>
                </Container>
            </Drawer>

            <Stack align="stretch"
                   justify="center"
                   gap={0}
                   // pt={7}
                   // pb={7}
                   pl={10}
                   pr={10}
                   // className={classes.moleculedrawertarget}
                   onClick={open} >
                {target}
            </Stack>
        </>
    );
};

/**
 * Nutrients
 *
 * @param nutrients
 * @constructor
 */
const Nutrients = ({nutrients}) => {

    const [page, setPage] = useState(1);
    const [search, setSearch] = useState(" ");
    const [searchItems, setSearchItems] = useState([]);

    const {t} = useTranslation();
    const theme = useMantineTheme();

    const { isSm } = useMedia();

    useEffect(() => {
        setSearch("");
    }, []);

    /**
     * rankedNutrients
     */
    const rankedNutrients = useMemo(() => {

        return nutrients.map(nutrient => {
            const category = NUTRIENT_CATEGORIES.find(cat =>
                cat.ranks.includes(nutrient.rank)
            );

            return {
                ...nutrient,
                nutrientCategoryId: category ? category.id : null,
                nutrientCategory: category ? t(`nutrientCategory.${category.id}`) : null
            };
        });
    }, [nutrients]);

    useEffect(() => {
        try {
            let items = [];

            const lower = search?.toLowerCase().trim() || "";

            if (!lower) {
                items = rankedNutrients; // clone
            }
            else {
                items = rankedNutrients.filter(nutrient => {
                    const name = localized(nutrient, 'name')?.toLowerCase() || "";
                    const category = nutrient.nutrientCategory?.toLowerCase() || "";
                    return name.includes(lower) || category.includes(lower);
                });
            }

            // Ordina per categoria ID, poi nutrient rank, poi nome
            items.sort((a, b) => {
                const catA = a.nutrientCategoryId ?? 999;
                const catB = b.nutrientCategoryId ?? 999;
                if (catA !== catB) return catA - catB;

                const rankA = a.rank ?? 999999;
                const rankB = b.rank ?? 999999;
                if (rankA !== rankB) return rankA - rankB;

                const nameA = localized(a, 'name')?.toLowerCase() || "";
                const nameB = localized(b, 'name')?.toLowerCase() || "";
                return nameA.localeCompare(nameB);
            });

            setSearchItems(items);
        }
        catch (e) {
            setSearchItems([]);
        }

    }, [search, rankedNutrients]);

    /**
     * pagedItems
     */
    const pagedItems = useMemo(() => {
        return searchItems.slice(paginationStartSkip(page, theme.custom.ingredient.paginationSize), paginationEndSkip(page, theme.custom.ingredient.paginationSize))
    }, [searchItems, page, theme.custom.ingredient.paginationSize]);

    /**
     * groupedByCategory
     */
    const groupedByCategory = useMemo(() => {
        return pagedItems.reduce((acc, nutrient) => {
            const catId = nutrient.nutrientCategoryId ?? 999;
            const catName = nutrient.nutrientCategory ?? "Other";

            if (!acc[catId]) {
                acc[catId] = {
                    categoryId: catId,
                    categoryName: catName,
                    items: []
                };
            }

            acc[catId].items.push(nutrient);
            return acc;
        }, {});
    }, [pagedItems]);

    /**
     * sortedCategories
     */
    const sortedCategories = useMemo(() => {
        return Object.values(groupedByCategory).sort((a, b) => {
            return a.categoryId - b.categoryId;
        });
    }, [groupedByCategory]);

    /**
     * @param value
     */
    const onSearch = (value) => {
        setSearch(value);
        setPage(1);
    }

    return (
        <Stack justify="space-between" gap={"0"}>
            <Stack justify="flex-start" gap="0">
                <VisuallyHidden>
                    <Title order={2} pt={"lg"}>{t('ingredient.nutrients')}</Title>
                </VisuallyHidden>
                <Space h="lg"/>
                <StripedTable striped={false} style={{color: "var(--mantine-color-tertiary-9)"}}>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th p={0} colSpan={isSm ? 2 : 1}>
                                <SearchInput
                                    value={search}
                                    placeholder={t('ingredient.nutrient')}
                                    onChange={(value) => onSearch(value)}
                                />
                            </Table.Th>
                            <Table.Th w={"260px"} style={{textAlign: "right", verticalAlign: "top"}} visibleFrom={"sm"}>
                                {`${t('common.amount')} (${NUTRIENT_DEFAULT_SERVING})`}
                            </Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Space h="lg"/>
                    <Table.Tbody>
                        {sortedCategories.map((group, index) => (
                            <React.Fragment key={`category-${group.categoryId}`}>
                                <Table.Tr bg={"var(--mantine-color-tertiary-light-hover)"}>
                                    <Table.Td colSpan={2}>
                                        <Text>
                                            <Highlight highlight={search} /*fw={700}*/>
                                                {group.categoryName}
                                            </Highlight>
                                        </Text>
                                    </Table.Td>
                                </Table.Tr>

                                {group.items.map((item, idx) => (
                                    <Table.Tr key={`nutrient-${index}-${idx}`} style={{backgroundColor: idx % 2 === 1 ? "var(--mantine-color-tertiary-outline-hover)" : "transparent"}}>
                                        <Table.Td pl={!isSm ? "calc(var(--mantine-spacing-xl) * 1)" : "xs"}>
                                            <Text>
                                                <Highlight highlight={search} >
                                                    {localized(item, 'name')}
                                                </Highlight>
                                            </Text>
                                        </Table.Td>
                                        <Table.Td ta={"right"}>
                                            <Stack align="flex-end" justify="flex-start" gap={0}>
                                                <Text>{`${item.amount}${item.unit}`}</Text>
                                                {item.serving !== NUTRIENT_DEFAULT_SERVING &&
                                                    <Text size={'xs'} ta={"right"} pb={3}>
                                                        {t("ingredient.perServing", { serving: item.serving })}
                                                    </Text>
                                                }
                                            </Stack>
                                        </Table.Td>
                                    </Table.Tr>
                                ))}
                            </React.Fragment>
                        ))}
                    </Table.Tbody>
                </StripedTable>
            </Stack>
            <Space h={"lg"}/>
            <Paginator page={page} onPageChange={setPage} paginationSize={theme.custom.ingredient.paginationSize}
                       selectedCount={searchItems.length} totalCount={nutrients.length}/>
            <FoodDataCentralCredits pl={"md"} pt={"md"} i18nKey={"common.foodDataCentralAttribution"}/>
        </Stack>
    )
}

/**
 * TechniqueLevels
 *
 * @param techniqueLevels
 * @constructor
 */
const TechniqueLevels = ({techniqueLevels}) => {

    const [page, setPage] = useState(1);
    const [search, setSearch] = useState(" ");
    const [searchItems, setSearchItems] = useState([]);

    const {t} = useTranslation();
    const theme = useMantineTheme();

    const { isSm } = useMedia();

    useEffect(() => {
        setSearch("");
    }, []);

    useEffect(() => {
        try {
            let items = [];

            if (!search || search.trim() === "") {
                items = [...techniqueLevels];  // clona l'array per non mutare il prop
            }
            else {
                const lower = search.toLowerCase();
                items = techniqueLevels.filter((tl) => {
                    const techniqueName = localized(tl.technique, "name")?.toLowerCase() || "";
                    const cookingLevelName = localized(tl.cookingLevel || {}, "name")?.toLowerCase() || "";
                    return techniqueName.includes(lower) || cookingLevelName.includes(lower);
                });
            }
            // Ordina i risultati in base al nome localizzato della tecnica (in ordine alfabetico)
            items.sort((a, b) => {
                const nameA = localized(a.technique, "name")?.toLowerCase() || "";
                const nameB = localized(b.technique, "name")?.toLowerCase() || "";
                return nameA.localeCompare(nameB);
            });
            setSearchItems(items);
        }
        catch (e) {
            setSearchItems([]);
        }
    }, [search, techniqueLevels]);

    // Paginate on the flat list of levels (searchItems)
    const pagedItems = useMemo(() => {
        return searchItems.slice(paginationStartSkip(page, theme.custom.ingredient.paginationSize), paginationEndSkip(page, theme.custom.ingredient.paginationSize))
    }, [searchItems, page, theme.custom.ingredient.paginationSize]);

    /**
     * groupedByTechnique
     */
    const groupedByTechnique = useMemo(() => {
        return pagedItems.reduce((acc, tl) => {
            const id = tl.technique?.techniqueId;
            if (!id) return acc;
            if (!acc[id]) acc[id] = { technique: tl.technique, levels: [] };
            acc[id].levels.push(tl);
            return acc;
        }, {});
    }, [pagedItems]);

    // Sort techniques by name (alphabetically)
    const sortedTechniques = useMemo(() => {
        return Object.values(groupedByTechnique).sort((a, b) => {
            const nameA = localized(a.technique, "name")?.toLowerCase() || "";
            const nameB = localized(b.technique, "name")?.toLowerCase() || "";
            return nameA.localeCompare(nameB);
        });
    }, [groupedByTechnique]);

    /**
     * @param value
     */
    const onSearch = (value) => {
        setSearch(value);
        setPage(1);
    }

    return (
        <Stack justify="space-between" gap={"0"}>
            <Stack justify="flex-start" gap="0">
                <VisuallyHidden>
                    <Title order={2} pt={"lg"}>{t('ingredient.cookingTechniquesAndLevels')}</Title>
                </VisuallyHidden>
                <Space h="lg"/>
                <SearchInput
                    value={search}
                    placeholder={t('ingredient.cookingTechniquesAndLevels')}
                    onChange={(value) => onSearch(value)}
                />
                <Space h="lg"/>
                <StripedTable striped={false} style={{color: "var(--mantine-color-tertiary-9)"}}>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th/>
                            <Table.Th w={"10%"}/>
                            <Table.Th w={"10%"} ta={"center"}>
                                {/*<Tooltip label={t("common.time")}>*/}
                                    <FontAwesomeIcon icon={faClock} size={"lg"}/>
                                {/*</Tooltip>*/}
                            </Table.Th>
                            <Table.Th w={"10%"} ta={"center"}>
                                {/*<Tooltip label={t("common.time")}>*/}
                                    <FontAwesomeIcon icon={faTemperatureLow} size={"lg"}/>
                                {/*</Tooltip>*/}
                            </Table.Th>
                            <Table.Th w={"10%"} ta={"center"}>
                                {/*<Tooltip label={t("common.thickness")}>*/}
                                    <FontAwesomeIcon icon={faArrowsLeftRightToLine} size={"lg"} rotation={90}/>
                                {/*</Tooltip>*/}
                            </Table.Th>
                            <Table.Th w={"10%"} ta={"center"}>
                                {/*<Tooltip label={t("common.weioht")}>*/}
                                    <FontAwesomeIcon icon={faScaleUnbalancedFlip} size={"lg"}/>
                                {/*</Tooltip>*/}
                            </Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Space h="lg"/>
                    <Table.Tbody>
                        {sortedTechniques.map((group, index) => (
                            <React.Fragment key={`technique-${group.technique.techniqueId}`}>
                                <Table.Tr bg={"var(--mantine-color-tertiary-light-hover)"}>
                                    <Table.Td colSpan={6}>
                                        <Text>
                                            <Highlight highlight={search} /*fw={700}*/>
                                                {localized(group.technique, 'name') || ""}
                                            </Highlight>
                                        </Text>
                                        {group.technique.descriptionEn && (
                                            <Text size="xs" opacity={0.75}>{localized(group.technique, 'description')}</Text>
                                        )}
                                    </Table.Td>
                                </Table.Tr>

                                {group.levels
                                    .sort((a, b) => a.ingredientTechniqueLevelSort - b.ingredientTechniqueLevelSort)
                                    .map((item, idx) => (
                                        <Table.Tr key={`technique-level-${index}-${idx}`} style={{backgroundColor: idx % 2 === 1 ? "var(--mantine-color-tertiary-outline-hover)" : "transparent"}}>
                                            <Table.Td pl={!isSm ? "calc(var(--mantine-spacing-xl) * 1)" : "xs"}>
                                                <Text>
                                                    <Highlight highlight={search}>
                                                        {localized(item.cookingLevel, 'name') || ""}
                                                    </Highlight>
                                                </Text>
                                            </Table.Td>
                                            <Table.Td>
                                                {(item.minTime && item.maxTime) || (item.minThickness && item.maxThickness) || (item.minWeight && item.maxWeight) ? (
                                                    <Stack gap={3}>
                                                        <Text size="xs">{t("common.min")}</Text>
                                                        <Text size="xs">{t("common.max")}</Text>
                                                    </Stack>
                                                ) : null}
                                            </Table.Td>
                                            <Table.Td ta="center">
                                                {(item.minTime || item.maxTime) && (
                                                    <Stack gap={0}>
                                                        <Text size="sm">{formatIsoDuration(item.minTime)}</Text>
                                                        <Text size="sm">{formatIsoDuration(item.maxTime)}</Text>
                                                    </Stack>
                                                )}
                                            </Table.Td>
                                            <Table.Td ta="center">
                                                <Text size="sm">{formatTemperature(item.temperature)}</Text>
                                            </Table.Td>
                                            <Table.Td ta="center">
                                                {(item.minThickness || item.maxThickness) && (
                                                    <Stack gap={0}>
                                                        <Text size="sm">{convertMeasure(item.minThickness, "mm")}</Text>
                                                        <Text size="sm">{convertMeasure(item.maxThickness, "mm")}</Text>
                                                    </Stack>
                                                )}
                                            </Table.Td>
                                            <Table.Td ta="center">
                                                {(item.minWeight || item.maxWeight) && (
                                                    <Stack gap={0}>
                                                        <Text size="sm">{convertMeasure(item.minWeight, "g")}</Text>
                                                        <Text size="sm">{convertMeasure(item.maxWeight, "g")}</Text>
                                                    </Stack>
                                                )}
                                            </Table.Td>
                                        </Table.Tr>
                                    ))}
                            </React.Fragment>
                        ))}
                    </Table.Tbody>

                </StripedTable>
            </Stack>
            <Space h={"lg"}/>
            <Paginator page={page} onPageChange={setPage} paginationSize={theme.custom.ingredient.paginationSize}
                       selectedCount={searchItems.length} totalCount={techniqueLevels.length}/>
        </Stack>
    )
}

/**
 * IngredientPage
 *
 * @param props
 * @returns {React.JSX.Element|string[]|string|*[]|undefined|*|string}
 * @constructor
 */
const IngredientPage = (props) => {

    const {paramLng} = useParams();
    const lng = parameterLanguage(paramLng);

    const {paramIngredientId, paramIngredientName, paramEntityId} = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const ingredientId = location.state?.ingredientId || paramIngredientId;
    const ingredientName = props.isIngredientName ? paramIngredientName : undefined;
    const entityId = props.isEntity ? paramEntityId : undefined;

    const { Features } = useFeatures();

    const [autoplayActivable, setAutoplayActivable] = useState(true);

    const [pageRecipes, setPageRecipes] = useState(1);
    const [previousTotalCountRecipes, setPreviousTotalCountRecipes] = useState(0);

    const [wiki, setWiki] = useState(undefined);

    const {loaded, isLoading, isLoaded} = useLoadingContext();

    const [tabValue, setTabValue] = useState(undefined);
    const { t } = useTranslation();

    const {key, stateKey, random} =
        useKey({
            isLoading: isLoading,
            onKeyChange: () => {
                console.debug(`IngredientPage`);

                setPageRecipes(1);
                setPreviousTotalCountRecipes(0);

                setWiki(undefined);

                resetNaturalSourceEntityWiki();
                resetEntityIngredientNaturalSources();
                resetNaturalSourceEntities();
                resetEntityPairingRepresentativeIngredients();
                resetEntityPairings();
                resetEntityCategoryPairings();
                resetEntityIngredientSynonyms();
                resetEntityWiki();
                resetEntity();
                resetRecipes();

                refetchItem();
            }
        });

    useEffect(() => {
        if(lng !== undefined && lng !== i18n.language) {
            i18n.changeLanguage(lng)
                .then(value => window.location.reload());
        }
    }, [lng]);

    /**
     * Ingredient
     */
    const {	data: dataItem, isLoaded: isLoadedItem, ingredientType, ingredientEntityId, naturalSourceEntityIds,
        	reset: resetItem, refetch: refetchItem} =
        useIngredient({
            ingredientId: ingredientId,
            ingredientName: ingredientName,
            entityId: entityId,
            excludeProjections: [!Features.ingredient.features.techniqueLevels.plan.enabled ? "techniqueLevels" : undefined],
            incrementViews: true,
            onError: (error) => {
                homeNavigate(navigate);
            }
        })

    const {	data: dataInsights, isLoaded: isLoadedInsights,
        reset: resetInsights, refetch: refetchInsights} =
        useInsights({
            enabled: isLoadedItem,
            collectionName: "ingredient",
            id: dataItem.name
        })

    const isEnabledRecipes = isLoadedItem;
    const {	data: dataRecipes, isLoaded: isLoadedRecipes, totalCount: totalCountRecipes,
        reset: resetRecipes, refetch: refetchRecipes} =
        useRecipeAll({
            enabled: isEnabledRecipes,
            page: pageRecipes,
            ingredientIds: [dataItem.ingredientId],
            onSuccess: (data, totalCount, count) => {
                setPreviousTotalCountRecipes(totalCount);
            }
        })

    useEffect(() => {
        refetchRecipes();
    }, [pageRecipes]);

    /**
     * Entity
     */
    const isEnabledEntity = isLoadedItem && ingredientEntityId !== undefined;
    const {	data: dataEntity, isLoaded: isLoadedEntity, naturalSourceIngredient ,
        	reset: resetEntity, refetch: refetchEntity} =
        useEntity({
            entityId: ingredientEntityId,
            excludeProjections: [!Features.ingredient.features.molecules.plan.enabled ? "molecules" : undefined, !Features.ingredient.features.nutrients.plan.enabled ? "nutrients" : undefined],
            enabled: isEnabledEntity
        })

    /**
     * @param items
     * @param key
     * @returns {unknown[]}
     */
    function getEntityIngredientSynonymIds(dataEntity, dataItem)  {

        let result = [];

        if(!dataEntity.ingredients) {
            return result;
        }

        dataEntity.ingredients
            .sort((a, b) => a.ingredientId - b.ingredientId)
            .forEach(ingredient => {

                if(ingredient.ingredientId !== dataItem.ingredientId && /*ingredient.representative === 0 && */ingredient.naturalSourceName === 0) {

                    result.push(ingredient.ingredientId);
                }
            });

        return result;
    }

    /**
     * Entity Ingredient Synonyms
     */
    const isEnabledEntityIngredientSynonyms = isLoadedEntity && dataEntity?.ingredients.length > 0 && getEntityIngredientSynonymIds(dataEntity, dataItem).length > 0;
    const {	data: dataEntityIngredientSynonyms, isLoaded: isLoadedEntityIngredientSynonyms,
        reset: resetEntityIngredientSynonyms, refetch: refetchEntityIngredientSynonyms} =
        useIngredientAll({
            ingredientIds: getEntityIngredientSynonymIds(dataEntity, dataItem),
            enabled: isEnabledEntityIngredientSynonyms
        })

    /**
     * Fetch Entity Data based on Item Entity ID
     */
    const entityIdFromEntity = dataEntity.entityId;

    /**
     * Wiki
     */
    const isEnabledEntityWiki = isLoadedEntity && dataEntity?.links.length > 0 && localizedLink(dataEntity?.links, WIKI_LINK_NAME) !== undefined;
    const { data: dataEntityWiki, isLoaded: isLoadedEntityWiki,
            reset: resetEntityWiki, refetch: refetchEntityWiki} =
        useWiki({
            url: localizedLink(dataEntity?.links, WIKI_LINK_NAME),
            enabled: isEnabledEntityWiki
        })

    /**
     * Entity Category Pairings
     */
    const isEnabledEntityCategoryPairings = Features.ingredient.features.categoryPairing.plan.enabled && isLoadedEntity && entityIdFromEntity !== undefined;
    const { data: dataEntityCategoryPairings, isLoaded: isLoadedEntityCategoryPairings,
            reset: resetEntityCategoryPairings, refetch: refetchEntityCategoryPairings} =
        useEntityCategoryPairingAll({
            entityId: entityIdFromEntity,
            enabled: isEnabledEntityCategoryPairings
        })

    /**
     * Entity Pairings
     */
    const isEnabledEntityPairings = isLoadedEntity && entityIdFromEntity !== undefined;
    const { data: dataEntityPairings, isLoaded: isLoadedEntityPairings, totalCount: totalCountEntityPairings,
            reset: resetEntityPairings, refetch: refetchEntityPairings} =
        useEntityPairingAll({
            entityId: entityIdFromEntity,
            enabled: isEnabledEntityPairings,
            random: Features.ingredient.features.limitedPairing.plan.enabled ? Features.ingredient.features.limitedPairing.limit : undefined
        })

    /**
     * @param entity
     * @returns {unknown[]}
     */
    function extractEntityPairingRepresentativeIngredientIds(entityPairings)  {

        let result = [];

        if (entityPairings === undefined || entityPairings.length === 0) {
            return result;
        }

        for(let entityPairing of entityPairings) {
            for (let ingredient of entityPairing.ingredients) {
                if (ingredient.representative) {
                    result.push(ingredient.ingredientId);
                }
            }
        }

        return result;
    }

    /**
     * Entity Pairing Representative Ingredient
     */
    const isEnabledEntityPairingRepresentativeIngredients = isLoadedEntityPairings && dataEntityPairings?.length > 0 && extractEntityPairingRepresentativeIngredientIds(dataEntityPairings).length > 0;
    const {	data: dataEntityPairingRepresentativeIngredients, isLoaded: isLoadedEntityPairingRepresentativeIngredients,
        reset: resetEntityPairingRepresentativeIngredients, refetch: refetchEntityPairingRepresentativeIngredients} =
        useIngredientAll({
            ingredientIds: extractEntityPairingRepresentativeIngredientIds(dataEntityPairings),
            enabled: isEnabledEntityPairingRepresentativeIngredients
        })

    /**
     * NaturalSourceEntities
     */
    const isEnabledNaturalSourceEntities = isLoadedItem && naturalSourceEntityIds !== undefined && naturalSourceEntityIds?.length > 0;
    const { data: dataNaturalSourceEntities, isLoaded: isLoadedNaturalSourceEntities,
            reset: resetNaturalSourceEntities, refetch: refetchNaturalSourceEntities} =
        useEntityAll({
            entityIds: naturalSourceEntityIds,
            enabled: isEnabledNaturalSourceEntities
        })

    /**
     * Entity Ingredient Natural Sources
     */
    const isEnabledEntityIngredientNaturalSources = Features.ingredient.features.naturalSources.plan.enabled && isLoadedNaturalSourceEntities && dataNaturalSourceEntities?.length > 0 && extractIngredientIds(dataNaturalSourceEntities, dataItem).length > 0;
    const {	data: dataEntityIngredientNaturalSources, isLoaded: isLoadedEntityIngredientNaturalSources,
        reset: resetEntityIngredientNaturalSources, refetch: refetchEntityIngredientNaturalSources} =
        useIngredientAll({
            ingredientIds: extractIngredientIds(dataNaturalSourceEntities, dataItem),
            enabled: isEnabledEntityIngredientNaturalSources
        })

    /**
     * NaturalSourceEntityWiki
     */
    const isEnabledNaturalSourceEntityWiki = Features.ingredient.features.naturalSources.plan.enabled && isLoadedNaturalSourceEntities && dataNaturalSourceEntities !== undefined && dataNaturalSourceEntities.length > 0 && dataNaturalSourceEntities[0] !== undefined && localizedLink(dataNaturalSourceEntities[0]?.links, WIKI_NATURAL_SOURCE_LINK_NAME) !== undefined;
    const { data: dataNaturalSourceEntityWiki, isLoaded: isLoadedNaturalSourceEntityWiki,
            reset: resetNaturalSourceEntityWiki, refetch: refetchNaturalSourceEntityWiki} =
        useWiki({
            url: localizedLink(dataNaturalSourceEntities[0]?.links, WIKI_NATURAL_SOURCE_LINK_NAME),
            enabled: isEnabledNaturalSourceEntityWiki
        })

    useEffect(() => {

        // Handle when ingredientType is NaturalSource and entity wikis are loaded/enabled
        if (ingredientType === IngredientTypes.NaturalSource) {

            // Check if the wiki data for NaturalSource is enabled and loaded
            if (isEnabledNaturalSourceEntityWiki && isLoadedNaturalSourceEntityWiki) {
                setWiki(dataNaturalSourceEntityWiki); // Set the wiki for NaturalSource
            }
        }

        // Check if the wiki data for the entity is enabled and loaded
        if (isEnabledEntityWiki && isLoadedEntityWiki) {
            setWiki(dataEntityWiki); // Set the wiki for Entity
        }

    }, [ingredientType, isEnabledNaturalSourceEntityWiki, isLoadedNaturalSourceEntityWiki, isEnabledEntityWiki, isLoadedEntityWiki]);

    const {isSuccess, isError, isOk} =
        useResult({
            isLoaded: isLoaded,
            isSuccess: (
                isLoadedItem &&
                // (isEnabledRecipes ? isLoadedRecipes : true) &&
                (isEnabledEntity ? isLoadedEntity : true) &&
                (isEnabledEntityIngredientSynonyms ? isLoadedEntityIngredientSynonyms : true) &&
                (isEnabledEntityWiki ? isLoadedEntityWiki : true) &&
                (isEnabledEntityCategoryPairings ? isLoadedEntityCategoryPairings : true) &&
                (isEnabledEntityPairings ? isLoadedEntityPairings : true) &&
                (isEnabledEntityPairingRepresentativeIngredients ? isLoadedEntityPairingRepresentativeIngredients : true) &&
                (isEnabledNaturalSourceEntities ? isLoadedNaturalSourceEntities : true) &&
                (isEnabledEntityIngredientNaturalSources ? isLoadedEntityIngredientNaturalSources : true) &&
                (isEnabledNaturalSourceEntityWiki ? isLoadedNaturalSourceEntityWiki : true)
            )
        })

    useEffect(() => {

        if(dataEntityCategoryPairings?.length > 0 || dataEntityPairings?.length > 0) {
            setTabValue("0");
        }
        // else if(entityCategoryPairings?.length > 0) {
        //     setTabValue("1");
        // }
        else if(dataEntity.nutrients?.length > 0) {
            setTabValue("1");
        }
        else if(dataItem.techniqueLevels?.length > 0) {
            setTabValue("2");
        }
        else if(dataEntityIngredientNaturalSources?.length > 0) {
            setTabValue("3");
        }
        else if(dataEntityIngredientSynonyms?.length > 0) {
            setTabValue("4");
        }
        else if(dataEntity.molecules?.length > 0) {
            setTabValue("5");
        }

    }, [dataEntityCategoryPairings, dataEntityPairings, dataEntity, dataItem, dataEntityIngredientNaturalSources, dataEntityIngredientSynonyms]);

    /**
     * Description
     */
    const Description = () => {

        let languageEnglishDescriptionsEnabled = useLanguageStore((state) => state.languageEnglishDescriptionsEnabled);

        let wikiDescription = "";
        let naturalSourceCategoriesDescription = "";

        // Wiki description
        if((languageEnglishDescriptionsEnabled || i18n.language === "en") && wiki && wiki.extract) {
            wikiDescription = wiki.extract;
        }

        // Natural source categories
        if(dataNaturalSourceEntities?.length > 0) {

            let categories = dataNaturalSourceEntities.map((entity) => entity.categories).flat();

            let result = categories
                .filter((category, index, self) =>
                    index === self.findIndex((t) => t.entityCategoryId === category.entityCategoryId)
                )
                .sort((a, b) => alphabeticComparator(localized(a, "name"), localized(b, "name")))
                .map((category, index) => localized(category, 'name')).join(", ")

            naturalSourceCategoriesDescription = t("ingredient.naturalSourceOfIngredientsInCategories", {categories: result});
        }

        return (
            <Stack gap={"xs"}>
                {wikiDescription.length === 0 ? null :
                    <>
                        <Text size={"sm"} lineClamp={5}>{wikiDescription}</Text>
                        <WikipediaCredits i18nKey={"common.wikiAttribution"} wikiUrl={wiki.url} textColor={"white"} linkColor={"secondary"}/>
                    </>
                }
                {naturalSourceCategoriesDescription.length === 0 ? null :
                    <Text size={"sm"}>{naturalSourceCategoriesDescription}</Text>
                }
                {dataEntity?.seasons?.length > 0 &&
                    <Stack align="flex-start" justify="flex-start" gap={0}>
                        <Text size={"sm"} fw={700} >{t("recipe.season")}</Text>
                        <Text size={"sm"} lineClamp={1}>
                            {dataEntity?.seasons?.sort(seasonSortComparator)
                                .map(season => t(`recipe.seasonType.${season}`)).join(", ")}
                        </Text>
                    </Stack>
                }
            </Stack>
        )
    }

    /**
     * SubTitleComponent
     */
    const SubTitleComponent = () => {

        return (
            <Group grow className={classes.subcomponentroot}>
                {Features.ingredient.features.naturalSources.plan.enabled && naturalSourceIngredient && naturalSourceIngredient.ingredientId !== dataItem.ingredientId &&
                    <Group justify="space-between" className={classes.subcomponentbutton} wrap={"nowrap"}
                           onClick={() => ingredientNavigate(navigate, naturalSourceIngredient)}>
                        <Stack align="flex-start" justify="center" gap={0}>
                            <Text size={"sm"} fw={700}>{t('ingredient.naturalSource')}</Text>
                            <IngredientText ingredient={naturalSourceIngredient} favoriteColor={"white"} color={"white"} badgeVariant={"filled"}/>
                        </Stack>
                        <FontAwesomeIcon icon={faChevronRight}/>
                    </Group>
                }
                {localizedLink([...(dataItem?.links || []), ...(dataEntity?.links || [])], FOOD_FACTS_VIDEO_LINK_NAME) !== undefined &&
                    <Group justify="space-between" className={classes.subcomponentbutton} wrap={"nowrap"}
                           onClick={() =>  window.open(localizedLink([...(dataItem?.links || []), ...(dataEntity?.links || [])], FOOD_FACTS_VIDEO_LINK_NAME), "_blank")}>
                        <Icon name={"food-facts"} style={{width: "100px", height: "100%", fill: "var(--mantine-color-white)", padding: 0, marginBottom: "-6px"}}/>
                        <FontAwesomeIcon icon={faUpRightFromSquare}/>
                    </Group>
                }
            </Group>
        )
    }

    /**
     * WikiSection
     */
    const WikiSection = () => {

        /**
         * WikiSection
         */
        const WikiIcon = () => {

            return (
                <Box className={classes.image169withzoomicon}>
                    <Center>
                        <FontAwesomeIcon icon={faWikipediaW} size={"lg"}/>
                    </Center>
                </Box>
            )
        }

        if(wiki === undefined) {
            return null;
        }

        if(wiki.url !== undefined && wiki.url.length > 0) {
            return <Anchor href={wiki.url} target={"_blank"} underline="never" className={classes.image169withzoomanchor} >
                        {wiki.image !== undefined && wiki.image.length > 0 ?
                            <Image169WithZoom src={wiki.image} rightIcon={<WikiIcon/>} withPointer withFullscreen={false}/>
                            :
                            <Image169WithZoom rightIcon={<WikiIcon/>} withPointer withFullscreen={false}/>
                        }
                    </Anchor>
        }

        if(wiki.image !== undefined && wiki.image.length > 0) {
            return <Image169WithZoom src={wiki.image} withZoom={false} withFullscreen={false}/>
        }

        return null;
    }

    /**
     * IngredientCard
     */
    const IngredientCard = () => {

        return (

            <StripedTable stripedColor={"tertiary"} className={classes.ingredientcardtable}>
                <Table.Tbody>
                    {!dataEntityPairings || dataEntityPairings.length === 0 || !dataEntityCategoryPairings || dataEntityCategoryPairings.length === 0 || !dataEntityPairingRepresentativeIngredients || dataEntityPairingRepresentativeIngredients.length === 0 ? null :
                        <Table.Tr>
                            <Table.Td pl={0}>
                                <IngredientPairingIconTitle />
                            </Table.Td>
                            <Table.Td pr={0} ta={"right"}>
                                <Text>
                                    {totalCountEntityPairings}
                                </Text>
                            </Table.Td>
                        </Table.Tr>
                    }
                    {!dataEntityCategoryPairings || dataEntityCategoryPairings.length === 0 ? null :
                        <Table.Tr>
                            <Table.Td pl={0}>
                                <IngredientPairingIconTitle textKey={'ingredient.entityCategoryPairing'}/>
                            </Table.Td>
                            <Table.Td pr={0} ta={"right"}>
                                <Text>
                                    {dataEntityCategoryPairings.length}
                                </Text>
                            </Table.Td>
                        </Table.Tr>
                    }
                    {!dataEntity.nutrients || dataEntity.nutrients.length === 0 ? null :
                        <Table.Tr>
                            <Table.Td pl={0}>
                                <Text size={"sm"} fw={700}>
                                    {t('ingredient.nutrients')}
                                </Text>
                           </Table.Td>
                            <Table.Td pr={0} ta={"right"}>
                                <Text>
                                    {dataEntity.nutrients.length}
                                </Text>
                            </Table.Td>
                        </Table.Tr>
                    }
                    {!dataItem.techniqueLevels || dataItem.techniqueLevels.length === 0 ? null :
                        <Table.Tr>
                            <Table.Td pl={0}>
                                <Text size={"sm"} fw={700}>
                                    {t('ingredient.cookingTechniquesAndLevels')}
                                </Text>
                            </Table.Td>
                            <Table.Td pr={0} ta={"right"}>
                                <Text>
                                    {dataItem.techniqueLevels.length}
                                </Text>
                            </Table.Td>
                        </Table.Tr>
                    }
                    {dataEntityIngredientNaturalSources?.length === 0 ? null :
                        <Table.Tr>
                            <Table.Td pl={0}>
                                <IngredientIconTitle textKey={'ingredient.naturalSourceOf'}/>
                            </Table.Td>
                            <Table.Td pr={0} ta={"right"}>
                                <Text>
                                    {dataEntityIngredientNaturalSources?.length}
                                </Text>
                            </Table.Td>
                        </Table.Tr>
                    }
                    {dataEntityIngredientSynonyms?.length === 0 ? null :
                        <Table.Tr>
                            <Table.Td pl={0}>
                                <IngredientIconTitle textKey={'ingredient.showSynonyms'}/>
                            </Table.Td>
                            <Table.Td pr={0} ta={"right"}>
                                <Text>
                                    {dataEntityIngredientSynonyms?.length}
                                </Text>
                            </Table.Td>
                        </Table.Tr>
                    }
                    {!dataEntity.molecules || dataEntity.molecules.length === 0 ? null :
                        <Table.Tr>
                            <Table.Td pl={0}>
                                <MoleculeIconTitle />
                            </Table.Td>
                            <Table.Td pr={0} ta={"right"}>
                                <Text>
                                    {dataEntity.molecules.length}
                                </Text>
                            </Table.Td>
                        </Table.Tr>
                    }
                    {previousTotalCountRecipes === 0 ? null :
                        <Table.Tr>
                            <Table.Td pl={0}>
                                <RecipeIconTitle textKey={'ingredient.showRecipes'}/>
                            </Table.Td>
                            <Table.Td pr={0} ta={"right"}>
                                <Text>
                                    {previousTotalCountRecipes}
                                </Text>
                            </Table.Td>
                        </Table.Tr>
                    }
                </Table.Tbody>
            </StripedTable>
        );
    }

    /**
     * DisplayTitle
     */
    const DisplayTitle = ({ingredient}) => {

        const { isAuthenticated, userAccount, updateUserAccountSettings } = useAccountContext();
        const [settings] = useState(isAuthenticated ? userAccount?.getSettings() : new Settings({}));
        const [favorite, setFavorite] = useState(settings.isFavoriteIngredient(ingredient.name));

        /**
         * handleFavorite
         */
        function handleFavorite() {
            settings.setFavoriteIngredient(ingredient.name);
            updateUserAccountSettings(userAccount);
            setFavorite(settings.isFavoriteIngredient(ingredient.name));
        }

        return <Group align={"flex-start"} gap={6} wrap={"nowrap"}>
                {Features.account.features.ingredientFavorites.plan.enabled &&
                    <FontAwesomeIcon icon={settings.isFavoriteIngredient(ingredient.name) ? faHeartSolid : faHeart} size={"md"} color={"white"} opacity={0.25}
                                     style={{cursor: "pointer", paddingTop: "8px", height: "38px"}}
                                     onClick={(event) => {event.stopPropagation(); event.preventDefault(); handleFavorite()}}/>
                }
                    <Stack gap={0} align="flex-start" justify="flex-start">
                        <Text lineClamp={2} inherit>
                            {localized(ingredient, 'name')}
                        </Text>
                        <Text size={"xs"} pl={2} pt={"xs"} ff={theme.fontFamily}><NumberFormatter thousandSeparator="'" value={dataInsights.views}/> {t("common.views")}</Text>
                    </Stack>
                    <TooltipEn label={localized(ingredient, 'name')} labelEn={localizedEn(ingredient, 'name')}/>
                </Group>
    }

    return (!isSuccess ? null :

        <Page
            pageTitle={localized(dataItem, 'name')}
            pageDescription={wiki && wiki.extract}
            pageKeywords={`ingredient,${dataItem.name},${localized(dataItem, 'name')}`}
            pageUrl={`/ingredient/name/${dataItem.name}`}
            pageImage={wiki && wiki.image}
            pageSchema={
                {
                    "@context": "https://schema.org",
                    "@type": "Thing",
                    "name": `${localized(dataItem, 'name')}`,
                    "description": `${wiki && wiki.extract}`,
                    "image": `${wiki && wiki.image}`
                }
            }
            hasTab
            pageTitles={[
                <PageTitle
                    displayTitle={<DisplayTitle ingredient={dataItem} />}
                    breadcrumb={sortedEntityCategories(dataEntity).map((category, index) => (
                        <PageTitle.Breadcrumb type={"a"}>
                            <EntityCategoryLink entityCategory={category}/>
                        </PageTitle.Breadcrumb>
                    ))
                    }
                    withDescription
                    descriptionComponent={<Description/>}
                    withSubTitle
                    subTitleComponent={<SubTitleComponent/>}
                    withCard
                    cardSection = {<CustomCarousel autoplayActivable={autoplayActivable} color={"tertiary"}>
                                    <CustomCarousel.Slide>
                                        <WikiSection/>
                                    </CustomCarousel.Slide>
                                    {localizedLink([...(dataItem?.links || []), ...(dataEntity?.links || [])], FOOD_FACTS_VIDEO_ID_LINK_NAME) === undefined ? null :
                                        <CustomCarousel.Slide>
                                            <YouTubeEmbed videoId={localizedLink([...(dataItem?.links || []), ...(dataEntity?.links || [])], FOOD_FACTS_VIDEO_ID_LINK_NAME)}
                                                          onPlay={() => setAutoplayActivable(false)}
                                                          onPause={() => setAutoplayActivable(true)}
                                                          onStop={() => setAutoplayActivable(true)}
                                            />
                                        </CustomCarousel.Slide>
                                    }
                                </CustomCarousel>}
                    cardComponent = {<IngredientCard/>}
                    titleIcon={<Icon name={"leaf"}/>}
                    hasTabs
                    />
            ]}>

                <Tabs defaultValue="0" variant="outline" value={tabValue}>

                    <Container>

                        <ScrollTab>

                            {(!dataEntityCategoryPairings || dataEntityCategoryPairings.length === 0) && (!dataEntityPairings || dataEntityPairings.length === 0 || !dataEntityPairingRepresentativeIngredients || dataEntityPairingRepresentativeIngredients.length === 0) ? null :
                                <ScrollTab.Tab selected={tabValue === "0"} ml={"xs"} onClick={() => setTabValue("0")}>
                                    <IngredientPairingIconTitle />
                                </ScrollTab.Tab>
                            }

                            {/*{!entityCategoryPairings || entityCategoryPairings.length === 0 ? null :*/}
                            {/*    <ScrollTab.Tab selected={tabValue === "1"} ml={"xs"} onClick={() => setTabValue("1")}>*/}
                            {/*        <Icon name={"leaf"}/>*/}
                            {/*        <Text size={"sm"} fw={700}>*/}
                            {/*            {t('ingredient.entityCategoryPairing')}*/}
                            {/*        </Text>*/}
                            {/*    </ScrollTab.Tab>*/}
                            {/*}*/}

                            {!dataEntity.nutrients || dataEntity.nutrients.length === 0 ? null :

                                <ScrollTab.Tab selected={tabValue === "1"} ml={"xs"} onClick={() => setTabValue("1")}>
                                    <Text size={"sm"} fw={700}>
                                        {t('ingredient.nutrients')}
                                    </Text>
                                </ScrollTab.Tab>
                            }

                            {!dataItem.techniqueLevels || dataItem.techniqueLevels.length === 0 ? null :
                                <ScrollTab.Tab selected={tabValue === "2"} ml={"xs"} onClick={() => setTabValue("2")}>
                                    <Text size={"sm"} fw={700}>
                                        {t('ingredient.cookingTechniquesAndLevels')}
                                    </Text>
                                </ScrollTab.Tab>
                            }

                            {dataEntityIngredientNaturalSources?.length === 0 ? null :
                                <ScrollTab.Tab selected={tabValue === "3"} ml={"xs"} onClick={() => setTabValue("3")}>
                                    <IngredientIconTitle textKey={'ingredient.naturalSourceOf'}/>
                                </ScrollTab.Tab>
                            }

                            {dataEntityIngredientSynonyms?.length === 0 ? null :
                                <ScrollTab.Tab selected={tabValue === "4"} ml={"xs"} onClick={() => setTabValue("4")}>
                                    <IngredientIconTitle textKey={'ingredient.showSynonyms'}/>
                                </ScrollTab.Tab>
                            }

                            {!dataEntity.molecules || dataEntity.molecules.length === 0 ? null :
                                <ScrollTab.Tab selected={tabValue === "5"} ml={"xs"} onClick={() => setTabValue("5")}>
                                    <MoleculeIconTitle/>
                                </ScrollTab.Tab>
                            }

                            {previousTotalCountRecipes === 0 ? null :
                                <ScrollTab.Tab selected={tabValue === "6"} ml={"xs"} onClick={() => setTabValue("6")}>
                                    <RecipeIconTitle textKey={'ingredient.showRecipes'}/>
                                </ScrollTab.Tab>
                            }

                        </ScrollTab>

                        <AdvertisingGrid>

                            {(!dataEntityCategoryPairings || dataEntityCategoryPairings.length === 0) && (!dataEntityPairings || dataEntityPairings.length === 0 || !dataEntityPairingRepresentativeIngredients || dataEntityPairingRepresentativeIngredients.length === 0) ? null :
                                <Tabs.Panel value="0" pb="xs">
                                    <EntityPairings ingredientName={localized(dataItem, 'name')} ingredientNameEn={localizedEn(dataItem, 'name')}
                                                    entity={dataEntity}
                                                    entityCategoryPairings={dataEntityCategoryPairings} entityPairings={dataEntityPairings} totalCountEntityPairings={totalCountEntityPairings} entityPairingRepresentativeIngredients={dataEntityPairingRepresentativeIngredients}/>
                                </Tabs.Panel>
                            }

                            {/*{!entityCategoryPairings || entityCategoryPairings.length === 0 ? null :*/}
                            {/*    <Tabs.Panel value="1" pb="xs">*/}
                            {/*        <EntityCategoryPairings entityCategoryPairings={entityCategoryPairings}/>*/}
                            {/*    </Tabs.Panel>*/}
                            {/*}*/}

                            {!dataEntity.nutrients || dataEntity.nutrients.length === 0 ? null :
                                <Tabs.Panel value="1" pb="xs">
                                    <Nutrients nutrients={dataEntity.nutrients}/>
                                </Tabs.Panel>
                            }

                            {!dataItem.techniqueLevels || dataItem.techniqueLevels.length === 0 ? null :
                                <Tabs.Panel value="2" pb="xs">
                                    <TechniqueLevels techniqueLevels={dataItem.techniqueLevels}/>
                                </Tabs.Panel>
                            }

                            {dataEntityIngredientNaturalSources?.length === 0 ? null :
                                <Tabs.Panel value="3" pb="xs">
                                    <Ingredient09 title= {
                                                    <>
                                                        <VisuallyHidden>
                                                            <Title order={2} pt={"lg"}>{t('ingredient.naturalSourceOf')}</Title>
                                                        </VisuallyHidden>
                                                        <Space h="lg"/>
                                                    </>
                                                    }
                                                  ingredients={dataEntityIngredientNaturalSources?.sort((a, b) => alphabeticComparator(localized(a, "name"), localized(b, "name")))}
                                                  ingredientTarget="_self" categoryTarget="_self"/>
                                </Tabs.Panel>
                            }

                            {dataEntityIngredientSynonyms?.length === 0 ? null :
                                <Tabs.Panel value="4" pb="xs">
                                    <Ingredient09 title= {
                                                        <>
                                                            <VisuallyHidden>
                                                                <Title order={2} pt={"lg"}>{t('ingredient.showSynonyms')}</Title>
                                                            </VisuallyHidden>
                                                            <Space h="lg"/>
                                                        </>
                                                    }
                                                  ingredients={dataEntityIngredientSynonyms?.sort((a, b) => alphabeticComparator(localized(a, "name"), localized(b, "name")))}
                                                  ingredientTarget="_self" categoryTarget="_self"/>
                                </Tabs.Panel>
                            }

                            {!dataEntity.molecules || dataEntity.molecules.length === 0 ? null :
                                <Tabs.Panel value="5" pb="xs">
                                    <Molecule09 title= {
                                        <>
                                            <VisuallyHidden>
                                                <Title order={2} pt={"lg"}>{t('common.molecules')}</Title>
                                            </VisuallyHidden>
                                            <Space h="lg"/>
                                        </>
                                    }
                                                molecules={dataEntity.molecules}/>
                                </Tabs.Panel>
                            }

                            {previousTotalCountRecipes === 0 ? null :
                                <Tabs.Panel value="6" pb="xs">
                                    <Recipe09 title={
                                                    <>
                                                        <VisuallyHidden>
                                                            <Title order={2} pt={"lg"}>{t('ingredient.showRecipes')}</Title>
                                                        </VisuallyHidden>
                                                        <Space h="lg"/>
                                                    </>
                                                }
                                              recipes={dataRecipes}
                                              page={pageRecipes}
                                              onPageChange={setPageRecipes}
                                              totalCount={totalCountRecipes}
                                              recipeTarget="_self"
                                              categoryTarget="_self"/>
                                </Tabs.Panel>
                            }

                            <Space h={"lg"}/>
                            <Sponsors section={"ingredient"} id={dataItem.name}/>

                        </AdvertisingGrid>
                    </Container>
                </Tabs>

                {/*TODO Remove*/}
                {true ? null :
                    <>
                        Ingredient
                        <pre><code style={{fontSize: 11}}>{JSON.stringify(dataItem, null, 4)}</code></pre>
                        Entity
                        <pre><code style={{fontSize: 11}}>{JSON.stringify(dataEntity, null, 4)}</code></pre>
                        Natural Source Entities {dataNaturalSourceEntities.length}<br/>
                        {dataNaturalSourceEntities.map((naturalSourceEntity) => (
                            <pre><code style={{fontSize: 11}}>{JSON.stringify(naturalSourceEntity, null, 4)}</code></pre>
                        ))}
                    </>
                }
        </Page>

    );
}

export default IngredientPage;