import React, {useMemo, useState} from 'react';
import {useDisclosure} from "@mantine/hooks";
import {
	Anchor,
	Box,
	Button, Card,
	Group, List,
	Modal,
	ScrollArea, SegmentedControl,
	SimpleGrid, Space,
	Stack,
	Text,
	Title
} from "@mantine/core";
import classes from "./Features.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faCheck,
	faChevronRight,
	faCircleCheck,
	faHandHoldingHeart
} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {useFeatures} from "./useFeatures";
import {PaymentSubscription} from "../payment/Payment";
import {useAccountContext} from "../account/AccountContext";
import {daysFromNow} from "../../util/time";
import {SimpleBox} from "../simpleBox/SimpleBox";
import {useEnvironmentContext} from "../environment/EnvironmentContext";
import {usePaymentContext} from "../payment/PaymentContext";
import useMedia from "../../pages/useMedia";

/**
 * FeaturesBanner
 */
export const FeaturesBanner = ({color = "secondary"}) => {

	const {t} = useTranslation();
	const [left] = useState(Math.random() < 0.6);

	const {environment} = useEnvironmentContext();

	const FeatureIcon = () => <FontAwesomeIcon icon={faHandHoldingHeart} className={classes.featureslinkicon}/>

	return	<FeaturesPlan targetComponent={<Anchor target={"_blank"} underline="never">
				<SimpleBox color={color} highlightOnHover>
					<Group justify={"space-between"} align={"center"}>
						{left && <FeatureIcon />}
						<Stack gap={0} flex={1}>
							<Text flex={1} size={"sm"}>
								{t("common.loginNow")}&nbsp;{t("feature.featurePlans.accountDescription", {trialDays: environment.paymentPremiumTrialDays})}
							</Text>
							<Text>
							</Text>
						</Stack>
						{!left && <FeatureIcon />}
						{/*{left &&*/}
						{/*    <FontAwesomeIcon icon={faUpRightFromSquare} color={`var(--mantine-color-${color}-3)`} style={{width: "18px", height: "18px"}}/>*/}
						{/*}*/}
					</Group>
				</SimpleBox>
			</Anchor>
		}/>
}

/**
 * FeaturesPlan
 */
export const FeaturesPlan = ({targetComponent}) => {

	const MONTHLY = "monthly";
	const YEARLY = "yearly";

	const [opened, { open, close }] = useDisclosure(false);

	const { isAuthenticated, userAccount, loginAccount } = useAccountContext();

	const { Features, FeaturePlans } = useFeatures();

	const {t} = useTranslation();

	const [pricePeriod, setPricePeriod] = useState(MONTHLY);

	const planKeys = Object.keys(FeaturePlans); // FREE, ACCOUNT, PREMIUM, STUDIO

	const color = userAccount?.isAdmin() || userAccount?.isPremium() || userAccount?.isStudio() ? "tertiary" : "secondary";

	const { isSm } = useMedia();

	// /**
	//  * getNextFeaturePlan - Returns the FeaturePlan with the next higher priority.
	//  * @param {Object} plan - The FeaturePlan object (e.g., FeaturePlans.FREE).
	//  * @returns {Object|null} - The next FeaturePlan object or null if not found.
	//  */
	// const getNextFeaturePlan = (plan) => {
	// 	// Convert FeaturePlans to an array and sort by priority (ascending)
	// 	const sortedPlans = Object.values(FeaturePlans).sort((a, b) => a.priority - b.priority);
	//
	// 	// Find the index of the given plan
	// 	const currentIndex = sortedPlans.findIndex(p => p.id === plan.id);
	//
	// 	// Return the next plan object if it exists, otherwise return null
	// 	return currentIndex >= 0 && currentIndex < sortedPlans.length - 1 ? sortedPlans[currentIndex + 1] : null;
	// };

	/**
	 * getPreviousFeaturePlan - Returns the first valid FeaturePlan with the next lower priority.
	 * Continues searching previous plans until it finds one that meets the criteria or returns null.
	 *
	 * @param {Object} plan - The FeaturePlan object (e.g., FeaturePlans.FREE).
	 * @param {Function} condition - (Optional) A function that determines if a plan is valid (default: always true).
	 * @returns {Object|null} - The previous valid FeaturePlan object or null if not found.
	 */
	const getPreviousFeaturePlan = (plan, condition = () => true) => {
		// Convert FeaturePlans to an array and sort by priority (ascending)
		const sortedPlans = Object.values(FeaturePlans).sort((a, b) => a.priority - b.priority);

		// Find the index of the given plan
		let currentIndex = sortedPlans.findIndex(p => p.id === plan.id);

		// Iterate backward to find the first valid previous plan
		while (currentIndex > 0) {
			currentIndex--;
			if (condition(sortedPlans[currentIndex])) {
				return sortedPlans[currentIndex];
			}
		}

		// Return null if no valid plan is found
		return null;
	};

	/**
	 * hasCategoryPlan - Checks if at least one feature in a given category has the specified plan.
	 */
	const hasCategoryPlan = (categoryKey, plan) => {

		// Ensure the category exists in Features
		if (!Features[categoryKey]) return false;

		// Check if any feature in the category has the specified plan
		return Object.keys(Features[categoryKey].features).some(featureKey =>
			Features[categoryKey].features[featureKey].plan === plan
		);
	};

	/**
	 * PlanColumn
	 */
	const PlanColumn = ({plan, color}) => {

		const {subscription, customerSession} = usePaymentContext();

		/**
		 * FreeCtaCardSection
		 */
		const FreeCtaCardSection = () => {

			return (
				<></>
			)
		}

		/**
		 * AccountCtaCardSection
		 */
		const AccountCtaCardSection = () => {

			return (!isAuthenticated &&
				<Button color={"white"} variant={"light"} size="lg" mt={"lg"}
						onClick={loginAccount}
						justify="space-between"
						fullWidth
						rightSection={<FontAwesomeIcon icon={faChevronRight} size={"2md"}/>}>
					{plan.cta}
				</Button>
			)
		}

		/**
		 * PremiumCtaCardSection
		 */
		const PremiumCtaCardSection = () => {

			return (
				userAccount?.isAdmin() || userAccount?.isPremium() ?
					<>
						{userAccount?.getProducts().filter(product => product.type === "premium").map((product, index) => (
							<Button color={"white"} variant={"light"} size="lg" mt={"lg"}
									onClick={() => customerSession()}
									justify="space-between"
									fullWidth
									rightSection={<FontAwesomeIcon icon={faChevronRight} size={"2md"}/>}>
								{t("payment.manage")}
							</Button>
						))}
					</>
					:
					plan.payments && plan.payments.length > 0 &&
					<>
						{!userAccount?.isPremiumTrial() &&
							<>
								{plan.payments.filter(payment => payment.pricePeriod === pricePeriod).map(payment => (
									<Button color={"white"} variant={"light"} size="lg" mt={"lg"}
											onClick={() => subscription(payment.priceId)}
											justify="space-between"
											fullWidth
											rightSection={<FontAwesomeIcon icon={faChevronRight} size={"2md"}/>}>
										{plan.cta}
									</Button>
								))}
							</>
						}
					</>
			)
		}

		/**
		 * StudioCtaCardSection
		 */
		const StudioCtaCardSection = () => {

			return (
				userAccount?.isAdmin() || userAccount?.isStudio() ?
					<>
						{userAccount?.getProducts().filter(product => product.type === "studio").map((product, index) => (
							<Button color={"white"} variant={"light"} size="lg" mt={"lg"}
									onClick={() => customerSession()}
									justify="space-between"
									fullWidth
									rightSection={<FontAwesomeIcon icon={faChevronRight} size={"2md"}/>}>
								{t("payment.manage")}
							</Button>
						))}
					</>
					:
					plan.payments && plan.payments.length > 0 &&
					<>
						{plan.payments.filter(payment => payment.pricePeriod === pricePeriod).map(payment => (
							<Button color={"white"} variant={"light"} size="lg" mt={"lg"}
									onClick={() => subscription(payment.priceId)}
									justify="space-between"
									fullWidth
									rightSection={<FontAwesomeIcon icon={faChevronRight} size={"2md"}/>}>
								{plan.cta}
							</Button>
						))}
					</>
			)
		}

		/**
		 * ctaSection
		 */
		const ctaSection = useMemo(() => {

			if(plan === FeaturePlans.FREE) {
				return <FreeCtaCardSection />
			}

			if(plan === FeaturePlans.ACCOUNT) {
				return <AccountCtaCardSection />
			}

			if(plan === FeaturePlans.PREMIUM) {
				return <PremiumCtaCardSection />
			}

			if(plan === FeaturePlans.STUDIO) {
				return <StudioCtaCardSection />
			}

			return null;
		}, [plan]);

		/**
		 * DefaultCardSection
		 */
		const DefaultCardSection = ({priceComponent}) => {

			return (
				<Card.Section mih={550} p={"md"} bg={`var(--mantine-color-${color}-6)`} c={"var(--mantine-color-white)"}
							  style={{borderBottomLeftRadius: "var(--mantine-spacing-md)", borderBottomRightRadius: "var(--mantine-spacing-md)"}}
					>

					<Stack gap={0} justify={"space-between"} flex={1} h={"100%"}>

						<Stack gap={0}>

							<Group align={"flex-start"} justify={"space-between"} wrap={"nowrap"}>
								<Stack gap={0}>
									<Text size={"xl"} fw={700} mih={70} lineClamp={2}>
										{plan.name}
									</Text>
									<Text size={"md"} fw={700} pt={"lg"}>
										{plan.planName}
									</Text>
								</Stack>
								{plan.enabled &&
									<FontAwesomeIcon icon={faCircleCheck} size={"2x"}/>
								}
							</Group>

							{priceComponent ?
								<>
									<Space h={"lg"}/>
									{priceComponent}
								</>
								:
								<>
									<Space h={"lg"}/>
									<Space h={"lg"}/>
								</>
							}

							<Stack gap={"xs"} mih={150} pt={"lg"}>
								<Text size={"sm"} >
									{plan.subhead}
								</Text>
								<Text size={"sm"}>
									{plan.claim}
								</Text>
								<Text size={"sm"}>
									{plan.target}
								</Text>
							</Stack>

							<List withPadding pt={"sm"}>
								{plan.highlights.map((item, index) => (
									<List.Item key={index} icon={<FontAwesomeIcon icon={faCheck} style={{marginLeft: "-6px"}}/>}>
										<Text size={"md"}>
											{item}
										</Text>
									</List.Item>
								))}
							</List>

						</Stack>

						{ctaSection}

					</Stack>

				</Card.Section>
			)
		}

		/**
		 * FreeCardSection
		 */
		const FreeCardSection = () => {

			return (
				<DefaultCardSection />
			)
		}

		/**
		 * AccountCardSection
		 */
		const AccountCardSection = () => {

			return (
				<DefaultCardSection />
			)
		}

		/**
		 * PremiumCardSection
		 */
		const PremiumCardSection = () => {

			const hasPremium = userAccount?.getProducts().some(product => product.type === "premium")

			return (
				<DefaultCardSection priceComponent={
					userAccount?.isAdmin() || userAccount?.isPremium() ?
						<>
							{hasPremium ?
								<>
									{userAccount?.getProducts().filter(product => product.type === "premium").map((product, index) => (
										<Text size={"24px"} fw={700} wrap={"nowrap"}>{t("account.subscriptionExpiration", {days: daysFromNow(product.validTo)})}</Text>
									))}
								</>
								:
								<Text size={"sm"} fw={700} c={`var(--mantine-color-white)`} h={24}>{t("account.roles")}: {userAccount?.getRoles()?.join(", ") || ""}</Text>
							}
						</>
						:
						plan.payments && plan.payments.length > 0 &&
						<>
							{userAccount?.isPremiumTrial() ?
								<Text size={"sm"} fw={700} c={`var(--mantine-color-white)`} h={24}>{t("account.trial", {trialDaysLeft: userAccount.getPremiumTrialDays() - userAccount.getPremiumTrialDaysLeft(), trialDays: userAccount.getPremiumTrialDays()})}</Text>
								:
								<>
									{plan.payments.filter(payment => payment.pricePeriod === pricePeriod).map(payment => (
										<PaymentSubscription priceUsd={payment.priceUsd} priceId={payment.priceId} pricePeriod={payment.pricePeriod} disabled={true} color={color}/>
									))}
								</>
							}
						</>
				}/>
			)
		}

		/**
		 * StudioCardSection
		 */
		const StudioCardSection = () => {

			const hasStudio = userAccount?.getProducts().some(product => product.type === "studio")

			return (
				<DefaultCardSection priceComponent={
					userAccount?.isAdmin() || userAccount?.isStudio() ?
						<>
							{hasStudio ?
								<>
									{userAccount?.getProducts().filter(product => product.type === "studio").map((product, index) => (
										<Text size={"24px"} fw={700} wrap={"nowrap"}>{t("account.subscriptionExpiration", {days: daysFromNow(product.validTo)})}</Text>
									))}
								</>
								:
								<Text size={"sm"} fw={700} c={`var(--mantine-color-white)`} h={24}>{t("account.roles")}: {userAccount?.getRoles()?.join(", ") || ""}</Text>
							}
						</>
						:
						plan.payments && plan.payments.length > 0 &&
							<>
								{plan.payments.filter(payment => payment.pricePeriod === pricePeriod).map(payment => (
									<PaymentSubscription priceUsd={payment.priceUsd} priceId={payment.priceId} pricePeriod={payment.pricePeriod} disabled={true} color={color}/>
								))}
							</>
				}/>
			)
		}

		/**
		 * headerSection
		 */
		const headerSection = useMemo(() => {

			if(plan === FeaturePlans.FREE) {
				return <FreeCardSection />
			}

			if(plan === FeaturePlans.ACCOUNT) {
				return <AccountCardSection />
			}

			if(plan === FeaturePlans.PREMIUM) {
				return <PremiumCardSection />
			}

			if(plan === FeaturePlans.STUDIO) {
				return <StudioCardSection />
			}

			return null;
		}, [plan]);

		return (
			<Card shadow={0} radius="lg" withBorder
				// c={FeaturePlans[planKey].enabled ? `var(--mantine-color-white)` : `var(--mantine-color-${color}-9)` }
				  c={`var(--mantine-color-${color}-9)`}
				// bg={FeaturePlans[planKey].enabled ? `var(--mantine-color-${color}-6)` : `var(--mantine-color-${color}-2)` }
				  bg={`var(--mantine-color-${color}-2)`}
				  style={{borderColor: `var(--mantine-color-${color}-6)`}}>

				<Stack justify="space-between" gap={0} flex={1}>

					<Stack justify="flex-start" gap="xs">

						{headerSection}

						<Card.Section p={"md"}>
							{Object.keys(Features).map((categoryKey) => (
								<div key={categoryKey}>

									{hasCategoryPlan(categoryKey, plan) &&

										<>
											<Text size={"sm"} fw={700} mt="md" mb={"sm"} >
												{Features[categoryKey].name}

												{Features[categoryKey].note &&
													<Text size={"xs"} fs="italic" opacity={0.75}>
														{Features[categoryKey].note}
													</Text>
												}
											</Text>
											<List withPadding>
												{getPreviousFeaturePlan(plan, (plan) => hasCategoryPlan(categoryKey, plan)) &&
													<List.Item icon={<FontAwesomeIcon icon={faHandHoldingHeart} style={{marginLeft: "-6px"}}/>}>
														<Text size={"sm"} fw={600}>
															{t("common.allOfPlan", {plan: getPreviousFeaturePlan(plan, (plan) => hasCategoryPlan(categoryKey, plan)).planName})}
														</Text>
													</List.Item>
												}

												{/* Features List */}
												{Object.keys(Features[categoryKey].features).map((featureKey) => (
													<>
														{Features[categoryKey].features[featureKey].plan === plan &&
															<List.Item>
																<Text key={featureKey} size="sm">
																	{Features[categoryKey].features[featureKey].name}

																	{Features[categoryKey].features[featureKey].note &&
																		<Text size={"xs"} fs="italic" opacity={0.75}>
																			{Features[categoryKey].features[featureKey].note}
																		</Text>
																	}
																</Text>
															</List.Item>
														}
													</>
												))}
											</List>
										</>
									}
								</div>
							))}
						</Card.Section>
					</Stack>

				</Stack>
			</Card>
		);
	}

	return (
		<>
			<Modal opened={opened}
				   color={color}
				   closeOnClickOutside={false}
				   fullScreen
				   withCloseButton
				   title={
					   <Title pl={28} order={2} ta={"center"}>{t('feature.plans')}</Title>
				   }
				   // size={"80%"}
				   // overlayProps={{color: `var(--mantine-color-${color}-12)`, backgroundOpacity: 0.75, blur: 7}}
				   zIndex={404}
				   onClose={close}
				   scrollAreaComponent={ScrollArea.Autosize}
				   classNames={{
					   root: classes[`modalroot${color}`],
					   header: classes[`modalheader${color}`],
					   content: classes[`modalcontent${color}`],
					   inner: classes[`modalinner${color}`],
					   body: classes[`modalbody${color}`],
				   }}
				   // style={{color: `var(--mantine-color-${color}-9)`}}
				   style={{color: `var(--mantine-color-white)`}}
			>

				<Stack align="center" justify="flex-start">

					{(!userAccount?.isAdmin() || !(userAccount?.isPremium() || userAccount?.isPremiumTrial()) || !userAccount.isStudio()) &&
						<SegmentedControl
							color={color}
							autoContrast
							withItemsBorders={false} size="md" radius="md"
							value={pricePeriod}
							onChange={setPricePeriod}
							data={[
								{ label: t("payment.monthly"), value: MONTHLY },
								{ label: t("payment.yearly"), value: YEARLY },
							]}
							classNames={{
								root: classes[`segmentedplansroot${color}`],
								label: classes[`segmentedplanslabel${color}`]
							}}
						/>
					}

					<Text h={25}>{t(`payment.${pricePeriod}Discount`)}</Text>
				</Stack>

				<SimpleGrid cols={isSm ? 1 : planKeys.length} spacing={"lg"} pl={"md"} pr={"md"} pt={"xl"} pb={"md"}>
					{planKeys.map(planKey => (
						<PlanColumn plan={FeaturePlans[planKey]} color={color}/>
					))}
				</SimpleGrid>

			</Modal>

			<Box onClick={open}>
				{targetComponent}
			</Box>
		</>
	);
}