import React, {createContext, useContext} from 'react';
import useEnvironment from "../../pages/useEnvironment";

/**
 * EnvironmentContext
 */
const EnvironmentContext = createContext(null);

/**
 * useEnvironmentContext
 */
export const useEnvironmentContext = () => {
	return useContext(EnvironmentContext);
};

/**
 * EnvironmentProvider
 */
export const EnvironmentProvider = ({ children }) => {

	const {data: environment, isLoaded: isLoadedEnvironment} =
		useEnvironment({
			enabled: true,
		});

	return (isLoadedEnvironment &&
		<EnvironmentContext.Provider value={{environment}}>
			{children}
		</EnvironmentContext.Provider>
	)
};