import React, {useEffect, useRef, useState} from 'react';
import {
	AspectRatio, Box,
	Button, Center, Checkbox,
	Container, Group, Image, Modal,
	Space,
	Stack,
	Tabs,
	Text,
} from '@mantine/core';
import {useTranslation} from 'react-i18next';
import {Page, PageTitle} from "../../components/page/Page";
import classes from "./HomePage.module.css";
import useKey from "../useKey";
import {useAccountContext} from "../../components/account/AccountContext";
import {useLoadingContext} from "../../components/loading/LoadingContext";
import {useApplicationContext} from "../../components/application/ApplicationContext";
import {EntityCategoryLink} from "../ingredient/EntityCategoryLink";
import {AdvertisingGrid} from "../../components/advertising/Advertising";
import RecipeCategoryLink from "../recipe/RecipeCategoryLink";
import {SimpleBox} from "../../components/simpleBox/SimpleBox";
import useRecipeAll from "../recipe/useRecipeAll";
import RecipeLink, {RecipeIconTitle} from "../recipe/RecipeLink";
import useEntityAll from "../ingredient/useEntityAll";
import {
	IngredientIconTitle,
	IngredientLink, LinkedIngredients
} from "../ingredient/IngredientLink";
import {CustomCarousel} from "../../components/carousel/CustomCarousel";
import {ScrollTab} from "../../components/scrollTab/ScrollTab";
import {FlavorDbCredits, SchemaOrgCredits} from "../../components/credits/Credits";
import {Icon} from "../../components/icons/Icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faPlus, faUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";
import {useDisclosure, useScrollIntoView} from "@mantine/hooks";
import {theme} from "../../Theme";
import {PaymentDonateLink} from "../../components/payment/Payment";
import {Utensils} from "../recipe/UtensilLink";
import {faHeart as faHeartSolid} from "@fortawesome/free-solid-svg-icons/faHeart";
import {SocialLink} from "../../components/social/Social";
import {Sponsors, SponsorsBanner} from "../../components/sponsor/Sponsor";
import useMedia from "../useMedia";
import {
	LanguageBanner,
	SelectedLanguageText,
} from "../../components/languageSelector/LanguageSelector";
import i18n, {parameterLanguage} from "../../i18n";
import {FeaturesBanner} from "../../components/features/Features";
import {useNavigate, useParams} from "react-router-dom";
import {create} from 'zustand';
import {ScrollableArea} from "../studio/ScrollableArea";
import {StudioBanner} from "../studio/StudioPage";
import {studioNavigate} from "../studio/StudioLink";

/**
 * useHomeStore
 */
export const useHomeStore = create((set) => ({

	homeOnboardingEnabled: localStorage.getItem('homeOnboardingEnabled') === null ? true : localStorage.getItem('homeOnboardingEnabled') === 'true',
	setHomeOnboardingEnabled: (homeOnboardingEnabled) => {
		set({ homeOnboardingEnabled });
		localStorage.setItem('homeOnboardingEnabled', homeOnboardingEnabled);
	},

}));

/**
 * FoodPairingBanner
 */
const FoodPairingBanner = ({color = "tertiary"}) => {

	const {t} = useTranslation();
	const [left] = useState(Math.random() < 0.6);

	const FoodPairingIcon = () => <Icon name={"circle-overlap"} className={classes.foodpairingicon}/>

	return	<SimpleBox color={color}>
				<Group justify={"space-between"} align={"center"}>
					{left && <FoodPairingIcon />}
					<Stack gap={0} flex={1}>
						<Text flex={1} size={"sm"}>
							{t("ingredient.pairingDescription")}
						</Text>
					</Stack>
					{!left && <FoodPairingIcon />}
					{/*{left &&*/}
					{/*    <FontAwesomeIcon icon={faUpRightFromSquare} color={`var(--mantine-color-${color}-3)`} style={{width: "18px", height: "18px"}}/>*/}
					{/*}*/}
				</Group>
			</SimpleBox>
}

/**
 * Onboarding
 */
export const Onboarding = (props) => {

	const {color = "primary"} = props;

	const {t} = useTranslation();

	let homeOnboardingEnabled = useHomeStore((state) => state.homeOnboardingEnabled);
	const setHomeOnboardingEnabled = useHomeStore((state) => state.setHomeOnboardingEnabled);

	const [slideIndex, setSlideIndex] = useState(-1);
	const videoRefs = [useRef(null), useRef(null), useRef(null)];

	const [opened, { open, close }] = useDisclosure(homeOnboardingEnabled);

	const { isSm } = useMedia();

	useEffect(() => {
		setSlideIndex(0);
	}, []);

	useEffect(() => {
		videoRefs.forEach((videoRef, index) => {
			if (videoRef.current) {
				if (index === slideIndex) {
					videoRef.current.play().catch((error) => console.error("Autoplay failed:", error));
				} else {
					videoRef.current.pause();
					videoRef.current.currentTime = 0; // Riavvia il video quando si cambia slide
				}
			}
		});
	}, [slideIndex]);

	return (
		<Modal opened={opened}
			   color={color}
			   centered
			   closeOnClickOutside
			   onClose={close}
			   withCloseButton={false}
			   size={"xl"}
			   overlayProps={{color: `var(--mantine-color-${color}-12)`, backgroundOpacity: 0.75, blur: 7}}
			   zIndex={404}
			   // title={<Text pt={"lg"} pb={0} p={"md"}>{t("onboarding.title")}</Text>}
			   // onClose={close}
			   classNames={{
				   root: classes.modalroot,
				   header: classes.modalheader,
				   content: classes.modalcontent,
				   inner: classes.modalinner,
				   body: classes.modalbody,
			   }}
		>
			<Icon name={"logo-full"} style={{position: "absolute", top: "40px", left: "50%", transform: 'translate(-50%, 0)', zIndex: 1}}/>

			<CustomCarousel interval = {0}
							color = {"secondary"}
							slideGap = {"md"}
							loop={false}
							indicatorsClassName={classes.customcarouselindicators}
							onSlideChange = {(index) => setSlideIndex(index)}>

				<CustomCarousel.Slide>
					<AspectRatio ratio={16 / 9}>
						<video ref={videoRefs[0]} width="100%" controls={false} muted playsInline style={{borderRadius: "var(--mantine-radius-default)"}}>
							<source src="/onboarding-0.mp4" type="video/mp4" />
						</video>
					</AspectRatio>
					<ScrollableArea h={!isSm ? "100%" : "200px"} mt={"lg"} mb={50}>
						<Text>{t("onboarding.slide1")}</Text>
					</ScrollableArea>
				</CustomCarousel.Slide>

				<CustomCarousel.Slide>
					<AspectRatio ratio={16 / 9}>
						<video ref={videoRefs[1]} width="100%" controls={false} muted playsInline style={{borderRadius: "var(--mantine-radius-default)"}}>
							<source src="/onboarding-1.mp4" type="video/mp4" />
						</video>
					</AspectRatio>
					<ScrollableArea h={!isSm ? "100%" : "200px"} mt={"lg"} mb={50}>
						<Text>{t("onboarding.slide2")}</Text>
					</ScrollableArea>
				</CustomCarousel.Slide>

				<CustomCarousel.Slide>
					<AspectRatio ratio={16 / 9}>
						<video ref={videoRefs[2]} width="100%" controls={false} muted playsInline style={{borderRadius: "var(--mantine-radius-default)"}}>
							<source src="/onboarding-2.mp4" type="video/mp4" />
						</video>
					</AspectRatio>
					<ScrollableArea h={!isSm ? "100%" : "200px"} mt={"lg"} mb={50}>
						<Text>{t("onboarding.slide3")}</Text>
					</ScrollableArea>
				</CustomCarousel.Slide>

				<CustomCarousel.Slide>
					<AspectRatio ratio={16 / 9} style={{overflow: "hidden", borderRadius: "var(--mantine-radius-default)"}}>
						<Image src="/recipes-gpt.jpg"
							   style={{
								   filter: "contrast(1.1) brightness(0.95)",
								   objectFit: "cover",
								   transform: slideIndex === 3 ? 'scale(1.1)' : 'scale(1)',
								   transition: 'transform 0.35s ease-in-out',
								   transitionDelay: slideIndex === 3 ? '0.2s' : '0s'
							   }}
						/>
						{!isSm &&
							<Group justify={"center"} w={"100%"} mah={"150px"} style={{position: "absolute", top: "40%", left: "50%", transform: 'translate(-50%, -50%)'}}>
								<Center>
									<Icon name={"gpt"} className={classes.homegpticon} />
									<FontAwesomeIcon icon={faPlus} className={classes.homeplusicon}/>
									<Icon name={"hat-chef"} className={classes.homeicon} />
								</Center>
							</Group>
						}
					</AspectRatio>
					<ScrollableArea h={!isSm ? "100%" : "200px"} mt={"lg"} mb={50}>
						<Text>{t("recipe.actionCallGpt")} {t("recipe.actionCallGptClause")}</Text>
					</ScrollableArea>
				</CustomCarousel.Slide>

				<CustomCarousel.Slide>
					<AspectRatio ratio={16 / 9} style={{overflow: "hidden", borderRadius: "var(--mantine-radius-default)"}}>
						<Image src="/home-welcome-3.jpg"
							style={{
								filter: "contrast(1.1) brightness(0.95)",
								objectFit: "cover",
								transform: slideIndex === 4 ? 'scale(1.1)' : 'scale(1)',
								transition: 'transform 0.35s ease-in-out',
								transitionDelay: slideIndex === 4 ? '0.2s' : '0s'
							}}
						/>
						{/*{!isSm &&*/}
						{/*	<Group justify={"center"} w={"100%"} mah={"150px"} style={{position: "absolute", top: "40%", left: "50%", transform: 'translate(-50%, -50%)'}}>*/}
						{/*		<Center>*/}
						{/*			<Icon name={"logo-full-fp"} className={classes.homegpticon} />*/}
						{/*		</Center>*/}
						{/*	</Group>*/}
						{/*}*/}
					</AspectRatio>
					<ScrollableArea h={!isSm ? "100%" : "200px"} mt={"lg"} mb={50}>
						<Text>{t("studio.studioDescription")}</Text>
					</ScrollableArea>
				</CustomCarousel.Slide>

				{i18n.language.toLowerCase() !== "en" &&
					<CustomCarousel.Slide>
						<AspectRatio ratio={16 / 9} w={"100%"}>
							<Group justify={"center"} style={{width: "100%", textAlign: "center", borderRadius: "var(--mantine-radius-default)"}} bg={"var(--mantine-color-tertiary-light-hover)"}>
								<Icon name={`language-${i18n.language}`} style={{paddingTop: "20%", width: "30%", height: "30%", fill: "var(--mantine-color-tertiary-6)", zIndex: 1}} />
							</Group>
						</AspectRatio>
						<ScrollableArea h={!isSm ? "100%" : "200px"} mt={"lg"} mb={50}>
							<Text>
								<SelectedLanguageText lng={i18n.language} text={"common.languageChangeDescription"}/>
							</Text>
						</ScrollableArea>
					</CustomCarousel.Slide>
				}
			</CustomCarousel>
			<Space h="lg"/>
			<Group justify="space-between" pt={"md"} wrap={"nowrap"}>
				<Checkbox
					color={"secondary"}
					checked={!homeOnboardingEnabled}
					onChange={(event) => setHomeOnboardingEnabled(!event.currentTarget.checked)}
					label={<Text size={"sm"}>{t("common.doNotShowAgain")}</Text>}
				/>
				<Button color={"secondary"} miw={140} onClick={close}>{t("common.gotIt")}</Button>
			</Group>
		</Modal>
	)
}

/**
 * Home
 */
const Home = (props) => {

	const {paramLng} = useParams();
	const lng = parameterLanguage(paramLng);

	const {isAuthenticated, userAccount, loginAccount } = useAccountContext();

    const {isLoading, isLoaded} = useLoadingContext();

	const [tabValue, setTabValue] = useState("0");

    const { t } = useTranslation();

	const {entityCategories, recipeCategories} = useApplicationContext();

	const { isSm } = useMedia();

	const navigate = useNavigate();

	const { scrollIntoView, targetRef } = useScrollIntoView({
		offset: theme.custom.components.header.heightPx,
		smooth: true,
	});

	const {key, stateKey} =
        useKey({
			isLoading: isLoading,
			onKeyChange: () => {
				console.debug(`Home ` + stateKey);
				isLoaded();
			}
		});

	useEffect(() => {
		if(lng !== undefined && lng !== i18n.language) {
			i18n.changeLanguage(lng)
				.then(value => window.location.reload());
		}
	}, [lng]);

	/**
	 * Function to randomly select a component based on weights
	 */
	const getRandomWeightedComponent = (components) => {
		// Step 1: Build a weighted list
		// Each component is repeated in the list based on its weight
		const weightedList = components.flatMap(({ component, weight }) =>
			Array(weight).fill(component) // Add the component `weight` number of times
		);

		// Step 2: Pick a random index from the weighted list
		const randomIndex = Math.floor(Math.random() * weightedList.length);

		// Step 3: Return the randomly selected component
		return weightedList[randomIndex];
	};

	const RandomComponent = () => {
		// Get a random component using the weighted function
		const selectedComponent = getRandomWeightedComponent([
			{ component: <StudioBanner />, weight: 25, enabled: true},
			{ component: <FeaturesBanner />, weight: 20, enabled: !isAuthenticated },
			{ component: <SocialLink random />, weight: 20, enabled: true },
			{ component: <LinkedIngredients />, weight: 15, enabled: true },
			{ component: <Utensils />, 			weight: 15, enabled: true },
			{ component: <SponsorsBanner />, weight: 10, enabled: true },
			{ component: <PaymentDonateLink />, weight: 10, enabled: true },
			{ component: <FoodPairingBanner />, weight: 5, enabled: true },
			{ component: <LanguageBanner />, weight: 5, enabled: i18n.language !== "en" },
		].filter(item => item.enabled));

		// Render the selected component
		return <>{selectedComponent}</>;
	};

	/**
	 * Entities
	 */
	const Entities = () => {

		/**
		 * EntitiesFromCategory
		 */
		const EntitiesFromCategory = ({category}) => {

			const {key, stateKey} =
				useKey({
					onKeyChange: () => {
						refetchItems();
					}
				});

			const { data: dataItems, isLoaded: isLoadedItems, totalCount: totalCountItems,
				reset: resetItems, refetch: refetchItems} =
				useEntityAll({
					entityCategoryId: category.entityCategoryId,
					random: theme.custom.home.randomIngredients,
				})

			return !isLoadedItems ? null :
				<CustomCarousel interval = {0}
								color={"secondary"}
								withControls={false}
								withIndicators={false}
								slideSize={{ base: '100%', sm: '50%'}}
								slidesToScroll={{ base: 1, sm: 2}}
								slideGap={"md"}>
					{dataItems?.map((item, index) =>
						<CustomCarousel.Slide>
							<IngredientLink entity={item} showCategory={false}/>
						</CustomCarousel.Slide>
					)}
				</CustomCarousel>
		}

		return (
			<>
				{entityCategories.map((category, index) =>
					<>
						{index % 3 === 0 &&
							<>
								<RandomComponent/>
								<Space h={"lg"}/>
							</>
						}

						<SimpleBox color={"secondary"}>
							<EntityCategoryLink entityCategory={category} withDescription
												style={{color: "var(--mantine-color-secondary-6)"}}
												styleDescription={{color: "var(--mantine-color-secondary-9)"}}/>
							<Space h={"lg"}/>
							<EntitiesFromCategory category={category}/>
						</SimpleBox>
						<Space h={"lg"}/>
					</>

				)}
			</>
		)
	}

	/**
	 * Recipes
	 */
	const Recipes = () => {

		/**
		 * RecipesFromCategory
		 */
		const RecipesFromCategory = ({category}) => {

			const {key, stateKey} =
				useKey({
					onKeyChange: () => {
						refetchItems();
					}
				});

			const { data: dataItems, isLoaded: isLoadedItems, totalCount: totalCountItems,
				reset: resetItems, refetch: refetchItems} =
				useRecipeAll({
					recipeCategoryId: category.recipeCategoryId,
					random: theme.custom.home.randomRecipes,
				})

			return !isLoadedItems ? null :
				<CustomCarousel interval = {0}
								color={"secondary"}
								withControls={false}
								withIndicators={false}
								slideSize={{ base: '100%', sm: '50%'}}
								slidesToScroll={{ base: 1, sm: 2}}
								slideGap={"md"}>
					{dataItems?.map((item, index) =>
						<CustomCarousel.Slide>
							<RecipeLink recipe={item} showCategory={false}/>
						</CustomCarousel.Slide>
					)}
				</CustomCarousel>
		}

		return (
			<>
				{recipeCategories.map((category, index) =>
					<>
						{index % 3 === 0 &&
							<>
								<RandomComponent/>
								<Space h={"lg"}/>
							</>
						}

						<SimpleBox color={"secondary"}>
							<RecipeCategoryLink recipeCategory={category} withDescription
												style={{color: "var(--mantine-color-secondary-6)"}}
												styleDescription={{color: "var(--mantine-color-secondary-9)"}}/>
							<Space h={"lg"}/>
							<RecipesFromCategory category={category}/>
						</SimpleBox>
						<Space h={"lg"}/>
					</>
				)}
			</>
		)
	}

    return (

        <Page
            pageTitle={t('home.title')}
			pageDescription={t('home.welcome0')}
			pageKeywords={"home"}
			pageUrl={`/`}
			pageImage={`${window.location.origin}/home-welcome-2.jpg`}
			svgZIndex={1}
			hasTabs
			pageTitles={[
				<PageTitle
					simpleText={
						<Stack align={"center"} style={{marginTop: isSm ? "calc(var(--mantine-spacing-md) * -4)" : "0"}}>
							<Icon name={"logo-full-slogan"} className={classes.homelogo} />
							{/*<Text inherit className={classes.homesimpletext}>{t('home.welcome0')}</Text>*/}
						</Stack>
					}
					backgroundImageSrc={"/home-welcome-0.jpg"}
					backgroundImageBlur={0}
					hasNavigationButton={false}
					hasHomeButton={false}
					hasTabs
				/>,
				<PageTitle
					simpleText={
						<Stack align={"center"} style={{marginTop: isSm ? "calc(var(--mantine-spacing-md) * -4)" : "0"}}>
							<Icon name={"leaf"} className={classes.homeicon} />
							<Text inherit className={classes.homesimpletext}>{t('home.welcome1', {name: isAuthenticated && userAccount?.getFirstName() ? `, ${userAccount.getFirstName()}, ` : ``})}</Text>
						</Stack>
					}
					backgroundImageSrc={"/home-welcome-1.jpg"}
					backgroundImageBlur={0}
					hasNavigationButton={false}
					hasHomeButton={false}
					hasTabs
				/>,
				<PageTitle
					simpleText={
						<Stack align={"center"} style={{marginTop: isSm ? "calc(var(--mantine-spacing-md) * -4)" : "0"}}>
							<FontAwesomeIcon icon={faHeartSolid} className={classes.homeicon + " " + classes.homeheartbeaticon}/>
							<Text inherit className={classes.homesimpletext}>{t('home.welcome2')}</Text>
						</Stack>
					}
					backgroundImageSrc={"/home-welcome-2.jpg"}
					backgroundImageBlur={0}
					hasNavigationButton={false}
					hasHomeButton={false}
					hasTabs
				/>,
				<PageTitle
					simpleText={
						<Stack align={"center"} gap={!isSm ? 0 : "md"} style={{marginTop: "calc(var(--mantine-spacing-md) * -6)"}}>
							<Group mah={"150px"}>
								<Icon name={"gpt"} className={classes.homegpticon} />
								<FontAwesomeIcon icon={faPlus} className={classes.homeplusicon}/>
								<Icon name={"hat-chef"} className={classes.homeicon} />
							</Group>

							<Text inherit className={classes.homesimpletext}>{t('recipe.actionCallGpt')}</Text>
							<Stack mt={!isSm ? "lg" : "xs"}>
								<Button component={"a"} size={!isSm ? "lg" : "xs"} color={"secondary"} variant={"filled"}
										style={{fontFamily: theme.fontFamily, color: "var(--mantine-color-white)"}}
										justify="space-between"
										leftSection={<Icon name={"gpt"} style={{width: "42px", height: "42px", padding: !isSm ? "0" : "12px", marginLeft: "-12px" }}/>}
										rightSection={<FontAwesomeIcon icon={faUpRightFromSquare}/>}
										target="_blank" href="https://chatgpt.com/g/g-FDauaPnt4-cuckookitchen-ai"
								>
									{t("recipe.getCookingNow")}
								</Button>
								<Button component={"a"} size={!isSm ? "lg" : "xs"} color={"secondary"} variant={"filled"}
										style={{fontFamily: theme.fontFamily, color: "var(--mantine-color-white)"}}
										justify="space-between"
										leftSection={<Icon name={"hat-chef"} style={{width: "18px", height: "18px", marginRight: "12px"}}/>}
										rightSection={<FontAwesomeIcon icon={faChevronRight} style={{paddingRight: "6px"}}/>}
										onClick={() => {setTabValue("0"); scrollIntoView();}}
								>
									{t("recipe.tryAiCreations")}
								</Button>
							</Stack>
							{isSm ? null :
								<Text size={"md"} mt={!isSm ? "lg" : "xs"} c={"white"} maw={!isSm ? "75%" : "100%"} style={{fontFamily: theme.fontFamily}}>
									{t("recipe.actionCallGptClause")}
								</Text>
							}
						</Stack>
					}
					backgroundImageSrc={"/recipes-gpt.jpg"}
					backgroundImageBlur={0}
					hasNavigationButton={false}
					hasHomeButton={false}
					hasTabs
				/>,
				<PageTitle
					simpleText={
						<Stack align={"center"} style={{marginTop: isSm ? "calc(var(--mantine-spacing-md) * -4)" : "0"}}>
							<Icon name={"logo-full-fp"} className={classes.fpslogo} />
							<Text inherit className={classes.homesimpletext}>{t('home.welcome3')}</Text>
							<Button component={"a"} size={!isSm ? "lg" : "xs"} color={"secondary"} variant={"filled"}
									style={{fontFamily: theme.fontFamily, color: "var(--mantine-color-white)"}}
									justify="space-between"
									rightSection={<FontAwesomeIcon icon={faChevronRight}/>}
									onClick={() => isAuthenticated ? studioNavigate(navigate) : loginAccount()}
							>
								{t("studio.studio")}
							</Button>
						</Stack>
					}
					backgroundImageSrc={"/home-welcome-3.jpg"}
					backgroundImageBlur={0}
					hasNavigationButton={false}
					hasHomeButton={false}
					hasTabs
				/>,
			]}>

			{/*<Box className={classes.homesvgouter} visibleFrom={"sm"}>*/}
			{/*	<BackgroundSvg className={classes.homesvg}/>*/}
			{/*</Box>*/}

			<Tabs defaultValue="0" variant="outline" value={tabValue}>

				<Container>

					<ScrollTab>
						<ScrollTab.Tab selected={tabValue === "0"} onClick={() => setTabValue("0")}>
							<RecipeIconTitle />
						</ScrollTab.Tab>
						<ScrollTab.Tab selected={tabValue === "1"} onClick={() => setTabValue("1")}>
							<IngredientIconTitle />
						</ScrollTab.Tab>
					</ScrollTab>

					<AdvertisingGrid>

						<Tabs.Panel value="0" ref={targetRef}>
							<Space h={"lg"}/>
							<Recipes />
							<SchemaOrgCredits pl={"md"} i18nKey={"common.schemaOrgAttribution"}/>
						</Tabs.Panel>

						<Tabs.Panel value="1">
							<Space h={"lg"}/>
							<Entities />
							<FlavorDbCredits pl={"md"} i18nKey={"common.flavordbIngredientsAttribution"}/>
						</Tabs.Panel>

						<Space h={"lg"}/>
						<Sponsors section={"home"}/>

					</AdvertisingGrid>
				</Container>
			</Tabs>
        </Page>
	)
}

export default Home;