import React, {useEffect, useRef, useState} from 'react';
import {Carousel} from "@mantine/carousel";
import classes from './CustomCarousel.module.css';
import Autoplay from 'embla-carousel-autoplay';
import {theme} from "../../Theme";

/**
 * CustomCarousel
 */
export const CustomCarousel = (props) => {

    const { interval = theme.custom.components.carousel.defaultInterval,
            autoplayActivable = true,
            color = "primary",
            slideSize="100%",
            slideGap= 0,
            slidesToScroll = 1,
            withControls = true,
            withIndicators = true,
            indicatorsClassName = "",
            orientation="horizontal",
            dragFree = false,
            loop= true,
            containScroll= "trimSnaps",
            children,
            onSlideChange = () => {}} = props;

    const validChildren = React.Children.toArray(children).filter(child => child !== null && child !== undefined);
    const autoplay = useRef(Autoplay({ delay: validChildren.length > 1 ? interval : 0 }));

    const [showControls, setShowControls] = useState(false);

    useEffect(() => {

        try {
            if (interval > 0 && validChildren.length > 1 && autoplay.current) {

                if (!autoplayActivable) {
                    autoplay.current.stop();
                }
                else {
                    autoplay.current.play();
                }
            }
        }
        catch(error) {
            // noop
        }

    }, [autoplayActivable]);

    const handleMouseEnter = () => {
        if (interval > 0 && validChildren.length > 1 && autoplayActivable && autoplay.current) {
            autoplay.current.stop();
        }

        setShowControls(true);
    };

    const handleMouseLeave = () => {
        if (interval > 0 && validChildren.length > 1 && autoplayActivable && autoplay.current) {
            autoplay.current.play();
        }

        setShowControls(false);
    };

    return (
        <Carousel
                plugins={interval > 0 && validChildren.length > 1 ? [autoplay.current] : []}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                slideSize={slideSize}
                slideGap={slideGap}
                align="start"
                controlsOffset="md"
                orientation={orientation}
                slidesToScroll={slidesToScroll}
                controlSize={38}
                dragFree={dragFree}
                loop={loop}
                draggable={validChildren.length > 1}
                withControls={validChildren.length > 1 && showControls && withControls}
                withIndicators={validChildren.length > 1 && withIndicators}
                speed={24}
                flex={1}
                containScroll={containScroll}
                onSlideChange={onSlideChange}
                classNames={{
                    control: `${classes.carouselcontrol} ${classes['carouselcontrol' + color]}`,
                    indicators: `${classes.carouselindicators} ${indicatorsClassName}`,
                    indicator: `${classes.carouselindicator} ${classes['carouselindicator' + color]}`,
                }}
        >
            {children}
        </Carousel>
    )
}

/**
 * CustomCarouselSlide
 */
const CustomCarouselSlide = (props) => {

    const {children} = props;

    return (
        <Carousel.Slide {...props}>
            {children}
        </Carousel.Slide>
    )
}

CustomCarousel.Slide = CustomCarouselSlide;