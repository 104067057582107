import React, {useEffect} from 'react';
import {Box, Grid, Space} from "@mantine/core";
import {theme} from "../../Theme";
import classes from './Advertising.module.css';

/**
 * AdvertisingBlock
 */
export const AdvertisingBlock = (props) => {

	const {slot} = props;

	useEffect(() => {
		if (typeof window !== "undefined" && window.adsbygoogle) {
			try {
				(window.adsbygoogle = window.adsbygoogle || []).push({});
			} catch (e) {
				console.error("AdSense error:", e);
			}
		}
	},[]);

	return <Box w="100%" style={{borderRadius: "var(--mantine-radius-default)", overflow: "hidden"}}>
			<ins
				className="adsbygoogle"
				style={{display: "block", width: "100%", height: "100%"}}
				data-ad-client={`ca-pub-2497244295589304`} // TODO in env
				// data-ad-slot={environment.googleAdsSlot || slot} // TODO in env
				data-ad-format="auto"
				data-full-width-responsive="true"
			/>
		</Box>
}

/**
 * AdvertisingGrid
 */
export const AdvertisingGrid = (props) => {

	const {space, component, children} = props;

	return (
		<Grid justify="space-between" align="flex-start">

			<Grid.Col span={{base: 12, xs: 12, sm: theme.custom.ads.active ? 9 : 12}}
					  style={{alignItems: 'flex-start'}}>
				{children}
			</Grid.Col>

			{theme.custom.ads.active &&
				<Grid.Col span={{base: 12, xs: 12, sm: 3 }} visibleFrom={"sm"}>

					{!space ? null :
						<Space h={space}/>
					}

					<Space h={"lg"}/>

					{!component ? null :
						<Box>
							{component}
							<Space h={"lg"}/>
						</Box>
					}

					<AdvertisingBlock/>

				</Grid.Col>
			}
		</Grid>
	);
}