import CryptoJS from "crypto-js";

/**
 * Decrypts the encrypted data received from the backend.
 *
 * @param {string} encryptedData - The encrypted string in Base64 format.
 * @returns {object|null} - The decrypted JSON object, or null if decryption fails.
 */
export function decrypt(encryptedData) {

    try {

        // Decode Base64
        const mergedBytes = CryptoJS.enc.Base64.parse(encryptedData);

        // Extract the last 32 bytes as AES key
        const keyHex = mergedBytes.words.slice(-8); // AES-256 key (last 32 bytes)
        const key = CryptoJS.lib.WordArray.create(keyHex, 32);

        // Extract encrypted data (excluding the last 32 bytes)
        const encryptedBytes = CryptoJS.lib.WordArray.create(
            mergedBytes.words.slice(0, -8), // Remove last 32 bytes (AES key)
            mergedBytes.sigBytes - 32
        );

        // Decrypt using AES-256 in ECB mode with PKCS7 padding
        const decryptedBytes = CryptoJS.AES.decrypt(
            { ciphertext: encryptedBytes },
            key,
            { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }
        );

        return JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
    }
    catch (error) {
        console.error("Decryption failed:", error);
        return null;
    }
}