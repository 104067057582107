import {useTranslation} from "react-i18next";
import {useAccountContext} from "../account/AccountContext";
import {useEnvironmentContext} from "../environment/EnvironmentContext";

/**
 * useFeatures
 */
export const useFeatures = () => {

	const {environment} = useEnvironmentContext();

	const { isAuthenticated, userAccount } = useAccountContext();

	const {t} = useTranslation();

	const featurePlansFree = t("feature.featurePlans.free1", { returnObjects: true });
	const featurePlansAccount = t("feature.featurePlans.account1", { returnObjects: true, trialDays: environment.paymentPremiumTrialDays, maxProjects: 10});
	const featurePlansPremium = t("feature.featurePlans.premium1", { returnObjects: true, aiRecipeTokens: environment.aiTryRecipeTokens, aiImageTokens: environment.aiTryImageTokens});
	const featurePlansStudio = t("feature.featurePlans.studio1", { returnObjects: true, aiRecipeTokens: environment.aiMaxRecipeTokens, aiImageTokens: environment.aiMaxImageTokens, culinaryTechniques: 500});

	/**
	 * FeaturePlans
	 */
	const FeaturePlans = {
		FREE: {
			id: "free",
			planName: featurePlansFree.plan,
			name: featurePlansFree.name,
			subhead: featurePlansFree.subhead,
			claim: featurePlansFree.claim,
			target: featurePlansFree.target,
			highlights: featurePlansFree.highlights,
			cta: featurePlansFree.cta,
			enabled: true,
			priority: 0
		},
		ACCOUNT: {
			id: "account",
			planName: featurePlansAccount.plan,
			name: featurePlansAccount.name,
			subhead: featurePlansAccount.subhead,
			claim: featurePlansAccount.claim,
			target: featurePlansAccount.target,
			highlights: featurePlansAccount.highlights,
			cta: featurePlansAccount.cta,
			enabled: isAuthenticated,
			priority: 1
		},
		PREMIUM: {
			id: "premium",
			planName: featurePlansPremium.plan,
			name: featurePlansPremium.name,
			subhead: featurePlansPremium.subhead,
			claim: featurePlansPremium.claim,
			target: featurePlansPremium.target,
			highlights: featurePlansPremium.highlights,
			cta: featurePlansPremium.cta,
			enabled: isAuthenticated && (userAccount?.isAdmin() || userAccount?.isPremiumTrial() || userAccount?.isPremium()),
			priority: 2,
			payments: [
				{
					pricePeriod: "monthly",
					priceUsd: environment.paymentPremiumMonthlyPriceUsd,
					priceId: environment.paymentPremiumMonthlyPriceId,
					enabled: isAuthenticated
				},
				{
					pricePeriod: "yearly",
					priceUsd: environment.paymentPremiumYearlyPriceUsd,
					priceId: environment.paymentPremiumYearlyPriceId,
					enabled: isAuthenticated
				}
			]
		},
		STUDIO: {
			id: "studio",
			planName: featurePlansStudio.plan,
			name: featurePlansStudio.name,
			subhead: featurePlansStudio.subhead,
			claim: featurePlansStudio.claim,
			target: featurePlansStudio.target,
			highlights: featurePlansStudio.highlights,
			cta: featurePlansStudio.cta,
			enabled: isAuthenticated && (userAccount?.isAdmin() || userAccount?.isStudio()),
			priority: 3,
			payments: [
				{
					pricePeriod: "monthly",
					priceUsd: environment.paymentStudioMonthlyPriceUsd,
					priceId: environment.paymentStudioMonthlyPriceId,
					enabled: isAuthenticated
				},
				{
					pricePeriod: "yearly",
					priceUsd: environment.paymentStudioYearlyPriceUsd,
					priceId: environment.paymentStudioYearlyPriceId,
					enabled: isAuthenticated
				}
			]
		}
	};

	/**
	 * Features
	 */
	const Features = {
		account: {
			name: t("feature.account.account"),
			plan: FeaturePlans.FREE,
			features: {
				recipeFavorites: {
					name: t("feature.account.recipeFavorites"),
					plan: FeaturePlans.ACCOUNT
				},
				ingredientFavorites: {
					name: t("feature.account.ingredientFavorites"),
					plan: FeaturePlans.ACCOUNT,
					// maxRecords: FeaturePlans.ACCOUNT.enabled ? 50 : 0 // Example to limit records...
				},
				moleculeFavorites: {
					name: t("feature.account.moleculeFavorites"),
					plan: FeaturePlans.ACCOUNT
				}
			}
		},
		studio: {
			name: t("studio.studio"),
			plan: FeaturePlans.ACCOUNT,
			features: {
				unlimitedProjects: {
					name: t("feature.studio.unlimitedProjects"),
					plan: FeaturePlans.STUDIO
				},
				limitedProjects: {
					name: t("feature.studio.limitedProjects", {maxProjects: 10}),
					plan: FeaturePlans.ACCOUNT,
					limit: 10
				},
				unlimitedPairings: {
					name: t("feature.studio.unlimitedPairings", {count: 2300}),
					plan: FeaturePlans.STUDIO,
				},
				// uniquePairingPerIngredient: {
				// 	name: t("feature.studio.uniquePairingPerIngredient"),
				// 	plan: FeaturePlans.ACCOUNT
				// },
				uniqueIngredientGlobally: {
					name: t("feature.studio.uniqueIngredientGlobally", {count: 850}),
					plan: FeaturePlans.ACCOUNT
				},
				aiTryRecipeGenerator: {
					name: t("feature.studio.aiRecipeGenerator", {tokens: environment.aiTryRecipeTokens}),
					plan: FeaturePlans.PREMIUM,
				},
				aiTryImageGenerator: {
					name: t("feature.studio.aiImageGenerator", {tokens: environment.aiTryImageTokens}),
					plan: FeaturePlans.PREMIUM,
				},
				aiRecipeGenerator: {
					name: t("feature.studio.aiRecipeGenerator", {tokens: environment.aiMaxRecipeTokens}),
					plan: FeaturePlans.STUDIO,
				},
				aiImageGenerator: {
					name: t("feature.studio.aiImageGenerator", {tokens: environment.aiMaxImageTokens}),
					plan: FeaturePlans.STUDIO,
				},
				ingredientGroups: {
					name: t("feature.studio.ingredientGroups"),
					plan: FeaturePlans.STUDIO,
				},
				techniques: {
					name: t("feature.studio.techniques", {count: 500}),
					plan: FeaturePlans.STUDIO,
				},
				customIngredients: {
					name: t("feature.studio.customIngredients"),
					plan: FeaturePlans.STUDIO,
				},
				heatmap: {
					name: t("studio.heatmap"),
					plan: FeaturePlans.STUDIO,
				},
				nutrients: {
					name: t("feature.ingredient.nutrients"),
					plan: FeaturePlans.STUDIO,
					note: "Coming Soon"
				},
				seasons: {
					name: t("feature.search.seasons"),
					plan: FeaturePlans.ACCOUNT,
				},
				ingredientEmotions: {
					name: t("feature.search.ingredientEmotions"),
					plan: FeaturePlans.STUDIO
				},
				ingredientFlavors: {
					name: t("feature.search.ingredientFlavors"),
					plan: FeaturePlans.STUDIO
				},
				ingredientOdors: {
					name: t("feature.search.ingredientOdors"),
					plan: FeaturePlans.STUDIO
				},
				ingredientTastes: {
					name: t("feature.search.ingredientTastes"),
					plan: FeaturePlans.STUDIO
				},
				ingredientCompounds: {
					name: t("feature.search.ingredientCompounds"),
					plan: FeaturePlans.STUDIO
				},
				molecules: {
					name: t("feature.search.molecules"),
					plan: FeaturePlans.ACCOUNT
				}
			}
		},
		search: {
			name: t("feature.search.search"),
			plan: FeaturePlans.FREE,
			features: {
				recipes: {
					name: t("feature.search.recipes"),
					plan: FeaturePlans.FREE
				},
				ingredients: {
					name: t("feature.search.ingredients"),
					plan: FeaturePlans.FREE,
				},
				seasons: {
					name: t("feature.search.seasons"),
					plan: FeaturePlans.FREE,
				},
				ingredientEmotions: {
					name: t("feature.search.ingredientEmotions"),
					plan: FeaturePlans.PREMIUM
				},
				ingredientFlavors: {
					name: t("feature.search.ingredientFlavors"),
					plan: FeaturePlans.PREMIUM
				},
				ingredientOdors: {
					name: t("feature.search.ingredientOdors"),
					plan: FeaturePlans.PREMIUM
				},
				ingredientTastes: {
					name: t("feature.search.ingredientTastes"),
					plan: FeaturePlans.PREMIUM
				},
				ingredientCompounds: {
					name: t("feature.search.ingredientCompounds"),
					plan: FeaturePlans.PREMIUM
				},
				ingredientScientific: {
					name: t("feature.search.ingredientScientific"),
					plan: FeaturePlans.PREMIUM
				},
				molecules: {
					name: t("feature.search.molecules"),
					plan: FeaturePlans.ACCOUNT
				}
			}
		},
		recipe: {
			name: t("feature.recipe.recipe"),
			plan: FeaturePlans.FREE,
			features: {
				ingredients: {
					name: t("feature.recipe.ingredients"),
					plan: FeaturePlans.FREE
				},
				ingredientsServingsCalculator: {
					name: t("feature.recipe.ingredientsServingsCalculator"),
					plan: FeaturePlans.FREE
				},
				ingredientsBasket: {
					name: t("feature.recipe.ingredientsBasket"),
					plan: FeaturePlans.ACCOUNT
				},
				instructions: {
					name: t("feature.recipe.instructions"),
					plan: FeaturePlans.FREE
				},
				instructionsStepsTracking: {
					name: t("feature.recipe.instructionsStepsTracking"),
					plan: FeaturePlans.ACCOUNT
				},
				instructionsStepsTimers: {
					name: t("feature.recipe.instructionsStepsTimers"),
					plan: FeaturePlans.PREMIUM
				},
				nutrients: {
					name: t("feature.recipe.nutrients"),
					plan: FeaturePlans.PREMIUM,
					note: "Coming Soon"
				},
				utensils: {
					name: t("feature.recipe.utensils"),
					plan: FeaturePlans.FREE,
				},
				relatedMolecules: {
					name: t("feature.recipe.relatedMolecules"),
					plan: FeaturePlans.ACCOUNT
				},
				relatedRecipes: {
					name: t("feature.recipe.relatedRecipes"),
					plan: FeaturePlans.FREE
				},
			}
		},
		ingredient: {
			name: t("feature.ingredient.ingredient"),
			plan: FeaturePlans.FREE,
			features: {
				foodFacts: {
					name: t("feature.ingredient.foodFacts"),
					plan: FeaturePlans.FREE
				},
				littleStories: {
					name: t("feature.ingredient.littleStories"),
					plan: FeaturePlans.FREE,
					note: "Coming Soon"
				},
				categoryPairing: {
					name: t("feature.ingredient.categoryPairing"),
					plan: FeaturePlans.FREE
				},
				limitedPairing: {
					name: t("feature.ingredient.limitedPairing", {maxPairings: 12}),
					plan: FeaturePlans.FREE,
					limit: 12
				},
				unlimitedPairing: {
					name: t("feature.ingredient.unlimitedPairing"),
					plan: FeaturePlans.ACCOUNT
				},
				nutrients: {
					name: t("feature.ingredient.nutrients"),
					plan: FeaturePlans.PREMIUM,
					note: "Coming Soon"
				},
				techniqueLevels: {
					name: t("feature.ingredient.cookingTechniquesAndLevels", {count: 19000}),
					plan: FeaturePlans.PREMIUM,
				},
				naturalSources: {
					name: t("feature.ingredient.naturalSources"),
					plan: FeaturePlans.ACCOUNT
				},
				relatedIngredients: {
					name: t("feature.ingredient.relatedIngredients"),
					plan: FeaturePlans.FREE
				},
				molecules: {
					name: t("feature.ingredient.molecules"),
					plan: FeaturePlans.ACCOUNT
				},
				relatedRecipes: {
					name: t("feature.ingredient.relatedRecipes"),
					plan: FeaturePlans.FREE
				}
			}
		},
		molecule: {
			name: t("feature.molecule.molecule"),
			plan: FeaturePlans.ACCOUNT,
			features: {
				emotions: {
					name: t("feature.molecule.emotions"),
					plan: FeaturePlans.PREMIUM
				},
				flavors: {
					name: t("feature.molecule.flavors"),
					plan: FeaturePlans.ACCOUNT
				},
				odors: {
					name: t("feature.molecule.odors"),
					plan: FeaturePlans.ACCOUNT
				},
				tastes: {
					name: t("feature.molecule.tastes"),
					plan: FeaturePlans.ACCOUNT
				},
				compounds: {
					name: t("feature.molecule.compounds"),
					plan: FeaturePlans.ACCOUNT
				},
				scientific: {
					name: t("feature.molecule.scientific"),
					plan: FeaturePlans.ACCOUNT
				},
				foundInIngredients: {
					name: t("feature.molecule.foundInIngredients"),
					plan: FeaturePlans.ACCOUNT
				},
				foundInRecipes: {
					name: t("feature.molecule.foundInRecipes"),
					plan: FeaturePlans.ACCOUNT
				}
			}
		}
	}

	return {
		FeaturePlans, Features
	};
};