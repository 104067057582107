import React, {memo, useCallback, useEffect, useMemo, useState} from "react";
import {
	Box,
	Button,
	Center,
	Group,
	Image,
	Modal, Slider, Space,
	Stack, TagsInput,
	Text, Textarea,
	TextInput,
	Tooltip,
} from "@mantine/core";
import {
	getBezierPath,
	Handle, NodeResizeControl,
	NodeToolbar,
	Position,
	useInternalNode,
	useOnSelectionChange, useReactFlow, useStore,
} from "@xyflow/react";

import classes from "./Studio.module.css"
import useWiki from "../useWiki";
import {
	faCircleInfo,
	faImage,
	faObjectUngroup,
	faTrash,
	faUpRightFromSquare
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	checkedGroupWeighting, extractEntityPairings, extractMolecules, formatPc,
	getColorByThreshold, getConnectedEdges, getEdgeWidth, getEntityById, getNodeById,
	getNodesByParentId, getSharedMoleculesPc, GROUP_WEIGHTING,
	MOLECULES_THRESHOLDS, PAIRINGS_THRESHOLDS, toEntityId,
	ungroupNode
} from "./StudioUtils";
import {ingredientNameNavigate} from "../ingredient/IngredientLink";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useStudioStore} from "./StudioPage";
import {adjustRgbBrightness, hexToRgb, interpolateColor, toRgb} from "../../util/color";
import {theme} from "../../Theme";
import {faPen} from "@fortawesome/free-solid-svg-icons/faPen";
import {useDisclosure} from "@mantine/hooks";
import {Icon} from "../../components/icons/Icons";
import {DeleteModal} from "../../components/delete/DeleteModal";
import {useApplicationContext} from "../../components/application/ApplicationContext";
import {localized} from "../../i18n";

/**
 * useGlow
 */
const useGlow = (nodes, thresholds = PAIRINGS_THRESHOLDS) => {

	// Get the edges from the global React Flow state using useStore
	const edges = useStore((state) => state.edges);

	let studioHeatmapEnabled = useStudioStore((state) => state.studioHeatmapEnabled);
	const [connectedEdgesPercentage, setConnectedEdgesPercentage] = useState(0);

	/**
	 * Calculates the glow color based on thresholds and percentage.
	 * @param {number} percentage - The percentage value to evaluate.
	 * @returns {string} - The glow color in RGB format.
	 */
	function getGlowColor(percentage) {

		const clampedPercentage = Math.min(100, Math.max(0, percentage));

		// Find the two closest thresholds to interpolate between
		for (let i = 0; i < thresholds.length - 1; i++) {
			const current = thresholds[i];
			const next = thresholds[i + 1];

			if (clampedPercentage >= next.limit) {
				const range = current.limit - next.limit;
				const factor = (clampedPercentage - next.limit) / range;
				return toRgb(interpolateColor(next.color, current.color, factor));
			}
		}

		// Fallback to the lowest color if no thresholds match
		return thresholds[thresholds.length - 1].color;
	}

	useEffect(() => {

		// Calculate the percentage of connected edges relative to all edges
		setConnectedEdgesPercentage(getConnectedEdges(edges, nodes)?.length / edges?.length * 100 || 0);

	}, [studioHeatmapEnabled, nodes, edges]);

	return {
		enabled: studioHeatmapEnabled && edges.length > 0 && connectedEdgesPercentage > 0,
		pc: connectedEdgesPercentage,
		color: () => getGlowColor(connectedEdgesPercentage)
	};
};

/**
 * DynamicGlow
 */
const DynamicGlow = ({
						node,
						percentage,
						color,
						opacity = 0.9,
						scaleFactor = 40,
						transition = "0.5s",
						blurRadius = "160px",
						borderRadius = "50%",
						minSpread = 80,
						maxSpread = 160,
					}) => {

	const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
	const { getViewport } = useReactFlow();
	const viewport = getViewport();

	// Mouse position relative to viewport
	useEffect(() => {
		const handleMouseMove = (e) => {
			setMousePosition({
				x: (e.clientX - viewport.x) / viewport.zoom,
				y: (e.clientY - viewport.y) / viewport.zoom,
			});
		};
		window.addEventListener("mousemove", handleMouseMove);
		return () => window.removeEventListener("mousemove", handleMouseMove);
	}, [viewport]);

	/**
	 * Calculate dynamic spread based on percentage
	 */
	function getSpreadRadius(percentage) {

		// Do not show to high density
		if(percentage === 100) {
			return maxSpread * 80 / 100;
		}

		// Map the percentage to a spread value between min and max
		return minSpread + ((percentage / 100) * (maxSpread - minSpread));
	}

	// Calculate shadow offset dynamically
	function getShadowOffset(node) {
		if (!node) return { x: 0, y: 0 };

		const nodeCenter = {
			x: node.positionAbsoluteX + node.width / 2,
			y: node.positionAbsoluteY + node.height / 2,
		};

		const dx = mousePosition.x - nodeCenter.x;
		const dy = mousePosition.y - nodeCenter.y;

		return {
			x: -dx / scaleFactor,
			y: -dy / scaleFactor,
		};
	}

	const shadowOffset = getShadowOffset(node); // Assuming nodes[0] is the target node

	return (
		<div style={{ position: "absolute", width: "100%", height: "100%" }}>
			<div
				style={{
					position: "absolute",
					top: `50%`,
					left: `50%`,
					width: `0%`,
					height: `0%`,
					borderRadius: borderRadius,
					boxShadow: `0px 0px ${blurRadius} ${getSpreadRadius(percentage)}px ${color}`,
					transition: `transform ${transition} ease-out`,
					transform: `translate(${shadowOffset.x}px, ${shadowOffset.y}px)`,
					pointerEvents: 'none',
					opacity: opacity,
					zIndex: -1,
				}}
			/>
		</div>
	);
};

/**
 * StaticGlow
 */
const StaticGlow = ({
				  percentage,
				  color,
				  opacity = 0.7,
				  blurRadius = "200px",
				  borderRadius = "8px",
				  minSpread = 0,
				  maxSpread = 10,
				  offset = 2
			  }) => {

	/**
	 * Calculate dynamic spread based on percentage
	 */
	function getSpreadRadius(percentage) {
		// Map the percentage to a spread value between min and max
		return minSpread + ((percentage / 100) * (maxSpread - minSpread));
	}

	return (
		<div style={{ position: "absolute", width: "100%", height: "100%" }}>
			<div
				style={{
					position: "absolute",
					top: `-${offset}px`,
					left: `-${offset}px`,
					width: `calc(100% + ${offset * 2}px)`,
					height: `calc(100% + ${offset * 2}px)`,
					borderRadius: borderRadius,
					boxShadow: `0px 0px ${blurRadius} ${getSpreadRadius(percentage)}px ${color}`,
					pointerEvents: 'none',
					opacity: opacity,
					zIndex: -1,
				}}
			/>
		</div>
	);
};

/**
 * useSelectionColor
 */
const useSelectionColor = (	nodeId,
							nodeSelected,
							glowColor,
						 	adjustBrightnessPc = -20,
							defaultSelectionColor = "var(--mantine-color-primary-6)",
							defaultUnselectionColor = "var(--mantine-color-primary-2)",
	) => {

	const [selectionColor, setSelectionColor] = useState(defaultSelectionColor);
	const [unselectionColor, setUnselectionColor] = useState(defaultUnselectionColor);

	useEffect(() => {

		const element = document.querySelector(`.react-flow__node[data-id="${nodeId}"]`);

		if (element) {

			const selectionColor = glowColor !== undefined ? glowColor : defaultSelectionColor;
			const unselectionColor = glowColor !== undefined ? toRgb(adjustRgbBrightness(glowColor, adjustBrightnessPc)) : defaultUnselectionColor;

			// Force inline border color
			element.style.borderColor = nodeSelected ? selectionColor : unselectionColor;

			// Mouse / focus handling
			const handleMouseEnter = () => (element.style.borderColor = selectionColor);
			const handleMouseLeave = () => (element.style.borderColor = nodeSelected ? selectionColor : unselectionColor);

			element.addEventListener("mouseenter", handleMouseEnter);
			element.addEventListener("mouseleave", handleMouseLeave);
			element.addEventListener("focus", handleMouseEnter);
			element.addEventListener("blur", handleMouseLeave);

			setSelectionColor(selectionColor);
			setUnselectionColor(unselectionColor);

			// return () => {
			// 	// Cleanup event listeners
			// 	element.removeEventListener("mouseenter", handleMouseEnter);
			// 	element.removeEventListener("mouseleave", handleMouseLeave);
			// 	element.removeEventListener("focus", handleMouseEnter);
			// 	element.removeEventListener("blur", handleMouseLeave);
			// };
		}
	}, [nodeId, nodeSelected, glowColor, adjustBrightnessPc, defaultSelectionColor, defaultUnselectionColor]);

	return {
		selectionColor,
		unselectionColor
	}
}

/**
 * ImageWithZoom
 */
const ImageWithZoom = ({node, selectionColor, unselectionColor}) => {

	const [hovered, setHovered] = useState(false);

	const {setNodes, getNode} = useReactFlow();

	const {
		data: dataEntityWiki, isLoaded: isLoadedEntityWiki,
		reset: resetEntityWiki, refetch: refetchEntityWiki} =
		useWiki({
			enabled: true,
			url: node.data.entity.wikiLink,
			onSuccess: (wiki) => {

				setNodes((prevNodes) =>
					prevNodes.map((n) => {
						// Update only the current group node
						if (n.id === node.id) {
							return {
								...n,
								data: {
									...n.data,
									wiki: wiki
								}
							};
						}
						return n; // Leave other nodes unchanged
					})
				);
			}
		})

	return !dataEntityWiki || !dataEntityWiki.image ?
		<Box className={`${classes.typesimagewithzoomroot} ${classes.typesimagewithzoomnoimage}`}>
			<FontAwesomeIcon icon={faImage} size={"3x"} color={node.selected ? selectionColor : unselectionColor}/>
		</Box>
		:
		<Box className={classes.typesimagewithzoomroot}
			 onMouseEnter={() => setHovered(true)}
			 onMouseLeave={() => setHovered(false)}>
			<Image
				src={dataEntityWiki.image}
				className={classes.typesimagewithzoomimage}
				style={{
					transform: hovered? 'scale(1.1)' : 'scale(1)',  // Scale on hover
					transition: 'transform 0.1s ease-in-out',  // Smooth transition
				}}
			/>

			{/*<Container className={classes.imagewithzoomoverlay}>*/}
			{/*	<Overlay color="var(--mantine-color-primary-9)" backgroundOpacity={!hovered ? 0 : 0.12} blur={0} zIndex={1}/>*/}
			{/*	/!*<Stack h={"100%"} pt={"100%"} align="flex-end" justify="center">*!/*/}
			{/*	/!*	*!/*/}
			{/*	/!*</Stack>*!/*/}
			{/*	<Stack gap={0} justify="flex-end" className={classes.imagewithzoomtitle}>*/}
			{/*		<Text size={"sm"}>{node.data.label}</Text>*/}
			{/*/!*		<Group align="flex-start" gap={6} wrap={"nowrap"} >*!/*/}

			{/*/!*			<Stack gap={0}>*!/*/}
			{/*/!*				<Text c={"white"} lineClamp={1} pb={4}>*!/*/}
			{/*/!*					{localized(recipe, 'name')}*!/*/}
			{/*/!*				</Text>*!/*/}
			{/*/!*				{showCategory ?*!/*/}
			{/*/!*					<Text c={"white"} size={'xs'} opacity={0.75}>*!/*/}
			{/*/!*						{sortedCategories(recipe.categories)*!/*/}
			{/*/!*							.map((category, index) => localized(category, 'name'))*!/*/}
			{/*/!*							.join(" / ")}*!/*/}
			{/*/!*					</Text>*!/*/}
			{/*/!*					:*!/*/}
			{/*/!*					// <Text c={"white"} size={'xs'} opacity={0.75}>*!/*/}
			{/*/!*					//     &nbsp;*!/*/}
			{/*/!*					// </Text>*!/*/}
			{/*/!*					null*!/*/}
			{/*/!*				}*!/*/}
			{/*/!*			</Stack>*!/*/}
			{/*/!*		</Group>*!/*/}
			{/*/!*		<FontAwesomeIcon icon={faChevronRight} color={"white"} />*!/*/}
			{/*	</Stack>*/}
			{/*</Container>*/}
		</Box>
};

/**
 * IconWithZoom
 */
const IconWithZoom = ({node, selectionColor, unselectionColor}) => {

	return (
		<Box className={`${classes.typesimagewithzoomroot} ${classes.typesimagewithzoomnoimage}`}>
			<Icon name={"leaf"} style={{marginTop: "5px", marginRight: "2px", width: `${node.width / 2}px`, height: `${node.height / 2}px`, fill: node.selected ? selectionColor : unselectionColor}}/>
		</Box>
	)
};

/**
 * NodeData
 */
const NodeData = ({label, description, color = "secondary", withTechniques = false, onSave = () => {}}) => {

	const [opened, { open, close }] = useDisclosure(false);

	const {techniques} = useApplicationContext();

	const [inputLabel, setInputLabel] = useState("");
	const [inputDescription, setInputDescription] = useState("");

	const {t} = useTranslation();

	useEffect(() => {
		if (opened) {
			setInputLabel(label);
			setInputDescription(description);
		}
	}, [opened, label, description]);

	/**
	 * techniquesDataTags
	 */
	const techniquesDataTags = useMemo(() => {

		if (!withTechniques) {
			return [];
		}

		// Group techniques in groups
		const grouped = techniques.reduce((acc, item) => {
			const categoryName = localized(item.techniqueCategory, "name");
			const categorySort = item.techniqueCategory?.sort ?? 0;

			if (!acc[categoryName]) {
				acc[categoryName] = {
					group: categoryName,
					sort: categorySort,
					items: [],
					itemsWithSort: []
				};
			}

			acc[categoryName].itemsWithSort.push({
				label: localized(item, "name"),
				sort: item.sort ?? 0
			});

			return acc;
		}, {});

		// Sort techniques in categories
		const formatted = Object.values(grouped).map((group) => {
			const sortedItems = group.itemsWithSort
				.sort((a, b) => {
					if (a.sort !== b.sort) return a.sort - b.sort;
					return a.label.localeCompare(b.label);
				})
				.map((el) => el.label);

			return {
				group: group.group,
				sort: group.sort,
				items: sortedItems
			};
		});

		// Sort categories
		return formatted.sort((a, b) => {
			if (a.sort !== b.sort) return a.sort - b.sort;
			return a.group.localeCompare(b.group);
		});

	}, [techniques, withTechniques]);

	/**
	 * tagsFromString
	 */
	const tagsFromString = (str) => str.split(',').map(s => s.trim()).filter(Boolean);

	/**
	 * stringFromTags
	 */
	const stringFromTags = (tags) => tags.join(', ');

	/**
	 * renderTagsInputOption
	 */
	const renderTagsInputOption = ({ option }) => {

		const technique = techniques.find(technique => localized(technique, "name") === option.value);

		return (
			<Stack pl={"lg"} gap={0} className={classes.modalinputoptionrendered} flex={1}>
				<Text size={"sm"} c={"secondary.9"} fw={700}>{localized(technique, "name")}</Text>
				<Text size={"xs"} c={"secondary.9"} opacity={0.75}>{localized(technique, "description")}</Text>
				<Text size={"xs"} c={"secondary.9"} opacity={0.75}>{localized(technique, "note")}</Text>
			</Stack>
		)
	};

	return (

		<>
			<Modal opened={opened}
				   color={"white"}
				   centered
				   closeOnClickOutside
				   withCloseButton={false}
				   size={"xl"}
				   overlayProps={{color: `var(--mantine-color-${color}-12)`, backgroundOpacity: 0.75, blur: 7}}
				   // zIndex={404}
				   onClose={close}
				   classNames={{
					   root: classes.modalroot,
					   header: classes.modalheader,
					   content: classes.modalcontent,
					   inner: classes.modalinner,
					   body: classes.modalbody,
					   option: classes.modalinputoption
				   }}
			>
				<Text size={"xl"} fw={700} pb={"md"} c={`white`} flex={1}>{inputLabel}</Text>

				<Stack>
					{techniquesDataTags?.length > 0 ?
						<TagsInput
							color={"secondary"}
							label={t("studio.name")}
							placeholder={t("studio.group.addTechniqueOrName")}
							data={techniquesDataTags}
							value={tagsFromString(inputLabel)}
							onChange={(val) => setInputLabel(stringFromTags(val))}
							onFocus={(e) => e.currentTarget.select()}
							renderOption={renderTagsInputOption}
							allowDuplicates
							splitChars={[',']}
							maxTags={10}
							classNames={{
								label: classes.modalinputlabel,
								input: classes.modalinputinput,
								inputField: classes.modalinputinputField,
								pill: classes.modalinputpill,
								option: classes.modalinputoption,
								groupLabel: classes.modalinputgroupLabel
							}}
						/>
						:
						<TextInput
							label={t("studio.name")}
							value={inputLabel}
							onChange={(e) => setInputLabel(e.currentTarget.value)}
							onFocus={(e) => e.currentTarget.select()}
							classNames={{
								label: classes.modalinputlabel,
								input: classes.modalinputinput
							}}
						/>
					}

					<Textarea
						label={t("studio.description")}
						value={inputDescription}
						onChange={(e) => setInputDescription(e.currentTarget.value)}
						onFocus={(e) => e.currentTarget.select()}
						autosize
						minRows={2}
						maxRows={4}
						classNames={{
							label: classes.modalinputlabel,
							input: classes.modalinputinput
						}}
					/>
				</Stack>
				<Space h="lg"/>
				<Group justify={"flex-end"} pt={"md"}>
					<Button c={`white`} color={color} miw={140} variant={"light"} onClick={close}>{t("common.cancel")}</Button>
					<Button color={color} miw={140} onClick={() => {onSave(inputLabel, inputDescription); close();}}>{t("common.save")}</Button>
				</Group>
			</Modal>

			<Button variant={"filled"} color={"secondary"} size={"xs"} pl={"xs"} pr={"xs"} radius={4} onClick={open}>
				<FontAwesomeIcon icon={faPen}/>
			</Button>
		</>
	)
}

/**
 * dispatchNodeParentIdChange
 */
function dispatchNodeParentIdChange(parentId) {
	window.dispatchEvent(new CustomEvent("nodeParentIdChange", { detail: { parentId }}));
}

/**
 * NodeToolbarDefault
 */
const NodeToolbarDefault = ({ node, withRemove = true, withNodeData = false }) => {

	const { setNodes, getNode } = useReactFlow();
	const [selectedNodes, setSelectedNodes] = useState([]); // State to keep track of selected nodes

	const navigate = useNavigate();
	const { t } = useTranslation();

	// This callback updates the selected nodes' IDs whenever a selection change occurs
	const onChange = useCallback(({ nodes }) => {
		setSelectedNodes(nodes.map((node) => node.id)); // Map selected nodes to their IDs
	}, []);

	// React Flow hook to listen for selection changes
	useOnSelectionChange({
		onChange, // Pass the memoized onChange handler
	});

	/**
	 * handleUngroup
	 * This function removes the `parentId` and `extent` properties from the node,
	 * effectively ungrouping it, and recalculates its absolute position to ensure it remains in the same spot on the canvas.
	 */
	const handleUngroup = () => {
		setNodes((prevNodes) =>

			prevNodes.map((n) => {

				if (n.id === node.id) {
					// Calculate the node's absolute position
					let absolutePosition = n.position;

					// If the node is inside a group, calculate its absolute position relative to the group's position
					if (n.parentId) {
						const groupNode = getNode(n.parentId); // Retrieve the parent group node using its ID
						if (groupNode) {
							// Add the group's position to the node's position to calculate the absolute position
							absolutePosition = {
								x: groupNode.position.x + n.position.x,
								y: groupNode.position.y + n.position.y,
							};
						}

						dispatchNodeParentIdChange(n.parentId);
					}

					// Return a new node object with `parentId` and `extent` removed, and the absolute position updated
					return ungroupNode(n, absolutePosition);
				}

				// Return unchanged nodes
				return n;
			})
		);
	};

	/**
	 * handleRemoveNode
	 * Removes the node entirely from the React Flow canvas.
	 */
	const handleRemoveNode = () => {
		setNodes((prevNodes) => prevNodes.filter((n) => n.id !== node.id)); // Filter out the current node by ID
	};

	// /**
	//  * entityId
	//  */
	// const entityId = useMemo(() => {
	// 	return toEntityId(node);
	// }, [node]);

	// Render the Node Toolbar. It is only visible when exactly one node is selected and it is the current node
	return (
		<NodeToolbar className="nopan" isVisible={selectedNodes.length === 1 && node.selected} position={"top"}>
			<Button.Group pb={2}>
				{node.data?.entity?.ingredientName &&
					<Button variant={"filled"} color={"secondary"} size={"xs"} pl={"xs"} pr={"xs"} radius={4} onClick={() => ingredientNameNavigate(navigate, node.data?.entity?.ingredientName, "_blank")}>
						<FontAwesomeIcon icon={faUpRightFromSquare}/>
					</Button>
				}
				{withNodeData &&
					<NodeData 	label={node.data?.label} description={node.data?.description}
								 onSave={(label, description) => {
									 setNodes(prevNodes =>
										 prevNodes.map(n =>
											 n.id === node.id
												 ? {
													 ...n,
													 data: { ...n.data, label, description }
												 }
												 : n
										 )
									 );
								 }}/>
				}
				{/* Render the ungroup button only if the node has a parentId (i.e., it is part of a group) */}
				{node.parentId && (
					<Tooltip label={t("studio.ungroup")} bg={"secondary"} withArrow>
						<Button variant={"filled"} color={"secondary"} size={"xs"} pl={"xs"} pr={"xs"} radius={4} onClick={handleUngroup}>
							<FontAwesomeIcon icon={faObjectUngroup}/>
						</Button>
					</Tooltip>
				)}
				{withRemove &&
					<DeleteModal
						targetComponent={
							<Tooltip label={t("studio.delete")} bg={"secondary"} withArrow>
								<Button variant={"filled"} color={"secondary"} size={"xs"} pl={"xs"} pr={"xs"} radius={4} style={{borderBottomLeftRadius: 0, borderTopLeftRadius: 0}}>
									<FontAwesomeIcon icon={faTrash}/>
								</Button>
							</Tooltip>
						}
						description={t("common.deleteModal", {item: node.data?.label})}
						onDelete={handleRemoveNode}/>
				}
			</Button.Group>
		</NodeToolbar>
	);
};

/**
 * GroupNode
 */
export const GroupNode = memo((node) => {

	let entities = useStudioStore((state) => state.entities);

	const { getNodes, setNodes, setEdges  } = useReactFlow();

	const {enabled: glowEnabled, color: glowColor, pc: glowPc } = useGlow([node]);

	const [selectedNodes, setSelectedNodes] = useState([]); // State to keep track of selected nodes
	const [hasChildren, setHasChildren] = useState(false); // State to track if the group has child nodes
	const [temporarySelection, setTemporarySelection] = useState(false);
	const [resizing, setResizing] = useState(false);

	const [tooltipOpened, setTooltipOpened] = useState(false);

	const [weightingLabel, setWeightingLabel] = useState(null);
	const [weightingTooltip, setWeightingTooltip] = useState(null);
	const [weightingTooltipOpened, setWeightingTooltipOpened] = useState(false);

	/**
	 * isSelected
	 */
	const isSelected = () => temporarySelection /*|| resizing*/ || node.selected;

	const {selectionColor, unselectionColor} = useSelectionColor(node.id, isSelected(), glowEnabled ? glowColor() : undefined);

	const { t } = useTranslation();

	const infoIconStyle = { width: "18px", height: "18px", paddingTop: "4px", opacity: 0.75, color: isSelected() ? selectionColor : glowEnabled ? unselectionColor : "var(--mantine-color-primary-9)"};

	// This callback updates the selected nodes' IDs whenever a selection change occurs
	const onChange = useCallback(({ nodes }) => {
		const childNodes = getNodesByParentId(getNodes(), node.id);
		setHasChildren(childNodes.length > 0); // Update state to reflect if the group has children
		setSelectedNodes(nodes.map((node) => node.id)); // Map selected nodes to their IDs
	}, []);

	// React Flow hook to listen for selection changes
	useOnSelectionChange({
		onChange, // Pass the memoized onChange handler
	});

	/**
	 * EntitiesMolecules
	 */
	const entitiesMolecules = useMemo(() => {
		return entities.flatMap(entity => entity.molecules || []);
	}, [entities]);

	useEffect(() => {

		switch (node.data.weighting) {

			case GROUP_WEIGHTING.min:
				setWeightingLabel(t("studio.weighting.creative"));
				setWeightingTooltip(t("studio.weighting.creativeDescription"));
				break;

			case (GROUP_WEIGHTING.max - GROUP_WEIGHTING.min) / 2:
				setWeightingLabel(t("studio.weighting.balanced"));
				setWeightingTooltip(t("studio.weighting.balancedDescription"));
				break;

			case GROUP_WEIGHTING.max:
				setWeightingLabel(t("studio.weighting.coherent"));
				setWeightingTooltip(t("studio.weighting.coherentDescription"));
				break;
		}
	}, [node.data.weighting]);

	/**
	 * handleNodeParentIdChange
	 */
	useEffect(() => {

		const handleNodeParentIdChange = (event) => {
			if(event.detail.parentId === node.id) {
				setTimeout(() => {
					updateEdges(node);
				}, 10);
			}
		};

		window.addEventListener("nodeParentIdChange", handleNodeParentIdChange);
		return () => window.removeEventListener("nodeParentIdChange", handleNodeParentIdChange);
	}, []);

	/**
	 * updateEdges
	 */
	const updateEdges = useCallback((node) => {

		const hasAtLeastOneCustomNode = getNodesByParentId(getNodes(), node.id).some(node => node.type === "custom");

		// Extract all molecules Ids from this node
		const moleculesIds = extractMolecules(entities, hasAtLeastOneCustomNode ? entitiesMolecules : [], getNodes(), [node]).map((molecule) => molecule.moleculeId);

		setEdges((prevEdges) =>
			prevEdges.map((e) => {
				// Update only the source group node edges
				if (e.type === "default" && e.source === node.id) {

					if(moleculesIds.length === 0) {

						return {
							...e,
							selectable: false,
							data: {
								...e.data,
								sharedMolecules: 0,
								sharedMoleculesPc: 0,
								width: 0,
							}
						};
					}

					// Get target node entity
					const targetEntity = getEntityById(entities, toEntityId(getNodeById(getNodes(), e.target)));
					const entityPairings = extractEntityPairings([targetEntity], moleculesIds);

					if(entityPairings.length === 0) {
						return e;
					}

					const sharedMoleculesPc = getSharedMoleculesPc(entityPairings[0].sharedMolecules, moleculesIds);

					return {
						...e,
						selectable: true,
						data: {
							...e.data,
							sharedMolecules: entityPairings[0].sharedMolecules || 0,
							sharedMoleculesPc: sharedMoleculesPc || 0,
							width: getEdgeWidth(sharedMoleculesPc),
						}
					};
				}

				return e; // Leave other edges unchanged
			})
		);

	}, [setEdges]);

	/**
	 * handleUngroup
	 * This function removes the `parentId` and `extent` properties from all child nodes of the group,
	 * effectively ungrouping them.
	 */
	const handleUngroup = () => {
		setNodes((prevNodes) => {

			const updatedNodes = prevNodes.map((n) => {

				// Check if the node is a child of the current group
				if (n.parentId === node.id) {
					const position = {
						x: n.position.x + (node.positionAbsoluteX || 0), // Adjust x position to keep its position on the canvas
						y: n.position.y + (node.positionAbsoluteY || 0), // Adjust y position to keep its position on the canvas
					};

					dispatchNodeParentIdChange(n.parentId);

					// Remove parentId and extent to ungroup the node
					return ungroupNode(n, position);
				}
				return n; // Return the node as is if it's not a child of the group
			});

			// After ungrouping, update `hasChildren` to false if there are no child nodes left
			setHasChildren(false);

			return updatedNodes;
		});
	};

	/**
	 * handleRemoveNode
	 * Removes the group node and all child nodes that reference it as a parentId.
	 */
	const handleRemoveNode = () => {

		setNodes((prevNodes) => {

			// Get all child nodes that have the current group node as their parentId
			const childNodes = prevNodes.filter((n) => n.parentId === node.id);

			// Remove the group node and all child nodes
			return prevNodes.filter((n) => n.id !== node.id && !childNodes.includes(n));
		});
	};

	/**
	 * changeWeighting
	 * Updates the `data.weighting` of the group node dynamically.
	 * @param {number} newWeighting - The new weighting value (between 0 and 1).
	 */
	const changeWeighting = (newWeighting) => () => {

		setTemporarySelection(true);

		let updatedNode = undefined;

		setNodes((prevNodes) =>
			prevNodes.map((n) => {
				// Update only the current group node
				if (n.id === node.id) {

					updatedNode = {
						...n,
						data: {
							...n.data,
							weighting: checkedGroupWeighting(newWeighting), // Update weighting value
						},
						selected: false
					};

					return updatedNode;
				}
				return n; // Leave other nodes unchanged
			})
		);

		// Simulate a re-selection with a slight delay to ensure React Flow processes the change
		setTimeout(() => {

			updateEdges(updatedNode);

			setNodes((prevNodes) =>
				prevNodes.map((n) => {
					if (n.id === node.id) {
						return {
							...n,
							selected: true, // Re-select the node
						};
					}
					return n;
				})
			);

			setTemporarySelection(false)
		}, 5); // Adjust the delay if necessary
	};

	/**
	 * onResizeStart
	 */
	const onResizeStart = useCallback((event) => {
		setResizing(true);
	},[]);

	/**
	 * onResizeStart
	 */
	const onResizeEnd = useCallback((event) => {
		setResizing(false);
	},[]);

	// /**
	//  * onLabelChange
	//  */
	// function onLabelChange(value) {
	// 	node.data.label = value;
	// }

	return (
			<Box className={classes.nodebase} style={{
				outline: isSelected() || resizing ? `2px solid ${selectionColor}` : "none",
				borderRadius: "6px",
				backgroundColor: glowEnabled ? toRgb(adjustRgbBrightness(glowColor(), 85)) : toRgb(adjustRgbBrightness(toRgb(hexToRgb(theme.colors.primary[6])), 95))
			}}>

			{glowEnabled &&
				<StaticGlow percentage={glowPc} color={glowColor()}/>
			}

			<Group align={"flex-start"} justify={"space-between"} wrap={"nowrap"} gap={7} p={"sm"}>
				<Text lineClamp={2} ta={"left"} c={isSelected() ? selectionColor : glowEnabled ? unselectionColor : "var(--mantine-color-primary-9)"}>{node.data?.label}</Text>
				{node.data?.description?.length > 0 &&
					<Tooltip opened={tooltipOpened} transitionProps={{ duration: 0 }} multiline maw={300} withArrow label={node.data?.description} bg={isSelected() ? selectionColor : glowEnabled ? unselectionColor : "var(--mantine-color-primary-9)"} color={"white"}>
						<FontAwesomeIcon icon={faCircleInfo} style={infoIconStyle}
										 onMouseEnter={() => setTooltipOpened(true)}
										 onMouseLeave={() => setTooltipOpened(false)}
										 onTouchStart={() => setTooltipOpened((o) => !o)}/>
					</Tooltip>
				}
			</Group>

			<NodeToolbar className="nopan" isVisible={isSelected() && selectedNodes.length === 1} position={"top"}>
				<Group gap={"xs"}>
					<Button.Group pb={2}>
						<NodeData 	label={node.data?.label} description={node.data?.description}
									withTechniques
									onSave={(label, description) => {
										 setNodes(prevNodes =>
											 prevNodes.map(n =>
												 n.id === node.id
													 ? {
														 ...n,
														 data: { ...n.data, label, description }
													 }
													 : n
											 )
										 );
									 }}/>
						{hasChildren &&
							<Tooltip label={t("studio.ungroup")} bg={"secondary"} withArrow>
								<Button variant={"filled"} color={"secondary"} size={"xs"} pl={"xs"} pr={"xs"} radius={4} onClick={handleUngroup}>
									<FontAwesomeIcon icon={faObjectUngroup}/>
								</Button>
							</Tooltip>
						}
						<DeleteModal
							targetComponent={
								<Tooltip label={t("studio.delete")} bg={"secondary"} withArrow>
									<Button variant={"filled"} color={"secondary"} size={"xs"} pl={"xs"} pr={"xs"} radius={4} style={{borderBottomLeftRadius: 0, borderTopLeftRadius: 0}}>
										<FontAwesomeIcon icon={faTrash}/>
									</Button>
								</Tooltip>
							}
							description={t("common.deleteModal", {item: node.data?.label})}
							onDelete={handleRemoveNode}/>
					</Button.Group>
					{/*{hasMolecules &&*/}
					{
						<Group w={"250px"} h={"30px"} justify={"space-between"} wrap={"nowrap"} p={5} pl={10} pr={10} mt={"-2px"}
							   style={{color: "var(--mantine-color-white)", backgroundColor: "var(--mantine-color-secondary-6)", borderRadius: "4px"}}>
							<Group gap={7} justify={"flex-start"} wrap={"nowrap"}>
								<Text size={"xs"} pt={1}>{weightingLabel}</Text>
								<Tooltip opened={weightingTooltipOpened} transitionProps={{ duration: 0 }} multiline maw={300} withArrow label={weightingTooltip} bg={"secondary"} offset={10}>
									<FontAwesomeIcon icon={faCircleInfo} style={{ width: "18px", height: "18px", opacity: 0.75, color: "var(--mantine-color-white)"}}
													 onMouseEnter={() => setWeightingTooltipOpened(true)}
													 onMouseLeave={() => setWeightingTooltipOpened(false)}
													 onTouchStart={() => setWeightingTooltipOpened((o) => !o)}/>
								</Tooltip>
							</Group>
							<Slider
								step={(GROUP_WEIGHTING.max - GROUP_WEIGHTING.min) / 2}
								marks={[
									{ value: GROUP_WEIGHTING.min},
									{ value: (GROUP_WEIGHTING.max - GROUP_WEIGHTING.min) / 2},
									{ value: GROUP_WEIGHTING.max}
								]}
								min={GROUP_WEIGHTING.min}
								max={GROUP_WEIGHTING.max}
								thumbSize={24}
								defaultValue={node.data.weighting}
								onChangeEnd={(value) => changeWeighting(value)()}
								w={"120px"}
								showLabelOnHover={false}
								label={null}
								restrictToMarks
								color={`secondary.9`}
								classNames={{
									track: classes.slidertrack
								}}
							/>
						</Group>
					}
				</Group>
			</NodeToolbar>

			<NodeResizeControl position={"bottom-right"}
							   onResizeStart={onResizeStart}
							   onResizeEnd={onResizeEnd}
								/>

			<Handle
				type="target"
				position={Position.Top}
				isConnectable={false}
				style={{
					backgroundColor: "transparent",
					border: "none",
				}}
			/>

			<Handle
				type="source"
				position={Position.Bottom}
				isConnectable={false}
				style={{
					backgroundColor: "transparent",
					border: "none",
				}}
			/>
		</Box>
	);
});

/**
 * InputNode
 */
export const InputNode = memo((node) => {

	const {enabled: glowEnabled, color: glowColor, pc: glowPc } = useGlow([node]);

	const {selectionColor, unselectionColor} = useSelectionColor(node.id, node.selected, glowEnabled ? glowColor() : undefined);

	useEffect(() => {
		if(node.parentId) {
			dispatchNodeParentIdChange(node.parentId);
		}
	}, [node.parentId]);

	return  (
		<Box className={classes.nodebase}>

			{glowEnabled &&
				<DynamicGlow node={node} percentage={glowPc} color={glowColor()}/>
			}

			<ImageWithZoom node={node} selectionColor={selectionColor} unselectionColor={unselectionColor}/>

			<Center>
				<Stack gap={0} justify="flex-end" className={classes.inputtitle}
					   style={{
						   color: node.selected ? "var(--mantine-color-white)" : glowEnabled ? unselectionColor : "var(--mantine-color-primary-9)",
						   backgroundColor: node.selected ? selectionColor : "var(--mantine-custom-color-body-light-hover)"
				}}>
					<Text lh={1.3} >{node.data.label}</Text>
					<Text size={"xs"} lh={1.3} opacity={0.75}>{node.data.entity.categoryLabel}</Text>
					{/*<Text size={"xs"} lh={1.3} opacity={0.75}>{JSON.stringify(node.data.wiki)}</Text>*/}
				</Stack>
			</Center>

			<NodeToolbarDefault node={node} withRemove={false}/>

			<Handle
				type="source"
				position={Position.Bottom}
				isConnectable={false}
				style={{
					backgroundColor: "transparent",
					border: "none",
				}}
			/>
		</Box>
	);
});

/**
 * IntermediateNode
 */
export const IntermediateNode = memo((node) => {

	const {enabled: glowEnabled, color: glowColor, pc: glowPc } = useGlow([node]);

	const {selectionColor, unselectionColor} = useSelectionColor(node.id, node.selected, glowEnabled ? glowColor() : undefined);

	useEffect(() => {
		if(node.parentId) {
			dispatchNodeParentIdChange(node.parentId);
		}
	}, [node.parentId]);

	return (
			<Box className={classes.nodebase}>

			{glowEnabled &&
				<DynamicGlow node={node} percentage={glowPc} color={glowColor()}/>
			}

			<ImageWithZoom node={node} selectionColor={selectionColor} unselectionColor={unselectionColor}/>

			<Center>
				<Stack gap={0} justify="flex-end" className={classes.intermediatetitle}
					   style={{
						   color: node.selected ? "var(--mantine-color-white)" : glowEnabled ? unselectionColor : "var(--mantine-color-primary-9)",
						   backgroundColor: node.selected ? selectionColor : "var(--mantine-custom-color-body-light-hover)"
				}}>
					<Text lh={1.3}>{node.data.label}</Text>
					<Text size={"xs"} lh={1.3} opacity={0.75}>{node.data.entity.categoryLabel}</Text>
					{/*<Text size={"xs"} lh={1.3} opacity={0.75}>{JSON.stringify(node.data.wiki)}</Text>*/}
				</Stack>
			</Center>

			<NodeToolbarDefault node={node}/>

			<Handle
				type="target"
				position={Position.Top}
				isConnectable={false}
				style={{
					backgroundColor: "transparent",
					border: "none",
				}}
			/>

			<Handle
				type="source"
				position={Position.Bottom}
				isConnectable={false}
				style={{
					backgroundColor: "transparent",
					border: "none",
				}}
			/>
		</Box>
	);
});

/**
 * CustomNode
 */
export const CustomNode = memo((node) => {

	const {enabled: glowEnabled, color: glowColor, pc: glowPc } = useGlow([node]);

	const {selectionColor, unselectionColor} = useSelectionColor(node.id, node.selected, glowEnabled ? glowColor() : undefined);

	useEffect(() => {
		if(node.parentId) {
			dispatchNodeParentIdChange(node.parentId);
		}
	}, [node.parentId]);

	return (
		<Box className={classes.nodebase}>

			{glowEnabled &&
				<DynamicGlow node={node} percentage={glowPc} color={glowColor()}/>
			}

			<IconWithZoom node={node} selectionColor={selectionColor} unselectionColor={unselectionColor}/>

			<Center>
				<Stack gap={0} justify="flex-end" className={classes.intermediatetitle}
					   style={{
						   color: node.selected ? "var(--mantine-color-white)" : glowEnabled ? unselectionColor : "var(--mantine-color-primary-9)",
						   backgroundColor: node.selected ? selectionColor : "var(--mantine-custom-color-body-light-hover)"
					   }}>
					<Text lh={1.3}>{node.data.label}</Text>
					{/*<Text size={"xs"} lh={1.3} opacity={0.75}>{node.data.entity.categoryLabel}</Text>*/}
					{/*<Text size={"xs"} lh={1.3} opacity={0.75}>{JSON.stringify(node.data.wiki)}</Text>*/}
				</Stack>
			</Center>

			<NodeToolbarDefault node={node} withNodeData/>

			<Handle
				type="target"
				position={Position.Top}
				isConnectable={false}
				style={{
					backgroundColor: "transparent",
					border: "none",
				}}
			/>

			<Handle
				type="source"
				position={Position.Bottom}
				isConnectable={false}
				style={{
					backgroundColor: "transparent",
					border: "none",
				}}
			/>
		</Box>
	);
});

/**
 * DefaultEdge
 */
export const DefaultEdge = memo(({ id, type, source, target, style = {}, data, markerEnd, selected }) => {

	const { t } = useTranslation();

	// Retrieve the source and target node details
	const sourceNode = useInternalNode(source);
	const targetNode = useInternalNode(target);

	const studioHeatmapEnabled = useStudioStore((state) => state.studioHeatmapEnabled);

	// // Apply a class for z-index adjustments if the source node is a group
	// useEffect(() => {
	// 	const element = document.querySelector(`.react-flow__edge[data-id="${id}"]`);
	// 	if (element && sourceNode.type === "group") {
	// 		element.classList.add("is-group");
	// 	}
	// 	else {
	// 		element.classList.remove("is-group");
	// 	}
	// }, [selected]);

	/**
	 * getSourceCenterX
	 */
	function getSourceCenterX() {

		if(sourceNode.type !== "group") {
			return sourceNode.internals.positionAbsolute.x + sourceNode.measured.width / 2;
		}

		// Calculate group edge positions

		// Top & Bottom
		if(	sourceNode.internals.positionAbsolute.y > targetNode.internals.positionAbsolute.y + targetNode.measured.height ||
			targetNode.internals.positionAbsolute.y > sourceNode.internals.positionAbsolute.y + sourceNode.measured.height) {
			return sourceNode.internals.positionAbsolute.x + sourceNode.measured.width / 2;
		}

		// Right
		if(sourceNode.internals.positionAbsolute.x < targetNode.internals.positionAbsolute.x + targetNode.measured.width / 2) {
			return sourceNode.internals.positionAbsolute.x + sourceNode.measured.width;
		}

		// Left
		return sourceNode.internals.positionAbsolute.x;
	}

	/**
	 * getSourceCenterY
	 */
	function getSourceCenterY() {

		if(sourceNode.type === "group") {

			// Top
			if(sourceNode.internals.positionAbsolute.y > targetNode.internals.positionAbsolute.y + targetNode.measured.height) {
				return sourceNode.internals.positionAbsolute.y;
			}

			// Bottom
			if(targetNode.internals.positionAbsolute.y > sourceNode.internals.positionAbsolute.y + sourceNode.measured.height) {
				return sourceNode.internals.positionAbsolute.y + sourceNode.measured.height;
			}
		}

		return sourceNode.internals.positionAbsolute.y + sourceNode.measured.height / 2;
	}

	// Calculate center positions of the source and target nodes
	const sourceCenterX = getSourceCenterX();
	const sourceCenterY = getSourceCenterY();

	const targetCenterX = targetNode.internals.positionAbsolute.x + targetNode.measured.width / 2;
	const targetCenterY = targetNode.internals.positionAbsolute.y + targetNode.measured.height / 2;

	/**
	 * getSourcePosition
	 */
	function getSourcePosition() {

		if(sourceNode.type === "group") {

			// Top
			if(sourceNode.internals.positionAbsolute.y > targetNode.internals.positionAbsolute.y + targetNode.measured.height) {
				return Position.Top;
			}

			// Bottom
			if(targetNode.internals.positionAbsolute.y > sourceNode.internals.positionAbsolute.y + sourceNode.measured.height) {
				return Position.Bottom;
			}
		}

		return sourceCenterX <= targetCenterX ? Position.Right : Position.Left;
	}

	// Determine edge positions relative to node centers
	const sourcePosition = getSourcePosition();
	const targetPosition = sourceCenterX <= targetCenterX ? Position.Left : Position.Right;

	// Generate the Bézier curve path
	const [edgePath] = getBezierPath({
		sourceX: sourceCenterX,
		sourceY: sourceCenterY,
		sourcePosition,
		targetX: targetCenterX,
		targetY: targetCenterY,
		targetPosition,
	});

	// Dynamic edge style based on selected state and heatmap state
	const strokeWidth = data?.width > 5 ? data?.width : 5;
	const edgeStyle = {
		stroke: selected ? !studioHeatmapEnabled || type === "unknownCompatibility" ? "var(--mantine-color-primary-light-hover)" : getColorByThreshold(data.sharedMoleculesPc, MOLECULES_THRESHOLDS, "light-hover")
						 : !studioHeatmapEnabled || type === "unknownCompatibility" ? "var(--mantine-color-primary-outline-hover)" : getColorByThreshold(data.sharedMoleculesPc, MOLECULES_THRESHOLDS, "outline-hover"),
		strokeWidth: strokeWidth,
		strokeDasharray: type === "unknownCompatibility" ? "16,8" : "none",
		...style,
	};

	/**
	 * Label
	 */
	const Label = ({ edgePath, position = 0.5, text, fontSize = 14, padding = 6, backgroundShape = "none", opacity = 1 }) => {

		if (!text || !edgePath) {
			return null;
		}

		// Create an SVG path
		const pathElement = document.createElementNS("http://www.w3.org/2000/svg", "path");
		pathElement.setAttribute("d", edgePath);
		const pathLength = pathElement.getTotalLength();
		const pointAtPosition = pathElement.getPointAtLength(position * pathLength);

		// Text dimensions
		const lineHeight = fontSize * 1.2;
		const textLines = text ? text.split("\n") : [];
		const textWidth = Math.max(...textLines.map((line) => line.length * (fontSize * 0.6)));
		const textHeight = textLines.length * lineHeight + padding * 2;

		// Choose shape based on prop
		const BackgroundShape = ({backgroundShape}) => {

			if(backgroundShape === "none") {
				return null;
			}

			return backgroundShape === "circle" ? (
				<circle
					cx={pointAtPosition.x}
					cy={pointAtPosition.y}
					r={Math.max(textWidth, textHeight) / 2 + padding}
					fill={selected ?
						!studioHeatmapEnabled || type === "unknownCompatibility" ? "var(--mantine-color-primary-6)" : getColorByThreshold(data.sharedMoleculesPc, MOLECULES_THRESHOLDS, "6")
						: "var(--mantine-custom-color-body-light-hover)"
					}
				/>
			) : (
				<rect
					x={pointAtPosition.x - textWidth / 2 - padding}
					y={pointAtPosition.y - textHeight / 2}
					rx={4}
					ry={4}
					width={textWidth + padding * 2}
					height={textHeight}
					fill={selected ?
						!studioHeatmapEnabled || type === "unknownCompatibility" ? "var(--mantine-color-primary-6)" : getColorByThreshold(data.sharedMoleculesPc, MOLECULES_THRESHOLDS, "6")
						: "var(--mantine-custom-color-body-light-hover)"
					}
				/>
			);
		}

		/**
		 * getTextFill
		 */
		function getTextFill() {

			if(selected) {
				return backgroundShape === "none" ?
					!studioHeatmapEnabled || type === "unknownCompatibility" ? "var(--mantine-color-primary-6)" : getColorByThreshold(data.sharedMoleculesPc, MOLECULES_THRESHOLDS) :
					"var(--mantine-color-white)";
			}

			return !studioHeatmapEnabled || type === "unknownCompatibility" ? "var(--mantine-color-primary-9)" : getColorByThreshold(data.sharedMoleculesPc, MOLECULES_THRESHOLDS);
		}

		return (
			<>
				{/* Background */}
				<BackgroundShape backgroundShape={backgroundShape}/>
				{/* Text */}
				{textLines.map((line, index) => (
					<text
						key={index}
						x={pointAtPosition.x}
						y={pointAtPosition.y - textHeight / 2 + padding + lineHeight * (index + 0.8)}
						textAnchor="middle"
						style={{
							fontSize: `${fontSize}px`,
							fill: getTextFill(),
							pointerEvents: "none",
							opacity: opacity
						}}
					>
						{line}
					</text>
				))}
			</>
		);
	};

	// Render the edge, label, and additional texts
	return sourceNode && targetNode &&
		<>
			{/* Render the Bézier curve path */}
			<path id={id} className="react-flow__edge-path" d={edgePath} style={edgeStyle} markerEnd={markerEnd} />

			{/* Centered label on the curve */}
			{/*{data?.label && <Label edgePath={edgePath} position={0.5} text={data?.label} backgroundShape={"rect"}/>}*/}
			{data?.sharedMoleculesPc && <Label edgePath={edgePath} position={0.5} text={t("ingredient.compatibleAt", {compatibility: formatPc(data?.sharedMoleculesPc)})} backgroundShape={"rect"}/>}

			{/* Rectangle and text for source label */}
			<Label edgePath={edgePath} position={0.25} text={data?.sourceLabel || t("studio.base")} fontSize={10} opacity={0.5}/>

			{/* Rectangle and text for target label */}
			<Label edgePath={edgePath} position={0.75} text={data?.targetLabel || t("studio.complement")} fontSize={10} opacity={0.5}/>
		</>
});

