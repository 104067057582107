import React, {createContext, useContext, useEffect} from 'react';
import {ParallaxProvider, useParallaxController} from "react-scroll-parallax";
import {useLocation} from "react-router-dom";

/**
 * ScrollParallaxContext
 */
const ScrollParallaxContext = createContext(null);

/**
 * useScrollParallaxContext
 */
export const useScrollParallaxContext = () => {
	return useContext(ScrollParallaxContext);
};

/**
 * ScrollParallaxProvider
 */
export const ScrollParallaxProvider = ({ children }) => {
	
	return (
		<ParallaxProvider>
			<ScrollParallaxLocationProvider>
				{children}
			</ScrollParallaxLocationProvider>
		</ParallaxProvider>
	)
};

/**
 * useUpdateControllerOnRouteChange
 */
const useUpdateControllerOnRouteChange = () => {

	const location = useLocation();
	const parallaxController = useParallaxController();

	useEffect(() => {
		parallaxController.update();
	}, [location.pathname]);
}

/**
 * ScrollParallaxLocationProvider
 */
const ScrollParallaxLocationProvider = ({ children }) => {

	useUpdateControllerOnRouteChange();
	
	return (
		<ScrollParallaxContext.Provider value={{}}>
			{children}
		</ScrollParallaxContext.Provider>
	)
};