import React, {useEffect, useMemo} from 'react';
import {useInViewport} from "@mantine/hooks";

/**
 * ScrollWatcher
 */
const ScrollWatcher = ({children, onScrollChange}) => {

	const { ref: ref1, inViewport: inViewport1 } = useInViewport(); // Top marker
	const { ref: ref2, inViewport: inViewport2 } = useInViewport(); // Bottom marker

	/**
	 * scrollActive
	 */
	const scrollActive = useMemo(() => {
		return !inViewport1 || !inViewport2;
	}, [inViewport1, inViewport2]);

	useEffect(() => {
		if (onScrollChange) {
			onScrollChange(scrollActive);
		}
	}, [scrollActive, onScrollChange]);

	return (
		<>
			<div ref={ref1} style={{width: "100%", height: "0px", backgroundColor: "red"}}/>
			{children}
			<div ref={ref2} style={{width: "100%", height: "0px", backgroundColor: "red"}}/>
		</>
	)
}

export default ScrollWatcher;
