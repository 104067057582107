import { useEffect, useState } from 'react';
import {fetchGeneratedRecipeAll} from "../../api/api";
import { theme } from "../../Theme";
import { useAccountContext } from "../../components/account/AccountContext";
import { useErrorContext } from "../../components/error/ErrorContext";
import axios from 'axios';

/**
 * useGeneratedRecipeAll
 */
const useGeneratedRecipeAll = ({
						  enabled,
						  onSuccess,
						  onError,
						   userId,
						   projectId,
						   publish,
						  search,
						  random,
						  page
					  } = {}) => {

	const { getIdToken } = useAccountContext();
	const { onError: onErrorContext } = useErrorContext();

	const [data, setData] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	const [count, setCount] = useState(0);
	const [isLoaded, setIsLoaded] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	/**
	 * Fetch data from API using Axios, return a Promise
	 */
	const fetchData = async (force = false) => {

		if (!force && (!enabled || isLoading)) {
			return;
		}

		setIsLoading(true);
		setIsLoaded(false); // Ensure isLoaded is reset on fetch start

		reset();

		const cancelTokenSource = axios.CancelToken.source(); // Create a new cancel token

		try {
			const responseData = await fetchGeneratedRecipeAll(
				userId,
				projectId,
				publish,
				search,
				random,
				page,
				theme.custom.recipe.paginationSize,
				await getIdToken(),
				cancelTokenSource.token
			);

			setData(responseData.documents);
			setTotalCount(responseData.totalCount);
			setCount(responseData.count);

			if (onSuccess) {
				onSuccess(responseData.documents, responseData.totalCount, responseData.count);
			}

			setIsLoaded(true);
			return responseData; // Resolve the promise with fetched data

		}
		catch (error) {

			if(axios.isCancel(error)) {
				console.log('Request canceled', error.message);
			}
			else {
				if(onError) {
					onError(error);
				}
				else {
					onErrorContext(error); // Handle the error via global error handler
					// throw error;
				}
			}
		}
		finally {
			setIsLoading(false); // Mark loading as complete
		}

		// Cancel token cleanup
		return () => cancelTokenSource.cancel("Request canceled by user.");
	};

	/**
	 * Reset the state and optionally disable further fetching
	 */
	const reset = () => {
		setIsLoaded(false);
		setData([]);
		setTotalCount(0);
		setCount(0);
	};

	/**
	 * Refetch the data (returns a Promise)
	 */
	const refetch = () => {
		return fetchData(true); // Force a refetch, bypassing fetchEnabled
	};

	useEffect(() => {
		fetchData();
	}, [enabled]);

	return {
		data,
		isLoaded,
		totalCount,
		count,
		reset,
		refetch
	};
};

export default useGeneratedRecipeAll;
