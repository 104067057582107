import React from 'react';
import {Box, Paper, useMantineTheme} from '@mantine/core';
import {useHover} from "@mantine/hooks";
import classes from './SimpleBox.module.css';

/**
 * SimpleBox
 *
 * @param props
 * @returns {Element}
 */
export const SimpleBox = (props) => {

    const {color, highlightOnHover, position = "inherit" /* relative to hide background */, children} = props;

    const {hovered, ref } = useHover();

    const theme = useMantineTheme();

    return (
        <Paper {...props}
               ref={ref}
               shadow={"none"}
               style={{position: position}}
        >

            <Box className={classes.simpleboxbackground}
                 bg={`var(--mantine-color-${color ? color : "primary"}-${highlightOnHover && hovered ? "light-hover" : "outline-hover"})`}
                 style={{color: `var(--mantine-color-${color ? color : "primary"}-9)`}}>

                {children}
            </Box>
        </Paper>
    );
}