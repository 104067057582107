import React, {createContext, useContext} from 'react';
import useDomainAll from "../../pages/useDomainAll";
import useEntityCategoryAll from "../../pages/ingredient/useEntityCategoryAll";
import useRecipeCategoryAll from "../../pages/recipe/useRecipeCategoryAll";
import useResult from "../../pages/useResult";
import {useAccountContext} from "../account/AccountContext";
import useTechniqueAll from "../../pages/useTechniqueAll";

/**
 * ApplicationContext
 */
const ApplicationContext = createContext(null);

/**
 * useApplicationContext
 */
export const useApplicationContext = () => {
	return useContext(ApplicationContext);
};

/**
 * ApplicationProvider
 */
export const ApplicationProvider = ({ children }) => {

	const {isLoaded: isLoadedAccount} = useAccountContext();

	const {data: domains, isLoaded: isLoadedDomains} =
		useDomainAll({
			enabled: isLoadedAccount
		});

	const {data: entityCategories, isLoaded: isLoadedEntityCategories} =
		useEntityCategoryAll({
			enabled: isLoadedAccount
		});

	const {data: recipeCategories, isLoaded: isLoadedRecipeCategories} =
		useRecipeCategoryAll({
			enabled: isLoadedAccount
		});

	const {data: techniques, isLoaded: isLoadedTechniques} =
		useTechniqueAll({
			enabled: isLoadedAccount
		});

	const {isSuccess, isError} =
		useResult({
			isSuccess: isLoadedAccount && isLoadedDomains && isLoadedEntityCategories && isLoadedRecipeCategories && isLoadedTechniques
		})

	return <ApplicationContext.Provider value={{isSuccess, domains, entityCategories, recipeCategories, techniques}}>
				{children}
			</ApplicationContext.Provider>
};