import React, {createContext, useState, useContext, useEffect, useRef} from 'react';
import {Auth0Provider, useAuth0} from "@auth0/auth0-react";
import {
	fetchAccount,
	fetchAccountDelete,
	fetchAccountLogout,
	fetchAccountUser,
	fetchAccountUserSettings, fetchGeneratedRecipeAll, getWebSocketUrl
} from "../../api/api";
import {useErrorContext} from "../error/ErrorContext";
import i18n from "../../i18n";
import {jwtDecode} from "jwt-decode";
import {useEnvironmentContext} from "../environment/EnvironmentContext";
import {useDebouncedCallback} from "@mantine/hooks";
import { EventEmitter } from 'events';
import {daysFromNow} from "../../util/time";
import moment from "moment/moment";
import {create} from 'zustand';
import {notifications} from "@mantine/notifications";
import {Button, Center, Group, Modal, Space, Stack, Text} from "@mantine/core";
import {useTranslation} from "react-i18next";
import classes from './Account.module.css';
import {Icon} from "../icons/Icons";
import {SimpleBox} from "../simpleBox/SimpleBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRepeat} from "@fortawesome/free-solid-svg-icons";

/**
 * useAccountStore
 */
export const useAccountStore = create((set) => ({

	accountTokenExpired: localStorage.getItem('accountTokenExpired') === null ? false : localStorage.getItem('accountTokenExpired') === 'true',
	setAccountTokenExpired: (accountTokenExpired) => {
		set({ accountTokenExpired });
		localStorage.setItem('accountTokenExpired', accountTokenExpired);
	},

}));

/**
 * uiLocales
 */
const uiLocales = () => {

	switch (i18n.language) {

		case "fr":
			return "fr-FR"

		case "pt":
			return "pt-PT"

		default:
			return i18n.language
	}
}

/**
 * isSettingsRecipeValid
 */
function isSettingsRecipeValid(recipe) {

	return	(recipe.active !== undefined && recipe.active) ||
			(recipe.serving !== undefined && recipe.serving > 1) ||
			(recipe.activeInstruction !== undefined && recipe.activeInstruction >= 0) ||
			(recipe.ingredients !== undefined && Array.isArray(recipe.ingredients) && recipe.ingredients.length > 0);
}

/**
 * Settings
 */
export class Settings {

	/**
	 * Constructor
	 *
	 * @param {Object} settings
	 */
	constructor(settings) {
		this.settings = settings;
	}

	getSettings() {
		return this.settings;
	}

	getRecipeIds() {

		// Check if the recipes array exists in settings, if not, initialize it
		if (!this.settings.recipes) {
			this.settings.recipes = [];
		}

		// Extract all settings recipes Ids
		return this.settings.recipes.map(recipe => recipe.id);
	}

	isRecipeValid(recipeId) {

		// Check if the recipes array exists in settings, if not, initialize it
		if (!this.settings.recipes) {
			this.settings.recipes = [];
		}

		// Find the recipe by recipeId
		let recipe = this.settings.recipes.find((recipe) => recipe.id === recipeId);

		// If the recipe does not exist, return false
		if (!recipe) {
			return false;
		}

		return isSettingsRecipeValid(recipe);
	}

	getRecipeById(recipeId) {

		// Check if the recipes array exists in settings, if not, initialize it
		if (!this.settings.recipes) {
			this.settings.recipes = [];
		}

		// Find the recipe by recipeId
		let recipe = this.settings.recipes.find((recipe) => recipe.id === recipeId);

		// If the recipe does not exist, create a new recipe object and add it to the array
		if (!recipe) {
			recipe = { id: recipeId };
			this.settings.recipes.push(recipe);
		}

		// Return the found or newly created recipe
		return recipe;
	}

	/**
	 * Remove a recipe by recipeId
	 *
	 * @param {number} recipeId - The ID of the recipe to remove
	 * @returns {boolean} - Returns true if the recipe was removed, false if it wasn't found
	 */
	removeRecipe(recipeId) {

		// Check if the recipes array exists in settings, if not, initialize it
		if (!this.settings.recipes) {
			this.settings.recipes = [];
			return false;
		}

		const initialLength = this.settings.recipes.length;

		// Filter out the recipe with the matching recipeId
		this.settings.recipes = this.settings.recipes.filter(recipe => recipe.id !== recipeId);

		// Return true if a recipe was removed, otherwise false
		return this.settings.recipes.length < initialLength;
	}

	getFavoriteIngredients() {
		return this.settings.favorites?.ingredients;
	}

	isFavoriteIngredient(ingredientName) {
		return this.settings.favorites?.ingredients?.includes(ingredientName) || false;
	}

	// Toggle favorite ingredient
	setFavoriteIngredient(ingredientName) {
		// Initialize favorites and ingredients array if they don't exist
		if (!this.settings.favorites) {
			this.settings.favorites = { ingredients: [] };
		}

		if (!Array.isArray(this.settings.favorites.ingredients)) {
			this.settings.favorites.ingredients = [];
		}

		const ingredientIndex = this.settings.favorites.ingredients.indexOf(ingredientName);

		if (ingredientIndex > -1) {
			// If ingredient exists, remove it
			this.settings.favorites.ingredients.splice(ingredientIndex, 1);
		} else {
			// If ingredient doesn't exist, add it
			this.settings.favorites.ingredients.push(ingredientName);
		}
	}

	getFavoriteMolecules() {
		return this.settings.favorites?.molecules;
	}

	isFavoriteMolecule(moleculeId) {
		return this.settings.favorites?.molecules?.includes(moleculeId) || false;
	}

	// Toggle favorite molecule
	setFavoriteMolecule(moleculeId) {
		// Initialize the favorites object if it doesn't exist
		if (!this.settings.favorites) {
			this.settings.favorites = { molecules: [] };
		}

		// Ensure the molecules array exists within favorites
		if (!Array.isArray(this.settings.favorites.molecules)) {
			this.settings.favorites.molecules = [];
		}

		const moleculeIndex = this.settings.favorites.molecules.indexOf(moleculeId);

		if (moleculeIndex > -1) {
			// If molecule exists, remove it
			this.settings.favorites.molecules.splice(moleculeIndex, 1);
		} else {
			// If molecule doesn't exist, add it
			this.settings.favorites.molecules.push(moleculeId);
		}
	}

	getFavoriteRecipes() {
		return this.settings.favorites?.recipes;
	}

	isFavoriteRecipe(recipeId) {
		return this.settings.favorites?.recipes?.includes(recipeId) || false;
	}

	// Toggle favorite recipe
	setFavoriteRecipe(recipeId) {
		// Initialize favorites and recipes array if they don't exist
		if (!this.settings.favorites) {
			this.settings.favorites = { recipes: [] };
		}

		if (!Array.isArray(this.settings.favorites.recipes)) {
			this.settings.favorites.recipes = [];
		}

		const recipeIndex = this.settings.favorites.recipes.indexOf(recipeId);

		if (recipeIndex > -1) {
			// If recipe exists, remove it
			this.settings.favorites.recipes.splice(recipeIndex, 1);
		} else {
			// If recipe doesn't exist, add it
			this.settings.favorites.recipes.push(recipeId);
		}
	}

	getStudio() {

		// Check if the studio array exists in settings, if not, initialize it
		if (!this.settings.studio) {
			this.settings.studio = [];
		}

		return this.settings.studio;
	}

	getStudioProjectById(projectId, orElseCreate = true) {

		// Check if the studio array exists in settings, if not, initialize it
		if (!this.settings.studio) {
			this.settings.studio = [];
		}

		// Find the studio by projectId
		let studio = this.settings.studio.find((studio) => studio.id === projectId);

		// If the studio does not exist, create a new studio object and add it to the array
		if (!studio) {

			if(!orElseCreate) {
				return undefined;
			}

			studio = {
				id: projectId,
				title: i18n.t("studio.newProject"),
				description: i18n.t("studio.newProjectDescription"),
				created: moment().toISOString(),
				creations: []
			};
			this.settings.studio.push(studio);
		}

		// Check if the studio creations array exists in settings, if not, initialize it
		if (!studio.creations) {
			studio.creations = [];
		}

		// Return the found or newly created studio
		return studio;
	}

	/**
	 * Remove a project by projectId
	 *
	 * @param {number} recipeId - The ID of the studio to remove
	 * @returns {boolean} - Returns true if the studio was removed, false if it wasn't found
	 */
	removeStudioByProjectId(projectId) {

		// Check if the studio array exists in settings, if not, initialize it
		if (!this.settings.studio) {
			this.settings.studio = [];
			return false;
		}

		const initialLength = this.settings.studio.length;

		// Filter out the studio with the matching recipeId
		this.settings.studio = this.settings.studio.filter(studio => studio.id !== projectId);

		// Return true if a studio was removed, otherwise false
		return this.settings.studio.length < initialLength;
	}
}

/**
 * UserAccount
 */
export class UserAccount {

	/**
	 * Constructor
	 *
	 * @param {Object} user - The user object from Auth0
	 * @param {Object} account - The account object from system
	 * @param {Number} premiumTrialDays - Premium trial days
	 */
	constructor(user, account, premiumTrialDays) {
		this.user = user;
		this.account = account;
		this.premiumTrialDays = premiumTrialDays;

		this.removeInvalidSettings();
	}

	getAi() {
		if (!this.account || !this.account.user || !this.account.user.ai) {
			return null;
		}
		return this.account.user.ai;
	}

	setAi(ai) {
		if (!this.account || !this.account.user) {
			return null;
		}
		this.account.user.ai = ai;
	}

	getCreated() {
		if (!this.account || !this.account.user || !this.account.user.created) {
			return null;
		}
		return this.account.user.created;
	}

	getUpdated() {
		if (!this.account || !this.account.user || !this.account.user.updated) {
			return null;
		}
		return this.account.user.updated;
	}

	setUpdated(updated) {
		if (!this.account || !this.account.user) {
			return null;
		}
		this.account.user.updated = updated;
	}

	getPicture() {
		if (!this.account || !this.account.user || !this.account.user.picture) {
			return null;
		}
		return this.account.user.picture;
	}

	setPicture(picture) {
		if (!this.account) {
			this.account = {};
		}
		if (!this.account.user) {
			this.account.user = {};
		}
		this.account.user.picture = picture;
	}

	getProvider() {
		if (!this.user || !this.user.sub) {
			return null;
		}
		return this.user.sub.split('|')[0];
	}

	getProviderId() {
		if (!this.user || !this.user.sub) {
			return null;
		}
		return this.user.sub.split('|')[1];
	}

	setSettings(settings) {
		return this.account.user.settings = settings;
	}

	getSettings() {
		return new Settings(this.account.user.settings);
	}

	getValidSettings() {
		this.removeInvalidSettings();
		return new Settings(this.account.user.settings);
	}

	/**
	 * Remove invalid settings
	 *
	 * A recipe is considered invalid if:
	 * - `serving` = 1
	 * - `activeInstruction` = 0
	 * - `ingredients` = [] (empty array)
	 *
	 * @returns {number} - Returns the number of recipes removed
	 */
	removeInvalidSettings() {

		// Ensure the recipes array exists in settings
		if (!this.account.user.settings.recipes) {
			this.account.user.settings.recipes = [];
			return 0;
		}

		// Filter out invalid recipes from the recipes array
		const initialLength = this.account.user.settings.recipes.length;
		this.account.user.settings.recipes = this.account.user.settings.recipes.filter(recipe => isSettingsRecipeValid(recipe));

		// Return the number of removed recipes
		return initialLength - this.account.user.settings.recipes.length;
	}

	getUserId() {
		if (!this.account || !this.account.user || !this.account.user.userId) {
			return null;
		}
		return this.account.user.userId;
	}

	getName() {
		if (!this.account || !this.account.user || !this.account.user.name) {
			return null;
		}
		return this.account.user.name;
	}

	setName(name) {
		if (!this.account) {
			this.account = {};
		}
		if (!this.account.user) {
			this.account.user = {};
		}
		this.account.user.name = name;
	}

	getFirstName() {

		// Get the full name from the getName function
		const name = this.getName();

		// If no name is found, return null
		if (!name) {
			return null;
		}

		return name.split(' ')[0];
	}

	getInitials() {

		// Get the formatted name from the getName function
		let name = this.getName();

		// If no name or email is found, return null
		if (!name) {
			return null;
		}

		// Replace periods with spaces (already handled in getName) and split on spaces
		return name
			.split(' ')              // Split on spaces (since periods are already replaced)
			.filter(word => word)     // Filter out empty words in case of extra spaces
			.map(word => word[0])     // Take the first letter of each word
			.join('')                 // Join the letters to form initials
			.slice(0, 2)              // Take the first 2 characters
			.toUpperCase();           // Convert to uppercase
	}

	getRoles() {

		// Initialize an empty array to store roles
		let rolesArray = [];

		// Iterate over the keys of the user object
		Object.keys(this.user).forEach(key => {

			// Check if the key ends with "/roles"
			if (key.endsWith('/roles') && Array.isArray(this.user[key])) {

				// If it's an array, add the roles to rolesArray
				rolesArray = rolesArray.concat(this.user[key]);
			}
		});

		return rolesArray;
	}

	/**
	 * Check if the user has a specified role.
	 *
	 * @param {string|string[]} roleOrRoles - A role or an array of roles to check.
	 * @returns {boolean} - True if the user has at least one of the roles, false otherwise.
	 */
	isUserInRole(roleOrRoles) {
		// Get the user's roles
		const userRoles = this.getRoles();

		// If a single role is provided, convert it to an array
		const rolesToCheck = Array.isArray(roleOrRoles) ? roleOrRoles : [roleOrRoles];

		// Check if the user has at least one of the roles
		return rolesToCheck.some(role => userRoles.includes(role));
	}

	/**
	 * Get all products associated with the user's account.
	 *
	 * @returns {Array} - An array of products if they exist, otherwise an empty array.
	 */
	getProducts() {
		// Ensure the account and products array exist
		if (!this.account || !Array.isArray(this.account.products)) {
			return []; // Return an empty array if no products are available
		}

		// Return the products array
		return this.account.products;
	}

	/**
	 * Check if the user has a product of a specified type.
	 *
	 * @param {string|string[]} typeOrTypes - A product type or an array of product types to check.
	 * @returns {boolean} - True if the user has at least one product of the specified type, false otherwise.
	 */
	hasProductType(typeOrTypes) {
		// Get the products using the getProducts function
		const products = this.getProducts();

		// If a single product type is provided, convert it to an array
		const typesToCheck = Array.isArray(typeOrTypes) ? typeOrTypes : [typeOrTypes];

		// Check if the user has at least one product of the specified type
		return products.some(product => typesToCheck.includes(product.type));
	}

	/**
	 * Check if the user has the 'admin' role.
	 *
	 * @returns {boolean} - True if the user has the 'admin' role, false otherwise.
	 */
	isAdmin() {
		return this.isUserInRole("admin");
	}

	/**
	 * Check if the user has access to 'premium' features.
	 *
	 * @returns {boolean} - True if the user has the 'premium' role or product, false otherwise.
	 */
	isPremium() {
		return this.isUserInRole("premium") || this.hasProductType("premium");
	}

	/**
	 * Check if the user has access to 'studio' features.
	 *
	 * @returns {boolean} - True if the user has the 'studio' role or product, false otherwise.
	 */
	isStudio() {
		return this.isUserInRole("studio") || this.hasProductType("studio");
	}

	/**
	 * Retrieve the total number of days allocated for the premium trial period.
	 *
	 * This method returns the predefined number of days that the user can access
	 * premium features during the trial period.
	 *
	 * @returns {number} - The number of days for the premium trial period.
	 */
	getPremiumTrialDays() {
		return this.premiumTrialDays;
	}

	/**
	 * Calculate the number of days since the user's account creation date.
	 *
	 * This method returns the absolute number of days between the current date
	 * and the user's account creation date, which can be used to track the trial period.
	 *
	 * @returns {number} - The number of days since the account creation date.
	 */
	getPremiumTrialDaysLeft() {
		return Math.abs(daysFromNow(this.getCreated()));
	}

	/**
	 * Check if the user is still within the trial period for 'premium' features.
	 *
	 * This method calculates the number of days since the user's account creation date
	 * and checks if it is within the allowed trial period for premium features.
	 *
	 * @returns {boolean} - True if the user is within the 'premium' trial period (based on
	 *                      account creation date), false otherwise.
	 */
	isPremiumTrial() {
		return this.getPremiumTrialDaysLeft() <= this.premiumTrialDays;
	}
}

/**
 * AccountContext
 */
const AccountContext = createContext(null);

/**
 * useAccountContext
 */
export const useAccountContext = () => {
	return useContext(AccountContext);
};

// Initialize the EventEmitter to notify other components
const accountContextEmitter = new EventEmitter();

/**
 * AuthProvider
 */
export const AuthProvider = ({ children }) => {

	const {environment} = useEnvironmentContext();

	const onRedirectCallback = async (appState) => {
		console.log('User successfully authenticated');
	};

	return (!environment ? null :
		<Auth0Provider
			domain={`${environment.authenticationAuth0Domain}`}
			clientId={`${environment.authenticationAuth0ClientId}`}
			onRedirectCallback={onRedirectCallback}
			cacheLocation={"localstorage"}
			useRefreshTokens
			useRefreshTokensFallback
			authorizationParams={{
				redirect_uri: window.location.origin,
				audience: `${environment.authenticationAuth0Audience}` // APIs identifier
			}}
		>
			<AccountProvider>
				{children}
			</AccountProvider>
		</Auth0Provider>
	);
};

/**
 * WebSocket messages
 */
const WEBSOCKET_MESSAGE_TYPE_CONNECTION_ID = "CONNECTION_ID";
const WEBSOCKET_MESSAGE_TYPE_ACCOUNT_UPDATE = "ACCOUNT_UPDATE";
const WEBSOCKET_MESSAGE_TYPE_GENERATED_RECIPE = "GENERATED_RECIPE";
// const WEBSOCKET_MESSAGE_TYPE_GENERATED_RECIPE_IMAGE = "GENERATED_RECIPE_IMAGE";

/**
 * useWebSocket
 */
const useWebSocket = ({userAccount, onConflict, onGeneratedRecipe} = {}) => {

	const [isConnected, setIsConnected] = useState(false);

	const socketRef = useRef(null);
	const reconnectAttempts = useRef(0);
	const reconnectTimeout = useRef(null);
	const connectionIdRef = useRef(undefined);

	// Function to create WebSocket connection
	const connectWebSocket = () => {
		if (!userAccount) return;

		console.log(`Connecting WebSocket for userId: ${userAccount.getUserId()}`);

		// Create WebSocket connection
		socketRef.current = new WebSocket(getWebSocketUrl(userAccount.getUserId()));

		socketRef.current.onopen = () => {
			console.debug("WebSocket Connected");
			setIsConnected(true);
			reconnectAttempts.current = 0; // Reset reconnect attempts
		};

		socketRef.current.onmessage = (event) => {

			console.debug("Received:", event.data);

			const data = JSON.parse(event.data);

			switch(data.type) {

				case WEBSOCKET_MESSAGE_TYPE_CONNECTION_ID:
					connectionIdRef.current = data.value;
					break;

				case WEBSOCKET_MESSAGE_TYPE_ACCOUNT_UPDATE:

					if(onConflict) {
						onConflict(true);
					}
					break;

				case WEBSOCKET_MESSAGE_TYPE_GENERATED_RECIPE:
				// case WEBSOCKET_MESSAGE_TYPE_GENERATED_RECIPE_IMAGE:

					if(onGeneratedRecipe) {
						onGeneratedRecipe(data);
					}
					break;
			}
		};

		socketRef.current.onclose = () => {
			console.debug("WebSocket Disconnected");
			setIsConnected(false);
			retryConnection();
		};

		socketRef.current.onerror = (error) => {
			console.error("WebSocket Error:", error);
			socketRef.current.close();
		};
	};

	// Function to handle reconnect attempts
	const retryConnection = () => {
		if (reconnectAttempts.current >= 3) return; // Limit retries

		const delay = Math.min(1000 * 2 ** reconnectAttempts.current, 30000); // Exponential backoff up to 30s
		console.log(`Retrying WebSocket connection in ${delay / 1000}s...`);
		reconnectTimeout.current = setTimeout(connectWebSocket, delay);
		reconnectAttempts.current++;
	};

	// Effect to manage WebSocket lifecycle
	useEffect(() => {

		if(userAccount?.getUserId()) {
			connectWebSocket();
		}

		return () => {
			if (socketRef.current) {
				socketRef.current.close();
			}
			clearTimeout(reconnectTimeout.current);
		};
	}, [userAccount]);

	/**
	 * sendMessage
	 */
	const sendMessage = (messageType, value = "") => {

		if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN && connectionIdRef.current) {

			socketRef.current.send(JSON.stringify({
				type: messageType,
				value: value,
				sender: connectionIdRef.current,
			}));
		}
		else {
			console.error("WebSocket is not open");
		}
	};

	/**
	 * disconnect
	 * This function closes the WebSocket connection and prevents reconnection attempts.
	 */
	const disconnect = () => {
		if (socketRef.current) {
			console.debug("Closing WebSocket connection...");
			socketRef.current.onclose = null; // Prevent auto-reconnect
			socketRef.current.close();
			socketRef.current = null;
		}
		clearTimeout(reconnectTimeout.current);
		setIsConnected(false);
	};

	return {
		isConnected,
		sendMessage,
		disconnect,
	};
};

/**
 * SessionConflict
 */
export const SessionConflict = ({conflict}) => {

	const {t} = useTranslation();

	return (
		<Modal opened={conflict}
			   color={"tertiary"}
			   centered
			   closeOnClickOutside={false}
			   withCloseButton={false}
			   size={"xl"}
			   overlayProps={{color: "var(--mantine-color-tertiary-12)", backgroundOpacity: 0.75, blur: 7}}
			   zIndex={404}
			   classNames={{
				   root: classes.modalroot,
				   header: classes.modalheader,
				   content: classes.modalcontent,
				   inner: classes.modalinner,
				   body: classes.modalbody,
			   }}
		>
			<Modal.Header>
				<Group justify={"center"} style={{width: "100%", height: "15vh", textAlign: "center"}}>
					<FontAwesomeIcon icon={faRepeat} size={"6x"} style={{color: "var(--mantine-color-tertiary-6)"}} />
				</Group>
			</Modal.Header>

			<SimpleBox color={"tertiary"}>
				<Text ta={"center"}>
					{t("common.conflictDescription")}
				</Text>
			</SimpleBox>
			<Space h={"lg"}/>
			<Space h={"lg"}/>
			<Center>
				<Button color={"tertiary"} miw={140} onClick={() => {
					window.location.reload();
				}}>
					{t("common.conflictContinueHere")}
				</Button>
			</Center>
		</Modal>
	);
}

/**
 * AccountProvider
 */
export const AccountProvider = ({children}) => {

	const { isLoading, isAuthenticated, getAccessTokenSilently, getIdTokenClaims, loginWithRedirect, logout } = useAuth0();

	const [idToken, setIdToken] = useState(undefined);
	const [idTokenExp, setIdTokenExp] = useState(undefined);
	const [userAccount, setUserAccount] = useState(undefined);
	const [isLoaded, setIsLoaded] = useState(false);
	const [conflict, setConflict] = useState(false);

	let accountTokenExpired = useAccountStore((state) => state.accountTokenExpired);
	const setAccountTokenExpired = useAccountStore((state) => state.setAccountTokenExpired);

	const {environment} = useEnvironmentContext();

	const {onError} = useErrorContext();

	const {t} = useTranslation();

	const { isConnected, sendMessage, disconnect } =
		useWebSocket({
			userAccount: userAccount,
			onConflict: (value) => {

				if(!conflict && value) {
					setConflict(true);
					disconnect();
				}
			},
			onGeneratedRecipe: async (event) => {

				notifications.show({
					position: "top-right",
					title: (
						<Stack gap={0}>
							<Text lineClamp={1} c={"white"} fw={700}>{t("studio.generatedRecipe")}</Text>
						</Stack>
					),
					message: (
						<Text size={"sm"} lineClamp={1} c={"white"}>{t("studio.generatedRecipeDescription")}</Text>
					),
					color: "var(--mantine-color-tertiary-6)",
					radius: "md",
					withBorder: false,
					withCloseButton: false,
					autoClose: 7500,
					style: {
						marginTop: "var(--mantine-spacing-xs)",
						color: "var(--mantine-color-white)",
						backgroundColor: "var(--mantine-color-tertiary-6)"
					}
				})

				await refreshAccount();
			}
		});

	useEffect(() => {

		if(accountTokenExpired) {

			notifications.show({
				position: "top-right",
				title: (
					<Stack gap={0}>
						<Text c={"white"} fw={700}>{t("common.logoutDueToSessionExpiration")}</Text>
					</Stack>
				),
				color: "var(--mantine-color-tertiary-6)",
				radius: "md",
				withBorder: false,
				withCloseButton: true,
				autoClose: false,
				style: {
					marginTop: "var(--mantine-spacing-xs)",
					color: "var(--mantine-color-white)",
					backgroundColor: "var(--mantine-color-tertiary-6)"
				}
			})

			setAccountTokenExpired(false);
		}

	}, []);

	/**
	 * isIdTokenExpired
	 */
	const isIdTokenExpired = (exp) => {
		const currentTime = Math.floor(Date.now() / 1000);
		return exp - 5 /* 5 seconds before expiration */ < currentTime;
	};

	/**
	 * fetchAccessToken
	 */
	const fetchAccessToken = async () => {

		setIsLoaded(false);

		try {
			const accessToken = await getAccessTokenSilently({
				authorizationParams: {
					audience: `${environment.authenticationAuth0Audience}`,
					cacheLocation: "localstorage", // Use localstorage for session persistence
					fallbackToRefreshToken: true
				}
			});

			if(accessToken) {
				await fetchIdToken();
			}
			else {
				await handleNotExistingToken();
			}
		}
		catch (error) {

			// User is not logged in

			setIdToken(undefined);
			setIdTokenExp(undefined);
			setUserAccount(undefined);
		}

		setIsLoaded(true);
	}

	/**
	 * fetchIdToken
	 */
	const fetchIdToken = async () => {

		try {
			const idTokenClaims = await getIdTokenClaims();

			// ID token is valid
			if(idTokenClaims && !isIdTokenExpired(idTokenClaims.exp)) {

				setIdTokenExp(idTokenClaims.exp);

				const account = await fetchAccount(idTokenClaims.__raw);

				const jwt = jwtDecode(idTokenClaims.__raw);

				// Check for JWT email
				if(jwt.email) {
					setIdToken(idTokenClaims.__raw);
					setUserAccount(new UserAccount(jwt, account, environment.paymentPremiumTrialDays));
				}
				else {
					console.warn("No email present")
					await logout({logoutParams: {returnTo: window.location.origin}});

					// TODO notification no email present
				}
			}
			else {
				await handleNotExistingToken();
			}
		}
		catch (error) {
			onError(error);
		}
	};

	/**
	 * handleNotExistingToken
	 */
	const handleNotExistingToken = async () => {

		// ID token expired. Login redirect
		if (isAuthenticated) {

			console.warn("Login with redirect")

			// Trigger silent authentication
			await loginWithRedirect({
				audience: `${environment.authenticationAuth0Audience}`,
				prompt: "none", // This avoids showing the login prompt
			})
		}
		else {
			setIdToken(undefined);
			setIdTokenExp(undefined);
			setUserAccount(undefined);
		}
	}

	// When the user is authenticated, retrieve the token silently
	useEffect(() => {

		if(!isLoading) {
			fetchAccessToken();
		}

	}, [isLoading, isAuthenticated, getAccessTokenSilently, getIdTokenClaims, loginWithRedirect]);

	/**
	 * refreshAccount
	 */
	async function refreshAccount() {

		if (!isAuthenticated || conflict) {
			return;
		}

		try {
			const result = await fetchAccount(await getIdToken())

			userAccount.setAi(result.user.ai);
			userAccount.setUpdated(result.user.updated);

			// After refresh generated recipes, emit an event to notify listeners
			accountContextEmitter.emit("onUpdateUserAccount", userAccount);
			accountContextEmitter.emit("onGeneratedRecipes");
		}
		catch (error) {
			onError(error);
		}
	}

	/**
	 * updateUserAccount
	 */
	const updateUserAccount = useDebouncedCallback(async (account) => {

		if (!isAuthenticated || conflict) {
			return;
		}

		try {
			await fetchAccountUser(account.getName(), account.getPicture(), await getIdToken());

			// After updating settings, emit an event to notify listeners
			accountContextEmitter.emit("onUpdateUserAccount", account);

			// Force all other instances to refresh
			sendMessage(WEBSOCKET_MESSAGE_TYPE_ACCOUNT_UPDATE);
		}
		catch (error) {
			onError(error);
		}
	}, 1000);

	/**
	 * updateUserAccountSettings
	 */
	const updateUserAccountSettings = useDebouncedCallback(async (account) => {

		if (!isAuthenticated || conflict) {
			return;
		}

		try {
			await fetchAccountUserSettings(account.getSettings().getSettings(), await getIdToken());

			// After updating settings, emit an event to notify listeners
			accountContextEmitter.emit("onUpdateUserAccountSettings", account);

			sendMessage(WEBSOCKET_MESSAGE_TYPE_ACCOUNT_UPDATE);
		}
		catch (error) {
			onError(error);
		}
	}, 1000);

	/**
	 * logoutAccount
	 */
	const loginAccount = async () => {
		await loginWithRedirect({
			audience: `${environment.authenticationAuth0Audience}`,
			authorizationParams: {
				ui_locales: uiLocales()
			},
			prompt: "login",
		})
	};

	/**
	 * logoutAccount
	 */
	const logoutAccount = async () => {
		try {
			await fetchAccountLogout(await getIdToken());
			await logout({logoutParams: {returnTo: window.location.origin}});
		}
		catch (error) {
			onError(error);
		}
	};

	/**
	 * deleteAccount
	 */
	const deleteAccount = async () => {
		try {
			await fetchAccountDelete(await getIdToken());
			await logout({logoutParams: {returnTo: window.location.origin}});
		}
		catch (error) {
			onError(error);
		}
	};

	/**
	 * getIdToken
	 */
	const getIdToken = async () => {

		// User not logged in
		if(idTokenExp === undefined) {
			return undefined;
		}

		// Token expired
		if(isIdTokenExpired(idTokenExp)) {
			console.warn("Token expired");
			await logout({logoutParams: {returnTo: window.location.origin}});
			setAccountTokenExpired(true);
		}
		else {
			return idToken;
		}

		return undefined;
	}

	return (!environment ? null :
		<AccountContext.Provider value={{
			conflict,
			isAuthenticated,
			isLoaded,
			getIdToken,
			userAccount,
			onGeneratedRecipes: (callback) => accountContextEmitter.on("onGeneratedRecipes", callback),
			offGeneratedRecipes: (callback) => accountContextEmitter.off("offGeneratedRecipes", callback),
			updateUserAccount,
			onUpdateUserAccount: (callback) => accountContextEmitter.on("onUpdateUserAccount", callback),
			offUpdateUserAccount: (callback) => accountContextEmitter.off("onUpdateUserAccount", callback),
			updateUserAccountSettings,
			onUpdateUserAccountSettings: (callback) => accountContextEmitter.on("onUpdateUserAccountSettings", callback),
			offUpdateUserAccountSettings: (callback) => accountContextEmitter.off("onUpdateUserAccountSettings", callback),
			loginAccount,
			logoutAccount,
			deleteAccount
		}}>
			{children}
		</AccountContext.Provider>
	);
};