import React, {useEffect, useMemo, useState} from "react";
import {
	useReactFlow, Panel, getNodesBounds, getViewportForBounds,
} from '@xyflow/react';
import {useTranslation} from "react-i18next";
import {
	Box,
	Button,
	Divider,
	Group,
	Modal, NumberInput,
	Overlay, Pill, PillGroup, Progress,
	Space,
	Stack,
	Text, Textarea,
	TextInput,
	Tooltip
} from "@mantine/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCamera, faCircleInfo, faHome, faRotateLeft, faRotateRight} from "@fortawesome/free-solid-svg-icons";
import {useStudioStore} from "./StudioPage";
import {faMagnifyingGlassPlus} from "@fortawesome/free-solid-svg-icons/faMagnifyingGlassPlus";
import {faMagnifyingGlassMinus} from "@fortawesome/free-solid-svg-icons/faMagnifyingGlassMinus";
import {faExpand} from "@fortawesome/free-solid-svg-icons/faExpand";
import {faObjectGroup} from "@fortawesome/free-regular-svg-icons";
import {faFire} from "@fortawesome/free-solid-svg-icons/faFire";
import useGenerateRecipe from "./useGenerateRecipe";
import classes from "./Studio.module.css";
import i18n from "../../i18n";
import {useDisclosure} from "@mantine/hooks";
import {toSvg} from "html-to-image";
import {Icon} from "../../components/icons/Icons";
import * as allTablerIcons from "@tabler/icons-react";
import {faPen} from "@fortawesome/free-solid-svg-icons/faPen";
import { useForceUpdate } from '@mantine/hooks';
import {ReactComponent as StudioHeaderSvg} from "./studioheader.svg";
import {formatPc, GRID_SIZE} from "./StudioUtils";
import {useFeatures} from "../../components/features/useFeatures";
import {useEnvironmentContext} from "../../components/environment/EnvironmentContext";
import {useAccountContext} from "../../components/account/AccountContext";
import Motion from "./Motion";

/**
 * Command
 */
export const Command = ({	variant = "subtle", color="secondary",
					 fontAwesomeIcon, tablerIcon, icon,
					 label, tooltip, tooltipPosition= "top",
					 disabled = false,
					 loading = false,
					 blur = 64, withOverlay = false,
					 onClick = () => {}}) => {

	const [tooltipOpened, setTooltipOpened] = useState(false);

	/**
	 * onTooltipOpened
	 */
	const onTooltipOpened = (value) => {

		if(tooltip === undefined) {
			setTooltipOpened(false);
		}
		else {
			setTooltipOpened(value);
		}
	}

	/**
	 * CommandIcon
	 */
	const CommandIcon = ({fontAwesomeIcon, tablerIcon, icon}) => {

		if(fontAwesomeIcon !== undefined) {
			return <FontAwesomeIcon icon={fontAwesomeIcon} style={{width: "18px", height: "18px"}}/>
		}

		if(tablerIcon !== undefined) {
			const TablerIcon = allTablerIcons[tablerIcon];
			return <TablerIcon size={22} stroke={2}/>
		}

		if(icon !== undefined) {
			return <Icon name={icon} style={{paddingTop: "2px", width: "18px", height: "18px", fill: `var(--mantine-color-${color}-6)`}}/>
		}

		return null;
	}

	return <Tooltip color={color} label={tooltip} position={tooltipPosition} opened={tooltipOpened} multiline maw={300} withArrow>
		<Button variant={loading ? "light" : variant} color={color} onClick={onClick} radius={4} pl={7} pr={7} opacity={disabled ? 0.25 : 1}
					style={{pointerEvents: disabled || loading ? "none" : "auto"}}
					className={loading ? classes.blinkingstart : classes.blinkingend}
				   	onMouseEnter={() => onTooltipOpened(true)}
				   	onMouseLeave={() => onTooltipOpened(false)}
				   	onTouchStart={() => onTooltipOpened(!tooltipOpened)}>

			{withOverlay &&
				<Overlay color={`var(--mantine-color-${color}-6)`} backgroundOpacity={0} blur={blur} radius={4} zIndex={-1}
						 onMouseLeave={() => onTooltipOpened(false)}/>
			}

			<Group gap={6} wrap={"nowrap"}>

				<CommandIcon fontAwesomeIcon={fontAwesomeIcon} tablerIcon={tablerIcon} icon={icon} />

				{label && <Text size={"sm"}>
					{label}
				</Text>}
			</Group>
		</Button>
	</Tooltip>
}

/**
 * DownloadImageButton
 */
const DownloadImageButton = () => {

	const { getNodes } = useReactFlow();
	const { t } = useTranslation();

	/**
	 * openImageInNewTab
	 * Opens the generated SVG in a new tab for viewing.
	 */
	function openImageInNewTab(dataUrl) {
		const newTab = window.open();
		if (newTab) {
			newTab.document.write(`<img src="${dataUrl}" style="max-width:100%; height:auto;" />`);
			newTab.document.title = "CuckooKitchen SVG Export";
		} else {
			alert("Popup blocked! Please allow popups for this site.");
		}
	}

	/**
	 * onClick
	 */
	const onClick = () => {
		const reactFlowViewport = document.querySelector('.react-flow__viewport');
		const container = document.querySelector('.react-flow');

		if (!reactFlowViewport || !container) return;

		const { width, height } = container.getBoundingClientRect();
		const nodesBounds = getNodesBounds(getNodes());
		const viewport = getViewportForBounds(nodesBounds, width, height, 0.5, 2);

		toSvg(reactFlowViewport, {
			background: 'transparent',
			width: width,
			height: height,
			style: {
				width: width,
				height: height,
				transform: `translate(${viewport.x}px, ${viewport.y}px) scale(1)`,
			},
		}).then(openImageInNewTab);
	};

	return (
		<Command
			/*tooltip={t("studio.exportImage")} tooltipPosition={"right"}*/
			fontAwesomeIcon={faCamera}
			onClick={onClick}
		/>
	);
};

/**
 * GenerateRecipe
 */
const GenerateRecipe = ({settings, color="secondary"}) => {

	const [loading, { toggle }] = useDisclosure();

	const [opened, { open, close }] = useDisclosure(false, {
		// onOpen: () => console.log('Opened'),
		// onClose: () => console.log('Closed')
	});

	const {userAccount} = useAccountContext();
	const {environment} = useEnvironmentContext();

	const [message, setMessage] = useState(undefined);

	const [servings, setServings] = useState(4);
	const [comment, setComment] = useState(undefined);
	const [selectedDiets, setSelectedDiets] = useState([]);

	const {t} = useTranslation();

	const { getNodes, getEdges } = useReactFlow();

	const {reset: resetGenerateRecipe, refetch: refetchGenerateRecipe} =
		useGenerateRecipe({
			projectId: settings.id,
			message: message,
			onSuccess: (data) => {

				setMessage(undefined);
				setServings(4);
				setComment(undefined);

				// Toggle loading
				toggle();
			},
			// onError: (error) => {
			//
			// 	console.error(error);
			//
			// 	// Check if another run is in progress
			// 	if(error.status !== 409) {
			//
			// 		setMessage(undefined);
			// 		setServings(4);
			// 		setComment(undefined);
			//
			// 		// Toggle loading
			// 		toggle();
			// 	}
			// }
		})

	useEffect(() => {
		setMessage(undefined);
		setServings(4);
		setComment(undefined);
	}, [getNodes, getEdges]);

	useEffect(() => {

		if(message !== undefined) {
			// console.log(message)
			refetchGenerateRecipe();
		}

	}, [message]);

	// Define diet options
	const dietOptions = {
		diabeticDiet: t("recipe.dietType.diabeticDiet"),
		glutenFreeDiet: t("recipe.dietType.glutenFreeDiet"),
		halalDiet: t("recipe.dietType.halalDiet"),
		hinduDiet: t("recipe.dietType.hinduDiet"),
		kosherDiet: t("recipe.dietType.kosherDiet"),
		lowCalorieDiet: t("recipe.dietType.lowCalorieDiet"),
		lowFatDiet: t("recipe.dietType.lowFatDiet"),
		lowLactoseDiet: t("recipe.dietType.lowLactoseDiet"),
		lowSaltDiet: t("recipe.dietType.lowSaltDiet"),
		veganDiet: t("recipe.dietType.veganDiet"),
		vegetarianDiet: t("recipe.dietType.vegetarianDiet"),
	};

	// Function to handle diet selection
	const handleDietChange = (key) => {
		setSelectedDiets((prevDiets) =>
			prevDiets.includes(key)
				? prevDiets.filter((d) => d !== key) // Remove if already selected
				: [...prevDiets, key] // Add if not selected
		);
	};

	/**
	 * generateRecipe
	 */
	function generateRecipe() {

		/**
		 * extractLabel
		 */
		function extractLabel(node) {

			switch (node.type) {

				case "input":
				case "intermediate":
					// return node?.data?.label?.ingredientName;
					return `${node?.data?.label} (en: ${node?.data?.label?.ingredientName})`;

				case "group":
				case "custom":
					return node?.data?.label;
			}

			return undefined;
		}

		const message = {
			language: i18n.language,

			projectTitle: settings.title, // The title of the project
			projectDescription: settings.description, // The description of the project

			servings: servings, // Number of servings

			// Include selected diets in the message
			diets: selectedDiets.map(diet => dietOptions[diet]).filter(Boolean),

			// Find the first node of type "input" and extract its ingredient name as the main ingredient
			mainIngredient: getNodes()
				.find(node => node?.type === "input")
				? extractLabel(getNodes().find(node => node?.type === "input"))
				: null,

			// Extract ingredients and groups together in a single array
			ingredients: [
				// Normal ingredients (not part of a group)
				...Array.from(
					new Set(
						getNodes()
							.filter(node => extractLabel(node) && !node?.parentId && node?.type !== "group") // Exclude grouped ingredients
							.map(node => extractLabel(node))
					)
				).map(name => ({ name, isGroup: false })),

				// Grouped ingredients, formatted as group objects
				...getNodes()
					.filter(node => node?.type === "group") // Select only group nodes
					.map(groupNode => ({
						name: extractLabel(groupNode) || "Unknown", // Group name
						isGroup: true, // Flag to identify as a group
						description: groupNode.data?.description || "", // Optional description
						ingredients: getNodes() // Find ingredients within this group
							.filter(node => node?.parentId === groupNode.id && extractLabel(node))
							.map(node => extractLabel(node))
					}))
			].sort((a, b) => a.name.localeCompare(b.name)), // Sort alphabetically

			// Extract pairings only between ingredients and groups, but not within group members
			pairings: getEdges()
				.map(edge => {
					const nodes = getNodes().filter(node => extractLabel(node) || node?.type === "group");

					const sourceNode = nodes.find(node => node.id === edge.source); // Find source node
					const targetNode = nodes.find(node => node.id === edge.target); // Find target node

					// Ensure both source and target nodes exist
					if (sourceNode && targetNode) {
						const isGroupBaseIngredient = sourceNode.type === "group"; // Check if base ingredient is a group

						return {
							baseIngredient: extractLabel(sourceNode), // Name of source (ingredient or group)
							complementIngredient: extractLabel(targetNode), // Name of target (ingredient or group)
							compatibilityPc: edge?.data?.sharedMoleculesPc > 0 ? formatPc(edge?.data?.sharedMoleculesPc, 0, 1, "") : undefined, // Compatibility percentage (default to 0 if undefined)
							isGroupBaseIngredient: isGroupBaseIngredient // Flag for group base ingredient
						};
					}
					return null; // Exclude invalid pairings
				})
				.filter(pairing => pairing !== null), // Remove null pairings

			// Instruction for the AI to generate a recipe
			prompt: `
        Create a **recipe in JSON format, no comments only the recipe_response** based on the provided project details, servings, diets, main ingredient, ingredients, and pairings.
        
        #### **Project Details**
        The recipe should align with the given project language, title and description. Ensure it serves the specified number of people, respects the specified diets and incorporates key elements appropriately.
        
        #### **Ingredients & Pairings**
        Use the given list of ingredients, ensuring the **main ingredient** plays a central role. Pairings are based on molecular compatibility and should guide the ingredient combinations. The recipe should highlight **harmonious flavor interactions**. Additional ingredients must be selected from the **ingredients.json** file.

        #### **Culinary Techniques & Groups**
        Some ingredients are categorized into **culinary technique groups**, such as emulsions, reductions, or specific preparation styles. Incorporate these techniques into the recipe to enhance the flavors and textures.
        
        #### **Instructions**
        - ${comment ? "Respect " + comment : ""}
        - Develop a **coherent and creative dish** using the ingredients.
        - Specify in the response the number of servings and the type of diet the recipe is designed for..
        - Ensure the **main ingredient is prominent**.
        - Utilize ingredient pairings effectively to create a balanced dish.
        - **The recipe must be inspired by the bibliography provided in the system instructions.**
        - Apply **relevant cooking techniques** where appropriate:
			- Avoid assuming prior knowledge of advanced cooking methods; instead, provide explanations that allow any cook to follow the process successfully.
			- For each cooking technique (e.g., reduction, smoking, emulsification), include:
				- The precise steps required to execute it properly with high details.
				- Why it is used in this recipe.
				- A brief definition of what the technique is.
				- Naturally explained within the step-by-step instructions. Avoid explicit labels such as 'Definition' or 'Purpose.' Instead, smoothly integrate explanations into the instructions, providing a natural learning experience for both expert chefs and amateur cooks.
			- Ensure clarity and completeness, so that even an amateur cook can recreate the dish with confidence.
			- The recipe should maintain a professional tone while remaining accessible, balancing technical precision with ease of understanding.
		- Food pairing must always be written with a space between the words, never as 'foodpairing'.
        `
		};

		// console.log(JSON.stringify(message));

		setMessage(JSON.stringify(message));

		// Start loading
		toggle();
	}

	/**
	 * getTokensColor
	 */
	function getTokensColor(value, colorLevel = "6", defaultColor = "tertiary") {

		/**
		 * TOKENS_THRESHOLDS
		 */
		const TOKENS_THRESHOLDS = [
			{ limit: 50, color: "lime" },
			{ limit: 20, color: "yellow" },
			{ limit: 10, color: "orange" },
			{ limit: 0, color: "red" }
		];

		for (const { limit, color } of TOKENS_THRESHOLDS) {
			if (value >= limit) {
				return `var(--mantine-color-${color}-${colorLevel})`;
			}
		}

		return `var(--mantine-color-${defaultColor}-${colorLevel})`;
	}

	/**
	 * usageRecipeTokensPc
	 */
	const usageRecipeTokensPc = useMemo(() => {
		return userAccount.getAi().recipeTokens * 100 / environment.aiMaxRecipeTokens;
	}, [userAccount.getAi().recipeTokens, environment.aiMaxRecipeTokens]);

	/**
	 * usageImageTokensPc
	 */
	const usageImageTokensPc = useMemo(() => {
		return userAccount.getAi().imageTokens * 100 / environment.aiMaxImageTokens;
	}, [userAccount.getAi().imageTokens, environment.aiMaxImageTokens]);

	return (
		<>
			<Modal opened={opened}
				   color={"white"}
				   centered
				   closeOnClickOutside
				   withCloseButton={false}
				   size={"xl"}
				   overlayProps={{color: `var(--mantine-color-${color}-12)`, backgroundOpacity: 0.75, blur: 7}}
				   // zIndex={404}
				   onClose={close}
				   classNames={{
					   root: classes.modalroot,
					   header: classes.modalheader,
					   content: classes.modalcontent,
					   inner: classes.modalinner,
					   body: classes.modalbody,
				   }}
			>

				<Text size={"xl"} fw={700} pb={"md"} c={`var(--mantine-color-white)`} flex={1}>{t("studio.generateRecipe")}</Text>
				<Text size={"sm"} pb={"md"} c={`var(--mantine-color-white)`} flex={1}>{t("studio.generateRecipeDescription")}</Text>

				<Stack>
					<NumberInput
						label={t("recipe.serving")}
						value={servings}
						onChange={(value) => setServings(value)}
						min={1}
						classNames={{
							label: classes.modalinputlabel,
							input: classes.modalinputinput,
							controls: classes.modalnumbercontrols,
							control: classes.modalnumbercontrol,
						}}
					/>
					<Textarea
						label={t("studio.comment")}
						value={comment}
						onChange={(e) => setComment(e.currentTarget.value)}
						onFocus={(e) => e.currentTarget.select()}
						autosize
						minRows={2}
						maxRows={4}
						classNames={{
							label: classes.modalinputlabel,
							input: classes.modalinputinput
						}}
					/>

					<Text size="sm" fw={700} c={`var(--mantine-color-white)`}>
						{t("studio.diet")}
					</Text>

					<Stack gap={0} c={`var(--mantine-color-white)`}>
						<PillGroup>
							{Object.entries(dietOptions).map(([key, label]) => (
								<Pill
									key={key}
									onClick={() => handleDietChange(key)}
									c={selectedDiets.includes(key) ? `var(--mantine-color-white)` : `var(--mantine-color-${color}-9)`}
									bg={selectedDiets.includes(key) ? `var(--mantine-color-${color}-6)` : `var(--mantine-color-${color}-outline-hover)`}
									style={{ cursor: "pointer" }}
									miw={150}
									radius={4}
									ta={"center"}
								>
									{label}
								</Pill>
							))}
						</PillGroup>
					</Stack>
				</Stack>

				<Space h="lg"/>
				<Space h="lg"/>

				<Progress.Root size={28} radius={"sm"} mb={"sm"} bg={"var(--mantine-color-secondary-light-hover)"}>
					<Progress.Section value={usageRecipeTokensPc} color={getTokensColor(usageRecipeTokensPc)}>
						<Progress.Label>
							<Text size={"xs"}>{`${userAccount.getAi().recipeTokens} / ${environment.aiMaxRecipeTokens}`}</Text>
						</Progress.Label>`
					</Progress.Section>
				</Progress.Root>

				<Progress.Root size={28} radius={"sm"} mb={"lg"} bg={"var(--mantine-color-secondary-light-hover)"}>
					<Progress.Section value={usageImageTokensPc} color={getTokensColor(usageImageTokensPc)}>
						<Progress.Label>
							<Text size={"xs"}>{`${userAccount.getAi().imageTokens} / ${environment.aiMaxImageTokens}`}</Text>
						</Progress.Label>
					</Progress.Section>
				</Progress.Root>

				<Group justify={"space-between"} pt={"md"}>
					<Group>
						<Text size={"xs"} c={`var(--mantine-color-white)`}>{t("studio.generateInfo", {
							// month: toMoment(userAccount.getAi().lastResetDate || moment.now(), "MM.YYYY"),
							usedRecipeTokens: userAccount.getAi().recipeTokens,
							totalRecipeTokens: environment.aiMaxRecipeTokens,
							usedImageTokens: userAccount.getAi().imageTokens,
							totalImageTokens: environment.aiMaxImageTokens})}</Text>
					</Group>
					<Group>
						<Button c={`white`} color={color} miw={140} variant={"light"} onClick={close}>{t("common.cancel")}</Button>
						<Button color={color} miw={140} disabled={userAccount.getAi().recipeTokens === 0} onClick={() => {close(); generateRecipe()}}>
							{t("studio.generateRecipeTokens", {usedRecipeTokens: userAccount.getAi().recipeTokens, totalRecipeTokens: environment.aiMaxRecipeTokens})}
						</Button>
					</Group>
				</Group>

			</Modal>

			<Command icon={"hat-chef"} onClick={open} label={t("studio.generateRecipe")} loading={loading}/>
		</>
	);
}

// /**
//  * FilePanel
//  */
// export const FilePanel = ({blur = 64,
// 								color = "var(--mantine-color-tertiary-6)",
// 								backgroundColor ="var(--mantine-color-tertiary-6)",
// 								backgroundOpacity = 0}) => {
//
// 	const { t } = useTranslation();
//
// 	return <Box m={"md"} p={"md"} c={color} style={{position: "absolute", zIndex: 1}}>
//
// 			<Overlay color={backgroundColor} backgroundOpacity={backgroundOpacity} blur={blur} radius={"md"} zIndex={-1}/>
//
// 			<Group align="flex-start" justify="center" wrap={"nowrap"} gap={"xl"}>
// 				<Group gap={4}>
// 					<Icon name={"logo"} style={{marginBottom: "-4px"}}/>
// 					<Text size={"sm"} fw={700} c={"white"}>FP-Studio</Text>
// 				</Group>
// 				<Group gap={4}>
// 					<DownloadButton />
// 				</Group>
// 			</Group>
// 		</Box>
// }

/**
 * ProjectData
 */
const ProjectData = ({title, description, color = "secondary", onSave = () => {}}) => {

	const [opened, { open, close }] = useDisclosure(false);

	const [inputTitle, setInputTitle] = useState("");
	const [inputDescription, setInputDescription] = useState("");

	const {t} = useTranslation();

	useEffect(() => {
		if (opened) {
			setInputTitle(title);
			setInputDescription(description);
		}
	}, [opened, title, description]);

	return (

		<>
			<Modal opened={opened}
				   color={color}
				   centered
				   closeOnClickOutside
				   withCloseButton={false}
				   size={"xl"}
				   overlayProps={{color: `var(--mantine-color-${color}-12)`, backgroundOpacity: 0.75, blur: 7}}
				   // zIndex={404}
				   onClose={close}
				   classNames={{
					   root: classes.modalroot,
					   header: classes.modalheader,
					   content: classes.modalcontent,
					   inner: classes.modalinner,
					   body: classes.modalbody,
				   }}
			>
				<Text size={"xl"} fw={700} pb={"md"} c={`var(--mantine-color-white)`} flex={1}>{inputTitle}</Text>

				<Stack>
					<TextInput
						label={t("studio.project.title")}
						value={inputTitle}
						onChange={(e) => setInputTitle(e.currentTarget.value)}
						onFocus={(e) => e.currentTarget.select()}
						classNames={{
							label: classes.modalinputlabel,
							input: classes.modalinputinput
						}}
					/>
					<Textarea
						label={t("studio.project.description")}
						value={inputDescription}
						onChange={(e) => setInputDescription(e.currentTarget.value)}
						onFocus={(e) => e.currentTarget.select()}
						autosize
						minRows={2}
						maxRows={4}
						classNames={{
							label: classes.modalinputlabel,
							input: classes.modalinputinput
						}}
					/>
				</Stack>
				<Space h="lg"/>
				<Group justify={"flex-end"} pt={"md"}>
					<Button c={`white`} color={color} miw={140} variant={"light"} onClick={close}>{t("common.cancel")}</Button>
					<Button color={color} miw={140} onClick={() => {onSave(inputTitle, inputDescription); close();}}>{t("common.save")}</Button>
				</Group>
			</Modal>

			<Button variant={"subtle"} color={"white"} justify="center" size="compact-md" w={36} h={28} radius={4} p={0} onClick={open}>
				<FontAwesomeIcon icon={faPen} style={{width: "16px", height: "16px"}}/>
			</Button>
		</>
	)
}

/**
 * HeaderPanel
 */
export const HeaderPanel = ({position="top-right",
							settings,
							blur = 64,
							color = "white",
							backgroundColor ="primary",
							backgroundOpacity = 1,
							onSave = () => {}}) => {

	const [tooltipOpened, setTooltipOpened] = useState(false);

	const forceUpdate = useForceUpdate();

	const { t } = useTranslation();

	const infoIconStyle = { width: "18px", height: "18px", marginTop: "1px", paddingLeft: "3px", opacity: 0.75, color: `var(--mantine-color-white)`};

	return <Panel position={position} className={classes.defaultreactflowpanel} style={{width: "200px", marginTop: 0, padding: 0}} >

		<Motion initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} delay={0.8}>
			<Box >

				{/*<Overlay color={`var(--mantine-color-${backgroundColor}-6)`} backgroundOpacity={backgroundOpacity} radius={"md} blur={blur} zIndex={-1}/>*/}

				<Stack align="flex-start" justify="flex-start" gap={0}>

					<Stack p={"md"} bg={`var(--mantine-color-primary-6)`} gap={0} w={200} pt={"20"} style={{position: "relative"}}>
						<Icon name={"logo-full-fp"} style={{fill: `var(--mantine-color-${color})`, width: "170px", height: "80px"}} />
						<StudioHeaderSvg style={{position: "absolute", left: 0, bottom: 0, width: "200px"}} />
					</Stack>

					<Stack p={"xs"} bg={`var(--mantine-color-secondary-6)`} gap={0} w={200} style={{borderBottomLeftRadius: "var(--mantine-radius-default)", borderBottomRightRadius: "var(--mantine-radius-default)" }}>

						<Group justify="space-between" c={color} wrap={"nowrap"} w={"100%"} style={{pointerEvents: "auto"}} gap={6}>
							<Group gap={3} justify={"flex-start"} wrap={"nowrap"}>
								<Text size={"sm"} fw={500} lh={"xs"} lineClamp={2} pt={1} pl={3}>{settings?.title}</Text>
								{settings?.description?.length > 0 &&
									<Tooltip opened={tooltipOpened} transitionProps={{ duration: 0 }} multiline maw={300} withArrow label={settings?.description} bg={"var(--mantine-color-secondary-0)"} c={"secondary.9"}>
										<FontAwesomeIcon icon={faCircleInfo} style={infoIconStyle}
														 onMouseEnter={() => setTooltipOpened(true)}
														 onMouseLeave={() => setTooltipOpened(false)}
														 onTouchStart={() => setTooltipOpened((o) => !o)}/>
									</Tooltip>
								}
							</Group>
							<ProjectData title={settings?.title} description={settings?.description}
										 onSave={(title, description) => {settings.title = title; settings.description=description; onSave(); forceUpdate();}}/>
						</Group>
					</Stack>

					{/*<Group align="center" justify="flex-start" wrap={"nowrap"} gap={"xl"}>*/}
					{/*	<Group gap={4}>*/}
					{/*		<Icon name={"logo-full"} style={{marginBottom: "-4px", fill: `var(--mantine-color-${color})`}}/>*/}
					{/*		<Text size={"sm"} fw={700} c={color}>FP-Studio</Text>*/}
					{/*	</Group>*/}
						{/*<Group gap={4}>*/}
						{/*	<DownloadImageButton />*/}
						{/*</Group>*/}
					{/*</Group>*/}
					{/*<Divider size={"xs"} color={`${color}.9`} w={"100%"} style={{opacity: 0.15}} />*/}
					{/*<Text size={"md"} fw={500} c={`${color}.9`} lineClamp={1} mt={"4px"}>{settings.title}</Text>*/}
					{/*<Text size={"xs"} c={`${color}.9`} lineClamp={3}>{settings.description}</Text>*/}
				</Stack>
			</Box>
		</Motion>
	</Panel>
}

/**
 * CommandPanel
 */
export const CommandPanel = ({position="top-center",
								 settings,
								 blur = 64,
								 color = "primary",
								 backgroundColor ="tertiary",
								 backgroundOpacity = 0,
								 isSm = false,
								 canUndo = false,
								 onUndo = () => {},
								 canRedo = false,
								 onRedo = () => {},
								 onProjects = () => {},
								 showGroup, onGroupClick = () => {}
							}) => {

	let lightThemeEnabled = useStudioStore((state) => state.studioLightThemeEnabled);
	const setLightThemeEnabled = useStudioStore((state) => state.setStudioLightThemeEnabled);

	let heatmapEnabled = useStudioStore((state) => state.studioHeatmapEnabled);
	const setHeatmapEnabled = useStudioStore((state) => state.setStudioHeatmapEnabled);

	const { Features } = useFeatures();

	const { t } = useTranslation();

	return <Panel position={position} className={classes.defaultreactflowpanel} /*style={{zIndex: 5}}*/>

		<Motion initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }}>
			<Box p={"md"}>

				<Overlay color={`var(--mantine-color-${backgroundColor}-6)`} backgroundOpacity={backgroundOpacity} blur={blur} radius={"md"} zIndex={-1}/>

				<Group align="center" justify="center" wrap={"nowrap"} gap={"xs"}>

					<Group gap={6} wrap={"nowrap"}>
						<Command /*tooltip={t("studio.zoomIn")} tooltipPosition={"left"}*/ fontAwesomeIcon={faHome} onClick={onProjects}/>
						{!isSm &&
							<DownloadImageButton />
						}
						<Command tablerIcon={lightThemeEnabled ? "IconMoonFilled" : "IconSunFilled"} onClick={() => setLightThemeEnabled(!lightThemeEnabled)}/>
					</Group>

					{!isSm &&
						<>
							<Divider orientation="vertical" color={"secondary"} mt={"xs"} mb={"xs"} opacity={0.25}/>

							<Group gap={6} wrap={"nowrap"}>
								<Command fontAwesomeIcon={faRotateLeft} onClick={onUndo} disabled={!canUndo}/>
								<Command fontAwesomeIcon={faRotateRight} onClick={onRedo} disabled={!canRedo}/>
							</Group>
						</>
					}

					{((!isSm && Features.studio.features.ingredientGroups.plan.enabled && showGroup) ||
						Features.studio.features.heatmap.plan.enabled ||
						(!isSm && Features.studio.features.aiRecipeGenerator.plan.enabled)) &&
						<>
							<Divider orientation="vertical" color={"secondary"} mt={"xs"} mb={"xs"} opacity={0.25}/>

							<Group gap={6} wrap={"nowrap"}>
								{!isSm && Features.studio.features.ingredientGroups.plan.enabled && showGroup &&
									<Command fontAwesomeIcon={faObjectGroup} onClick={onGroupClick} label={t("studio.newGroupNode")}/>
								}

								{Features.studio.features.heatmap.plan.enabled &&
									<Command fontAwesomeIcon={faFire} variant={heatmapEnabled ? "light" : "subtle"} /*color={heatmapEnabled ? "secondary" : "gray"}*/
											 onClick={() => setHeatmapEnabled(!heatmapEnabled)} label={t("studio.heatmap")}/>
								}

								{!isSm && Features.studio.features.aiRecipeGenerator.plan.enabled &&
									<GenerateRecipe settings={settings}/>
								}
							</Group>
						</>
					}
				</Group>

			</Box>
		</Motion>
	</Panel>
}

/**
 * ToolsPanel
 */
export const ToolsPanel = ({position="top-left",
							blur = 64,
							color = "tertiary",
							backgroundColor ="tertiary",
							selectionOnDrag, setSelectionOnDrag = () => {},
							backgroundOpacity = 0,
							isSm = false}) => {

	const { zoomIn, zoomOut , fitView} = useReactFlow();

	let studioGridSize = useStudioStore((state) => state.studioGridSize);
	const setStudioGridSize = useStudioStore((state) => state.setStudioGridSize);

	const { t } = useTranslation();

	return (
		<Panel position={position} className={classes.defaultreactflowpanel} style={{top: "50%", transform: "translateY(-50%)"}}>

			<Motion initial={{ opacity: 0, x: -50 }} animate={{ opacity: 1, x: 0 }}>
				<Box p={4} c={`var(--mantine-color-${color}-6)`}>

					<Overlay color={`var(--mantine-color-${backgroundColor}-6)`} backgroundOpacity={backgroundOpacity} blur={blur} radius={"md"} zIndex={-1}/>

					<Stack gap={4} wrap={"nowrap"}>
						<Command /*tooltip={t("studio.zoomIn")} tooltipPosition={"left"}*/ fontAwesomeIcon={faMagnifyingGlassPlus} onClick={zoomIn}/>
						<Command /*tooltip={t("studio.zoomOut")} tooltipPosition={"left"}*/ fontAwesomeIcon={faMagnifyingGlassMinus} onClick={zoomOut}/>
						<Command /*tooltip={t("studio.fitView")} tooltipPosition={"left"}*/ fontAwesomeIcon={faExpand} onClick={fitView}/>

						<Space h={"md"}/>

						{!isSm &&
							<Command /*tooltip={t("studio.fitView")} tooltipPosition={"left"}*/ tablerIcon={"IconSelectAll"}
																								variant={selectionOnDrag ? "light" : "subtle"}
																								onClick={() => setSelectionOnDrag(!selectionOnDrag)}/>
						}

						<Command /*tooltip={t("studio.fitView")} tooltipPosition={"left"}*/ tablerIcon={"IconGrid3x3"}
																							variant={studioGridSize > GRID_SIZE.min ? "light" : "subtle"}
																							onClick={() => setStudioGridSize(studioGridSize > GRID_SIZE.min ? GRID_SIZE.min : GRID_SIZE.max)}/>
					</Stack>

				</Box>
			</Motion>
		</Panel>
	)
}