import React, {useEffect} from "react";
import {Avatar, Text} from "@mantine/core";
import {useAccountContext} from "./AccountContext";
import classes from './Account.module.css';
import {useTranslation} from "react-i18next";
import {CustomMenu} from "../menu/CustomMenu";
import {accountNavigate} from "../../pages/account/AccountLink";
import {useNavigate} from "react-router-dom";
import {useForceUpdate} from "@mantine/hooks";
import useMedia from "../../pages/useMedia";
import {studioNavigate} from "../../pages/studio/StudioLink";
import {useFeatures} from "../features/useFeatures";


/**
 * AccountLogin
 */
export const AccountLogin = (props) => {

	const {showFPS = true, authenticatedColor = "secondary", authenticatedVariant = "filled", unauthenticatedColor = "white", unauthenticatedVariant = "transparent", onClick = () => {}} = props;

	const { isAuthenticated, userAccount, onUpdateUserAccount, offUpdateUserAccount, loginAccount, logoutAccount } = useAccountContext();

	const { Features } = useFeatures();

	const { isSm } = useMedia();

	const forceUpdate = useForceUpdate();

	const { t } = useTranslation();
	const navigate = useNavigate();

	useEffect(() => {

		// Define the callback to execute on update
		const handleOnUpdateUserAccount = () => {
			forceUpdate();
		};

		// Subscribe to the settings update event
		onUpdateUserAccount(handleOnUpdateUserAccount);

		// Clean up by unsubscribing when component unmounts
		return () => {
			offUpdateUserAccount(handleOnUpdateUserAccount);
		};

	}, [onUpdateUserAccount, offUpdateUserAccount]);

	return (
		!isSm ?
			<>
				{!isAuthenticated ?
					<CustomMenu>

						<CustomMenu.Target style={{cursor: 'pointer'}} onClick={(event) => {loginAccount(); onClick(event)}}>
							<Avatar component="a" color={authenticatedColor} variant={authenticatedVariant} radius={"md"} className={classes.login}/>
						</CustomMenu.Target>

					</CustomMenu>
					:
					<CustomMenu>

						<CustomMenu.Target style={{cursor: 'pointer'}}>
							<Avatar component="a" color={authenticatedColor} variant={authenticatedVariant} radius={"md"}
									name={userAccount?.getName()} src={userAccount?.getPicture()} className={classes.menutarget} />
						</CustomMenu.Target>

						<CustomMenu.Dropdown>
							<CustomMenu.Item onClick={(event) => {accountNavigate(navigate); onClick(event)}}>
								<Text className={classes.anchor}>{t("account.myCuckooKitchen")}</Text>
							</CustomMenu.Item>
							{Features.studio.plan.enabled && showFPS &&
								<CustomMenu.Item onClick={(event) => {studioNavigate(navigate); onClick(event)}}>
									<Text className={classes.anchor}>{t("studio.studio")}</Text>
								</CustomMenu.Item>
							}
							<CustomMenu.Item onClick={(event) => {logoutAccount(); onClick(event)}}>
								<Text className={classes.anchor}>{t("common.logout")}</Text>
							</CustomMenu.Item>
						</CustomMenu.Dropdown>

					</CustomMenu>
				}
			</>
		:
			<>
				{!isAuthenticated ?
					<Avatar component="a" color={unauthenticatedColor} variant={unauthenticatedVariant} className={classes.login} onClick={(event) => {loginAccount(); onClick(event)}}/>
				:
					<Avatar component="a" color={unauthenticatedColor} variant={unauthenticatedVariant} radius={"md"}
							name={userAccount?.getName()} src={userAccount?.getPicture()} className={classes.login} onClick={(event) => {accountNavigate(navigate); onClick(event)}}/>
				}
			</>
	);
};

/**
 * AccountProfile
 */
export const AccountProfile = () => {

	const { userAccount, isAuthenticated, isLoaded } = useAccountContext();

	if (!isLoaded) {
		return <div>Loading ...</div>;
	}

	return (
		isAuthenticated && (
			<div>
				<img src={userAccount.getPicture()} alt={userAccount.getName()} />
				<h2>{userAccount.getName()}</h2>
				<p>{userAccount.getEmail()}</p>
			</div>
		)
	);
};