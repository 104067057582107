import React from "react";
import classes from './DeleteModal.module.css';
import {useDisclosure} from "@mantine/hooks";
import {useTranslation} from "react-i18next";
import {Box, Button, Group, Modal, Text} from "@mantine/core";

/**
 * DeleteModal
 */
export const DeleteModal = ({targetComponent,
                        title,
                        description,
                        cancelText,
                        deleteText,
                        onDelete = () => {},
                        color = "primary",
                        }) => {

    const [opened, { close, open }] = useDisclosure(false);
    const {t} = useTranslation();

    return (
        <>
            <Modal opened={opened}
                   onClose={close}
                   color={color}
                   closeOnClickOutside
                   withCloseButton={false}
                   centered
                   size={"lg"}
                   title={<Text fw={700}>
                               {title || t("common.delete")}
                           </Text>}
                   overlayProps={{color: `var(--mantine-color-${color}-12)`, backgroundOpacity: 0.75, blur: 7}}
                   zIndex={404}
                   classNames={{
                       root: classes[`modalroot${color}`],
                       header: classes[`modalheader${color}`],
                       content: classes[`modalcontent${color}`],
                       inner: classes[`modalinner${color}`],
                       body: classes[`modalbody${color}`],
                   }}
            >
                <Text pt={"xs"}>
                    {description}
                </Text>
                <Group justify={"flex-end"} pt={"xl"}>
                    <Button miw={140} variant={"light"} c={"white"} onClick={close}>{cancelText || t("common.cancel")}</Button>
                    <Button miw={140} onClick={(event) => {
                        event.preventDefault(); event.stopPropagation();
                        onDelete();
                        close();
                    }}>{deleteText || t("common.delete")}</Button>
                </Group>
            </Modal>

            <Box onClick={open}>
                {targetComponent}
            </Box>
        </>
    )
}