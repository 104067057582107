import {keyState} from "../useKey";

/**
 * studioNavigate
 */
export function studioNavigate(navigate, target = "_self") {

    if(target === "_self") {
        navigate(`/studio`, {state: {...keyState()}});
    }
    else {
        window.open(`/studio`, target);
    }
}

/**
 * studioProjectNavigate
 */
export function studioProjectNavigate(navigate, projectId, replace = false, target = "_self") {

    if(target === "_self") {
        navigate(`/studio/${projectId}`, {replace: replace, state: {...keyState()}});
    }
    else {
        window.open(`/studio/${projectId}`, "_self");
    }
}

/**
 * studioNewProjectNavigate
 */
export function studioNewProjectNavigate(navigate, projectId, entityId, ingredientLabel, ingredientName) {
    navigate(`/studio/${projectId}`, {state: {...keyState(), entityId: entityId, ingredientLabel: ingredientLabel, ingredientName: ingredientName}});
}