import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    ActionIcon, Anchor,
    Box,
    Button,
    Checkbox, Divider,
    Group,
    Modal,
    Space,
    Stack,
    Text,
    Tooltip, UnstyledButton, useMantineTheme
} from '@mantine/core';
import classes from './LanguageSelector.module.css';
import {Icon} from "../icons/Icons";
import {useDisclosure, useLocalStorage, useTimeout} from "@mantine/hooks";
import {useLoadingContext} from "../loading/LoadingContext";
import {CustomMenu} from "../menu/CustomMenu";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSquare, faSquareCheck} from "@fortawesome/free-regular-svg-icons";
import {create} from 'zustand';
import {SimpleBox} from "../simpleBox/SimpleBox";
import useMedia from "../../pages/useMedia";
import useSendMail from "../../pages/useSendMail";

/**
 * useLanguageStore
 */
export const useLanguageStore = create((set) => ({

    languageEnglishDescriptionsEnabled: localStorage.getItem('languageEnglishDescriptionsEnabled') === null ? true : localStorage.getItem('languageEnglishDescriptionsEnabled') === 'true',
    setLanguageEnglishDescriptionsEnabled: (languageEnglishDescriptionsEnabled) => {
        set({ languageEnglishDescriptionsEnabled });
        localStorage.setItem('languageEnglishDescriptionsEnabled', languageEnglishDescriptionsEnabled);
    },
}));

/**
 * LanguageBanner
 */
export const LanguageBanner = ({color = "tertiary"}) => {

    const {i18n } = useTranslation();
    const [left] = useState(Math.random() < 0.6);

    const LanguageIcon = () => <Icon name={`language-en`} className={classes.bannersvg} style={{fill: `var(--mantine-color-${color}-6)`}}/>

    return <SimpleBox color={color}>
        <Group justify={"space-between"} align={"center"}>
            {left && <LanguageIcon />}
            <Stack gap={0} flex={1}>
                <Text flex={1} size={"sm"}>
                    {i18n.t("common.languageChangeDescription")}
                </Text>
            </Stack>
            {!left && <LanguageIcon />}
            {/*{left &&*/}
            {/*    <FontAwesomeIcon icon={faUpRightFromSquare} color={`var(--mantine-color-${color}-3)`} style={{width: "18px", height: "18px"}}/>*/}
            {/*}*/}
        </Group>
    </SimpleBox>
}

/**
 * TooltipEn
 *
 * @returns {React.JSX.Element|undefined}
 * @constructor
 */
export const TooltipEn = ({label, labelEn}) => {

    const [tooltipOpened, setTooltipOpened] = useState(false);
    const {i18n } = useTranslation();
    const theme = useMantineTheme();

    const [subject, setSubject] = useState("");
    const [body, setBody] = useState("");
    const [message, setMessage] = useState(i18n.t("common.translationIssue"));

    let languageEnglishDescriptionsEnabled = useLanguageStore((state) => state.languageEnglishDescriptionsEnabled);

    const { start: startTooltipCloseTimeout } = useTimeout(() => {
        setTooltipOpened(false);
        setSubject("");
        setBody("");
        setMessage(i18n.t("common.translationIssue"));
    }, theme.custom.components.languageselector.tooltipEnTimeoutMs);

    const {reset: resetSendEmail, refetch: refetchSendEmail} =
        useSendMail({
            subject: subject,
            body: body,
            onSuccess: () => {
                setMessage(i18n.t("common.translationIssueNotified"));
            }
        })

    useEffect(() => {

        if(subject && body) {
            refetchSendEmail();
        }

    }, [subject, body]);

    /**
     * handleToolTipClick
     */
    function handleToolTipClick(event) {
        event.stopPropagation();
        event.preventDefault();
        setTooltipOpened((o) => !o)
        startTooltipCloseTimeout();
    }

    return i18n.language === "en" || !languageEnglishDescriptionsEnabled ? null :
        <Tooltip color="tertiary"
                 transitionProps={{ transition: 'fade-left', duration: 100 }}
                 position="right-start"
                 offset={{ mainAxis: 4, crossAxis: -4 }}
                 zIndex={403}
                 style={{pointerEvents: "auto", cursor: "pointer"}}
                 p={0}
                 label={
                     <Box p={"7px var(--mantine-spacing-sm)"}
                          bg={"var(--mantine-color-tertiary-6)"}
                          style={{ pointerEvents: 'auto', borderRadius: "var(--mantine-radius-default)",
                              maxWidth: "160px",
                              overflow: "hidden",
                              whiteSpace: "normal",
                              wordBreak: "break-word",
                              overflowWrap: "break-word"
                          }}
                          onClick={(e) => {e.stopPropagation();}}
                     >
                         <Stack gap={0} onClick={() => {
                             setSubject(`[TRANSLATION] ${labelEn} (en) != ${label} (${i18n.language})`);
                             setBody(`${labelEn} (en) != ${label} (${i18n.language})`);
                         }}>
                             <Text size={"sm"} c={"white"} style={{
                                 whiteSpace: "normal",
                                 wordBreak: "break-word",
                                 overflowWrap: "break-word",
                             }}>
                                 {labelEn}
                             </Text>
                             <Text size={"xs"} c={"white"} pt={"xs"} style={{
                                 whiteSpace: "normal",
                                 wordBreak: "break-word",
                                 overflowWrap: "break-word",
                             }}>
                                 {message}
                             </Text>
                         </Stack>
                     </Box>
                 }
                 opened={tooltipOpened}>

            <ActionIcon variant={"transparent"}
                        onClick={handleToolTipClick}>
                <Icon name={"language-en"} style={{marginTop: "-3px", width:"20px", fill: "var(--mantine-color-tertiary-6)"}}/>
                {/*<Box bg={"var(--mantine-color-white)"} style={{position:"absolute", width: "100%", height: "100%"}}/>*/}
            </ActionIcon>
        </Tooltip>
}

/**
 * LanguageIconTitle
 */
export const LanguageIconTitle = (props) => {

    const {textKey = 'common.language', color = "var(--mantine-color-white)", withIcon = true} = props;

    const {i18n} = useTranslation();

    return <Group gap="2" wrap={"nowrap"} {...props}>
        {!withIcon ? null :
            <Icon name={`language-${i18n.language}`} style={{width: "24px", height: "24px", marginLeft: "-3px", marginBottom: "-6px", fill: color}}/>
        }
        <Text pl={withIcon ? 4 : 0} size={"sm"} fw={700}>{i18n.t(textKey)}</Text>
    </Group>
}

/**
 * SelectedLanguageText
 * @param lng
 * @param text
 * @returns {Element}
 * @constructor
 */
export const SelectedLanguageText = ({ lng, text }) => {
    const {i18n} = useTranslation();
    const { t } = useTranslation('translation', { i18n, lng });
    return t(text);
};

/**
 * LanguageSelector
 */
export const LanguageSelector = (props) => {

    const {compact = false} = props;

    const [opened, { open, close }] = useDisclosure(false);

    const [languageStatementAccepted, setLanguageStatementAccepted] = useLocalStorage({
        key: 'languageStatementAccepted',
        defaultValue: false,
    });

    let languageEnglishDescriptionsEnabled = useLanguageStore((state) => state.languageEnglishDescriptionsEnabled);
    const setLanguageEnglishDescriptionsEnabled = useLanguageStore((state) => state.setLanguageEnglishDescriptionsEnabled);

    const {isLoading} = useLoadingContext();

    const [lng, setLng] = useState(undefined);

    const checkboxRef = useRef(null);

    const { isSm } = useMedia();

    const {i18n} = useTranslation();

    /**
     * @param lng
     */
    const handleChangeLanguage = (lng) => {

        setLng(lng);

        // Open message to inform about translations problem due to AI
        if(lng !== "en" && !languageStatementAccepted) {
            open();
        }
        else {
            changeLanguage(lng);
        }
    };

    /**
     * @param lng
     */
    const changeLanguage = (lng) => {

        i18n.changeLanguage(lng)
            .then(value => isLoading(false))
            .then(value => window.location.reload());
    };

    /**
     * LanguageLink
     */
    const LanguageLink = (p) => {

        const {lng = "en", displayText = "English"} = p;

        return (
            <Anchor underline="never" onClick={() => handleChangeLanguage(lng)} {...props} {...p}>
                {displayText}
            </Anchor>
        );
    };

    return (
        <>
            <Modal opened={opened}
                   color={"tertiary"}
                   centered
                   withCloseButton={false}
                   size={"lg"}
                   overlayProps={{color: "var(--mantine-color-tertiary-12)", backgroundOpacity: 0.75, blur: 7}}
                   zIndex={404}
                   onClose={close}
                   classNames={{
                       root: classes.modalroot,
                       header: classes.modalheader,
                       content: classes.modalcontent,
                       inner: classes.modalinner,
                       body: classes.modalbody,
                   }}
            >
                <Modal.Header>
                    <Group justify={"center"} style={{width: "100%", height: "15vh", textAlign: "center"}}>
                        <Icon name={`language-${lng}`} style={{width: "30%", height: "30%", fill: "var(--mantine-color-white)"}} />
                    </Group>
                </Modal.Header>

                <Text c={"white"}>
                    <SelectedLanguageText lng={lng} text={"common.languageChangeDescription"}/>
                </Text>

                <Space h={"lg"}/>

                <Group justify="space-between" pt={"md"}>
                    <Checkbox
                        color={"tertiary"}
                        ref={checkboxRef}
                        label={<Text size={"sm"} c={"white"}><SelectedLanguageText lng={lng} text={"common.doNotShowAgain"}/></Text>}
                    />
                    <Group justify={"space-between"} w={!isSm ? "auto" : "100%"}  pt={"md"}>
						<Button variant={"light"} color={"tertiary"} c={"white"} miw={140} onClick={close}><SelectedLanguageText lng={lng} text={"common.cancel"}/></Button>
						<Button color={"tertiary"} miw={140} onClick={() => {
							close();
							if (checkboxRef.current) {
								setLanguageStatementAccepted(checkboxRef.current.checked);
							}
							changeLanguage(lng)
						}}>
							<SelectedLanguageText lng={lng} text={"common.gotIt"}/>
						 </Button>
                    </Group>
                </Group>
            </Modal>

            {compact ?
                <>
                    {i18n.language !== "en" &&
                        <LanguageLink lng={"en"} displayText={"English"} pb={"xs"}/>
                    }
                    <Text size={"xs"} c={"white"} opacity={0.5} {...props}>{i18n.t("common.languagePartiallySupported")}</Text>
                    {i18n.language !== "de" &&
                        <LanguageLink lng={"de"} displayText={"Deutsch"}/>
                    }
                    {i18n.language !== "it" &&
                        <LanguageLink lng={"it"} displayText={"Italiano"}/>
                    }
                    {i18n.language !== "es" &&
                        <LanguageLink lng={"es"} displayText={"Español"}/>
                    }
                    {i18n.language !== "fr" &&
                        <LanguageLink lng={"fr"} displayText={"Français"}/>
                    }
                    {i18n.language !== "pt" &&
                        <LanguageLink lng={"pt"} displayText={"Português"}/>
                    }

                    <UnstyledButton disabled={i18n.language === "en"} opacity={i18n.language === "en" ? 0.5 : 1} onClick={() => setLanguageEnglishDescriptionsEnabled(!languageEnglishDescriptionsEnabled)}>
                        <Group wrap={"nowrap"} gap={8} {...props}>
                            <FontAwesomeIcon icon={languageEnglishDescriptionsEnabled ? faSquareCheck : faSquare} style={{paddingBottom: "1px", color: "var(--mantine-color-white)"}}/>
                            <Text size={"xs"} className={classes.anchor}>{i18n.t("common.enableEnglishDescriptions")}</Text>
                        </Group>
                    </UnstyledButton>
                </>
            :
                <CustomMenu>

                    <CustomMenu.Target style={{cursor: 'pointer'}}>
                        <Button variant={"transparent"} p={0} className={classes.menutarget}>
                            <Icon name={`language-${i18n.language}`} />
                        </Button>
                    </CustomMenu.Target>

                    <CustomMenu.Dropdown>
                        {i18n.language === "en" ? null :
                            <CustomMenu.Item key={'en'} onClick={() => handleChangeLanguage('en')}>
                                <Text className={classes.anchor}>English</Text>
                            </CustomMenu.Item>
                        }
                        <Text size={"xs"} c={"white"} pt={"sm"} pb={"sm"} opacity={0.5}>{i18n.t("common.languagePartiallySupported")}</Text>
                        {i18n.language === "de" ? null :
                            <CustomMenu.Item key={'de'} onClick={() => handleChangeLanguage('de')}>
                                <Text className={classes.anchor}>Deutsch</Text>
                            </CustomMenu.Item>
                        }
                        {i18n.language === "it" ? null :
                            <CustomMenu.Item key={'it'}  onClick={() => handleChangeLanguage('it')}>
                                <Text className={classes.anchor}>Italiano</Text>
                            </CustomMenu.Item>
                        }
                        {i18n.language === "es" ? null :
                            <CustomMenu.Item key={'es'} onClick={() => handleChangeLanguage('es')}>
                                <Text className={classes.anchor}>Español</Text>
                            </CustomMenu.Item>
                        }
                        {i18n.language === "fr" ? null :
                            <CustomMenu.Item key={'fr'} onClick={() => handleChangeLanguage('fr')}>
                                <Text className={classes.anchor}>Français</Text>
                            </CustomMenu.Item>
                        }
                        {i18n.language === "pt" ? null :
                            <CustomMenu.Item key={'pt'} onClick={() => handleChangeLanguage('pt')}>
                                <Text className={classes.anchor}>Português</Text>
                            </CustomMenu.Item>
                        }
                        <CustomMenu.Item key={'not-en'}
                                         disabled={i18n.language === "en"}
                                         onClick={() => setLanguageEnglishDescriptionsEnabled(!languageEnglishDescriptionsEnabled)}>
                            <Divider color={"white"} variant="dashed" ml={"-md"} mr={"-md"}/>
                            <Group wrap={"nowrap"} gap={8} pt={"md"}>
                                <FontAwesomeIcon icon={languageEnglishDescriptionsEnabled ? faSquareCheck : faSquare} style={{paddingBottom: "1px", color: "var(--mantine-color-white)"}}/>
                                <Text size={"xs"} className={classes.anchor}>{i18n.t("common.enableEnglishDescriptions")}</Text>
                            </Group>
                        </CustomMenu.Item>
                    </CustomMenu.Dropdown>

                </CustomMenu>
            }
        </>
    );
}